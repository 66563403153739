import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { getUser } from "../redux/clientConfiguration/action";

class Banner extends Component {
  render() {
    const { data, title, subtitle } = this.props || {};
    return (
      <div>
        <div className="page-banner">
          <div className="container">
            <div className="column">
              <img src="img/scjp-logo.png" style={{ height: "60px" }} />
            </div>
          </div>
        </div>
        <div className="page-title section-grey section-green lp-banner">
          <div className="container lp-content">
            <h1 style={{ textAlign: "center" }}>
              {title || "Compatibility Selector Tool"}
            </h1>
            <p>
              {subtitle ||
                "Let us help find the right SC Johnson Professional® Products to Protect, Cleanse, Sanitize and Restore."}
            </p>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  data: state.users.data,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getUser }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Banner);
