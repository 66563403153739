import React, { Component } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getUser, changing } from "../redux/clientConfiguration/action";
import Footer from "../components/footer";
import Banner from "../components/banner";
import axios from "axios";
import history from '../../src/history';

class Contact extends Component {
  componentDidMount() {
    //this.props.getUser();
  }

  render() {
    const { data, selected, arrConPass } = this.props || {};

    // const phoneRegex = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
    const zipRegex = /^\d{5}(?:[-\s]\d{4})?$/;
    console.log("arrConPass", arrConPass);

    //console.log("ddddddd", this.props.location.state)

    return (
      <div>
        <Banner />
        <div className="container padding-bottom-3x mb-2">
          <Formik
            initialValues={{
              email: "",
              firstName: "",
              lastName: "",
              company: "",
              country: "",
              email: "",
              phone: "",
              comments:"",
              optinemail: false,
              protect1: arrConPass.includes("Stokoderm® Advanced"),
              protect2: arrConPass.includes("Stokoderm® Grip PURE"),
              protect3: arrConPass.includes("Travabon® Classic"),
              protect4: arrConPass.includes("Stokoderm® Aqua PURE"),
              protect5: arrConPass.includes("Stokoderm® Sun Protect 30 PURE"),
              cleanse1: arrConPass.includes("Refresh™ Clear FOAM (UL, GS)"),
              cleanse2: arrConPass.includes(
                "Refresh™ Luxury 3-in-1 Hair & Body"
              ),
              cleanse3: arrConPass.includes("AgroBac Pure FOAM"),
              cleanse4: arrConPass.includes("Estesol® Lotion PURE (UL)"),
              cleanse5: arrConPass.includes("Solopol® Classic (USDA)"),
              cleanse6: arrConPass.includes("Kresto® kwik wipes®"),
              cleanse7: arrConPass.includes("Kresto® Heritage (UL)"),
              cleanse8: arrConPass.includes("Kresto® Classic"),
              cleanse9: arrConPass.includes("Kresto® Cherry Wipes"),
              sanitize1: arrConPass.includes("InstantFOAM™ (USDA, UL)"),
              sanitize2: arrConPass.includes(
                "InstantFOAM™ Complete (USDA, UL)"
              ),
              sanitize3: arrConPass.includes("InstantFOAM™ Non-Alcohol PURE"),
              restore1: arrConPass.includes("Stokolan® Light PURE (USDA)"),
              restore2: arrConPass.includes("SBS® 40 (USDA)"),
              restore3: arrConPass.includes("Stokolan® Light GEL"),
              restore4: arrConPass.includes("Stokolan® Hand & Body")
            }}
            onSubmit={async values => {
              await new Promise(resolve => setTimeout(resolve, 500));
              {/* alert(JSON.stringify(values, null, 2)); */}
              let prods = "";
              if (values.protect1) prods += "Stokoderm Advanced;";
              if (values.protect2) prods+="Stokoderm Grip PURE;";
              if (values.protect3) prods+="Travabon Classic;";
              if (values.protect4) prods+="Stokoderm Aqua PURE;";
              if (values.protect5)
                prods+="Stokoderm Sun Protect 30 PURE;";
              if (values.cleanse1) prods+="Refresh Clear FOAM (UL, GS);";
              if (values.cleanse2)
                prods+="Refresh Luxury 3-in-1 Hair Body;";
              if (values.cleanse3) prods+="AgroBac Pure FOAM;";
              if (values.cleanse4) prods+="Estesol Lotion PURE (UL);";
              if (values.cleanse5) prods+="Solopol Classic (USDA);";
              if (values.cleanse6) prods+="Kresto kwik-wipes;";
              if (values.cleanse7) prods+="Kresto Heritage (UL);";
              if (values.cleanse8) prods+="Kresto Classic;";
              if (values.cleanse9) prods+="Kresto Cherry Wipes;";
              if (values.sanitize1) prods+="InstantFOAM (USDA, UL);";
              if (values.sanitize2)
                prods+="InstantFOAM Complete (USDA, UL);";
              if (values.sanitize3)
                prods+="InstantFOAM Non-Alcohol PURE;";
              if (values.restore1) prods+="Stokolan Light PURE (USDA);";
              if (values.restore2) prods+="SBS 40 (USDA);";
              if (values.restore3) prods+="Stokolan Light GEL;";
              if (values.restore4) prods+="Stokolan Hand Body;";
              console.log("prods" , prods)
       
                axios.post(
                "https://forms.hubspot.com/uploads/form/v2/2647097/c8d11f54-b2bd-41c3-b6c1-fb3c15bcd8e9?firstname=" +
                  values.firstName +
                  "&lastname=" +
                  values.lastName +
                  "&company=" +
                  values.company +
                  "&country="+values.country+"&email=" +
                  values.email +
                  "&phone=" +
                  values.phone +
                  "&these_are_the_products_that_best_fit_your_needs=" +
                  prods +
                  "&questions_comments=" +
                  values.comments +
                  "&opt_in_confirmation="+
                  values.optinemail 
              );
              history.push("/thank-you")
            }}
             validationSchema={Yup.object().shape({
              email: Yup.string()
                .email()
                .required("Required"),
              firstName: Yup.string().required("Required"),
              lastName: Yup.string().required("Required"),
              
            })} 
          >
            {props => {
              const {
                values,
                touched,
                errors,
                dirty,
                isSubmitting,
                handleChange,
                handleBlur,
                handleSubmit,
                handleReset,
                setFieldValue
              } = props;

              return (
                <Form onSubmit={handleSubmit}>
                  <div className="container padding-bottom-3x mb-2">
                    <div className="row justify-content-center">
                      <div className="col-xl-10 col-lg-8">
                        <div className="form-section-title">
                          <h3>Tell Us About You</h3>
                          <hr className="padding-bottom-1x" />
                        </div>
                        <div className="row">
                          <div className="col-sm-6">
                            <div className="form-group">
                              <label for="firstName">
                                <sup
                                  className="required"
                                  style={{ color: "red" }}
                                >
                                  *
                                </sup>{" "}
                                First Name{" "}
                              </label>
                              <input
                                className="form-control form-control-square"
                                type="text"
                                id="firstName"
                                placeholder=""
                                value={values.firstName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors.firstName &&
                                touched.firstName && (
                                  <div className="input-feedback" stye={{color: "red !important"}}>
                                    {errors.firstName}
                                  </div>
                                )}
                            </div>
                          </div>

                          <div className="col-sm-6">
                            <div className="form-group">
                              <label for="lastName">
                                <sup
                                  className="required"
                                  style={{ color: "red" }}
                                >
                                  *
                                </sup>{" "}
                                Last Name&nbsp;
                              </label>
                              <input
                                className="form-control form-control-square"
                                type="text"
                                id="lastName"
                                placeholder=""
                                value={values.lastName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors.lastName &&
                                touched.lastName && (
                                  <div className="input-feedback"  stye={{color: "red !important"}}>
                                    {errors.lastName}
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-6">
                            <div className="form-group">
                              <label for="middleName">Company</label>
                              <input
                                className="form-control form-control-square"
                                type="text"
                                id="company"
                                placeholder=""
                                value={values.company}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors.company &&
                                touched.company && (
                                  <div className="input-feedback"  stye={{color: "red !important"}}>
                                    {errors.company}
                                  </div>
                                )}
                            </div>
                          </div>

                          <div className="col-sm-4">
                            <div className="form-group">
                              <label for="country">
                                Country
                              </label>
                              <select
                                className="form-control form-control-square"
                                id="country"
                                value={values.country}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              >
                                <option value="United States">United States</option>
                                <option value="Afganistan">Afghanistan</option>
   <option value="Albania">Albania</option>
   <option value="Algeria">Algeria</option>
   <option value="American Samoa">American Samoa</option>
   <option value="Andorra">Andorra</option>
   <option value="Angola">Angola</option>
   <option value="Anguilla">Anguilla</option>
   <option value="Antigua & Barbuda">Antigua & Barbuda</option>
   <option value="Argentina">Argentina</option>
   <option value="Armenia">Armenia</option>
   <option value="Aruba">Aruba</option>
   <option value="Australia">Australia</option>
   <option value="Austria">Austria</option>
   <option value="Azerbaijan">Azerbaijan</option>
   <option value="Bahamas">Bahamas</option>
   <option value="Bahrain">Bahrain</option>
   <option value="Bangladesh">Bangladesh</option>
   <option value="Barbados">Barbados</option>
   <option value="Belarus">Belarus</option>
   <option value="Belgium">Belgium</option>
   <option value="Belize">Belize</option>
   <option value="Benin">Benin</option>
   <option value="Bermuda">Bermuda</option>
   <option value="Bhutan">Bhutan</option>
   <option value="Bolivia">Bolivia</option>
   <option value="Bonaire">Bonaire</option>
   <option value="Bosnia & Herzegovina">Bosnia & Herzegovina</option>
   <option value="Botswana">Botswana</option>
   <option value="Brazil">Brazil</option>
   <option value="British Indian Ocean Ter">British Indian Ocean Ter</option>
   <option value="Brunei">Brunei</option>
   <option value="Bulgaria">Bulgaria</option>
   <option value="Burkina Faso">Burkina Faso</option>
   <option value="Burundi">Burundi</option>
   <option value="Cambodia">Cambodia</option>
   <option value="Cameroon">Cameroon</option>
   <option value="Canada">Canada</option>
   <option value="Canary Islands">Canary Islands</option>
   <option value="Cape Verde">Cape Verde</option>
   <option value="Cayman Islands">Cayman Islands</option>
   <option value="Central African Republic">Central African Republic</option>
   <option value="Chad">Chad</option>
   <option value="Channel Islands">Channel Islands</option>
   <option value="Chile">Chile</option>
   <option value="China">China</option>
   <option value="Christmas Island">Christmas Island</option>
   <option value="Cocos Island">Cocos Island</option>
   <option value="Colombia">Colombia</option>
   <option value="Comoros">Comoros</option>
   <option value="Congo">Congo</option>
   <option value="Cook Islands">Cook Islands</option>
   <option value="Costa Rica">Costa Rica</option>
   <option value="Cote DIvoire">Cote DIvoire</option>
   <option value="Croatia">Croatia</option>
   <option value="Cuba">Cuba</option>
   <option value="Curaco">Curacao</option>
   <option value="Cyprus">Cyprus</option>
   <option value="Czech Republic">Czech Republic</option>
   <option value="Denmark">Denmark</option>
   <option value="Djibouti">Djibouti</option>
   <option value="Dominica">Dominica</option>
   <option value="Dominican Republic">Dominican Republic</option>
   <option value="East Timor">East Timor</option>
   <option value="Ecuador">Ecuador</option>
   <option value="Egypt">Egypt</option>
   <option value="El Salvador">El Salvador</option>
   <option value="Equatorial Guinea">Equatorial Guinea</option>
   <option value="Eritrea">Eritrea</option>
   <option value="Estonia">Estonia</option>
   <option value="Ethiopia">Ethiopia</option>
   <option value="Falkland Islands">Falkland Islands</option>
   <option value="Faroe Islands">Faroe Islands</option>
   <option value="Fiji">Fiji</option>
   <option value="Finland">Finland</option>
   <option value="France">France</option>
   <option value="French Guiana">French Guiana</option>
   <option value="French Polynesia">French Polynesia</option>
   <option value="French Southern Ter">French Southern Ter</option>
   <option value="Gabon">Gabon</option>
   <option value="Gambia">Gambia</option>
   <option value="Georgia">Georgia</option>
   <option value="Germany">Germany</option>
   <option value="Ghana">Ghana</option>
   <option value="Gibraltar">Gibraltar</option>
   <option value="Great Britain">Great Britain</option>
   <option value="Greece">Greece</option>
   <option value="Greenland">Greenland</option>
   <option value="Grenada">Grenada</option>
   <option value="Guadeloupe">Guadeloupe</option>
   <option value="Guam">Guam</option>
   <option value="Guatemala">Guatemala</option>
   <option value="Guinea">Guinea</option>
   <option value="Guyana">Guyana</option>
   <option value="Haiti">Haiti</option>
   <option value="Hawaii">Hawaii</option>
   <option value="Honduras">Honduras</option>
   <option value="Hong Kong">Hong Kong</option>
   <option value="Hungary">Hungary</option>
   <option value="Iceland">Iceland</option>
   <option value="Indonesia">Indonesia</option>
   <option value="India">India</option>
   <option value="Iran">Iran</option>
   <option value="Iraq">Iraq</option>
   <option value="Ireland">Ireland</option>
   <option value="Isle of Man">Isle of Man</option>
   <option value="Israel">Israel</option>
   <option value="Italy">Italy</option>
   <option value="Jamaica">Jamaica</option>
   <option value="Japan">Japan</option>
   <option value="Jordan">Jordan</option>
   <option value="Kazakhstan">Kazakhstan</option>
   <option value="Kenya">Kenya</option>
   <option value="Kiribati">Kiribati</option>
   <option value="Korea North">Korea North</option>
   <option value="Korea Sout">Korea South</option>
   <option value="Kuwait">Kuwait</option>
   <option value="Kyrgyzstan">Kyrgyzstan</option>
   <option value="Laos">Laos</option>
   <option value="Latvia">Latvia</option>
   <option value="Lebanon">Lebanon</option>
   <option value="Lesotho">Lesotho</option>
   <option value="Liberia">Liberia</option>
   <option value="Libya">Libya</option>
   <option value="Liechtenstein">Liechtenstein</option>
   <option value="Lithuania">Lithuania</option>
   <option value="Luxembourg">Luxembourg</option>
   <option value="Macau">Macau</option>
   <option value="Macedonia">Macedonia</option>
   <option value="Madagascar">Madagascar</option>
   <option value="Malaysia">Malaysia</option>
   <option value="Malawi">Malawi</option>
   <option value="Maldives">Maldives</option>
   <option value="Mali">Mali</option>
   <option value="Malta">Malta</option>
   <option value="Marshall Islands">Marshall Islands</option>
   <option value="Martinique">Martinique</option>
   <option value="Mauritania">Mauritania</option>
   <option value="Mauritius">Mauritius</option>
   <option value="Mayotte">Mayotte</option>
   <option value="Mexico">Mexico</option>
   <option value="Midway Islands">Midway Islands</option>
   <option value="Moldova">Moldova</option>
   <option value="Monaco">Monaco</option>
   <option value="Mongolia">Mongolia</option>
   <option value="Montserrat">Montserrat</option>
   <option value="Morocco">Morocco</option>
   <option value="Mozambique">Mozambique</option>
   <option value="Myanmar">Myanmar</option>
   <option value="Nambia">Nambia</option>
   <option value="Nauru">Nauru</option>
   <option value="Nepal">Nepal</option>
   <option value="Netherland Antilles">Netherland Antilles</option>
   <option value="Netherlands">Netherlands (Holland, Europe)</option>
   <option value="Nevis">Nevis</option>
   <option value="New Caledonia">New Caledonia</option>
   <option value="New Zealand">New Zealand</option>
   <option value="Nicaragua">Nicaragua</option>
   <option value="Niger">Niger</option>
   <option value="Nigeria">Nigeria</option>
   <option value="Niue">Niue</option>
   <option value="Norfolk Island">Norfolk Island</option>
   <option value="Norway">Norway</option>
   <option value="Oman">Oman</option>
   <option value="Pakistan">Pakistan</option>
   <option value="Palau Island">Palau Island</option>
   <option value="Palestine">Palestine</option>
   <option value="Panama">Panama</option>
   <option value="Papua New Guinea">Papua New Guinea</option>
   <option value="Paraguay">Paraguay</option>
   <option value="Peru">Peru</option>
   <option value="Phillipines">Philippines</option>
   <option value="Pitcairn Island">Pitcairn Island</option>
   <option value="Poland">Poland</option>
   <option value="Portugal">Portugal</option>
   <option value="Puerto Rico">Puerto Rico</option>
   <option value="Qatar">Qatar</option>
   <option value="Republic of Montenegro">Republic of Montenegro</option>
   <option value="Republic of Serbia">Republic of Serbia</option>
   <option value="Reunion">Reunion</option>
   <option value="Romania">Romania</option>
   <option value="Russia">Russia</option>
   <option value="Rwanda">Rwanda</option>
   <option value="St Barthelemy">St Barthelemy</option>
   <option value="St Eustatius">St Eustatius</option>
   <option value="St Helena">St Helena</option>
   <option value="St Kitts-Nevis">St Kitts-Nevis</option>
   <option value="St Lucia">St Lucia</option>
   <option value="St Maarten">St Maarten</option>
   <option value="St Pierre & Miquelon">St Pierre & Miquelon</option>
   <option value="St Vincent & Grenadines">St Vincent & Grenadines</option>
   <option value="Saipan">Saipan</option>
   <option value="Samoa">Samoa</option>
   <option value="Samoa American">Samoa American</option>
   <option value="San Marino">San Marino</option>
   <option value="Sao Tome & Principe">Sao Tome & Principe</option>
   <option value="Saudi Arabia">Saudi Arabia</option>
   <option value="Senegal">Senegal</option>
   <option value="Seychelles">Seychelles</option>
   <option value="Sierra Leone">Sierra Leone</option>
   <option value="Singapore">Singapore</option>
   <option value="Slovakia">Slovakia</option>
   <option value="Slovenia">Slovenia</option>
   <option value="Solomon Islands">Solomon Islands</option>
   <option value="Somalia">Somalia</option>
   <option value="South Africa">South Africa</option>
   <option value="Spain">Spain</option>
   <option value="Sri Lanka">Sri Lanka</option>
   <option value="Sudan">Sudan</option>
   <option value="Suriname">Suriname</option>
   <option value="Swaziland">Swaziland</option>
   <option value="Sweden">Sweden</option>
   <option value="Switzerland">Switzerland</option>
   <option value="Syria">Syria</option>
   <option value="Tahiti">Tahiti</option>
   <option value="Taiwan">Taiwan</option>
   <option value="Tajikistan">Tajikistan</option>
   <option value="Tanzania">Tanzania</option>
   <option value="Thailand">Thailand</option>
   <option value="Togo">Togo</option>
   <option value="Tokelau">Tokelau</option>
   <option value="Tonga">Tonga</option>
   <option value="Trinidad & Tobago">Trinidad & Tobago</option>
   <option value="Tunisia">Tunisia</option>
   <option value="Turkey">Turkey</option>
   <option value="Turkmenistan">Turkmenistan</option>
   <option value="Turks & Caicos Is">Turks & Caicos Is</option>
   <option value="Tuvalu">Tuvalu</option>
   <option value="Uganda">Uganda</option>
   <option value="United Kingdom">United Kingdom</option>
   <option value="Ukraine">Ukraine</option>
   <option value="United Arab Erimates">United Arab Emirates</option>
   <option value="United States of America">United States of America</option>
   <option value="Uraguay">Uruguay</option>
   <option value="Uzbekistan">Uzbekistan</option>
   <option value="Vanuatu">Vanuatu</option>
   <option value="Vatican City State">Vatican City State</option>
   <option value="Venezuela">Venezuela</option>
   <option value="Vietnam">Vietnam</option>
   <option value="Virgin Islands (Brit)">Virgin Islands (Brit)</option>
   <option value="Virgin Islands (USA)">Virgin Islands (USA)</option>
   <option value="Wake Island">Wake Island</option>
   <option value="Wallis & Futana Is">Wallis & Futana Is</option>
   <option value="Yemen">Yemen</option>
   <option value="Zaire">Zaire</option>
   <option value="Zambia">Zambia</option>
   <option value="Zimbabwe">Zimbabwe</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-6">
                            <div className="form-group">
                              <label for="email">
                                <sup
                                  className="required"
                                  style={{ color: "red" }}
                                >
                                  *
                                </sup>{" "}
                                Email Address
                              </label>
                              <input
                                className="form-control form-control-square"
                                type="text"
                                id="email"
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors.email &&
                                touched.email && (
                                  <div className="input-feedback" stye={{color: "red !important"}}>
                                    {errors.email}
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="form-group">
                              <label for="phone">Business Phone Number</label>
                              <input
                                className="form-control form-control-square"
                                type="text"
                                id="phone"
                                value=""
                                value={values.phone}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form-section-title">
                          <h3>
                            These Are the Products That Best Fit Your
                            Application
                          </h3>
                          <hr className="padding-bottom-1x" />
                        </div>
                        <div className="row">
                          <div className="col-sm-3 form-group">
                            <h5>Protect</h5>
                            <div className="custom-control custom-checkbox">
                              <input
                                className="custom-control-input"
                                type="checkbox"
                                id="protect1"
                                checked={values.protect1}
                                value={values.protect1}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <label
                                className="custom-control-label"
                                for="protect1"
                              >
                                Stokoderm Advanced
                              </label>
                            </div>
                            <div className="custom-control custom-checkbox">
                              <input
                                className="custom-control-input"
                                type="checkbox"
                                id="protect2"
                                checked={values.protect2}
                                value={values.protect2}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <label
                                className="custom-control-label"
                                for="protect2"
                              >
                                Stokoderm Grip PURE
                              </label>
                            </div>
                            <div className="custom-control custom-checkbox">
                              <input
                                className="custom-control-input"
                                type="checkbox"
                                id="protect3"
                                checked={values.protect3}
                                value={values.protect3}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <label
                                className="custom-control-label"
                                for="protect3"
                              >
                                Travabon Classic
                              </label>
                            </div>
                            <div className="custom-control custom-checkbox">
                              <input
                                className="custom-control-input"
                                type="checkbox"
                                id="protect4"
                                value={values.protect4}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                checked={values.protect4}
                              />
                              <label
                                className="custom-control-label"
                                for="protect4"
                              >
                                Stokoderm Aqua PURE
                              </label>
                            </div>
                            <div className="custom-control custom-checkbox">
                              <input
                                className="custom-control-input"
                                type="checkbox"
                                id="protect5"
                                value={values.protect5}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                checked={values.protect5}
                              />
                              <label
                                className="custom-control-label"
                                for="protect5"
                              >
                                Stokoderm Sun Protect 30 PURE
                              </label>
                            </div>
                          </div>
                          <div className="col-sm-3 form-group">
                            <h5>Cleanse</h5>
                            <div className="custom-control custom-checkbox">
                              <input
                                className="custom-control-input"
                                type="checkbox"
                                id="cleanse1"
                                value={values.cleanse1}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                checked={values.cleanse1}
                              />
                              <label
                                className="custom-control-label"
                                for="cleanse1"
                              >
                                Refresh Clear FOAM (UL, GS)
                              </label>
                            </div>
                            <div className="custom-control custom-checkbox">
                              <input
                                className="custom-control-input"
                                type="checkbox"
                                id="cleanse2"
                                value={values.cleanse2}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                checked={values.cleanse2}
                              />
                              <label
                                className="custom-control-label"
                                for="cleanse2"
                              >
                                Refresh Luxury 3-in-1 Hair &amp; Body
                              </label>
                            </div>
                            <div className="custom-control custom-checkbox">
                              <input
                                className="custom-control-input"
                                type="checkbox"
                                id="cleanse3"
                                value={values.cleanse3}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                checked={values.cleanse3}
                              />
                              <label
                                className="custom-control-label"
                                for="cleanse3"
                              >
                                AgroBac Pure FOAM
                              </label>
                            </div>
                            <div className="custom-control custom-checkbox">
                              <input
                                className="custom-control-input"
                                type="checkbox"
                                id="cleanse4"
                                value={values.cleanse4}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                checked={values.cleanse4}
                              />
                              <label
                                className="custom-control-label"
                                for="cleanse4"
                              >
                                Estesol Lotion PURE (UL)
                              </label>
                            </div>
                            <div className="custom-control custom-checkbox">
                              <input
                                className="custom-control-input"
                                type="checkbox"
                                id="cleanse5"
                                value={values.cleanse5}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                checked={values.cleanse5}
                              />
                              <label
                                className="custom-control-label"
                                for="cleanse5"
                              >
                                Solopol Classic (USDA)
                              </label>
                            </div>
                            <div className="custom-control custom-checkbox">
                              <input
                                className="custom-control-input"
                                type="checkbox"
                                id="cleanse6"
                                value={values.cleanse6}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                checked={values.cleanse6}
                              />
                              <label
                                className="custom-control-label"
                                for="cleanse6"
                              >
                                Kresto kwik-wipes
                              </label>
                            </div>
                            <div className="custom-control custom-checkbox">
                              <input
                                className="custom-control-input"
                                type="checkbox"
                                id="cleanse7"
                                value={values.cleanse7}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                checked={values.cleanse7}
                              />
                              <label
                                className="custom-control-label"
                                for="cleanse7"
                              >
                                Kresto Heritage (UL)
                              </label>
                            </div>
                            <div className="custom-control custom-checkbox">
                              <input
                                className="custom-control-input"
                                type="checkbox"
                                id="cleanse8"
                                value={values.cleanse8}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                checked={values.cleanse8}
                              />
                              <label
                                className="custom-control-label"
                                for="cleanse8"
                              >
                                Kresto Classic
                              </label>
                            </div>
                            <div className="custom-control custom-checkbox">
                              <input
                                className="custom-control-input"
                                type="checkbox"
                                id="cleanse9"
                                value={values.cleanse9}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                checked={values.cleanse9}
                              />
                              <label
                                className="custom-control-label"
                                for="cleanse9"
                              >
                                Kresto Cherry Wipes
                              </label>
                            </div>
                          </div>
                          <div className="col-sm-3 form-group">
                            <h5>Sanitize</h5>
                            <div className="custom-control custom-checkbox">
                              <input
                                className="custom-control-input"
                                type="checkbox"
                                id="sanitize1"
                                value={values.sanitize1}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                checked={values.sanitize1}
                              />
                              <label
                                className="custom-control-label"
                                for="sanitize1"
                              >
                                InstantFOAM (USDA, UL)
                              </label>
                            </div>
                            <div className="custom-control custom-checkbox">
                              <input
                                className="custom-control-input"
                                type="checkbox"
                                id="sanitize2"
                                value={values.sanitize2}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                checked={values.sanitize2}
                              />
                              <label
                                className="custom-control-label"
                                for="sanitize2"
                              >
                                InstantFOAM Complete (USDA, UL)
                              </label>
                            </div>
                            <div className="custom-control custom-checkbox">
                              <input
                                className="custom-control-input"
                                type="checkbox"
                                id="sanitize3"
                                value={values.sanitize3}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                checked={values.sanitize3}
                              />
                              <label
                                className="custom-control-label"
                                for="sanitize3"
                              >
                                InstantFOAM Non-Alcohol PURE
                              </label>
                            </div>
                          </div>
                          <div className="col-sm-3 form-group">
                            <h5>Restore</h5>
                            <div className="custom-control custom-checkbox">
                              <input
                                className="custom-control-input"
                                type="checkbox"
                                id="restore1"
                                value={values.restore1}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                checked={values.restore1}
                              />
                              <label
                                className="custom-control-label"
                                for="restore1"
                              >
                                Stokolan Light PURE (USDA)
                              </label>
                            </div>
                            <div className="custom-control custom-checkbox">
                              <input
                                className="custom-control-input"
                                type="checkbox"
                                id="restore2"
                                value={values.restore2}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                checked={values.restore2}
                              />
                              <label
                                className="custom-control-label"
                                for="restore2"
                              >
                                SBS 40 (USDA)
                              </label>
                            </div>
                            <div className="custom-control custom-checkbox">
                              <input
                                className="custom-control-input"
                                type="checkbox"
                                id="restore3"
                                value={values.restore3}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                checked={values.restore3}
                              />
                              <label
                                className="custom-control-label"
                                for="restore3"
                              >
                                Stokolan Light GEL
                              </label>
                            </div>
                            <div className="custom-control custom-checkbox">
                              <input
                                className="custom-control-input"
                                type="checkbox"
                                id="restore4"
                                value={values.restore4}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                checked={values.restore4}
                              />
                              <label
                                className="custom-control-label"
                                for="restore4"
                              >
                                Stokolan Hand &amp; Body
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-12">
                            <div className="form-group">
                              <label for="comments">Questions/Comments</label>
                              <textarea
                                className="form-control form-control-square"
                                id="comments"
                                rows="5"
                                value={values.comments}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-12">
                            <div className="custom-control custom-checkbox">
                              <input
                                className="custom-control-input"
                                type="checkbox"
                                id="optinemail"
                                value={values.optinemail}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <label
                                className="custom-control-label"
                                for="optinemail"
                              >
                                Yes, Please sign me up to receive email
                                communications from SC Johnson Professional®,
                                including information about product, services,
                                news, events, promotions, training, sales,
                                career opportunities, research participation,
                                articles and other general information. By
                                signing up you agree to the{" "}
                                <a href="https://privacy.scjbrands.com/en-us">
                                  Privacy Notice
                                </a>{" "}
                                and{" "}
                                <a href="https://terms.scjbrands.com/en-us">
                                  Terms &amp; Conditions
                                </a>.*
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="form-footer">
                          {/* <Link to="/thank-you">
                        <a className="btn btn-lg btn-rounded btn-primary">Submit</a>
                        </Link> */}
                          <button className="btn btn-lg btn-rounded btn-primary">
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
        <Footer />
      </div>
    );
  }
}
const mapStateToProps = state => ({
  data: state.users.data,
  selected: state.users.selected,
  arrConPass: state.users.arrConPass
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ getUser, changing }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
