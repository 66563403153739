import { SUCCESS_GET_USER, GET_USER, ERROR_GET_USER, CHANGING, UPDATE } from "./action";
import { filter } from "../../pages/form";
import { store } from "../store";
import SCJdata from "../../data/scjdata";

export const initialState = {
  data: SCJdata,
  substance: [],
  selected: {
    //location: "Body",
    level1: false,
    level2: false,
    level3: false,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: true,
    body: true,
    hair: false,
    hands: true,
    glove: true,
    individual: false,
    dispenser: false,
    environmentalCert: false,
    textinput: ""
  },
  arrConPass:[]
};

function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_USER:
      return {
        ...state
      };
    case SUCCESS_GET_USER:
      console.log("data", action.data);
      let apiData = action.data;
      let array = [];
      apiData.map(a => {
        if (!array.includes(a.hazzard.substanceWorkCondition)) {
          array.push(a.hazzard.substanceWorkCondition);
        }
      });
//  console.log("aquiiii",array)
      return {
        ...state,
        data: apiData,
        substance: array
      };
    case ERROR_GET_USER:
      return {
        ...state
      };
      case UPDATE:
      let up = action.data
      return {
        ...state,
        arrConPass : up
      };
    case CHANGING:
      console.log("actionee", action);
      let type = action.ty;
      let obj = { ...state };
      let sel = state.selected;

      for (let [key, value] of Object.entries(sel)) {
        if (key === action.ty) {
          sel[key] = action.data;
        }
      }
      filter(sel);
      return {
        ...state,
        selected: sel
      };
    default:
      return { ...state };
  }
}

export default reducer;
