import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { getUser, update } from "../redux/clientConfiguration/action";
//let arrCon = []
let i = 0
class Banner extends Component {
  
  constructor(props) {
    super(props);
    this.state = { prev: [], ex: this.props.e };
  }
  
  componentDidUpdate(){
    
    
    // arrCon = []
   
  }

  render() {
    const { data, selected, filters, lengthObj, e, arrCon } = this.props || {};
    let objNew = {};
    let objNew1 = {};
    let objNew2 = {};
    let objNew3 = {};
    let objCon = {}
    let prev = this.state.prev;
    let my = [];
    let body = [];
    let face = [];
    let hands = [];
    let gloves = [];
    let some = [];
    filters.hands = true
    let keys = Object.keys(filters);
    console.log(keys[0]);
    //let len = Object.keys(filters).length;
    let len = keys.length;
    let inp = "";
    console.log("kdjdqjqkjej", selected);
    console.log("kdjdqjqkjejuuuuuuu", filters);
    let xx = 0;
    this.props.update(arrCon)
    return (
      <div>
        <div className="form-section-title">
          <h3 style={{ marginBottom: "0px" }}>Compatible Skin Care Products</h3>
          <p>
            Click the product name below to learn more about the suggested
            product.
          </p>
        </div>
        <div className="table-responsive">
          <table className="table table-bordered" id="scjTable">
            <thead>
              <tr>
                <th className="forUseOn">For Use On</th>
                <th className="protect" style={{ width: "22%" }}>
                  Protect
                </th>
                <th className="cleanse" style={{ width: "22%" }}>
                  Cleanse
                </th>
                <th className="sanitize" style={{ width: "22%" }}>
                  Sanitize
                </th>
                <th className="restore" style={{ width: "22%" }}>
                  Restore
                </th>
              </tr>
            </thead>
            <tbody>

              {data.map(a => {
                 a.hands = true
                a.face = true
                a.body = true
                a.glove = true 
                let bol = false;
                if (selected.textinput) {
                  inp = filters.textinput;
                }
                let prev = [];
                for (let i = 0; i < len; i++) {
                  if (
                    keys[i] === "perfume" ||
                    keys[i] === "preservative" ||
                    keys[i] === "dye" ||
                    keys[i] === "silicone"
                  ) {
                    if (!a[keys[i]]) {
                      if (
                      a.hazzard.substanceWorkCondition
                        .toLowerCase()
                        .includes(inp.toLowerCase())
                    )
                      bol = true;
                    } else {
                      bol = false;
                      return
                    }
                  }
                  else if (a[keys[i]]) {
                    if (
                      a.hazzard.substanceWorkCondition
                        .toLowerCase()
                        .includes(inp.toLowerCase())
                    )
                      bol = true;
                  } else if (keys[i] === "textinput") {
                    if (
                      a.hazzard.substanceWorkCondition
                        .toLowerCase()
                        .includes(inp.toLowerCase())
                    ) {
                      bol = true;
                    }
                  } else {
                    bol = false;
                    return;
                  }
                }

                if (bol) {
                  if (!objCon[a.skin_care_product.productName]) {
                      arrCon.push(a.skin_care_product.productName);
                      objCon[a.skin_care_product.productName] = true;
                    }

                  if (a.location.toLowerCase() === "body") {
                    if (!objNew[a.skin_care_product.productName]) {
                      body.push(a);
                      objNew[a.skin_care_product.productName] = true;
                    }
                  }
                  if (a.location.toLowerCase() === "hands") {
                    if (!objNew1[a.skin_care_product.productName]) {
                      hands.push(a);
                      objNew1[a.skin_care_product.productName] = true;
                    }
                  }
                  if (a.location.toLowerCase() === "face") {
                    if (!objNew2[a.skin_care_product.productName]) {
                      face.push(a);

                      objNew2[a.skin_care_product.productName] = true;
                    }
                  }
                  if (a.location.toLowerCase() === "gloves") {
                    if (!objNew3[a.skin_care_product.productName]) {
                      gloves.push(a);
                      objNew3[a.skin_care_product.productName] = true;
                    }
                  }
                }
              })
              }
              {selected.body ? (
                <tr>
                  <td className="forUseOn">Body</td>
                  <td className="protect">
                    {body.map(a => {
                      return a.step === "Protect" ? (
                        <a href={a.skin_care_product.productURL}>
                          {a.skin_care_product.productName}
                          <br />
                        </a>
                      ) : null;
                    })}
                  </td>
                  <td className="cleanse">
                    {body.map(a => {
                      return a.step === "Cleanse" ? (
                        <a href={a.skin_care_product.productURL}>
                          {a.skin_care_product.productName}
                          <br />
                        </a>
                      ) : null;
                    })}
                  </td>
                  <td className="sanitize">
                    {body.map(a => {
                      return a.step === "Sanitize" ? (
                        <a href={a.skin_care_product.productURL}>
                          {a.skin_care_product.productName}
                          <br />
                        </a>
                      ) : null;
                    })}
                  </td>
                  <td className="restore">
                    {body.map(a => {
                      return a.step === "Restore" ? (
                        <a href={a.skin_care_product.productURL}>
                          {a.skin_care_product.productName}
                          <br />
                        </a>
                      ) : null;
                    })}
                  </td>
                </tr>
              ) : null}

              {selected.face ? (
                <tr>
                  <td className="forUseOn">Face</td>
                  <td className="protect">
                    {face.map(a => {
                      return a.step === "Protect" ? (
                        <a href={a.skin_care_product.productURL}>
                          {a.skin_care_product.productName}
                          <br />
                        </a>
                      ) : null;
                    })}
                  </td>
                  <td className="cleanse">
                    {face.map(a => {
                      return a.step === "Cleanse" ? (
                        <a href={a.skin_care_product.productURL}>
                          {a.skin_care_product.productName}
                          <br />
                        </a>
                      ) : null;
                    })}
                  </td>
                  <td className="sanitize">
                    {face.map(a => {
                      return a.step === "Sanitize" ? (
                        <a href={a.skin_care_product.productURL}>
                          {a.skin_care_product.productName}
                          <br />
                        </a>
                      ) : null;
                    })}
                  </td>
                  <td className="restore">
                    {face.map(a => {
                      return a.step === "Restore" ? (
                        <a href={a.skin_care_product.productURL}>
                          {a.skin_care_product.productName}
                          <br />
                        </a>
                      ) : null;
                    })}
                  </td>
                </tr>
              ) : null}
              {selected.hands ? (
                <tr>
                  <td className="forUseOn">Hands</td>
                  <td className="protect">
                    {hands.map(a => {
                      return a.step === "Protect" ? (
                        <a href={a.skin_care_product.productURL}>
                          {a.skin_care_product.productName}
                          <br />
                        </a>
                      ) : null;
                    })}
                  </td>
                  <td className="cleanse">
                    {hands.map(a => {
                      return a.step === "Cleanse" ? (
                        <a href={a.skin_care_product.productURL}>
                          {a.skin_care_product.productName}
                          <br />
                        </a>
                      ) : null;
                    })}
                  </td>
                  <td className="sanitize">
                    {hands.map(a => {
                      return a.step === "Sanitize" ? (
                        <a href={a.skin_care_product.productURL}>
                          {a.skin_care_product.productName}
                          <br />
                        </a>
                      ) : null;
                    })}
                  </td>
                  <td className="restore">
                    {hands.map(a => {
                      return a.step === "Restore" ? (
                        <a href={a.skin_care_product.productURL}>
                          {a.skin_care_product.productName}
                          <br />
                        </a>
                      ) : null;
                    })}
                  </td>
                </tr>
              ) : null}
              
              {selected.glove ? (
                <tr>
                  <td className="forUseOn">Gloves</td>
                  <td className="protect">
                    {gloves.map(a => {
                      return a.step === "Protect" ? (
                        <a href={a.skin_care_product.productURL}>
                          {a.skin_care_product.productName}
                          <br />
                        </a>
                      ) : null;
                    })}
                  </td>
                  <td className="cleanse">
                    {gloves.map(a => {
                      return a.step === "Cleanse" ? (
                        <a href={a.skin_care_product.productURL}>
                          {a.skin_care_product.productName}
                          <br />
                        </a>
                      ) : null;
                    })}
                  </td>
                  <td className="sanitize">
                    {gloves.map(a => {
                      return a.step === "Sanitize" ? (
                        <a href={a.skin_care_product.productURL}>
                          {a.skin_care_product.productName}
                          <br />
                        </a>
                      ) : null;
                    })}
                  </td>
                  <td className="restore">
                    {gloves.map(a => {
                      return a.step === "Restore" ? (
                        <a href={a.skin_care_product.productURL}>
                          {a.skin_care_product.productName}
                          <br />
                        </a>
                      ) : null;
                    })}
                  </td>
                </tr>
              ) : null}
            </tbody>
          </table>
        </div>
        <div className="form-footer">
          <Link to={{pathname:"/contact-us", state: { pass: 'some data' }}}>
            <a className="btn btn-lg btn-rounded btn-primary">Contact Us</a>
          </Link>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  data: state.users.data,
  selected: state.users.selected,
  arrConPass: state.users.arrConPass
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ getUser, update }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Banner);
