import React from "react";
import Format from "./pages/form";
import Contact from "./pages/contact";
import Thanks from "./pages/thanks";
import { Switch, Route } from "react-router-dom";

//import './App.css';

function App() {
  return (
    <div>
      <Switch>
        <Route path="/contact-us">
          <Contact />
        </Route>
        <Route path="/thank-you">
          <Thanks />
        </Route>
        <Route path="/">
          <Format />
        </Route>
      </Switch>
    </div>
  );
}

export default App;
