import React, { Component } from "react";

class Footer extends Component {
  render() {
    return (
      <div>
        <footer
          className="site-footer"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.87) !important" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-12 footerLeft">
                <section className="widget widget-light-skin">
                  <div id="footer-logo"></div>
                </section>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-12">
                <section className="widget widget-links widget-light-skin footerCenter">
                  <p className="text-white">
                    2815 Coliseum Centre Drive
                    <br />
                    Suite 600
                    <br />
                    Charlotte, NC 28217
                    <br />
                    United States
                  </p>
                </section>
              </div>
              <div className="col-lg-4 col-md-12 col-sm-12 footerRight">
                <section
                  className="widget widget-links widget-light-skin"
                  style={{ paddingTop: "12px" }}
                >
                  <ul>
                    <li>
                      <a target="_blank" href="about-scjp.html">
                        About SC Johnson Professional
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="https://www.scjohnson.com/">
                        scjp.com
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="https://terms.scjbrands.com/en-us"
                      >
                        Terms &amp; Conditions
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="https://privacy.scjbrands.com/en-us"
                      >
                        Privacy Notice
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="https://www.scjp.com/en-us/contact"
                      >
                        Contact Us
                      </a>
                    </li>
                  </ul>
                </section>
              </div>

              <hr
                className="hr-light mt-2 margin-bottom-2x"
                style={{ border: "0px" }}
              />

              <p
                className="footer-copyright"
                style={{ textAlign: "center", width: "100%" }}
              >
                © 2022 SC Johnson Professional USA, Inc.{" "}
                <br className="mobile-break" />
                All Rights reserved
              </p>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

export default Footer;
