export const GET_USER = "GET_USER";
export const SUCCESS_GET_USER = "SUCCESS_GET_USER";
export const ERROR_GET_USER = "ERROR_GET_USER";
export const CHANGING = "CHANGING"
export const UPDATE = "UPDATE"

// GET DATA
export const getUser = id => ({ type: GET_USER, id });

// SUCCESS
export const successGetUser = data => ({ type: SUCCESS_GET_USER, data });
export const update = data => ({ type: UPDATE, data });

// ERROR
export const errorGetUser = data => ({ type: ERROR_GET_USER, data });

export const changing = (data, ty) => ({ type: CHANGING, data, ty });


