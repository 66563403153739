const SCJdata = [
  {
    id: 2660,
    hazzard: {
      id: 1,
      substanceWorkCondition: "2-Butanone (MEK)",
      cas: "78-93-3",
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: "oil",
      created_at: "2020-03-02T17:39:32.000Z",
      updated_at: "2020-03-05T14:48:01.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 4,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:42.000Z",
    updated_at: "2020-03-02T22:49:42.000Z"
  },
  {
    id: 2661,
    hazzard: {
      id: 1,
      substanceWorkCondition: "2-Butanone (MEK)",
      cas: "78-93-3",
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: "oil",
      created_at: "2020-03-02T17:39:32.000Z",
      updated_at: "2020-03-05T14:48:01.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 4,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:43.000Z",
    updated_at: "2020-03-02T22:49:43.000Z"
  },
  {
    id: 2662,
    hazzard: {
      id: 2,
      substanceWorkCondition: "Acetone / Dimethyl ketone",
      cas: "67-64-1",
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 4,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:44.000Z",
    updated_at: "2020-03-02T22:49:44.000Z"
  },
  {
    id: 2663,
    hazzard: {
      id: 2,
      substanceWorkCondition: "Acetone / Dimethyl ketone",
      cas: "67-64-1",
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 4,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:44.000Z",
    updated_at: "2020-03-02T22:49:44.000Z"
  },
  {
    id: 2664,
    hazzard: {
      id: 6,
      substanceWorkCondition: "Adhesive, water-soluble",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 4,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:45.000Z",
    updated_at: "2020-03-02T22:49:45.000Z"
  },
  {
    id: 2665,
    hazzard: {
      id: 6,
      substanceWorkCondition: "Adhesive, water-soluble",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 4,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:45.000Z",
    updated_at: "2020-03-02T22:49:45.000Z"
  },
  {
    id: 2666,
    hazzard: {
      id: 8,
      substanceWorkCondition: "Aldehydes, diluted",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 4,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:46.000Z",
    updated_at: "2020-03-02T22:49:46.000Z"
  },
  {
    id: 2667,
    hazzard: {
      id: 8,
      substanceWorkCondition: "Aldehydes, diluted",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 4,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:47.000Z",
    updated_at: "2020-03-02T22:49:47.000Z"
  },
  {
    id: 2668,
    hazzard: {
      id: 9,
      substanceWorkCondition: "Alkaline solutions / lyes / thinned caustics",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 4,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:47.000Z",
    updated_at: "2020-03-02T22:49:47.000Z"
  },
  {
    id: 2669,
    hazzard: {
      id: 9,
      substanceWorkCondition: "Alkaline solutions / lyes / thinned caustics",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 4,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:47.000Z",
    updated_at: "2020-03-02T22:49:47.000Z"
  },
  {
    id: 2670,
    hazzard: {
      id: 10,
      substanceWorkCondition: "Alkaline solutions, diluted",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 4,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:47.000Z",
    updated_at: "2020-03-02T22:49:47.000Z"
  },
  {
    id: 2671,
    hazzard: {
      id: 10,
      substanceWorkCondition: "Alkaline solutions, diluted",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 4,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:47.000Z",
    updated_at: "2020-03-02T22:49:47.000Z"
  },
  {
    id: 2672,
    hazzard: {
      id: 11,
      substanceWorkCondition: "Alkaline solutions, lyes (solvent based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 4,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:47.000Z",
    updated_at: "2020-03-02T22:49:47.000Z"
  },
  {
    id: 2673,
    hazzard: {
      id: 12,
      substanceWorkCondition: "Aluminium",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 4,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:47.000Z",
    updated_at: "2020-03-02T22:49:47.000Z"
  },
  {
    id: 2674,
    hazzard: {
      id: 14,
      substanceWorkCondition: "Antistatics",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 4,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:47.000Z",
    updated_at: "2020-03-02T22:49:47.000Z"
  },
  {
    id: 2675,
    hazzard: {
      id: 19,
      substanceWorkCondition: "Battery acid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 4,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:47.000Z",
    updated_at: "2020-03-02T22:49:47.000Z"
  },
  {
    id: 2676,
    hazzard: {
      id: 19,
      substanceWorkCondition: "Battery acid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 4,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:47.000Z",
    updated_at: "2020-03-02T22:49:47.000Z"
  },
  {
    id: 2677,
    hazzard: {
      id: 22,
      substanceWorkCondition: "Bitumen emulsion",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 4,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:47.000Z",
    updated_at: "2020-03-02T22:49:47.000Z"
  },
  {
    id: 2678,
    hazzard: {
      id: 23,
      substanceWorkCondition: "Bleaching agent (lye)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 4,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:47.000Z",
    updated_at: "2020-03-02T22:49:47.000Z"
  },
  {
    id: 2679,
    hazzard: {
      id: 23,
      substanceWorkCondition: "Bleaching agent (lye)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 4,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:47.000Z",
    updated_at: "2020-03-02T22:49:47.000Z"
  },
  {
    id: 2680,
    hazzard: {
      id: 24,
      substanceWorkCondition: "Brake fluid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 4,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:47.000Z",
    updated_at: "2020-03-02T22:49:47.000Z"
  },
  {
    id: 2681,
    hazzard: {
      id: 24,
      substanceWorkCondition: "Brake fluid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 4,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:47.000Z",
    updated_at: "2020-03-02T22:49:47.000Z"
  },
  {
    id: 2682,
    hazzard: {
      id: 26,
      substanceWorkCondition: "Cement (dry)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 4,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:47.000Z",
    updated_at: "2020-03-02T22:49:47.000Z"
  },
  {
    id: 2683,
    hazzard: {
      id: 26,
      substanceWorkCondition: "Cement (dry)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 4,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:47.000Z",
    updated_at: "2020-03-02T22:49:47.000Z"
  },
  {
    id: 2684,
    hazzard: {
      id: 27,
      substanceWorkCondition: "Cement (wet)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 4,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:47.000Z",
    updated_at: "2020-03-02T22:49:47.000Z"
  },
  {
    id: 2685,
    hazzard: {
      id: 27,
      substanceWorkCondition: "Cement (wet)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 4,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:47.000Z",
    updated_at: "2020-03-02T22:49:47.000Z"
  },
  {
    id: 2686,
    hazzard: {
      id: 29,
      substanceWorkCondition:
        "Chromates (water- insoluble) = Chromium pigments",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 4,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:47.000Z",
    updated_at: "2020-03-02T22:49:47.000Z"
  },
  {
    id: 2687,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 4,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:47.000Z",
    updated_at: "2020-03-02T22:49:47.000Z"
  },
  {
    id: 2688,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 4,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:47.000Z",
    updated_at: "2020-03-02T22:49:47.000Z"
  },
  {
    id: 2689,
    hazzard: {
      id: 31,
      substanceWorkCondition: "cleaning agent, detergent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 4,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:47.000Z",
    updated_at: "2020-03-02T22:49:47.000Z"
  },
  {
    id: 2690,
    hazzard: {
      id: 31,
      substanceWorkCondition: "cleaning agent, detergent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 4,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:47.000Z",
    updated_at: "2020-03-02T22:49:47.000Z"
  },
  {
    id: 2691,
    hazzard: {
      id: 34,
      substanceWorkCondition: "Coating materials, emulsified",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 4,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:47.000Z",
    updated_at: "2020-03-02T22:49:47.000Z"
  },
  {
    id: 2692,
    hazzard: {
      id: 49,
      substanceWorkCondition: "Cooling lubricant (water- based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 4,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:47.000Z",
    updated_at: "2020-03-02T22:49:47.000Z"
  },
  {
    id: 2693,
    hazzard: {
      id: 49,
      substanceWorkCondition: "Cooling lubricant (water- based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 4,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:47.000Z",
    updated_at: "2020-03-02T22:49:47.000Z"
  },
  {
    id: 2694,
    hazzard: {
      id: 50,
      substanceWorkCondition: "Cooling water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 4,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:47.000Z",
    updated_at: "2020-03-02T22:49:47.000Z"
  },
  {
    id: 2695,
    hazzard: {
      id: 50,
      substanceWorkCondition: "Cooling water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 4,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:47.000Z",
    updated_at: "2020-03-02T22:49:47.000Z"
  },
  {
    id: 2696,
    hazzard: {
      id: 59,
      substanceWorkCondition: "Disinfectants (alcohol-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 4,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:47.000Z",
    updated_at: "2020-03-02T22:49:47.000Z"
  },
  {
    id: 2697,
    hazzard: {
      id: 59,
      substanceWorkCondition: "Disinfectants (alcohol-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 4,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:47.000Z",
    updated_at: "2020-03-02T22:49:47.000Z"
  },
  {
    id: 2698,
    hazzard: {
      id: 60,
      substanceWorkCondition: "Disinfectants (formaldehyd-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 4,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:47.000Z",
    updated_at: "2020-03-02T22:49:47.000Z"
  },
  {
    id: 2699,
    hazzard: {
      id: 60,
      substanceWorkCondition: "Disinfectants (formaldehyd-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 4,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:47.000Z",
    updated_at: "2020-03-02T22:49:47.000Z"
  },
  {
    id: 2700,
    hazzard: {
      id: 61,
      substanceWorkCondition: "Disinfectants (water-based) ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 4,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:47.000Z",
    updated_at: "2020-03-02T22:49:47.000Z"
  },
  {
    id: 2701,
    hazzard: {
      id: 61,
      substanceWorkCondition: "Disinfectants (water-based) ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 4,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:47.000Z",
    updated_at: "2020-03-02T22:49:47.000Z"
  },
  {
    id: 2702,
    hazzard: {
      id: 62,
      substanceWorkCondition: "Dispersing Agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 4,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:47.000Z",
    updated_at: "2020-03-02T22:49:47.000Z"
  },
  {
    id: 2703,
    hazzard: {
      id: 62,
      substanceWorkCondition: "Dispersing Agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 4,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:47.000Z",
    updated_at: "2020-03-02T22:49:47.000Z"
  },
  {
    id: 2704,
    hazzard: {
      id: 63,
      substanceWorkCondition: "Drilling emulsion (water based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 4,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:47.000Z",
    updated_at: "2020-03-02T22:49:47.000Z"
  },
  {
    id: 2705,
    hazzard: {
      id: 63,
      substanceWorkCondition: "Drilling emulsion (water based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 4,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:47.000Z",
    updated_at: "2020-03-02T22:49:47.000Z"
  },
  {
    id: 2706,
    hazzard: {
      id: 64,
      substanceWorkCondition: "Drilling oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 4,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:47.000Z",
    updated_at: "2020-03-02T22:49:47.000Z"
  },
  {
    id: 2707,
    hazzard: {
      id: 66,
      substanceWorkCondition: "Dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 4,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:47.000Z",
    updated_at: "2020-03-02T22:49:47.000Z"
  },
  {
    id: 2708,
    hazzard: {
      id: 72,
      substanceWorkCondition: "Ethanol / methylated spirits ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 4,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:47.000Z",
    updated_at: "2020-03-02T22:49:47.000Z"
  },
  {
    id: 2709,
    hazzard: {
      id: 75,
      substanceWorkCondition: "fine anorganic/inorganic powders ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 4,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:47.000Z",
    updated_at: "2020-03-02T22:49:47.000Z"
  },
  {
    id: 2710,
    hazzard: {
      id: 75,
      substanceWorkCondition: "fine anorganic/inorganic powders ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 4,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:47.000Z",
    updated_at: "2020-03-02T22:49:47.000Z"
  },
  {
    id: 2711,
    hazzard: {
      id: 80,
      substanceWorkCondition: "Food Dyestuff",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 4,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:47.000Z",
    updated_at: "2020-03-02T22:49:47.000Z"
  },
  {
    id: 2712,
    hazzard: {
      id: 80,
      substanceWorkCondition: "Food Dyestuff",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 4,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:47.000Z",
    updated_at: "2020-03-02T22:49:47.000Z"
  },
  {
    id: 2713,
    hazzard: {
      id: 85,
      substanceWorkCondition: "Glycols",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 4,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:47.000Z",
    updated_at: "2020-03-02T22:49:47.000Z"
  },
  {
    id: 2714,
    hazzard: {
      id: 85,
      substanceWorkCondition: "Glycols",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 4,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:47.000Z",
    updated_at: "2020-03-02T22:49:47.000Z"
  },
  {
    id: 2715,
    hazzard: {
      id: 86,
      substanceWorkCondition: "Graphite",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 4,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:47.000Z",
    updated_at: "2020-03-02T22:49:47.000Z"
  },
  {
    id: 2716,
    hazzard: {
      id: 89,
      substanceWorkCondition: "Grinding water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 4,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:47.000Z",
    updated_at: "2020-03-02T22:49:47.000Z"
  },
  {
    id: 2717,
    hazzard: {
      id: 89,
      substanceWorkCondition: "Grinding water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 4,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:47.000Z",
    updated_at: "2020-03-02T22:49:47.000Z"
  },
  {
    id: 2718,
    hazzard: {
      id: 90,
      substanceWorkCondition: "Gypsum",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 4,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:47.000Z",
    updated_at: "2020-03-02T22:49:47.000Z"
  },
  {
    id: 2719,
    hazzard: {
      id: 90,
      substanceWorkCondition: "Gypsum",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 4,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:47.000Z",
    updated_at: "2020-03-02T22:49:47.000Z"
  },
  {
    id: 2720,
    hazzard: {
      id: 95,
      substanceWorkCondition: "Hydraulic fluid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 4,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:47.000Z",
    updated_at: "2020-03-02T22:49:47.000Z"
  },
  {
    id: 2721,
    hazzard: {
      id: 97,
      substanceWorkCondition: "Industry Heavy - Building Materials",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 4,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:47.000Z",
    updated_at: "2020-03-02T22:49:47.000Z"
  },
  {
    id: 2722,
    hazzard: {
      id: 97,
      substanceWorkCondition: "Industry Heavy - Building Materials",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 4,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:47.000Z",
    updated_at: "2020-03-02T22:49:47.000Z"
  },
  {
    id: 2723,
    hazzard: {
      id: 98,
      substanceWorkCondition: "Industry Heavy - Construction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 4,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:47.000Z",
    updated_at: "2020-03-02T22:49:47.000Z"
  },
  {
    id: 2724,
    hazzard: {
      id: 98,
      substanceWorkCondition: "Industry Heavy - Construction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 4,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:47.000Z",
    updated_at: "2020-03-02T22:49:47.000Z"
  },
  {
    id: 2725,
    hazzard: {
      id: 99,
      substanceWorkCondition: "Industry Heavy - Mining & Extraction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 4,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:47.000Z",
    updated_at: "2020-03-02T22:49:47.000Z"
  },
  {
    id: 2726,
    hazzard: {
      id: 99,
      substanceWorkCondition: "Industry Heavy - Mining & Extraction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 4,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:47.000Z",
    updated_at: "2020-03-02T22:49:47.000Z"
  },
  {
    id: 2727,
    hazzard: {
      id: 100,
      substanceWorkCondition: "Industry Heavy - Off-Shore Extraction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 4,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:47.000Z",
    updated_at: "2020-03-02T22:49:47.000Z"
  },
  {
    id: 2728,
    hazzard: {
      id: 100,
      substanceWorkCondition: "Industry Heavy - Off-Shore Extraction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 4,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:47.000Z",
    updated_at: "2020-03-02T22:49:47.000Z"
  },
  {
    id: 2729,
    hazzard: {
      id: 101,
      substanceWorkCondition: "Industry Heavy - Printing Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 4,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:47.000Z",
    updated_at: "2020-03-02T22:49:47.000Z"
  },
  {
    id: 2730,
    hazzard: {
      id: 101,
      substanceWorkCondition: "Industry Heavy - Printing Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 4,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:47.000Z",
    updated_at: "2020-03-02T22:49:47.000Z"
  },
  {
    id: 2731,
    hazzard: {
      id: 102,
      substanceWorkCondition:
        "Industry Heavy - Road & Transportation Construction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 4,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:47.000Z",
    updated_at: "2020-03-02T22:49:47.000Z"
  },
  {
    id: 2732,
    hazzard: {
      id: 102,
      substanceWorkCondition:
        "Industry Heavy - Road & Transportation Construction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 4,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:47.000Z",
    updated_at: "2020-03-02T22:49:47.000Z"
  },
  {
    id: 2733,
    hazzard: {
      id: 103,
      substanceWorkCondition: "Industry Heavy - Ship & Boat Building",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 4,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:47.000Z",
    updated_at: "2020-03-02T22:49:47.000Z"
  },
  {
    id: 2734,
    hazzard: {
      id: 103,
      substanceWorkCondition: "Industry Heavy - Ship & Boat Building",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 4,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:47.000Z",
    updated_at: "2020-03-02T22:49:47.000Z"
  },
  {
    id: 2735,
    hazzard: {
      id: 104,
      substanceWorkCondition: "Industry Heavy - Shipping & Marine",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 4,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:47.000Z",
    updated_at: "2020-03-02T22:49:47.000Z"
  },
  {
    id: 2736,
    hazzard: {
      id: 104,
      substanceWorkCondition: "Industry Heavy - Shipping & Marine",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 4,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:47.000Z",
    updated_at: "2020-03-02T22:49:47.000Z"
  },
  {
    id: 2737,
    hazzard: {
      id: 105,
      substanceWorkCondition:
        "Industry Heavy - Steel, Engineering & Heavy Manufacturing",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 4,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:47.000Z",
    updated_at: "2020-03-02T22:49:47.000Z"
  },
  {
    id: 2738,
    hazzard: {
      id: 105,
      substanceWorkCondition:
        "Industry Heavy - Steel, Engineering & Heavy Manufacturing",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 4,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:47.000Z",
    updated_at: "2020-03-02T22:49:47.000Z"
  },
  {
    id: 2739,
    hazzard: {
      id: 106,
      substanceWorkCondition:
        "Industry Light - Airports & Transportation Terminals - Services & Facility Maintenance",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 4,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:47.000Z",
    updated_at: "2020-03-02T22:49:47.000Z"
  },
  {
    id: 2740,
    hazzard: {
      id: 107,
      substanceWorkCondition: "Industry Light - Emergency Services",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 4,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:47.000Z",
    updated_at: "2020-03-02T22:49:47.000Z"
  },
  {
    id: 2741,
    hazzard: {
      id: 107,
      substanceWorkCondition: "Industry Light - Emergency Services",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 4,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:47.000Z",
    updated_at: "2020-03-02T22:49:47.000Z"
  },
  {
    id: 2742,
    hazzard: {
      id: 108,
      substanceWorkCondition:
        "Industry Light - General Manufacturing & Assembly",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 4,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:47.000Z",
    updated_at: "2020-03-02T22:49:47.000Z"
  },
  {
    id: 2743,
    hazzard: {
      id: 109,
      substanceWorkCondition:
        "Industry Light - Hosiery, Knitware, Fabric & Clothing Manufacturing",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 4,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:47.000Z",
    updated_at: "2020-03-02T22:49:47.000Z"
  },
  {
    id: 2744,
    hazzard: {
      id: 110,
      substanceWorkCondition: "Industry Light - Science & Laboratories",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 4,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:47.000Z",
    updated_at: "2020-03-02T22:49:47.000Z"
  },
  {
    id: 2745,
    hazzard: {
      id: 111,
      substanceWorkCondition: "Industry Light - Warehousing & Logistics",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 4,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:47.000Z",
    updated_at: "2020-03-02T22:49:47.000Z"
  },
  {
    id: 2746,
    hazzard: {
      id: 112,
      substanceWorkCondition: "Industry Medium - Ceramics & Glassware",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 4,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:47.000Z",
    updated_at: "2020-03-02T22:49:47.000Z"
  },
  {
    id: 2747,
    hazzard: {
      id: 112,
      substanceWorkCondition: "Industry Medium - Ceramics & Glassware",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 4,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:47.000Z",
    updated_at: "2020-03-02T22:49:47.000Z"
  },
  {
    id: 2748,
    hazzard: {
      id: 114,
      substanceWorkCondition: "Industry Medium - Electronics Industries",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 4,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:47.000Z",
    updated_at: "2020-03-02T22:49:47.000Z"
  },
  {
    id: 2749,
    hazzard: {
      id: 114,
      substanceWorkCondition: "Industry Medium - Electronics Industries",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 4,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:47.000Z",
    updated_at: "2020-03-02T22:49:47.000Z"
  },
  {
    id: 2750,
    hazzard: {
      id: 115,
      substanceWorkCondition: "Industry Medium - Metal Working & Fabrication",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 4,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:47.000Z",
    updated_at: "2020-03-02T22:49:47.000Z"
  },
  {
    id: 2751,
    hazzard: {
      id: 115,
      substanceWorkCondition: "Industry Medium - Metal Working & Fabrication",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 4,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:47.000Z",
    updated_at: "2020-03-02T22:49:47.000Z"
  },
  {
    id: 2752,
    hazzard: {
      id: 116,
      substanceWorkCondition: "Industry Medium - Outdoor Maintenance & Repair",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 4,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:47.000Z",
    updated_at: "2020-03-02T22:49:47.000Z"
  },
  {
    id: 2753,
    hazzard: {
      id: 116,
      substanceWorkCondition: "Industry Medium - Outdoor Maintenance & Repair",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 4,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:47.000Z",
    updated_at: "2020-03-02T22:49:47.000Z"
  },
  {
    id: 2754,
    hazzard: {
      id: 118,
      substanceWorkCondition: "Industry Medium - Plastics Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 4,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2755,
    hazzard: {
      id: 122,
      substanceWorkCondition: "Ketone",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 4,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2756,
    hazzard: {
      id: 122,
      substanceWorkCondition: "Ketone",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 4,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2757,
    hazzard: {
      id: 125,
      substanceWorkCondition: "Lacquers (UV-hardening)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 4,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2758,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 4,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2759,
    hazzard: {
      id: 127,
      substanceWorkCondition: "Latex paint",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 4,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2760,
    hazzard: {
      id: 127,
      substanceWorkCondition: "Latex paint",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 4,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2761,
    hazzard: {
      id: 128,
      substanceWorkCondition: "Lime",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 4,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2762,
    hazzard: {
      id: 128,
      substanceWorkCondition: "Lime",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 4,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2763,
    hazzard: {
      id: 129,
      substanceWorkCondition: "Lubricants, water-based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 4,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2764,
    hazzard: {
      id: 131,
      substanceWorkCondition: "Methanol / Methyl Alcohol",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 4,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2765,
    hazzard: {
      id: 137,
      substanceWorkCondition: "Oil-emulsions (water- mixed)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 4,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2766,
    hazzard: {
      id: 138,
      substanceWorkCondition: "Oils / Oil based lubricants",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 4,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2767,
    hazzard: {
      id: 141,
      substanceWorkCondition: "Pit water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 4,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2768,
    hazzard: {
      id: 141,
      substanceWorkCondition: "Pit water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 4,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2769,
    hazzard: {
      id: 143,
      substanceWorkCondition: "Polishes (water- soluble)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 4,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2770,
    hazzard: {
      id: 153,
      substanceWorkCondition: "Salts / Salt solution",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 4,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2771,
    hazzard: {
      id: 153,
      substanceWorkCondition: "Salts / Salt solution",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 4,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2772,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 4,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2773,
    hazzard: {
      id: 160,
      substanceWorkCondition: "Surface Cleanser (surfactant based), Detergents",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 4,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2774,
    hazzard: {
      id: 163,
      substanceWorkCondition: "Tanning agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 4,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2775,
    hazzard: {
      id: 170,
      substanceWorkCondition: "Washing bath, washing liquid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 4,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2776,
    hazzard: {
      id: 170,
      substanceWorkCondition: "Washing bath, washing liquid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 4,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2777,
    hazzard: {
      id: 172,
      substanceWorkCondition: "Water / wet work",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 4,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2778,
    hazzard: {
      id: 172,
      substanceWorkCondition: "Water / wet work",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 4,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2779,
    hazzard: {
      id: 999,
      substanceWorkCondition: "All Products",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:44:03.000Z",
      updated_at: "2020-03-02T17:44:03.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2780,
    hazzard: {
      id: 999,
      substanceWorkCondition: "All Products",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:44:03.000Z",
      updated_at: "2020-03-02T17:44:03.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 4,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2781,
    hazzard: {
      id: 18,
      substanceWorkCondition:
        "Automotive Aftermarket - Public Transport Maintenance&Repair",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 4,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2782,
    hazzard: {
      id: 91,
      substanceWorkCondition: "Healthcare - Care Homes",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 4,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2783,
    hazzard: {
      id: 93,
      substanceWorkCondition: "Healthcare - Nursing Homes",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 4,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2784,
    hazzard: {
      id: 97,
      substanceWorkCondition: "Industry Heavy - Building Materials",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 4,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2785,
    hazzard: {
      id: 98,
      substanceWorkCondition: "Industry Heavy - Construction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 4,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2786,
    hazzard: {
      id: 99,
      substanceWorkCondition: "Industry Heavy - Mining & Extraction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 4,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2787,
    hazzard: {
      id: 100,
      substanceWorkCondition: "Industry Heavy - Off-Shore Extraction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 4,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2788,
    hazzard: {
      id: 101,
      substanceWorkCondition: "Industry Heavy - Printing Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 4,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2789,
    hazzard: {
      id: 102,
      substanceWorkCondition:
        "Industry Heavy - Road & Transportation Construction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 4,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2790,
    hazzard: {
      id: 103,
      substanceWorkCondition: "Industry Heavy - Ship & Boat Building",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 4,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2791,
    hazzard: {
      id: 104,
      substanceWorkCondition: "Industry Heavy - Shipping & Marine",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 4,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2792,
    hazzard: {
      id: 105,
      substanceWorkCondition:
        "Industry Heavy - Steel, Engineering & Heavy Manufacturing",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 4,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2793,
    hazzard: {
      id: 107,
      substanceWorkCondition: "Industry Light - Emergency Services",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 4,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2794,
    hazzard: {
      id: 108,
      substanceWorkCondition:
        "Industry Light - General Manufacturing & Assembly",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 4,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2795,
    hazzard: {
      id: 109,
      substanceWorkCondition:
        "Industry Light - Hosiery, Knitware, Fabric & Clothing Manufacturing",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 4,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2796,
    hazzard: {
      id: 110,
      substanceWorkCondition: "Industry Light - Science & Laboratories",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 4,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2797,
    hazzard: {
      id: 112,
      substanceWorkCondition: "Industry Medium - Ceramics & Glassware",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 4,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2798,
    hazzard: {
      id: 113,
      substanceWorkCondition: "Industry Medium - Chemical Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 4,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2799,
    hazzard: {
      id: 114,
      substanceWorkCondition: "Industry Medium - Electronics Industries",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 4,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2800,
    hazzard: {
      id: 115,
      substanceWorkCondition: "Industry Medium - Metal Working & Fabrication",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 4,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2801,
    hazzard: {
      id: 116,
      substanceWorkCondition: "Industry Medium - Outdoor Maintenance & Repair",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 4,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2802,
    hazzard: {
      id: 117,
      substanceWorkCondition: "Industry Medium - Pharmaceutical Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 4,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2803,
    hazzard: {
      id: 118,
      substanceWorkCondition: "Industry Medium - Plastics Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 4,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2804,
    hazzard: {
      id: 999,
      substanceWorkCondition: "All Products",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:44:03.000Z",
      updated_at: "2020-03-02T17:44:03.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Body",
    level1: false,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2805,
    hazzard: {
      id: 1,
      substanceWorkCondition: "2-Butanone (MEK)",
      cas: "78-93-3",
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: "oil",
      created_at: "2020-03-02T17:39:32.000Z",
      updated_at: "2020-03-05T14:48:01.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2806,
    hazzard: {
      id: 1,
      substanceWorkCondition: "2-Butanone (MEK)",
      cas: "78-93-3",
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: "oil",
      created_at: "2020-03-02T17:39:32.000Z",
      updated_at: "2020-03-05T14:48:01.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 3,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2807,
    hazzard: {
      id: 2,
      substanceWorkCondition: "Acetone / Dimethyl ketone",
      cas: "67-64-1",
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2808,
    hazzard: {
      id: 2,
      substanceWorkCondition: "Acetone / Dimethyl ketone",
      cas: "67-64-1",
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 3,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2809,
    hazzard: {
      id: 3,
      substanceWorkCondition: "Acids, diluted",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 3,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2810,
    hazzard: {
      id: 6,
      substanceWorkCondition: "Adhesive, water-soluble",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2811,
    hazzard: {
      id: 6,
      substanceWorkCondition: "Adhesive, water-soluble",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 3,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2812,
    hazzard: {
      id: 8,
      substanceWorkCondition: "Aldehydes, diluted",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2813,
    hazzard: {
      id: 8,
      substanceWorkCondition: "Aldehydes, diluted",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 3,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2814,
    hazzard: {
      id: 9,
      substanceWorkCondition: "Alkaline solutions / lyes / thinned caustics",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2815,
    hazzard: {
      id: 9,
      substanceWorkCondition: "Alkaline solutions / lyes / thinned caustics",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 3,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2816,
    hazzard: {
      id: 10,
      substanceWorkCondition: "Alkaline solutions, diluted",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2817,
    hazzard: {
      id: 10,
      substanceWorkCondition: "Alkaline solutions, diluted",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 3,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2818,
    hazzard: {
      id: 11,
      substanceWorkCondition: "Alkaline solutions, lyes (solvent based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2819,
    hazzard: {
      id: 12,
      substanceWorkCondition: "Aluminium",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2820,
    hazzard: {
      id: 14,
      substanceWorkCondition: "Antistatics",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2821,
    hazzard: {
      id: 19,
      substanceWorkCondition: "Battery acid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2822,
    hazzard: {
      id: 19,
      substanceWorkCondition: "Battery acid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 3,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2823,
    hazzard: {
      id: 22,
      substanceWorkCondition: "Bitumen emulsion",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2824,
    hazzard: {
      id: 23,
      substanceWorkCondition: "Bleaching agent (lye)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2825,
    hazzard: {
      id: 23,
      substanceWorkCondition: "Bleaching agent (lye)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 3,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2826,
    hazzard: {
      id: 24,
      substanceWorkCondition: "Brake fluid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2827,
    hazzard: {
      id: 24,
      substanceWorkCondition: "Brake fluid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 3,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2828,
    hazzard: {
      id: 26,
      substanceWorkCondition: "Cement (dry)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2829,
    hazzard: {
      id: 26,
      substanceWorkCondition: "Cement (dry)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 3,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2830,
    hazzard: {
      id: 27,
      substanceWorkCondition: "Cement (wet)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2831,
    hazzard: {
      id: 27,
      substanceWorkCondition: "Cement (wet)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 3,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2832,
    hazzard: {
      id: 29,
      substanceWorkCondition:
        "Chromates (water- insoluble) = Chromium pigments",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2833,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2834,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 3,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2835,
    hazzard: {
      id: 31,
      substanceWorkCondition: "cleaning agent, detergent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2836,
    hazzard: {
      id: 31,
      substanceWorkCondition: "cleaning agent, detergent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 3,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2837,
    hazzard: {
      id: 34,
      substanceWorkCondition: "Coating materials, emulsified",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2838,
    hazzard: {
      id: 49,
      substanceWorkCondition: "Cooling lubricant (water- based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2839,
    hazzard: {
      id: 49,
      substanceWorkCondition: "Cooling lubricant (water- based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 3,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2840,
    hazzard: {
      id: 50,
      substanceWorkCondition: "Cooling water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2841,
    hazzard: {
      id: 50,
      substanceWorkCondition: "Cooling water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 3,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2842,
    hazzard: {
      id: 59,
      substanceWorkCondition: "Disinfectants (alcohol-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2843,
    hazzard: {
      id: 59,
      substanceWorkCondition: "Disinfectants (alcohol-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 3,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2844,
    hazzard: {
      id: 60,
      substanceWorkCondition: "Disinfectants (formaldehyd-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2845,
    hazzard: {
      id: 60,
      substanceWorkCondition: "Disinfectants (formaldehyd-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 3,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2846,
    hazzard: {
      id: 61,
      substanceWorkCondition: "Disinfectants (water-based) ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2847,
    hazzard: {
      id: 61,
      substanceWorkCondition: "Disinfectants (water-based) ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 3,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2848,
    hazzard: {
      id: 62,
      substanceWorkCondition: "Dispersing Agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2849,
    hazzard: {
      id: 62,
      substanceWorkCondition: "Dispersing Agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 3,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2850,
    hazzard: {
      id: 63,
      substanceWorkCondition: "Drilling emulsion (water based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2851,
    hazzard: {
      id: 63,
      substanceWorkCondition: "Drilling emulsion (water based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 3,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2852,
    hazzard: {
      id: 64,
      substanceWorkCondition: "Drilling oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2853,
    hazzard: {
      id: 66,
      substanceWorkCondition: "Dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2854,
    hazzard: {
      id: 72,
      substanceWorkCondition: "Ethanol / methylated spirits ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2855,
    hazzard: {
      id: 75,
      substanceWorkCondition: "fine anorganic/inorganic powders ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2856,
    hazzard: {
      id: 75,
      substanceWorkCondition: "fine anorganic/inorganic powders ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 3,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2857,
    hazzard: {
      id: 80,
      substanceWorkCondition: "Food Dyestuff",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2858,
    hazzard: {
      id: 80,
      substanceWorkCondition: "Food Dyestuff",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 3,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2859,
    hazzard: {
      id: 85,
      substanceWorkCondition: "Glycols",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2860,
    hazzard: {
      id: 85,
      substanceWorkCondition: "Glycols",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 3,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2861,
    hazzard: {
      id: 86,
      substanceWorkCondition: "Graphite",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2862,
    hazzard: {
      id: 89,
      substanceWorkCondition: "Grinding water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2863,
    hazzard: {
      id: 89,
      substanceWorkCondition: "Grinding water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 3,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2864,
    hazzard: {
      id: 90,
      substanceWorkCondition: "Gypsum",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2865,
    hazzard: {
      id: 90,
      substanceWorkCondition: "Gypsum",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 3,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2866,
    hazzard: {
      id: 95,
      substanceWorkCondition: "Hydraulic fluid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2867,
    hazzard: {
      id: 97,
      substanceWorkCondition: "Industry Heavy - Building Materials",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2868,
    hazzard: {
      id: 97,
      substanceWorkCondition: "Industry Heavy - Building Materials",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 3,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2869,
    hazzard: {
      id: 98,
      substanceWorkCondition: "Industry Heavy - Construction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2870,
    hazzard: {
      id: 98,
      substanceWorkCondition: "Industry Heavy - Construction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 3,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2871,
    hazzard: {
      id: 99,
      substanceWorkCondition: "Industry Heavy - Mining & Extraction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2872,
    hazzard: {
      id: 99,
      substanceWorkCondition: "Industry Heavy - Mining & Extraction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 3,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2873,
    hazzard: {
      id: 100,
      substanceWorkCondition: "Industry Heavy - Off-Shore Extraction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2874,
    hazzard: {
      id: 100,
      substanceWorkCondition: "Industry Heavy - Off-Shore Extraction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 3,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2875,
    hazzard: {
      id: 101,
      substanceWorkCondition: "Industry Heavy - Printing Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2876,
    hazzard: {
      id: 101,
      substanceWorkCondition: "Industry Heavy - Printing Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 3,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2877,
    hazzard: {
      id: 102,
      substanceWorkCondition:
        "Industry Heavy - Road & Transportation Construction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2878,
    hazzard: {
      id: 102,
      substanceWorkCondition:
        "Industry Heavy - Road & Transportation Construction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 3,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2879,
    hazzard: {
      id: 103,
      substanceWorkCondition: "Industry Heavy - Ship & Boat Building",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2880,
    hazzard: {
      id: 103,
      substanceWorkCondition: "Industry Heavy - Ship & Boat Building",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 3,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2881,
    hazzard: {
      id: 104,
      substanceWorkCondition: "Industry Heavy - Shipping & Marine",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2882,
    hazzard: {
      id: 104,
      substanceWorkCondition: "Industry Heavy - Shipping & Marine",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 3,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2883,
    hazzard: {
      id: 105,
      substanceWorkCondition:
        "Industry Heavy - Steel, Engineering & Heavy Manufacturing",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2884,
    hazzard: {
      id: 105,
      substanceWorkCondition:
        "Industry Heavy - Steel, Engineering & Heavy Manufacturing",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 3,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2885,
    hazzard: {
      id: 106,
      substanceWorkCondition:
        "Industry Light - Airports & Transportation Terminals - Services & Facility Maintenance",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2886,
    hazzard: {
      id: 107,
      substanceWorkCondition: "Industry Light - Emergency Services",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2887,
    hazzard: {
      id: 107,
      substanceWorkCondition: "Industry Light - Emergency Services",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 3,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2888,
    hazzard: {
      id: 108,
      substanceWorkCondition:
        "Industry Light - General Manufacturing & Assembly",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2889,
    hazzard: {
      id: 109,
      substanceWorkCondition:
        "Industry Light - Hosiery, Knitware, Fabric & Clothing Manufacturing",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2890,
    hazzard: {
      id: 110,
      substanceWorkCondition: "Industry Light - Science & Laboratories",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2891,
    hazzard: {
      id: 111,
      substanceWorkCondition: "Industry Light - Warehousing & Logistics",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2892,
    hazzard: {
      id: 112,
      substanceWorkCondition: "Industry Medium - Ceramics & Glassware",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2893,
    hazzard: {
      id: 112,
      substanceWorkCondition: "Industry Medium - Ceramics & Glassware",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 3,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2894,
    hazzard: {
      id: 114,
      substanceWorkCondition: "Industry Medium - Electronics Industries",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2895,
    hazzard: {
      id: 114,
      substanceWorkCondition: "Industry Medium - Electronics Industries",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 3,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2896,
    hazzard: {
      id: 115,
      substanceWorkCondition: "Industry Medium - Metal Working & Fabrication",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2897,
    hazzard: {
      id: 115,
      substanceWorkCondition: "Industry Medium - Metal Working & Fabrication",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 3,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2898,
    hazzard: {
      id: 116,
      substanceWorkCondition: "Industry Medium - Outdoor Maintenance & Repair",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2899,
    hazzard: {
      id: 116,
      substanceWorkCondition: "Industry Medium - Outdoor Maintenance & Repair",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 3,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2900,
    hazzard: {
      id: 118,
      substanceWorkCondition: "Industry Medium - Plastics Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2901,
    hazzard: {
      id: 122,
      substanceWorkCondition: "Ketone",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2902,
    hazzard: {
      id: 122,
      substanceWorkCondition: "Ketone",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 3,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2903,
    hazzard: {
      id: 125,
      substanceWorkCondition: "Lacquers (UV-hardening)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:48.000Z",
    updated_at: "2020-03-02T22:49:48.000Z"
  },
  {
    id: 2904,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 2905,
    hazzard: {
      id: 127,
      substanceWorkCondition: "Latex paint",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 2906,
    hazzard: {
      id: 127,
      substanceWorkCondition: "Latex paint",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 3,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 2907,
    hazzard: {
      id: 128,
      substanceWorkCondition: "Lime",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 2908,
    hazzard: {
      id: 128,
      substanceWorkCondition: "Lime",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 3,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 2909,
    hazzard: {
      id: 129,
      substanceWorkCondition: "Lubricants, water-based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 2910,
    hazzard: {
      id: 131,
      substanceWorkCondition: "Methanol / Methyl Alcohol",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 2911,
    hazzard: {
      id: 137,
      substanceWorkCondition: "Oil-emulsions (water- mixed)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 2912,
    hazzard: {
      id: 138,
      substanceWorkCondition: "Oils / Oil based lubricants",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 2913,
    hazzard: {
      id: 141,
      substanceWorkCondition: "Pit water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 2914,
    hazzard: {
      id: 141,
      substanceWorkCondition: "Pit water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 3,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 2915,
    hazzard: {
      id: 143,
      substanceWorkCondition: "Polishes (water- soluble)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 2916,
    hazzard: {
      id: 149,
      substanceWorkCondition: "release agent, parting agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 2917,
    hazzard: {
      id: 153,
      substanceWorkCondition: "Salts / Salt solution",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 2918,
    hazzard: {
      id: 153,
      substanceWorkCondition: "Salts / Salt solution",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 3,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 2919,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 2920,
    hazzard: {
      id: 160,
      substanceWorkCondition: "Surface Cleanser (surfactant based), Detergents",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 3,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 2921,
    hazzard: {
      id: 163,
      substanceWorkCondition: "Tanning agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 3,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 2922,
    hazzard: {
      id: 170,
      substanceWorkCondition: "Washing bath, washing liquid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 2923,
    hazzard: {
      id: 170,
      substanceWorkCondition: "Washing bath, washing liquid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 3,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 2924,
    hazzard: {
      id: 172,
      substanceWorkCondition: "Water / wet work",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 2925,
    hazzard: {
      id: 172,
      substanceWorkCondition: "Water / wet work",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 3,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 2926,
    hazzard: {
      id: 999,
      substanceWorkCondition: "All Products",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:44:03.000Z",
      updated_at: "2020-03-02T17:44:03.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 2927,
    hazzard: {
      id: 999,
      substanceWorkCondition: "All Products",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:44:03.000Z",
      updated_at: "2020-03-02T17:44:03.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 4,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 2928,
    hazzard: {
      id: 1,
      substanceWorkCondition: "2-Butanone (MEK)",
      cas: "78-93-3",
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: "oil",
      created_at: "2020-03-02T17:39:32.000Z",
      updated_at: "2020-03-05T14:48:01.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 2929,
    hazzard: {
      id: 2,
      substanceWorkCondition: "Acetone / Dimethyl ketone",
      cas: "67-64-1",
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 2930,
    hazzard: {
      id: 3,
      substanceWorkCondition: "Acids, diluted",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 2931,
    hazzard: {
      id: 4,
      substanceWorkCondition: "Acrylates",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 2932,
    hazzard: {
      id: 5,
      substanceWorkCondition: "Adhesive single-component",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 2933,
    hazzard: {
      id: 6,
      substanceWorkCondition: "Adhesive, water-soluble",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 2934,
    hazzard: {
      id: 7,
      substanceWorkCondition: "Adhesives, glues based on synthetic resin",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 2935,
    hazzard: {
      id: 8,
      substanceWorkCondition: "Aldehydes, diluted",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 2936,
    hazzard: {
      id: 9,
      substanceWorkCondition: "Alkaline solutions / lyes / thinned caustics",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 2937,
    hazzard: {
      id: 10,
      substanceWorkCondition: "Alkaline solutions, diluted",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 2938,
    hazzard: {
      id: 11,
      substanceWorkCondition: "Alkaline solutions, lyes (solvent based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 2939,
    hazzard: {
      id: 12,
      substanceWorkCondition: "Aluminium",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 2940,
    hazzard: {
      id: 14,
      substanceWorkCondition: "Antistatics",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 2941,
    hazzard: {
      id: 15,
      substanceWorkCondition: "Asphalt",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 2942,
    hazzard: {
      id: 18,
      substanceWorkCondition:
        "Automotive Aftermarket - Public Transport Maintenance&Repair",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 2943,
    hazzard: {
      id: 19,
      substanceWorkCondition: "Battery acid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 2944,
    hazzard: {
      id: 20,
      substanceWorkCondition: "Benzene",
      cas: null,
      glovesRecommended: true,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 2945,
    hazzard: {
      id: 22,
      substanceWorkCondition: "Bitumen emulsion",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 2946,
    hazzard: {
      id: 21,
      substanceWorkCondition: "Bitumen",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 2947,
    hazzard: {
      id: 23,
      substanceWorkCondition: "Bleaching agent (lye)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 2948,
    hazzard: {
      id: 24,
      substanceWorkCondition: "Brake fluid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 2949,
    hazzard: {
      id: 25,
      substanceWorkCondition: "Casting dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 2950,
    hazzard: {
      id: 26,
      substanceWorkCondition: "Cement (dry)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 2951,
    hazzard: {
      id: 27,
      substanceWorkCondition: "Cement (wet)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 2952,
    hazzard: {
      id: 28,
      substanceWorkCondition: "Cement, putty epoxy-based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 2953,
    hazzard: {
      id: 29,
      substanceWorkCondition:
        "Chromates (water- insoluble) = Chromium pigments",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 2954,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 2955,
    hazzard: {
      id: 31,
      substanceWorkCondition: "cleaning agent, detergent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 2956,
    hazzard: {
      id: 32,
      substanceWorkCondition: "Coal dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 2957,
    hazzard: {
      id: 33,
      substanceWorkCondition: "Coating materials, bituminous (bitumen varnish)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 2958,
    hazzard: {
      id: 34,
      substanceWorkCondition: "Coating materials, emulsified",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 2959,
    hazzard: {
      id: 35,
      substanceWorkCondition: "Coating materials, oily",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 2960,
    hazzard: {
      id: 36,
      substanceWorkCondition: "Cold degreasing agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 2961,
    hazzard: {
      id: 37,
      substanceWorkCondition: "cold detergent, solvent based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 2962,
    hazzard: {
      id: 38,
      substanceWorkCondition: "Cold working conditions",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 2963,
    hazzard: {
      id: 40,
      substanceWorkCondition: "Commercial - Cleaning & Maintenance",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 2964,
    hazzard: {
      id: 41,
      substanceWorkCondition: "Commercial - Commerce & Office",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 2965,
    hazzard: {
      id: 42,
      substanceWorkCondition: "Commercial - Education",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 2966,
    hazzard: {
      id: 43,
      substanceWorkCondition: "Commercial - Hotel & Leisure",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 2967,
    hazzard: {
      id: 44,
      substanceWorkCondition: "Commercial - Prisons & Security Services",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 2968,
    hazzard: {
      id: 47,
      substanceWorkCondition: "Concrete",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 2969,
    hazzard: {
      id: 48,
      substanceWorkCondition: "Cooling lubricant (non-water-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 2970,
    hazzard: {
      id: 49,
      substanceWorkCondition: "Cooling lubricant (water- based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 2971,
    hazzard: {
      id: 50,
      substanceWorkCondition: "Cooling water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 2972,
    hazzard: {
      id: 51,
      substanceWorkCondition: "Copying ink",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 2973,
    hazzard: {
      id: 52,
      substanceWorkCondition: "Cutting oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 2974,
    hazzard: {
      id: 53,
      substanceWorkCondition: "Degreaser solvent - based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 2975,
    hazzard: {
      id: 54,
      substanceWorkCondition: "Degreaser without organic solvent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 2976,
    hazzard: {
      id: 55,
      substanceWorkCondition: "Detachment solution for concrete",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 2977,
    hazzard: {
      id: 56,
      substanceWorkCondition: "Detachments (alcohol-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 2978,
    hazzard: {
      id: 57,
      substanceWorkCondition: "Detachments (solvent-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 2979,
    hazzard: {
      id: 58,
      substanceWorkCondition: "Detachments (sour)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 2980,
    hazzard: {
      id: 59,
      substanceWorkCondition: "Disinfectants (alcohol-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 2981,
    hazzard: {
      id: 60,
      substanceWorkCondition: "Disinfectants (formaldehyd-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 2982,
    hazzard: {
      id: 61,
      substanceWorkCondition: "Disinfectants (water-based) ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 2983,
    hazzard: {
      id: 62,
      substanceWorkCondition: "Dispersing Agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 2984,
    hazzard: {
      id: 63,
      substanceWorkCondition: "Drilling emulsion (water based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 2985,
    hazzard: {
      id: 64,
      substanceWorkCondition: "Drilling oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 2986,
    hazzard: {
      id: 65,
      substanceWorkCondition: "Dryness",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 2987,
    hazzard: {
      id: 66,
      substanceWorkCondition: "Dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 2988,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 2989,
    hazzard: {
      id: 70,
      substanceWorkCondition: "Epoxy resin",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 2990,
    hazzard: {
      id: 71,
      substanceWorkCondition: "Etching (mordant)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 2991,
    hazzard: {
      id: 72,
      substanceWorkCondition: "Ethanol / methylated spirits ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 2992,
    hazzard: {
      id: 73,
      substanceWorkCondition: "Fat",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 2993,
    hazzard: {
      id: 74,
      substanceWorkCondition: "Fertilizer",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 2994,
    hazzard: {
      id: 75,
      substanceWorkCondition: "fine anorganic/inorganic powders ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 2995,
    hazzard: {
      id: 76,
      substanceWorkCondition: "Floor cleaner (water- based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 2996,
    hazzard: {
      id: 81,
      substanceWorkCondition: "fuel: gas / diesel",
      cas: null,
      glovesRecommended: true,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 2997,
    hazzard: {
      id: 82,
      substanceWorkCondition: "Galvanic bath",
      cas: null,
      glovesRecommended: true,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 2998,
    hazzard: {
      id: 83,
      substanceWorkCondition: "Glass fibre dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 2999,
    hazzard: {
      id: 84,
      substanceWorkCondition: "Glass wool dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 3000,
    hazzard: {
      id: 85,
      substanceWorkCondition: "Glycols",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 3001,
    hazzard: {
      id: 86,
      substanceWorkCondition: "Graphite",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 3002,
    hazzard: {
      id: 87,
      substanceWorkCondition: "Grease",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 3003,
    hazzard: {
      id: 88,
      substanceWorkCondition: "Grinding oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 3004,
    hazzard: {
      id: 89,
      substanceWorkCondition: "Grinding water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 3005,
    hazzard: {
      id: 90,
      substanceWorkCondition: "Gypsum",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 3006,
    hazzard: {
      id: 91,
      substanceWorkCondition: "Healthcare - Care Homes",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 3007,
    hazzard: {
      id: 92,
      substanceWorkCondition:
        "Healthcare - Medical Centers/Surgeries/General Practitioners",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 3008,
    hazzard: {
      id: 93,
      substanceWorkCondition: "Healthcare - Nursing Homes",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 3009,
    hazzard: {
      id: 94,
      substanceWorkCondition: "Healthcare - Veterinary Services",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 3010,
    hazzard: {
      id: 95,
      substanceWorkCondition: "Hydraulic fluid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 3011,
    hazzard: {
      id: 96,
      substanceWorkCondition: "Hyrocarbons",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 3012,
    hazzard: {
      id: 97,
      substanceWorkCondition: "Industry Heavy - Building Materials",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 3013,
    hazzard: {
      id: 98,
      substanceWorkCondition: "Industry Heavy - Construction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 3014,
    hazzard: {
      id: 99,
      substanceWorkCondition: "Industry Heavy - Mining & Extraction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 3015,
    hazzard: {
      id: 100,
      substanceWorkCondition: "Industry Heavy - Off-Shore Extraction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 3016,
    hazzard: {
      id: 101,
      substanceWorkCondition: "Industry Heavy - Printing Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 3017,
    hazzard: {
      id: 102,
      substanceWorkCondition:
        "Industry Heavy - Road & Transportation Construction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 3018,
    hazzard: {
      id: 103,
      substanceWorkCondition: "Industry Heavy - Ship & Boat Building",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 3019,
    hazzard: {
      id: 104,
      substanceWorkCondition: "Industry Heavy - Shipping & Marine",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 3020,
    hazzard: {
      id: 105,
      substanceWorkCondition:
        "Industry Heavy - Steel, Engineering & Heavy Manufacturing",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 3021,
    hazzard: {
      id: 106,
      substanceWorkCondition:
        "Industry Light - Airports & Transportation Terminals - Services & Facility Maintenance",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 3022,
    hazzard: {
      id: 107,
      substanceWorkCondition: "Industry Light - Emergency Services",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 3023,
    hazzard: {
      id: 108,
      substanceWorkCondition:
        "Industry Light - General Manufacturing & Assembly",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 3024,
    hazzard: {
      id: 109,
      substanceWorkCondition:
        "Industry Light - Hosiery, Knitware, Fabric & Clothing Manufacturing",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 3025,
    hazzard: {
      id: 110,
      substanceWorkCondition: "Industry Light - Science & Laboratories",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 3026,
    hazzard: {
      id: 111,
      substanceWorkCondition: "Industry Light - Warehousing & Logistics",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 3027,
    hazzard: {
      id: 112,
      substanceWorkCondition: "Industry Medium - Ceramics & Glassware",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 3028,
    hazzard: {
      id: 113,
      substanceWorkCondition: "Industry Medium - Chemical Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 3029,
    hazzard: {
      id: 114,
      substanceWorkCondition: "Industry Medium - Electronics Industries",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 3030,
    hazzard: {
      id: 115,
      substanceWorkCondition: "Industry Medium - Metal Working & Fabrication",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 3031,
    hazzard: {
      id: 116,
      substanceWorkCondition: "Industry Medium - Outdoor Maintenance & Repair",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 3032,
    hazzard: {
      id: 117,
      substanceWorkCondition: "Industry Medium - Pharmaceutical Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 3033,
    hazzard: {
      id: 118,
      substanceWorkCondition: "Industry Medium - Plastics Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 3034,
    hazzard: {
      id: 119,
      substanceWorkCondition: "Ink / printing ink",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 3035,
    hazzard: {
      id: 120,
      substanceWorkCondition: "Insulating material",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 3036,
    hazzard: {
      id: 121,
      substanceWorkCondition: "Kerosene",
      cas: null,
      glovesRecommended: true,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 3037,
    hazzard: {
      id: 122,
      substanceWorkCondition: "Ketone",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 3038,
    hazzard: {
      id: 123,
      substanceWorkCondition: "Lacquers (nitro/solvent-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 3039,
    hazzard: {
      id: 124,
      substanceWorkCondition: "Lacquers (PU based, synthetic resin based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 3040,
    hazzard: {
      id: 125,
      substanceWorkCondition: "Lacquers (UV-hardening)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 3041,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 3042,
    hazzard: {
      id: 127,
      substanceWorkCondition: "Latex paint",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 3043,
    hazzard: {
      id: 128,
      substanceWorkCondition: "Lime",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 3044,
    hazzard: {
      id: 129,
      substanceWorkCondition: "Lubricants, water-based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 3045,
    hazzard: {
      id: 130,
      substanceWorkCondition: "Metal dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 3046,
    hazzard: {
      id: 131,
      substanceWorkCondition: "Methanol / Methyl Alcohol",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 3047,
    hazzard: {
      id: 132,
      substanceWorkCondition: "Mineral oil / paraffin oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 3048,
    hazzard: {
      id: 132,
      substanceWorkCondition: "Mineral oil / paraffin oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 3049,
    hazzard: {
      id: 133,
      substanceWorkCondition: "Multi component adhesive",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 3050,
    hazzard: {
      id: 134,
      substanceWorkCondition: "Multi component resin",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 3051,
    hazzard: {
      id: 135,
      substanceWorkCondition: "Naphthalene",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 3052,
    hazzard: {
      id: 136,
      substanceWorkCondition: "Oil coke (carbon)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 3053,
    hazzard: {
      id: 137,
      substanceWorkCondition: "Oil-emulsions (water- mixed)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 3054,
    hazzard: {
      id: 138,
      substanceWorkCondition: "Oils / Oil based lubricants",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 3055,
    hazzard: {
      id: 140,
      substanceWorkCondition: "Paint Thinner ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 3056,
    hazzard: {
      id: 139,
      substanceWorkCondition: "Paint",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:49.000Z",
    updated_at: "2020-03-02T22:49:49.000Z"
  },
  {
    id: 3057,
    hazzard: {
      id: 141,
      substanceWorkCondition: "Pit water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3058,
    hazzard: {
      id: 142,
      substanceWorkCondition: "Pitch",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3059,
    hazzard: {
      id: 143,
      substanceWorkCondition: "Polishes (water- soluble)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3060,
    hazzard: {
      id: 144,
      substanceWorkCondition: "Polyester / Polyester resin",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3061,
    hazzard: {
      id: 145,
      substanceWorkCondition: "Polyurethane",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3062,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3063,
    hazzard: {
      id: 148,
      substanceWorkCondition:
        "Propanols (propyl alcohols): n-Propanol, Isopropanol / 2-Propanol ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3064,
    hazzard: {
      id: 149,
      substanceWorkCondition: "release agent, parting agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3065,
    hazzard: {
      id: 150,
      substanceWorkCondition: "Resins / urea resins",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3066,
    hazzard: {
      id: 151,
      substanceWorkCondition: "Rock wool",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3067,
    hazzard: {
      id: 152,
      substanceWorkCondition: "Rubber",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3068,
    hazzard: {
      id: 153,
      substanceWorkCondition: "Salts / Salt solution",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3069,
    hazzard: {
      id: 154,
      substanceWorkCondition: "Sealing compound",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3070,
    hazzard: {
      id: 155,
      substanceWorkCondition: "Sintering",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3071,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3072,
    hazzard: {
      id: 157,
      substanceWorkCondition: "Solvents, - organic",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3073,
    hazzard: {
      id: 158,
      substanceWorkCondition: "Soot",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3074,
    hazzard: {
      id: 159,
      substanceWorkCondition: "Styrene",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3075,
    hazzard: {
      id: 160,
      substanceWorkCondition: "Surface Cleanser (surfactant based), Detergents",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3076,
    hazzard: {
      id: 161,
      substanceWorkCondition: "Synthetic melting glaze",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3077,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3078,
    hazzard: {
      id: 163,
      substanceWorkCondition: "Tanning agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3079,
    hazzard: {
      id: 164,
      substanceWorkCondition: "Tar",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3080,
    hazzard: {
      id: 166,
      substanceWorkCondition: "Thinner",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3081,
    hazzard: {
      id: 167,
      substanceWorkCondition: "Toluene",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3082,
    hazzard: {
      id: 168,
      substanceWorkCondition: "Turpentine oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3083,
    hazzard: {
      id: 170,
      substanceWorkCondition: "Washing bath, washing liquid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3084,
    hazzard: {
      id: 171,
      substanceWorkCondition: "Waste oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3085,
    hazzard: {
      id: 172,
      substanceWorkCondition: "Water / wet work",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3086,
    hazzard: {
      id: 173,
      substanceWorkCondition:
        "Wearing protective clothing / Softening of the skin (Maceration)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3087,
    hazzard: {
      id: 174,
      substanceWorkCondition: "wire drawing oil / wire drawing grease",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3088,
    hazzard: {
      id: 175,
      substanceWorkCondition: "Xylene",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3089,
    hazzard: {
      id: 999,
      substanceWorkCondition: "All Products",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:44:03.000Z",
      updated_at: "2020-03-02T17:44:03.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Face",
    level1: false,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3090,
    hazzard: {
      id: 1,
      substanceWorkCondition: "2-Butanone (MEK)",
      cas: "78-93-3",
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: "oil",
      created_at: "2020-03-02T17:39:32.000Z",
      updated_at: "2020-03-05T14:48:01.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3091,
    hazzard: {
      id: 1,
      substanceWorkCondition: "2-Butanone (MEK)",
      cas: "78-93-3",
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: "oil",
      created_at: "2020-03-02T17:39:32.000Z",
      updated_at: "2020-03-05T14:48:01.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 2,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3092,
    hazzard: {
      id: 2,
      substanceWorkCondition: "Acetone / Dimethyl ketone",
      cas: "67-64-1",
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3093,
    hazzard: {
      id: 2,
      substanceWorkCondition: "Acetone / Dimethyl ketone",
      cas: "67-64-1",
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 2,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3094,
    hazzard: {
      id: 3,
      substanceWorkCondition: "Acids, diluted",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3095,
    hazzard: {
      id: 3,
      substanceWorkCondition: "Acids, diluted",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 2,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3096,
    hazzard: {
      id: 4,
      substanceWorkCondition: "Acrylates",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3097,
    hazzard: {
      id: 5,
      substanceWorkCondition: "Adhesive single-component",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3098,
    hazzard: {
      id: 6,
      substanceWorkCondition: "Adhesive, water-soluble",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3099,
    hazzard: {
      id: 6,
      substanceWorkCondition: "Adhesive, water-soluble",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 2,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3100,
    hazzard: {
      id: 7,
      substanceWorkCondition: "Adhesives, glues based on synthetic resin",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3101,
    hazzard: {
      id: 8,
      substanceWorkCondition: "Aldehydes, diluted",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3102,
    hazzard: {
      id: 8,
      substanceWorkCondition: "Aldehydes, diluted",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 2,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3103,
    hazzard: {
      id: 9,
      substanceWorkCondition: "Alkaline solutions / lyes / thinned caustics",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3104,
    hazzard: {
      id: 9,
      substanceWorkCondition: "Alkaline solutions / lyes / thinned caustics",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 2,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3105,
    hazzard: {
      id: 10,
      substanceWorkCondition: "Alkaline solutions, diluted",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3106,
    hazzard: {
      id: 10,
      substanceWorkCondition: "Alkaline solutions, diluted",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 2,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3107,
    hazzard: {
      id: 11,
      substanceWorkCondition: "Alkaline solutions, lyes (solvent based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3108,
    hazzard: {
      id: 12,
      substanceWorkCondition: "Aluminium",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3109,
    hazzard: {
      id: 14,
      substanceWorkCondition: "Antistatics",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3110,
    hazzard: {
      id: 15,
      substanceWorkCondition: "Asphalt",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3111,
    hazzard: {
      id: 19,
      substanceWorkCondition: "Battery acid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3112,
    hazzard: {
      id: 19,
      substanceWorkCondition: "Battery acid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 2,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3113,
    hazzard: {
      id: 20,
      substanceWorkCondition: "Benzene",
      cas: null,
      glovesRecommended: true,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3114,
    hazzard: {
      id: 22,
      substanceWorkCondition: "Bitumen emulsion",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3115,
    hazzard: {
      id: 21,
      substanceWorkCondition: "Bitumen",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3116,
    hazzard: {
      id: 23,
      substanceWorkCondition: "Bleaching agent (lye)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3117,
    hazzard: {
      id: 23,
      substanceWorkCondition: "Bleaching agent (lye)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 2,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3118,
    hazzard: {
      id: 24,
      substanceWorkCondition: "Brake fluid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3119,
    hazzard: {
      id: 24,
      substanceWorkCondition: "Brake fluid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 2,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3120,
    hazzard: {
      id: 25,
      substanceWorkCondition: "Casting dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3121,
    hazzard: {
      id: 26,
      substanceWorkCondition: "Cement (dry)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3122,
    hazzard: {
      id: 26,
      substanceWorkCondition: "Cement (dry)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 2,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3123,
    hazzard: {
      id: 27,
      substanceWorkCondition: "Cement (wet)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3124,
    hazzard: {
      id: 27,
      substanceWorkCondition: "Cement (wet)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 2,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3125,
    hazzard: {
      id: 28,
      substanceWorkCondition: "Cement, putty epoxy-based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3126,
    hazzard: {
      id: 29,
      substanceWorkCondition:
        "Chromates (water- insoluble) = Chromium pigments",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3127,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3128,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 2,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3129,
    hazzard: {
      id: 31,
      substanceWorkCondition: "cleaning agent, detergent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3130,
    hazzard: {
      id: 31,
      substanceWorkCondition: "cleaning agent, detergent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 2,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3131,
    hazzard: {
      id: 32,
      substanceWorkCondition: "Coal dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3132,
    hazzard: {
      id: 33,
      substanceWorkCondition: "Coating materials, bituminous (bitumen varnish)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3133,
    hazzard: {
      id: 34,
      substanceWorkCondition: "Coating materials, emulsified",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3134,
    hazzard: {
      id: 35,
      substanceWorkCondition: "Coating materials, oily",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3135,
    hazzard: {
      id: 36,
      substanceWorkCondition: "Cold degreasing agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3136,
    hazzard: {
      id: 37,
      substanceWorkCondition: "cold detergent, solvent based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3137,
    hazzard: {
      id: 43,
      substanceWorkCondition: "Commercial - Hotel & Leisure",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 2,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3138,
    hazzard: {
      id: 44,
      substanceWorkCondition: "Commercial - Prisons & Security Services",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 2,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3139,
    hazzard: {
      id: 45,
      substanceWorkCondition:
        "Commercial - Public Entertainments & Theme Parks",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 2,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3140,
    hazzard: {
      id: 47,
      substanceWorkCondition: "Concrete",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3141,
    hazzard: {
      id: 47,
      substanceWorkCondition: "Concrete",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 2,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3142,
    hazzard: {
      id: 48,
      substanceWorkCondition: "Cooling lubricant (non-water-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3143,
    hazzard: {
      id: 49,
      substanceWorkCondition: "Cooling lubricant (water- based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3144,
    hazzard: {
      id: 49,
      substanceWorkCondition: "Cooling lubricant (water- based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 2,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3145,
    hazzard: {
      id: 50,
      substanceWorkCondition: "Cooling water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3146,
    hazzard: {
      id: 50,
      substanceWorkCondition: "Cooling water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 2,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3147,
    hazzard: {
      id: 51,
      substanceWorkCondition: "Copying ink",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3148,
    hazzard: {
      id: 52,
      substanceWorkCondition: "Cutting oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3149,
    hazzard: {
      id: 53,
      substanceWorkCondition: "Degreaser solvent - based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3150,
    hazzard: {
      id: 54,
      substanceWorkCondition: "Degreaser without organic solvent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3151,
    hazzard: {
      id: 55,
      substanceWorkCondition: "Detachment solution for concrete",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3152,
    hazzard: {
      id: 56,
      substanceWorkCondition: "Detachments (alcohol-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3153,
    hazzard: {
      id: 57,
      substanceWorkCondition: "Detachments (solvent-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3154,
    hazzard: {
      id: 58,
      substanceWorkCondition: "Detachments (sour)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3155,
    hazzard: {
      id: 59,
      substanceWorkCondition: "Disinfectants (alcohol-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3156,
    hazzard: {
      id: 59,
      substanceWorkCondition: "Disinfectants (alcohol-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 2,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3157,
    hazzard: {
      id: 60,
      substanceWorkCondition: "Disinfectants (formaldehyd-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3158,
    hazzard: {
      id: 60,
      substanceWorkCondition: "Disinfectants (formaldehyd-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 2,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3159,
    hazzard: {
      id: 61,
      substanceWorkCondition: "Disinfectants (water-based) ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3160,
    hazzard: {
      id: 61,
      substanceWorkCondition: "Disinfectants (water-based) ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 2,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3161,
    hazzard: {
      id: 62,
      substanceWorkCondition: "Dispersing Agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3162,
    hazzard: {
      id: 62,
      substanceWorkCondition: "Dispersing Agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 2,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3163,
    hazzard: {
      id: 63,
      substanceWorkCondition: "Drilling emulsion (water based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3164,
    hazzard: {
      id: 63,
      substanceWorkCondition: "Drilling emulsion (water based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 2,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3165,
    hazzard: {
      id: 64,
      substanceWorkCondition: "Drilling oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3166,
    hazzard: {
      id: 65,
      substanceWorkCondition: "Dryness",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3167,
    hazzard: {
      id: 66,
      substanceWorkCondition: "Dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3168,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3169,
    hazzard: {
      id: 70,
      substanceWorkCondition: "Epoxy resin",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3170,
    hazzard: {
      id: 71,
      substanceWorkCondition: "Etching (mordant)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3171,
    hazzard: {
      id: 72,
      substanceWorkCondition: "Ethanol / methylated spirits ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3172,
    hazzard: {
      id: 73,
      substanceWorkCondition: "Fat",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3173,
    hazzard: {
      id: 74,
      substanceWorkCondition: "Fertilizer",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3174,
    hazzard: {
      id: 74,
      substanceWorkCondition: "Fertilizer",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 2,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3175,
    hazzard: {
      id: 75,
      substanceWorkCondition: "fine anorganic/inorganic powders ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3176,
    hazzard: {
      id: 75,
      substanceWorkCondition: "fine anorganic/inorganic powders ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 2,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3177,
    hazzard: {
      id: 76,
      substanceWorkCondition: "Floor cleaner (water- based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3178,
    hazzard: {
      id: 76,
      substanceWorkCondition: "Floor cleaner (water- based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 2,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3179,
    hazzard: {
      id: 80,
      substanceWorkCondition: "Food Dyestuff",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3180,
    hazzard: {
      id: 80,
      substanceWorkCondition: "Food Dyestuff",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 2,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3181,
    hazzard: {
      id: 81,
      substanceWorkCondition: "fuel: gas / diesel",
      cas: null,
      glovesRecommended: true,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3182,
    hazzard: {
      id: 82,
      substanceWorkCondition: "Galvanic bath",
      cas: null,
      glovesRecommended: true,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3183,
    hazzard: {
      id: 82,
      substanceWorkCondition: "Galvanic bath",
      cas: null,
      glovesRecommended: true,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 2,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3184,
    hazzard: {
      id: 83,
      substanceWorkCondition: "Glass fibre dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3185,
    hazzard: {
      id: 84,
      substanceWorkCondition: "Glass wool dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3186,
    hazzard: {
      id: 85,
      substanceWorkCondition: "Glycols",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3187,
    hazzard: {
      id: 85,
      substanceWorkCondition: "Glycols",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 2,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3188,
    hazzard: {
      id: 86,
      substanceWorkCondition: "Graphite",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3189,
    hazzard: {
      id: 87,
      substanceWorkCondition: "Grease",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3190,
    hazzard: {
      id: 88,
      substanceWorkCondition: "Grinding oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:50.000Z",
    updated_at: "2020-03-02T22:49:50.000Z"
  },
  {
    id: 3191,
    hazzard: {
      id: 89,
      substanceWorkCondition: "Grinding water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3192,
    hazzard: {
      id: 89,
      substanceWorkCondition: "Grinding water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 2,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3193,
    hazzard: {
      id: 90,
      substanceWorkCondition: "Gypsum",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3194,
    hazzard: {
      id: 95,
      substanceWorkCondition: "Hydraulic fluid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3195,
    hazzard: {
      id: 96,
      substanceWorkCondition: "Hyrocarbons",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3196,
    hazzard: {
      id: 97,
      substanceWorkCondition: "Industry Heavy - Building Materials",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3197,
    hazzard: {
      id: 97,
      substanceWorkCondition: "Industry Heavy - Building Materials",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 2,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3198,
    hazzard: {
      id: 98,
      substanceWorkCondition: "Industry Heavy - Construction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3199,
    hazzard: {
      id: 98,
      substanceWorkCondition: "Industry Heavy - Construction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 2,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3200,
    hazzard: {
      id: 99,
      substanceWorkCondition: "Industry Heavy - Mining & Extraction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3201,
    hazzard: {
      id: 99,
      substanceWorkCondition: "Industry Heavy - Mining & Extraction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 2,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3202,
    hazzard: {
      id: 100,
      substanceWorkCondition: "Industry Heavy - Off-Shore Extraction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3203,
    hazzard: {
      id: 100,
      substanceWorkCondition: "Industry Heavy - Off-Shore Extraction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 2,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3204,
    hazzard: {
      id: 101,
      substanceWorkCondition: "Industry Heavy - Printing Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3205,
    hazzard: {
      id: 101,
      substanceWorkCondition: "Industry Heavy - Printing Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 2,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3206,
    hazzard: {
      id: 102,
      substanceWorkCondition:
        "Industry Heavy - Road & Transportation Construction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3207,
    hazzard: {
      id: 102,
      substanceWorkCondition:
        "Industry Heavy - Road & Transportation Construction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 2,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3208,
    hazzard: {
      id: 103,
      substanceWorkCondition: "Industry Heavy - Ship & Boat Building",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3209,
    hazzard: {
      id: 103,
      substanceWorkCondition: "Industry Heavy - Ship & Boat Building",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 2,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3210,
    hazzard: {
      id: 104,
      substanceWorkCondition: "Industry Heavy - Shipping & Marine",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3211,
    hazzard: {
      id: 104,
      substanceWorkCondition: "Industry Heavy - Shipping & Marine",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 2,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3212,
    hazzard: {
      id: 105,
      substanceWorkCondition:
        "Industry Heavy - Steel, Engineering & Heavy Manufacturing",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3213,
    hazzard: {
      id: 105,
      substanceWorkCondition:
        "Industry Heavy - Steel, Engineering & Heavy Manufacturing",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 2,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3214,
    hazzard: {
      id: 107,
      substanceWorkCondition: "Industry Light - Emergency Services",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3215,
    hazzard: {
      id: 107,
      substanceWorkCondition: "Industry Light - Emergency Services",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 2,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3216,
    hazzard: {
      id: 108,
      substanceWorkCondition:
        "Industry Light - General Manufacturing & Assembly",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3217,
    hazzard: {
      id: 109,
      substanceWorkCondition:
        "Industry Light - Hosiery, Knitware, Fabric & Clothing Manufacturing",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3218,
    hazzard: {
      id: 110,
      substanceWorkCondition: "Industry Light - Science & Laboratories",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3219,
    hazzard: {
      id: 111,
      substanceWorkCondition: "Industry Light - Warehousing & Logistics",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3220,
    hazzard: {
      id: 112,
      substanceWorkCondition: "Industry Medium - Ceramics & Glassware",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3221,
    hazzard: {
      id: 112,
      substanceWorkCondition: "Industry Medium - Ceramics & Glassware",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 2,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3222,
    hazzard: {
      id: 114,
      substanceWorkCondition: "Industry Medium - Electronics Industries",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3223,
    hazzard: {
      id: 114,
      substanceWorkCondition: "Industry Medium - Electronics Industries",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 2,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3224,
    hazzard: {
      id: 115,
      substanceWorkCondition: "Industry Medium - Metal Working & Fabrication",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3225,
    hazzard: {
      id: 115,
      substanceWorkCondition: "Industry Medium - Metal Working & Fabrication",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 2,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3226,
    hazzard: {
      id: 116,
      substanceWorkCondition: "Industry Medium - Outdoor Maintenance & Repair",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3227,
    hazzard: {
      id: 116,
      substanceWorkCondition: "Industry Medium - Outdoor Maintenance & Repair",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 2,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3228,
    hazzard: {
      id: 118,
      substanceWorkCondition: "Industry Medium - Plastics Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3229,
    hazzard: {
      id: 121,
      substanceWorkCondition: "Kerosene",
      cas: null,
      glovesRecommended: true,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3230,
    hazzard: {
      id: 122,
      substanceWorkCondition: "Ketone",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3231,
    hazzard: {
      id: 122,
      substanceWorkCondition: "Ketone",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 2,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3232,
    hazzard: {
      id: 123,
      substanceWorkCondition: "Lacquers (nitro/solvent-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3233,
    hazzard: {
      id: 124,
      substanceWorkCondition: "Lacquers (PU based, synthetic resin based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3234,
    hazzard: {
      id: 125,
      substanceWorkCondition: "Lacquers (UV-hardening)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3235,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3236,
    hazzard: {
      id: 127,
      substanceWorkCondition: "Latex paint",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3237,
    hazzard: {
      id: 127,
      substanceWorkCondition: "Latex paint",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 2,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3238,
    hazzard: {
      id: 128,
      substanceWorkCondition: "Lime",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3239,
    hazzard: {
      id: 128,
      substanceWorkCondition: "Lime",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 2,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3240,
    hazzard: {
      id: 129,
      substanceWorkCondition: "Lubricants, water-based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3241,
    hazzard: {
      id: 130,
      substanceWorkCondition: "Metal dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3242,
    hazzard: {
      id: 131,
      substanceWorkCondition: "Methanol / Methyl Alcohol",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3243,
    hazzard: {
      id: 132,
      substanceWorkCondition: "Mineral oil / paraffin oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3244,
    hazzard: {
      id: 133,
      substanceWorkCondition: "Multi component adhesive",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3245,
    hazzard: {
      id: 134,
      substanceWorkCondition: "Multi component resin",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3246,
    hazzard: {
      id: 135,
      substanceWorkCondition: "Naphthalene",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3247,
    hazzard: {
      id: 136,
      substanceWorkCondition: "Oil coke (carbon)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3248,
    hazzard: {
      id: 137,
      substanceWorkCondition: "Oil-emulsions (water- mixed)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3249,
    hazzard: {
      id: 138,
      substanceWorkCondition: "Oils / Oil based lubricants",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3250,
    hazzard: {
      id: 140,
      substanceWorkCondition: "Paint Thinner ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3251,
    hazzard: {
      id: 139,
      substanceWorkCondition: "Paint",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3252,
    hazzard: {
      id: 141,
      substanceWorkCondition: "Pit water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3253,
    hazzard: {
      id: 141,
      substanceWorkCondition: "Pit water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 2,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3254,
    hazzard: {
      id: 142,
      substanceWorkCondition: "Pitch",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3255,
    hazzard: {
      id: 143,
      substanceWorkCondition: "Polishes (water- soluble)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3256,
    hazzard: {
      id: 143,
      substanceWorkCondition: "Polishes (water- soluble)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3257,
    hazzard: {
      id: 144,
      substanceWorkCondition: "Polyester / Polyester resin",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3258,
    hazzard: {
      id: 145,
      substanceWorkCondition: "Polyurethane",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3259,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3260,
    hazzard: {
      id: 148,
      substanceWorkCondition:
        "Propanols (propyl alcohols): n-Propanol, Isopropanol / 2-Propanol ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3261,
    hazzard: {
      id: 150,
      substanceWorkCondition: "Resins / urea resins",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3262,
    hazzard: {
      id: 151,
      substanceWorkCondition: "Rock wool",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3263,
    hazzard: {
      id: 152,
      substanceWorkCondition: "Rubber",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3264,
    hazzard: {
      id: 153,
      substanceWorkCondition: "Salts / Salt solution",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3265,
    hazzard: {
      id: 153,
      substanceWorkCondition: "Salts / Salt solution",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 2,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3266,
    hazzard: {
      id: 154,
      substanceWorkCondition: "Sealing compound",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3267,
    hazzard: {
      id: 155,
      substanceWorkCondition: "Sintering",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3268,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3269,
    hazzard: {
      id: 157,
      substanceWorkCondition: "Solvents, - organic",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3270,
    hazzard: {
      id: 158,
      substanceWorkCondition: "Soot",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3271,
    hazzard: {
      id: 159,
      substanceWorkCondition: "Styrene",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3272,
    hazzard: {
      id: 160,
      substanceWorkCondition: "Surface Cleanser (surfactant based), Detergents",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3273,
    hazzard: {
      id: 160,
      substanceWorkCondition: "Surface Cleanser (surfactant based), Detergents",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 2,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3274,
    hazzard: {
      id: 161,
      substanceWorkCondition: "Synthetic melting glaze",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3275,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3276,
    hazzard: {
      id: 163,
      substanceWorkCondition: "Tanning agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3277,
    hazzard: {
      id: 163,
      substanceWorkCondition: "Tanning agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 2,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3278,
    hazzard: {
      id: 164,
      substanceWorkCondition: "Tar",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3279,
    hazzard: {
      id: 166,
      substanceWorkCondition: "Thinner",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3280,
    hazzard: {
      id: 167,
      substanceWorkCondition: "Toluene",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3281,
    hazzard: {
      id: 168,
      substanceWorkCondition: "Turpentine oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3282,
    hazzard: {
      id: 170,
      substanceWorkCondition: "Washing bath, washing liquid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3283,
    hazzard: {
      id: 170,
      substanceWorkCondition: "Washing bath, washing liquid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 2,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3284,
    hazzard: {
      id: 171,
      substanceWorkCondition: "Waste oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3285,
    hazzard: {
      id: 172,
      substanceWorkCondition: "Water / wet work",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3286,
    hazzard: {
      id: 172,
      substanceWorkCondition: "Water / wet work",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 2,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3287,
    hazzard: {
      id: 174,
      substanceWorkCondition: "wire drawing oil / wire drawing grease",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3288,
    hazzard: {
      id: 175,
      substanceWorkCondition: "Xylene",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3289,
    hazzard: {
      id: 999,
      substanceWorkCondition: "All Products",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:44:03.000Z",
      updated_at: "2020-03-02T17:44:03.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3290,
    hazzard: {
      id: 999,
      substanceWorkCondition: "All Products",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:44:03.000Z",
      updated_at: "2020-03-02T17:44:03.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 4,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3291,
    hazzard: {
      id: 1,
      substanceWorkCondition: "2-Butanone (MEK)",
      cas: "78-93-3",
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: "oil",
      created_at: "2020-03-02T17:39:32.000Z",
      updated_at: "2020-03-05T14:48:01.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3292,
    hazzard: {
      id: 1,
      substanceWorkCondition: "2-Butanone (MEK)",
      cas: "78-93-3",
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: "oil",
      created_at: "2020-03-02T17:39:32.000Z",
      updated_at: "2020-03-05T14:48:01.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 1,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3293,
    hazzard: {
      id: 2,
      substanceWorkCondition: "Acetone / Dimethyl ketone",
      cas: "67-64-1",
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3294,
    hazzard: {
      id: 2,
      substanceWorkCondition: "Acetone / Dimethyl ketone",
      cas: "67-64-1",
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 1,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3295,
    hazzard: {
      id: 3,
      substanceWorkCondition: "Acids, diluted",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3296,
    hazzard: {
      id: 3,
      substanceWorkCondition: "Acids, diluted",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 1,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3297,
    hazzard: {
      id: 5,
      substanceWorkCondition: "Adhesive single-component",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Protect",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 34,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:44:37.000Z",
      productName: "Stokoderm® Advanced",
      productURL: "https://www.debgroup.com/us/products/stokoderm-advanced-0"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: true,
    hair: false,
    glove: false,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3298,
    hazzard: {
      id: 5,
      substanceWorkCondition: "Adhesive single-component",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Protect",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 43,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:50.000Z",
      productName: "Travabon® Classic",
      productURL: "https://www.debgroup.com/us/products/travabon-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: null,
    hair: false,
    glove: false,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:51.000Z",
    updated_at: "2020-03-02T22:49:51.000Z"
  },
  {
    id: 3299,
    hazzard: {
      id: 6,
      substanceWorkCondition: "Adhesive, water-soluble",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3300,
    hazzard: {
      id: 6,
      substanceWorkCondition: "Adhesive, water-soluble",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 1,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3301,
    hazzard: {
      id: 7,
      substanceWorkCondition: "Adhesives, glues based on synthetic resin",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Protect",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 43,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:50.000Z",
      productName: "Travabon® Classic",
      productURL: "https://www.debgroup.com/us/products/travabon-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: null,
    hair: false,
    glove: false,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3302,
    hazzard: {
      id: 8,
      substanceWorkCondition: "Aldehydes, diluted",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3303,
    hazzard: {
      id: 8,
      substanceWorkCondition: "Aldehydes, diluted",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 1,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3304,
    hazzard: {
      id: 9,
      substanceWorkCondition: "Alkaline solutions / lyes / thinned caustics",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3305,
    hazzard: {
      id: 9,
      substanceWorkCondition: "Alkaline solutions / lyes / thinned caustics",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 1,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3306,
    hazzard: {
      id: 10,
      substanceWorkCondition: "Alkaline solutions, diluted",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3307,
    hazzard: {
      id: 10,
      substanceWorkCondition: "Alkaline solutions, diluted",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 1,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3308,
    hazzard: {
      id: 11,
      substanceWorkCondition: "Alkaline solutions, lyes (solvent based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3309,
    hazzard: {
      id: 12,
      substanceWorkCondition: "Aluminium",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 43,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:50.000Z",
      productName: "Travabon® Classic",
      productURL: "https://www.debgroup.com/us/products/travabon-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: null,
    hair: false,
    glove: false,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3310,
    hazzard: {
      id: 14,
      substanceWorkCondition: "Antistatics",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3311,
    hazzard: {
      id: 18,
      substanceWorkCondition:
        "Automotive Aftermarket - Public Transport Maintenance&Repair",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 43,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:50.000Z",
      productName: "Travabon® Classic",
      productURL: "https://www.debgroup.com/us/products/travabon-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: null,
    hair: false,
    glove: false,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3312,
    hazzard: {
      id: 19,
      substanceWorkCondition: "Battery acid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3313,
    hazzard: {
      id: 19,
      substanceWorkCondition: "Battery acid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 1,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3314,
    hazzard: {
      id: 22,
      substanceWorkCondition: "Bitumen emulsion",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3315,
    hazzard: {
      id: 21,
      substanceWorkCondition: "Bitumen",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 43,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:50.000Z",
      productName: "Travabon® Classic",
      productURL: "https://www.debgroup.com/us/products/travabon-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: null,
    hair: false,
    glove: false,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3316,
    hazzard: {
      id: 23,
      substanceWorkCondition: "Bleaching agent (lye)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3317,
    hazzard: {
      id: 23,
      substanceWorkCondition: "Bleaching agent (lye)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 1,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3318,
    hazzard: {
      id: 24,
      substanceWorkCondition: "Brake fluid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3319,
    hazzard: {
      id: 24,
      substanceWorkCondition: "Brake fluid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 1,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3320,
    hazzard: {
      id: 25,
      substanceWorkCondition: "Casting dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 43,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:50.000Z",
      productName: "Travabon® Classic",
      productURL: "https://www.debgroup.com/us/products/travabon-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: null,
    hair: false,
    glove: false,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3321,
    hazzard: {
      id: 26,
      substanceWorkCondition: "Cement (dry)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3322,
    hazzard: {
      id: 26,
      substanceWorkCondition: "Cement (dry)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 1,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3323,
    hazzard: {
      id: 27,
      substanceWorkCondition: "Cement (wet)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3324,
    hazzard: {
      id: 27,
      substanceWorkCondition: "Cement (wet)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 1,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3325,
    hazzard: {
      id: 29,
      substanceWorkCondition:
        "Chromates (water- insoluble) = Chromium pigments",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 43,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:50.000Z",
      productName: "Travabon® Classic",
      productURL: "https://www.debgroup.com/us/products/travabon-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: null,
    hair: false,
    glove: false,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3326,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3327,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 1,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3328,
    hazzard: {
      id: 31,
      substanceWorkCondition: "cleaning agent, detergent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3329,
    hazzard: {
      id: 31,
      substanceWorkCondition: "cleaning agent, detergent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 1,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3330,
    hazzard: {
      id: 32,
      substanceWorkCondition: "Coal dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 43,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:50.000Z",
      productName: "Travabon® Classic",
      productURL: "https://www.debgroup.com/us/products/travabon-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: null,
    hair: false,
    glove: false,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3331,
    hazzard: {
      id: 33,
      substanceWorkCondition: "Coating materials, bituminous (bitumen varnish)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 43,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:50.000Z",
      productName: "Travabon® Classic",
      productURL: "https://www.debgroup.com/us/products/travabon-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: null,
    hair: false,
    glove: false,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3332,
    hazzard: {
      id: 35,
      substanceWorkCondition: "Coating materials, oily",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 43,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:50.000Z",
      productName: "Travabon® Classic",
      productURL: "https://www.debgroup.com/us/products/travabon-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: null,
    hair: false,
    glove: false,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3333,
    hazzard: {
      id: 43,
      substanceWorkCondition: "Commercial - Hotel & Leisure",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 1,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3334,
    hazzard: {
      id: 44,
      substanceWorkCondition: "Commercial - Prisons & Security Services",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 1,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3335,
    hazzard: {
      id: 45,
      substanceWorkCondition:
        "Commercial - Public Entertainments & Theme Parks",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 1,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3336,
    hazzard: {
      id: 47,
      substanceWorkCondition: "Concrete",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 1,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3337,
    hazzard: {
      id: 49,
      substanceWorkCondition: "Cooling lubricant (water- based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3338,
    hazzard: {
      id: 49,
      substanceWorkCondition: "Cooling lubricant (water- based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 1,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3339,
    hazzard: {
      id: 50,
      substanceWorkCondition: "Cooling water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3340,
    hazzard: {
      id: 50,
      substanceWorkCondition: "Cooling water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 1,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3341,
    hazzard: {
      id: 54,
      substanceWorkCondition: "Degreaser without organic solvent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3342,
    hazzard: {
      id: 55,
      substanceWorkCondition: "Detachment solution for concrete",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3343,
    hazzard: {
      id: 56,
      substanceWorkCondition: "Detachments (alcohol-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3344,
    hazzard: {
      id: 59,
      substanceWorkCondition: "Disinfectants (alcohol-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 1,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3345,
    hazzard: {
      id: 61,
      substanceWorkCondition: "Disinfectants (water-based) ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3346,
    hazzard: {
      id: 61,
      substanceWorkCondition: "Disinfectants (water-based) ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 1,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3347,
    hazzard: {
      id: 62,
      substanceWorkCondition: "Dispersing Agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3348,
    hazzard: {
      id: 62,
      substanceWorkCondition: "Dispersing Agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 1,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3349,
    hazzard: {
      id: 63,
      substanceWorkCondition: "Drilling emulsion (water based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3350,
    hazzard: {
      id: 63,
      substanceWorkCondition: "Drilling emulsion (water based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 1,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3351,
    hazzard: {
      id: 66,
      substanceWorkCondition: "Dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3352,
    hazzard: {
      id: 75,
      substanceWorkCondition: "fine anorganic/inorganic powders ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3353,
    hazzard: {
      id: 75,
      substanceWorkCondition: "fine anorganic/inorganic powders ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 1,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3354,
    hazzard: {
      id: 85,
      substanceWorkCondition: "Glycols",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 1,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3355,
    hazzard: {
      id: 89,
      substanceWorkCondition: "Grinding water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3356,
    hazzard: {
      id: 89,
      substanceWorkCondition: "Grinding water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 1,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3357,
    hazzard: {
      id: 90,
      substanceWorkCondition: "Gypsum",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3358,
    hazzard: {
      id: 90,
      substanceWorkCondition: "Gypsum",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 1,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3359,
    hazzard: {
      id: 97,
      substanceWorkCondition: "Industry Heavy - Building Materials",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3360,
    hazzard: {
      id: 97,
      substanceWorkCondition: "Industry Heavy - Building Materials",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 1,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3361,
    hazzard: {
      id: 98,
      substanceWorkCondition: "Industry Heavy - Construction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3362,
    hazzard: {
      id: 98,
      substanceWorkCondition: "Industry Heavy - Construction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 1,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3363,
    hazzard: {
      id: 99,
      substanceWorkCondition: "Industry Heavy - Mining & Extraction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3364,
    hazzard: {
      id: 99,
      substanceWorkCondition: "Industry Heavy - Mining & Extraction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 43,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:50.000Z",
      productName: "Travabon® Classic",
      productURL: "https://www.debgroup.com/us/products/travabon-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: null,
    hair: false,
    glove: false,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3365,
    hazzard: {
      id: 99,
      substanceWorkCondition: "Industry Heavy - Mining & Extraction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 1,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3366,
    hazzard: {
      id: 100,
      substanceWorkCondition: "Industry Heavy - Off-Shore Extraction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3367,
    hazzard: {
      id: 100,
      substanceWorkCondition: "Industry Heavy - Off-Shore Extraction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 1,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3368,
    hazzard: {
      id: 101,
      substanceWorkCondition: "Industry Heavy - Printing Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3369,
    hazzard: {
      id: 101,
      substanceWorkCondition: "Industry Heavy - Printing Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 43,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:50.000Z",
      productName: "Travabon® Classic",
      productURL: "https://www.debgroup.com/us/products/travabon-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: null,
    hair: false,
    glove: false,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3370,
    hazzard: {
      id: 101,
      substanceWorkCondition: "Industry Heavy - Printing Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 1,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3371,
    hazzard: {
      id: 102,
      substanceWorkCondition:
        "Industry Heavy - Road & Transportation Construction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3372,
    hazzard: {
      id: 102,
      substanceWorkCondition:
        "Industry Heavy - Road & Transportation Construction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 1,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3373,
    hazzard: {
      id: 103,
      substanceWorkCondition: "Industry Heavy - Ship & Boat Building",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3374,
    hazzard: {
      id: 103,
      substanceWorkCondition: "Industry Heavy - Ship & Boat Building",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 1,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3375,
    hazzard: {
      id: 104,
      substanceWorkCondition: "Industry Heavy - Shipping & Marine",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3376,
    hazzard: {
      id: 104,
      substanceWorkCondition: "Industry Heavy - Shipping & Marine",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 1,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3377,
    hazzard: {
      id: 105,
      substanceWorkCondition:
        "Industry Heavy - Steel, Engineering & Heavy Manufacturing",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3378,
    hazzard: {
      id: 105,
      substanceWorkCondition:
        "Industry Heavy - Steel, Engineering & Heavy Manufacturing",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 1,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3379,
    hazzard: {
      id: 106,
      substanceWorkCondition:
        "Industry Light - Airports & Transportation Terminals - Services & Facility Maintenance",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3380,
    hazzard: {
      id: 107,
      substanceWorkCondition: "Industry Light - Emergency Services",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3381,
    hazzard: {
      id: 107,
      substanceWorkCondition: "Industry Light - Emergency Services",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 43,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:50.000Z",
      productName: "Travabon® Classic",
      productURL: "https://www.debgroup.com/us/products/travabon-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: null,
    hair: false,
    glove: false,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3382,
    hazzard: {
      id: 107,
      substanceWorkCondition: "Industry Light - Emergency Services",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 1,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3383,
    hazzard: {
      id: 108,
      substanceWorkCondition:
        "Industry Light - General Manufacturing & Assembly",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3384,
    hazzard: {
      id: 109,
      substanceWorkCondition:
        "Industry Light - Hosiery, Knitware, Fabric & Clothing Manufacturing",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3385,
    hazzard: {
      id: 110,
      substanceWorkCondition: "Industry Light - Science & Laboratories",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3386,
    hazzard: {
      id: 111,
      substanceWorkCondition: "Industry Light - Warehousing & Logistics",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3387,
    hazzard: {
      id: 112,
      substanceWorkCondition: "Industry Medium - Ceramics & Glassware",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3388,
    hazzard: {
      id: 112,
      substanceWorkCondition: "Industry Medium - Ceramics & Glassware",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 43,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:50.000Z",
      productName: "Travabon® Classic",
      productURL: "https://www.debgroup.com/us/products/travabon-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: null,
    hair: false,
    glove: false,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3389,
    hazzard: {
      id: 112,
      substanceWorkCondition: "Industry Medium - Ceramics & Glassware",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 1,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3390,
    hazzard: {
      id: 114,
      substanceWorkCondition: "Industry Medium - Electronics Industries",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3391,
    hazzard: {
      id: 114,
      substanceWorkCondition: "Industry Medium - Electronics Industries",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 1,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3392,
    hazzard: {
      id: 115,
      substanceWorkCondition: "Industry Medium - Metal Working & Fabrication",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3393,
    hazzard: {
      id: 115,
      substanceWorkCondition: "Industry Medium - Metal Working & Fabrication",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 43,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:50.000Z",
      productName: "Travabon® Classic",
      productURL: "https://www.debgroup.com/us/products/travabon-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: null,
    hair: false,
    glove: false,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3394,
    hazzard: {
      id: 115,
      substanceWorkCondition: "Industry Medium - Metal Working & Fabrication",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 1,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3395,
    hazzard: {
      id: 116,
      substanceWorkCondition: "Industry Medium - Outdoor Maintenance & Repair",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3396,
    hazzard: {
      id: 116,
      substanceWorkCondition: "Industry Medium - Outdoor Maintenance & Repair",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 1,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3397,
    hazzard: {
      id: 118,
      substanceWorkCondition: "Industry Medium - Plastics Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3398,
    hazzard: {
      id: 122,
      substanceWorkCondition: "Ketone",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3399,
    hazzard: {
      id: 122,
      substanceWorkCondition: "Ketone",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 1,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3400,
    hazzard: {
      id: 125,
      substanceWorkCondition: "Lacquers (UV-hardening)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 43,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:50.000Z",
      productName: "Travabon® Classic",
      productURL: "https://www.debgroup.com/us/products/travabon-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: null,
    hair: false,
    glove: false,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3401,
    hazzard: {
      id: 127,
      substanceWorkCondition: "Latex paint",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3402,
    hazzard: {
      id: 127,
      substanceWorkCondition: "Latex paint",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 1,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3403,
    hazzard: {
      id: 128,
      substanceWorkCondition: "Lime",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3404,
    hazzard: {
      id: 128,
      substanceWorkCondition: "Lime",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 1,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3405,
    hazzard: {
      id: 129,
      substanceWorkCondition: "Lubricants, water-based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3406,
    hazzard: {
      id: 137,
      substanceWorkCondition: "Oil-emulsions (water- mixed)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3407,
    hazzard: {
      id: 137,
      substanceWorkCondition: "Oil-emulsions (water- mixed)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 1,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3408,
    hazzard: {
      id: 141,
      substanceWorkCondition: "Pit water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3409,
    hazzard: {
      id: 141,
      substanceWorkCondition: "Pit water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 1,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3410,
    hazzard: {
      id: 143,
      substanceWorkCondition: "Polishes (water- soluble)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3411,
    hazzard: {
      id: 149,
      substanceWorkCondition: "release agent, parting agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 43,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:50.000Z",
      productName: "Travabon® Classic",
      productURL: "https://www.debgroup.com/us/products/travabon-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: null,
    hair: false,
    glove: false,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3412,
    hazzard: {
      id: 153,
      substanceWorkCondition: "Salts / Salt solution",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3413,
    hazzard: {
      id: 153,
      substanceWorkCondition: "Salts / Salt solution",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 1,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3414,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Protect",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 43,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:50.000Z",
      productName: "Travabon® Classic",
      productURL: "https://www.debgroup.com/us/products/travabon-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: null,
    hair: false,
    glove: false,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3415,
    hazzard: {
      id: 158,
      substanceWorkCondition: "Soot",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 43,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:50.000Z",
      productName: "Travabon® Classic",
      productURL: "https://www.debgroup.com/us/products/travabon-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: null,
    hair: false,
    glove: false,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3416,
    hazzard: {
      id: 160,
      substanceWorkCondition: "Surface Cleanser (surfactant based), Detergents",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 1,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3417,
    hazzard: {
      id: 163,
      substanceWorkCondition: "Tanning agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 1,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3418,
    hazzard: {
      id: 170,
      substanceWorkCondition: "Washing bath, washing liquid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3419,
    hazzard: {
      id: 170,
      substanceWorkCondition: "Washing bath, washing liquid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 1,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3420,
    hazzard: {
      id: 172,
      substanceWorkCondition: "Water / wet work",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3421,
    hazzard: {
      id: 172,
      substanceWorkCondition: "Water / wet work",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 1,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3422,
    hazzard: {
      id: 174,
      substanceWorkCondition: "wire drawing oil / wire drawing grease",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 43,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:50.000Z",
      productName: "Travabon® Classic",
      productURL: "https://www.debgroup.com/us/products/travabon-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: null,
    hair: false,
    glove: false,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3423,
    hazzard: {
      id: 999,
      substanceWorkCondition: "All Products",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:44:03.000Z",
      updated_at: "2020-03-02T17:44:03.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3424,
    hazzard: {
      id: 999,
      substanceWorkCondition: "All Products",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:44:03.000Z",
      updated_at: "2020-03-02T17:44:03.000Z"
    },
    step: "Protect",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 43,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:50.000Z",
      productName: "Travabon® Classic",
      productURL: "https://www.debgroup.com/us/products/travabon-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: null,
    hair: false,
    glove: false,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3425,
    hazzard: {
      id: 999,
      substanceWorkCondition: "All Products",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:44:03.000Z",
      updated_at: "2020-03-02T17:44:03.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 4,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3426,
    hazzard: {
      id: 4,
      substanceWorkCondition: "Acrylates",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Cleanse",
    productOrder: 21,
    locationOrder: 1,
    skin_care_product: {
      id: 14,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:29:50.000Z",
      productName: "Kresto® Classic",
      productURL: "https://www.debgroup.com/us/products/kresto-classic"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3427,
    hazzard: {
      id: 4,
      substanceWorkCondition: "Acrylates",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Cleanse",
    productOrder: 22,
    locationOrder: 4,
    skin_care_product: {
      id: 12,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:28:21.000Z",
      productName: "Kresto® Cherry",
      productURL: "https://www.debgroup.com/us/products/kresto-cherry"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3428,
    hazzard: {
      id: 4,
      substanceWorkCondition: "Acrylates",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Cleanse",
    productOrder: 23,
    locationOrder: 1,
    skin_care_product: {
      id: 13,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:28:48.000Z",
      productName: "Kresto® Cherry Wipes",
      productURL: "https://www.debgroup.com/us/products/kresto-cherry-wipes"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3429,
    hazzard: {
      id: 4,
      substanceWorkCondition: "Acrylates",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Cleanse",
    productOrder: 25,
    locationOrder: 1,
    skin_care_product: {
      id: 17,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:32:06.000Z",
      productName: "Kresto® Special ULTRA",
      productURL: "https://www.debgroup.com/us/products/kresto-special-ultra"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: false,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3430,
    hazzard: {
      id: 5,
      substanceWorkCondition: "Adhesive single-component",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Cleanse",
    productOrder: 21,
    locationOrder: 1,
    skin_care_product: {
      id: 14,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:29:50.000Z",
      productName: "Kresto® Classic",
      productURL: "https://www.debgroup.com/us/products/kresto-classic"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3431,
    hazzard: {
      id: 5,
      substanceWorkCondition: "Adhesive single-component",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Cleanse",
    productOrder: 22,
    locationOrder: 4,
    skin_care_product: {
      id: 12,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:28:21.000Z",
      productName: "Kresto® Cherry",
      productURL: "https://www.debgroup.com/us/products/kresto-cherry"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3432,
    hazzard: {
      id: 5,
      substanceWorkCondition: "Adhesive single-component",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Cleanse",
    productOrder: 23,
    locationOrder: 1,
    skin_care_product: {
      id: 13,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:28:48.000Z",
      productName: "Kresto® Cherry Wipes",
      productURL: "https://www.debgroup.com/us/products/kresto-cherry-wipes"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3433,
    hazzard: {
      id: 5,
      substanceWorkCondition: "Adhesive single-component",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Cleanse",
    productOrder: 25,
    locationOrder: 1,
    skin_care_product: {
      id: 17,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:32:06.000Z",
      productName: "Kresto® Special ULTRA",
      productURL: "https://www.debgroup.com/us/products/kresto-special-ultra"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3434,
    hazzard: {
      id: 6,
      substanceWorkCondition: "Adhesive, water-soluble",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Cleanse",
    productOrder: 15,
    locationOrder: 1,
    skin_care_product: {
      id: 29,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:41:00.000Z",
      productName: "Solopol® Classic  (USDA)",
      productURL: "https://www.debgroup.com/us/products/solopol-classic"
    },
    rating: 4,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3435,
    hazzard: {
      id: 7,
      substanceWorkCondition: "Adhesives, glues based on synthetic resin",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Cleanse",
    productOrder: 21,
    locationOrder: 1,
    skin_care_product: {
      id: 14,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:29:50.000Z",
      productName: "Kresto® Classic",
      productURL: "https://www.debgroup.com/us/products/kresto-classic"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3436,
    hazzard: {
      id: 7,
      substanceWorkCondition: "Adhesives, glues based on synthetic resin",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Cleanse",
    productOrder: 22,
    locationOrder: 4,
    skin_care_product: {
      id: 12,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:28:21.000Z",
      productName: "Kresto® Cherry",
      productURL: "https://www.debgroup.com/us/products/kresto-cherry"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3437,
    hazzard: {
      id: 7,
      substanceWorkCondition: "Adhesives, glues based on synthetic resin",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Cleanse",
    productOrder: 23,
    locationOrder: 1,
    skin_care_product: {
      id: 13,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:28:48.000Z",
      productName: "Kresto® Cherry Wipes",
      productURL: "https://www.debgroup.com/us/products/kresto-cherry-wipes"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3438,
    hazzard: {
      id: 7,
      substanceWorkCondition: "Adhesives, glues based on synthetic resin",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Cleanse",
    productOrder: 25,
    locationOrder: 1,
    skin_care_product: {
      id: 17,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:32:06.000Z",
      productName: "Kresto® Special ULTRA",
      productURL: "https://www.debgroup.com/us/products/kresto-special-ultra"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: false,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3439,
    hazzard: {
      id: 8,
      substanceWorkCondition: "Aldehydes, diluted",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Cleanse",
    productOrder: 13,
    locationOrder: 1,
    skin_care_product: {
      id: 33,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:49.000Z",
      productName: "Solopol® Lime (UL)",
      productURL: "https://www.debgroup.com/us/products/solopol-lime"
    },
    rating: 3,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3440,
    hazzard: {
      id: 11,
      substanceWorkCondition: "Alkaline solutions, lyes (solvent based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 13,
    locationOrder: 1,
    skin_care_product: {
      id: 33,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:49.000Z",
      productName: "Solopol® Lime (UL)",
      productURL: "https://www.debgroup.com/us/products/solopol-lime"
    },
    rating: 3,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3441,
    hazzard: {
      id: 12,
      substanceWorkCondition: "Aluminium",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 15,
    locationOrder: 1,
    skin_care_product: {
      id: 29,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:41:00.000Z",
      productName: "Solopol® Classic  (USDA)",
      productURL: "https://www.debgroup.com/us/products/solopol-classic"
    },
    rating: 4,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3442,
    hazzard: {
      id: 14,
      substanceWorkCondition: "Antistatics",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 13,
    locationOrder: 1,
    skin_care_product: {
      id: 33,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:49.000Z",
      productName: "Solopol® Lime (UL)",
      productURL: "https://www.debgroup.com/us/products/solopol-lime"
    },
    rating: 3,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3443,
    hazzard: {
      id: 15,
      substanceWorkCondition: "Asphalt",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 21,
    locationOrder: 1,
    skin_care_product: {
      id: 14,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:29:50.000Z",
      productName: "Kresto® Classic",
      productURL: "https://www.debgroup.com/us/products/kresto-classic"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3444,
    hazzard: {
      id: 15,
      substanceWorkCondition: "Asphalt",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 23,
    locationOrder: 1,
    skin_care_product: {
      id: 13,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:28:48.000Z",
      productName: "Kresto® Cherry Wipes",
      productURL: "https://www.debgroup.com/us/products/kresto-cherry-wipes"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3445,
    hazzard: {
      id: 15,
      substanceWorkCondition: "Asphalt",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 25,
    locationOrder: 1,
    skin_care_product: {
      id: 17,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:32:06.000Z",
      productName: "Kresto® Special ULTRA",
      productURL: "https://www.debgroup.com/us/products/kresto-special-ultra"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: false,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3446,
    hazzard: {
      id: 16,
      substanceWorkCondition:
        "Automotive Aftermarket - Automotive Maintenance & Repair",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 20,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:34:28.000Z",
      productName: "KrestoGT™ Paint Shop",
      productURL: "https://www.debgroup.com/us/products/krestogt-paint-shop"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3447,
    hazzard: {
      id: 16,
      substanceWorkCondition:
        "Automotive Aftermarket - Automotive Maintenance & Repair",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 19,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:33:37.000Z",
      productName: "KrestoGT® Cherry Wipes",
      productURL:
        "https://www.debgroup.com/us/products/krestogt-cherry-scrubbing-wipes"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: false,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3448,
    hazzard: {
      id: 17,
      substanceWorkCondition: "Automotive Aftermarket - Paint & Bodyshops",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 20,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:34:28.000Z",
      productName: "KrestoGT™ Paint Shop",
      productURL: "https://www.debgroup.com/us/products/krestogt-paint-shop"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3449,
    hazzard: {
      id: 17,
      substanceWorkCondition: "Automotive Aftermarket - Paint & Bodyshops",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 19,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:33:37.000Z",
      productName: "KrestoGT® Cherry Wipes",
      productURL:
        "https://www.debgroup.com/us/products/krestogt-cherry-scrubbing-wipes"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: false,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3450,
    hazzard: {
      id: 18,
      substanceWorkCondition:
        "Automotive Aftermarket - Public Transport Maintenance&Repair",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 15,
    locationOrder: 1,
    skin_care_product: {
      id: 29,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:41:00.000Z",
      productName: "Solopol® Classic  (USDA)",
      productURL: "https://www.debgroup.com/us/products/solopol-classic"
    },
    rating: 4,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3451,
    hazzard: {
      id: 18,
      substanceWorkCondition:
        "Automotive Aftermarket - Public Transport Maintenance&Repair",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 21,
    locationOrder: 1,
    skin_care_product: {
      id: 14,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:29:50.000Z",
      productName: "Kresto® Classic",
      productURL: "https://www.debgroup.com/us/products/kresto-classic"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3452,
    hazzard: {
      id: 18,
      substanceWorkCondition:
        "Automotive Aftermarket - Public Transport Maintenance&Repair",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 22,
    locationOrder: 4,
    skin_care_product: {
      id: 12,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:28:21.000Z",
      productName: "Kresto® Cherry",
      productURL: "https://www.debgroup.com/us/products/kresto-cherry"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3453,
    hazzard: {
      id: 18,
      substanceWorkCondition:
        "Automotive Aftermarket - Public Transport Maintenance&Repair",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 23,
    locationOrder: 1,
    skin_care_product: {
      id: 13,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:28:48.000Z",
      productName: "Kresto® Cherry Wipes",
      productURL: "https://www.debgroup.com/us/products/kresto-cherry-wipes"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3454,
    hazzard: {
      id: 18,
      substanceWorkCondition:
        "Automotive Aftermarket - Public Transport Maintenance&Repair",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 25,
    locationOrder: 1,
    skin_care_product: {
      id: 17,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:32:06.000Z",
      productName: "Kresto® Special ULTRA",
      productURL: "https://www.debgroup.com/us/products/kresto-special-ultra"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: false,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3455,
    hazzard: {
      id: 19,
      substanceWorkCondition: "Battery acid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 13,
    locationOrder: 1,
    skin_care_product: {
      id: 33,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:49.000Z",
      productName: "Solopol® Lime (UL)",
      productURL: "https://www.debgroup.com/us/products/solopol-lime"
    },
    rating: 3,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3456,
    hazzard: {
      id: 20,
      substanceWorkCondition: "Benzene",
      cas: null,
      glovesRecommended: true,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 13,
    locationOrder: 1,
    skin_care_product: {
      id: 33,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:49.000Z",
      productName: "Solopol® Lime (UL)",
      productURL: "https://www.debgroup.com/us/products/solopol-lime"
    },
    rating: 3,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3457,
    hazzard: {
      id: 22,
      substanceWorkCondition: "Bitumen emulsion",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 21,
    locationOrder: 1,
    skin_care_product: {
      id: 14,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:29:50.000Z",
      productName: "Kresto® Classic",
      productURL: "https://www.debgroup.com/us/products/kresto-classic"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:52.000Z",
    updated_at: "2020-03-02T22:49:52.000Z"
  },
  {
    id: 3458,
    hazzard: {
      id: 22,
      substanceWorkCondition: "Bitumen emulsion",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 22,
    locationOrder: 4,
    skin_care_product: {
      id: 12,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:28:21.000Z",
      productName: "Kresto® Cherry",
      productURL: "https://www.debgroup.com/us/products/kresto-cherry"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3459,
    hazzard: {
      id: 22,
      substanceWorkCondition: "Bitumen emulsion",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 23,
    locationOrder: 1,
    skin_care_product: {
      id: 13,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:28:48.000Z",
      productName: "Kresto® Cherry Wipes",
      productURL: "https://www.debgroup.com/us/products/kresto-cherry-wipes"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3460,
    hazzard: {
      id: 22,
      substanceWorkCondition: "Bitumen emulsion",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 25,
    locationOrder: 1,
    skin_care_product: {
      id: 17,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:32:06.000Z",
      productName: "Kresto® Special ULTRA",
      productURL: "https://www.debgroup.com/us/products/kresto-special-ultra"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: false,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3461,
    hazzard: {
      id: 21,
      substanceWorkCondition: "Bitumen",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 21,
    locationOrder: 1,
    skin_care_product: {
      id: 14,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:29:50.000Z",
      productName: "Kresto® Classic",
      productURL: "https://www.debgroup.com/us/products/kresto-classic"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3462,
    hazzard: {
      id: 21,
      substanceWorkCondition: "Bitumen",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 22,
    locationOrder: 4,
    skin_care_product: {
      id: 12,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:28:21.000Z",
      productName: "Kresto® Cherry",
      productURL: "https://www.debgroup.com/us/products/kresto-cherry"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3463,
    hazzard: {
      id: 21,
      substanceWorkCondition: "Bitumen",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 23,
    locationOrder: 1,
    skin_care_product: {
      id: 13,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:28:48.000Z",
      productName: "Kresto® Cherry Wipes",
      productURL: "https://www.debgroup.com/us/products/kresto-cherry-wipes"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3464,
    hazzard: {
      id: 21,
      substanceWorkCondition: "Bitumen",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 25,
    locationOrder: 1,
    skin_care_product: {
      id: 17,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:32:06.000Z",
      productName: "Kresto® Special ULTRA",
      productURL: "https://www.debgroup.com/us/products/kresto-special-ultra"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3465,
    hazzard: {
      id: 23,
      substanceWorkCondition: "Bleaching agent (lye)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 13,
    locationOrder: 1,
    skin_care_product: {
      id: 33,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:49.000Z",
      productName: "Solopol® Lime (UL)",
      productURL: "https://www.debgroup.com/us/products/solopol-lime"
    },
    rating: 3,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3466,
    hazzard: {
      id: 24,
      substanceWorkCondition: "Brake fluid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 15,
    locationOrder: 1,
    skin_care_product: {
      id: 29,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:41:00.000Z",
      productName: "Solopol® Classic  (USDA)",
      productURL: "https://www.debgroup.com/us/products/solopol-classic"
    },
    rating: 4,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3467,
    hazzard: {
      id: 25,
      substanceWorkCondition: "Casting dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 15,
    locationOrder: 1,
    skin_care_product: {
      id: 29,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:41:00.000Z",
      productName: "Solopol® Classic  (USDA)",
      productURL: "https://www.debgroup.com/us/products/solopol-classic"
    },
    rating: 4,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3468,
    hazzard: {
      id: 25,
      substanceWorkCondition: "Casting dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 15,
    locationOrder: 1,
    skin_care_product: {
      id: 29,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:41:00.000Z",
      productName: "Solopol® Classic  (USDA)",
      productURL: "https://www.debgroup.com/us/products/solopol-classic"
    },
    rating: 4,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3469,
    hazzard: {
      id: 26,
      substanceWorkCondition: "Cement (dry)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 14,
    locationOrder: 1,
    skin_care_product: {
      id: 32,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:07.000Z",
      productName: "Solopol® GFX™ (USDA, UL, GS)",
      productURL: "https://www.debgroup.com/us/products/solopol-gfx"
    },
    rating: 3,
    location: "Hands",
    level1: false,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3470,
    hazzard: {
      id: 26,
      substanceWorkCondition: "Cement (dry)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 15,
    locationOrder: 1,
    skin_care_product: {
      id: 29,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:41:00.000Z",
      productName: "Solopol® Classic  (USDA)",
      productURL: "https://www.debgroup.com/us/products/solopol-classic"
    },
    rating: 4,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3471,
    hazzard: {
      id: 27,
      substanceWorkCondition: "Cement (wet)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 15,
    locationOrder: 1,
    skin_care_product: {
      id: 29,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:41:00.000Z",
      productName: "Solopol® Classic  (USDA)",
      productURL: "https://www.debgroup.com/us/products/solopol-classic"
    },
    rating: 4,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3472,
    hazzard: {
      id: 27,
      substanceWorkCondition: "Cement (wet)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 17,
    locationOrder: 1,
    skin_care_product: {
      id: 31,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:42:23.000Z",
      productName: "Solopol® GFX™",
      productURL: "https://www.debgroup.com/us/products/solopol-gfx"
    },
    rating: 1,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: null,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3473,
    hazzard: {
      id: 28,
      substanceWorkCondition: "Cement, putty epoxy-based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 21,
    locationOrder: 1,
    skin_care_product: {
      id: 14,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:29:50.000Z",
      productName: "Kresto® Classic",
      productURL: "https://www.debgroup.com/us/products/kresto-classic"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3474,
    hazzard: {
      id: 28,
      substanceWorkCondition: "Cement, putty epoxy-based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 23,
    locationOrder: 1,
    skin_care_product: {
      id: 13,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:28:48.000Z",
      productName: "Kresto® Cherry Wipes",
      productURL: "https://www.debgroup.com/us/products/kresto-cherry-wipes"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3475,
    hazzard: {
      id: 28,
      substanceWorkCondition: "Cement, putty epoxy-based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 25,
    locationOrder: 1,
    skin_care_product: {
      id: 17,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:32:06.000Z",
      productName: "Kresto® Special ULTRA",
      productURL: "https://www.debgroup.com/us/products/kresto-special-ultra"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: false,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3476,
    hazzard: {
      id: 29,
      substanceWorkCondition:
        "Chromates (water- insoluble) = Chromium pigments",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 14,
    locationOrder: 1,
    skin_care_product: {
      id: 32,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:07.000Z",
      productName: "Solopol® GFX™ (USDA, UL, GS)",
      productURL: "https://www.debgroup.com/us/products/solopol-gfx"
    },
    rating: 3,
    location: "Hands",
    level1: false,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3477,
    hazzard: {
      id: 29,
      substanceWorkCondition:
        "Chromates (water- insoluble) = Chromium pigments",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 15,
    locationOrder: 1,
    skin_care_product: {
      id: 29,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:41:00.000Z",
      productName: "Solopol® Classic  (USDA)",
      productURL: "https://www.debgroup.com/us/products/solopol-classic"
    },
    rating: 4,
    location: "Hands",
    level1: false,
    level2: false,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3478,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Cleanse",
    productOrder: 14,
    locationOrder: 1,
    skin_care_product: {
      id: 32,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:07.000Z",
      productName: "Solopol® GFX™ (USDA, UL, GS)",
      productURL: "https://www.debgroup.com/us/products/solopol-gfx"
    },
    rating: 3,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3479,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Cleanse",
    productOrder: 15,
    locationOrder: 1,
    skin_care_product: {
      id: 29,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:41:00.000Z",
      productName: "Solopol® Classic  (USDA)",
      productURL: "https://www.debgroup.com/us/products/solopol-classic"
    },
    rating: 4,
    location: "Hands",
    level1: false,
    level2: false,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3480,
    hazzard: {
      id: 31,
      substanceWorkCondition: "cleaning agent, detergent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 13,
    locationOrder: 1,
    skin_care_product: {
      id: 33,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:49.000Z",
      productName: "Solopol® Lime (UL)",
      productURL: "https://www.debgroup.com/us/products/solopol-lime"
    },
    rating: 3,
    location: "Hands",
    level1: false,
    level2: false,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3481,
    hazzard: {
      id: 32,
      substanceWorkCondition: "Coal dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 15,
    locationOrder: 1,
    skin_care_product: {
      id: 29,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:41:00.000Z",
      productName: "Solopol® Classic  (USDA)",
      productURL: "https://www.debgroup.com/us/products/solopol-classic"
    },
    rating: 4,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3482,
    hazzard: {
      id: 32,
      substanceWorkCondition: "Coal dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 23,
    locationOrder: 1,
    skin_care_product: {
      id: 13,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:28:48.000Z",
      productName: "Kresto® Cherry Wipes",
      productURL: "https://www.debgroup.com/us/products/kresto-cherry-wipes"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3483,
    hazzard: {
      id: 33,
      substanceWorkCondition: "Coating materials, bituminous (bitumen varnish)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 21,
    locationOrder: 1,
    skin_care_product: {
      id: 14,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:29:50.000Z",
      productName: "Kresto® Classic",
      productURL: "https://www.debgroup.com/us/products/kresto-classic"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3484,
    hazzard: {
      id: 33,
      substanceWorkCondition: "Coating materials, bituminous (bitumen varnish)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 22,
    locationOrder: 4,
    skin_care_product: {
      id: 12,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:28:21.000Z",
      productName: "Kresto® Cherry",
      productURL: "https://www.debgroup.com/us/products/kresto-cherry"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3485,
    hazzard: {
      id: 33,
      substanceWorkCondition: "Coating materials, bituminous (bitumen varnish)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 23,
    locationOrder: 1,
    skin_care_product: {
      id: 13,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:28:48.000Z",
      productName: "Kresto® Cherry Wipes",
      productURL: "https://www.debgroup.com/us/products/kresto-cherry-wipes"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3486,
    hazzard: {
      id: 33,
      substanceWorkCondition: "Coating materials, bituminous (bitumen varnish)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 25,
    locationOrder: 1,
    skin_care_product: {
      id: 17,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:32:06.000Z",
      productName: "Kresto® Special ULTRA",
      productURL: "https://www.debgroup.com/us/products/kresto-special-ultra"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: false,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3487,
    hazzard: {
      id: 34,
      substanceWorkCondition: "Coating materials, emulsified",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 15,
    locationOrder: 1,
    skin_care_product: {
      id: 29,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:41:00.000Z",
      productName: "Solopol® Classic  (USDA)",
      productURL: "https://www.debgroup.com/us/products/solopol-classic"
    },
    rating: 4,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3488,
    hazzard: {
      id: 34,
      substanceWorkCondition: "Coating materials, emulsified",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 21,
    locationOrder: 1,
    skin_care_product: {
      id: 14,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:29:50.000Z",
      productName: "Kresto® Classic",
      productURL: "https://www.debgroup.com/us/products/kresto-classic"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3489,
    hazzard: {
      id: 35,
      substanceWorkCondition: "Coating materials, oily",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 15,
    locationOrder: 1,
    skin_care_product: {
      id: 29,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:41:00.000Z",
      productName: "Solopol® Classic  (USDA)",
      productURL: "https://www.debgroup.com/us/products/solopol-classic"
    },
    rating: 4,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3490,
    hazzard: {
      id: 35,
      substanceWorkCondition: "Coating materials, oily",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 21,
    locationOrder: 1,
    skin_care_product: {
      id: 14,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:29:50.000Z",
      productName: "Kresto® Classic",
      productURL: "https://www.debgroup.com/us/products/kresto-classic"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3491,
    hazzard: {
      id: 35,
      substanceWorkCondition: "Coating materials, oily",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 23,
    locationOrder: 1,
    skin_care_product: {
      id: 13,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:28:48.000Z",
      productName: "Kresto® Cherry Wipes",
      productURL: "https://www.debgroup.com/us/products/kresto-cherry-wipes"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3492,
    hazzard: {
      id: 36,
      substanceWorkCondition: "Cold degreasing agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 13,
    locationOrder: 1,
    skin_care_product: {
      id: 33,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:49.000Z",
      productName: "Solopol® Lime (UL)",
      productURL: "https://www.debgroup.com/us/products/solopol-lime"
    },
    rating: 3,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3493,
    hazzard: {
      id: 37,
      substanceWorkCondition: "cold detergent, solvent based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 13,
    locationOrder: 1,
    skin_care_product: {
      id: 33,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:49.000Z",
      productName: "Solopol® Lime (UL)",
      productURL: "https://www.debgroup.com/us/products/solopol-lime"
    },
    rating: 3,
    location: "Hands",
    level1: false,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3494,
    hazzard: {
      id: 37,
      substanceWorkCondition: "cold detergent, solvent based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 14,
    locationOrder: 1,
    skin_care_product: {
      id: 32,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:07.000Z",
      productName: "Solopol® GFX™ (USDA, UL, GS)",
      productURL: "https://www.debgroup.com/us/products/solopol-gfx"
    },
    rating: 3,
    location: "Hands",
    level1: false,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3495,
    hazzard: {
      id: 37,
      substanceWorkCondition: "cold detergent, solvent based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 15,
    locationOrder: 1,
    skin_care_product: {
      id: 29,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:41:00.000Z",
      productName: "Solopol® Classic  (USDA)",
      productURL: "https://www.debgroup.com/us/products/solopol-classic"
    },
    rating: 4,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3496,
    hazzard: {
      id: 38,
      substanceWorkCondition: "Cold working conditions",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 13,
    locationOrder: 1,
    skin_care_product: {
      id: 33,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:49.000Z",
      productName: "Solopol® Lime (UL)",
      productURL: "https://www.debgroup.com/us/products/solopol-lime"
    },
    rating: 3,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3497,
    hazzard: {
      id: 44,
      substanceWorkCondition: "Commercial - Prisons & Security Services",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 13,
    locationOrder: 1,
    skin_care_product: {
      id: 33,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:49.000Z",
      productName: "Solopol® Lime (UL)",
      productURL: "https://www.debgroup.com/us/products/solopol-lime"
    },
    rating: 3,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3498,
    hazzard: {
      id: 44,
      substanceWorkCondition: "Commercial - Prisons & Security Services",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 21,
    locationOrder: 1,
    skin_care_product: {
      id: 14,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:29:50.000Z",
      productName: "Kresto® Classic",
      productURL: "https://www.debgroup.com/us/products/kresto-classic"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3499,
    hazzard: {
      id: 45,
      substanceWorkCondition:
        "Commercial - Public Entertainments & Theme Parks",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 15,
    locationOrder: 1,
    skin_care_product: {
      id: 29,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:41:00.000Z",
      productName: "Solopol® Classic  (USDA)",
      productURL: "https://www.debgroup.com/us/products/solopol-classic"
    },
    rating: 4,
    location: "Hands",
    level1: false,
    level2: false,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3500,
    hazzard: {
      id: 45,
      substanceWorkCondition:
        "Commercial - Public Entertainments & Theme Parks",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 21,
    locationOrder: 1,
    skin_care_product: {
      id: 14,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:29:50.000Z",
      productName: "Kresto® Classic",
      productURL: "https://www.debgroup.com/us/products/kresto-classic"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: false,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3501,
    hazzard: {
      id: 46,
      substanceWorkCondition: "Commercial - Public Facilities",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 13,
    locationOrder: 1,
    skin_care_product: {
      id: 33,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:49.000Z",
      productName: "Solopol® Lime (UL)",
      productURL: "https://www.debgroup.com/us/products/solopol-lime"
    },
    rating: 3,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3502,
    hazzard: {
      id: 46,
      substanceWorkCondition: "Commercial - Public Facilities",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 14,
    locationOrder: 1,
    skin_care_product: {
      id: 32,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:07.000Z",
      productName: "Solopol® GFX™ (USDA, UL, GS)",
      productURL: "https://www.debgroup.com/us/products/solopol-gfx"
    },
    rating: 3,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3503,
    hazzard: {
      id: 46,
      substanceWorkCondition: "Commercial - Public Facilities",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 21,
    locationOrder: 1,
    skin_care_product: {
      id: 14,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:29:50.000Z",
      productName: "Kresto® Classic",
      productURL: "https://www.debgroup.com/us/products/kresto-classic"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: false,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3504,
    hazzard: {
      id: 47,
      substanceWorkCondition: "Concrete",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 15,
    locationOrder: 1,
    skin_care_product: {
      id: 29,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:41:00.000Z",
      productName: "Solopol® Classic  (USDA)",
      productURL: "https://www.debgroup.com/us/products/solopol-classic"
    },
    rating: 4,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3505,
    hazzard: {
      id: 48,
      substanceWorkCondition: "Cooling lubricant (non-water-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 15,
    locationOrder: 1,
    skin_care_product: {
      id: 29,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:41:00.000Z",
      productName: "Solopol® Classic  (USDA)",
      productURL: "https://www.debgroup.com/us/products/solopol-classic"
    },
    rating: 4,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3506,
    hazzard: {
      id: 48,
      substanceWorkCondition: "Cooling lubricant (non-water-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 21,
    locationOrder: 1,
    skin_care_product: {
      id: 14,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:29:50.000Z",
      productName: "Kresto® Classic",
      productURL: "https://www.debgroup.com/us/products/kresto-classic"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3507,
    hazzard: {
      id: 49,
      substanceWorkCondition: "Cooling lubricant (water- based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 15,
    locationOrder: 1,
    skin_care_product: {
      id: 29,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:41:00.000Z",
      productName: "Solopol® Classic  (USDA)",
      productURL: "https://www.debgroup.com/us/products/solopol-classic"
    },
    rating: 4,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3508,
    hazzard: {
      id: 50,
      substanceWorkCondition: "Cooling water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 13,
    locationOrder: 1,
    skin_care_product: {
      id: 33,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:49.000Z",
      productName: "Solopol® Lime (UL)",
      productURL: "https://www.debgroup.com/us/products/solopol-lime"
    },
    rating: 3,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3509,
    hazzard: {
      id: 50,
      substanceWorkCondition: "Cooling water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 14,
    locationOrder: 1,
    skin_care_product: {
      id: 32,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:07.000Z",
      productName: "Solopol® GFX™ (USDA, UL, GS)",
      productURL: "https://www.debgroup.com/us/products/solopol-gfx"
    },
    rating: 3,
    location: "Hands",
    level1: false,
    level2: false,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3510,
    hazzard: {
      id: 52,
      substanceWorkCondition: "Cutting oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 15,
    locationOrder: 1,
    skin_care_product: {
      id: 29,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:41:00.000Z",
      productName: "Solopol® Classic  (USDA)",
      productURL: "https://www.debgroup.com/us/products/solopol-classic"
    },
    rating: 4,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3511,
    hazzard: {
      id: 52,
      substanceWorkCondition: "Cutting oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 21,
    locationOrder: 1,
    skin_care_product: {
      id: 14,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:29:50.000Z",
      productName: "Kresto® Classic",
      productURL: "https://www.debgroup.com/us/products/kresto-classic"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3512,
    hazzard: {
      id: 53,
      substanceWorkCondition: "Degreaser solvent - based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 15,
    locationOrder: 1,
    skin_care_product: {
      id: 29,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:41:00.000Z",
      productName: "Solopol® Classic  (USDA)",
      productURL: "https://www.debgroup.com/us/products/solopol-classic"
    },
    rating: 4,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3513,
    hazzard: {
      id: 54,
      substanceWorkCondition: "Degreaser without organic solvent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 13,
    locationOrder: 1,
    skin_care_product: {
      id: 33,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:49.000Z",
      productName: "Solopol® Lime (UL)",
      productURL: "https://www.debgroup.com/us/products/solopol-lime"
    },
    rating: 3,
    location: "Hands",
    level1: false,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3514,
    hazzard: {
      id: 54,
      substanceWorkCondition: "Degreaser without organic solvent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 14,
    locationOrder: 1,
    skin_care_product: {
      id: 32,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:07.000Z",
      productName: "Solopol® GFX™ (USDA, UL, GS)",
      productURL: "https://www.debgroup.com/us/products/solopol-gfx"
    },
    rating: 3,
    location: "Hands",
    level1: false,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3515,
    hazzard: {
      id: 54,
      substanceWorkCondition: "Degreaser without organic solvent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 15,
    locationOrder: 1,
    skin_care_product: {
      id: 29,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:41:00.000Z",
      productName: "Solopol® Classic  (USDA)",
      productURL: "https://www.debgroup.com/us/products/solopol-classic"
    },
    rating: 4,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3516,
    hazzard: {
      id: 55,
      substanceWorkCondition: "Detachment solution for concrete",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 15,
    locationOrder: 1,
    skin_care_product: {
      id: 29,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:41:00.000Z",
      productName: "Solopol® Classic  (USDA)",
      productURL: "https://www.debgroup.com/us/products/solopol-classic"
    },
    rating: 4,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3517,
    hazzard: {
      id: 55,
      substanceWorkCondition: "Detachment solution for concrete",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 21,
    locationOrder: 1,
    skin_care_product: {
      id: 14,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:29:50.000Z",
      productName: "Kresto® Classic",
      productURL: "https://www.debgroup.com/us/products/kresto-classic"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: false,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3518,
    hazzard: {
      id: 56,
      substanceWorkCondition: "Detachments (alcohol-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 13,
    locationOrder: 1,
    skin_care_product: {
      id: 33,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:49.000Z",
      productName: "Solopol® Lime (UL)",
      productURL: "https://www.debgroup.com/us/products/solopol-lime"
    },
    rating: 3,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3519,
    hazzard: {
      id: 56,
      substanceWorkCondition: "Detachments (alcohol-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 14,
    locationOrder: 1,
    skin_care_product: {
      id: 32,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:07.000Z",
      productName: "Solopol® GFX™ (USDA, UL, GS)",
      productURL: "https://www.debgroup.com/us/products/solopol-gfx"
    },
    rating: 3,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3520,
    hazzard: {
      id: 58,
      substanceWorkCondition: "Detachments (sour)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 13,
    locationOrder: 1,
    skin_care_product: {
      id: 33,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:49.000Z",
      productName: "Solopol® Lime (UL)",
      productURL: "https://www.debgroup.com/us/products/solopol-lime"
    },
    rating: 3,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3521,
    hazzard: {
      id: 58,
      substanceWorkCondition: "Detachments (sour)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 14,
    locationOrder: 1,
    skin_care_product: {
      id: 32,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:07.000Z",
      productName: "Solopol® GFX™ (USDA, UL, GS)",
      productURL: "https://www.debgroup.com/us/products/solopol-gfx"
    },
    rating: 3,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3522,
    hazzard: {
      id: 60,
      substanceWorkCondition: "Disinfectants (formaldehyd-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 13,
    locationOrder: 1,
    skin_care_product: {
      id: 33,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:49.000Z",
      productName: "Solopol® Lime (UL)",
      productURL: "https://www.debgroup.com/us/products/solopol-lime"
    },
    rating: 3,
    location: "Hands",
    level1: false,
    level2: false,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3523,
    hazzard: {
      id: 62,
      substanceWorkCondition: "Dispersing Agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 13,
    locationOrder: 1,
    skin_care_product: {
      id: 33,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:49.000Z",
      productName: "Solopol® Lime (UL)",
      productURL: "https://www.debgroup.com/us/products/solopol-lime"
    },
    rating: 3,
    location: "Hands",
    level1: false,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3524,
    hazzard: {
      id: 62,
      substanceWorkCondition: "Dispersing Agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 14,
    locationOrder: 1,
    skin_care_product: {
      id: 32,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:07.000Z",
      productName: "Solopol® GFX™ (USDA, UL, GS)",
      productURL: "https://www.debgroup.com/us/products/solopol-gfx"
    },
    rating: 3,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3525,
    hazzard: {
      id: 62,
      substanceWorkCondition: "Dispersing Agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 15,
    locationOrder: 1,
    skin_care_product: {
      id: 29,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:41:00.000Z",
      productName: "Solopol® Classic  (USDA)",
      productURL: "https://www.debgroup.com/us/products/solopol-classic"
    },
    rating: 4,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3526,
    hazzard: {
      id: 63,
      substanceWorkCondition: "Drilling emulsion (water based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 15,
    locationOrder: 1,
    skin_care_product: {
      id: 29,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:41:00.000Z",
      productName: "Solopol® Classic  (USDA)",
      productURL: "https://www.debgroup.com/us/products/solopol-classic"
    },
    rating: 4,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3527,
    hazzard: {
      id: 64,
      substanceWorkCondition: "Drilling oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 15,
    locationOrder: 1,
    skin_care_product: {
      id: 29,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:41:00.000Z",
      productName: "Solopol® Classic  (USDA)",
      productURL: "https://www.debgroup.com/us/products/solopol-classic"
    },
    rating: 4,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3528,
    hazzard: {
      id: 64,
      substanceWorkCondition: "Drilling oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 21,
    locationOrder: 1,
    skin_care_product: {
      id: 14,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:29:50.000Z",
      productName: "Kresto® Classic",
      productURL: "https://www.debgroup.com/us/products/kresto-classic"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: false,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3529,
    hazzard: {
      id: 66,
      substanceWorkCondition: "Dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 13,
    locationOrder: 1,
    skin_care_product: {
      id: 33,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:49.000Z",
      productName: "Solopol® Lime (UL)",
      productURL: "https://www.debgroup.com/us/products/solopol-lime"
    },
    rating: 3,
    location: "Hands",
    level1: false,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3530,
    hazzard: {
      id: 66,
      substanceWorkCondition: "Dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 14,
    locationOrder: 1,
    skin_care_product: {
      id: 32,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:07.000Z",
      productName: "Solopol® GFX™ (USDA, UL, GS)",
      productURL: "https://www.debgroup.com/us/products/solopol-gfx"
    },
    rating: 3,
    location: "Hands",
    level1: false,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3531,
    hazzard: {
      id: 66,
      substanceWorkCondition: "Dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 15,
    locationOrder: 1,
    skin_care_product: {
      id: 29,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:41:00.000Z",
      productName: "Solopol® Classic  (USDA)",
      productURL: "https://www.debgroup.com/us/products/solopol-classic"
    },
    rating: 4,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3532,
    hazzard: {
      id: 66,
      substanceWorkCondition: "Dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 21,
    locationOrder: 1,
    skin_care_product: {
      id: 14,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:29:50.000Z",
      productName: "Kresto® Classic",
      productURL: "https://www.debgroup.com/us/products/kresto-classic"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: false,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3533,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Cleanse",
    productOrder: 21,
    locationOrder: 1,
    skin_care_product: {
      id: 14,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:29:50.000Z",
      productName: "Kresto® Classic",
      productURL: "https://www.debgroup.com/us/products/kresto-classic"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3534,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Cleanse",
    productOrder: 23,
    locationOrder: 1,
    skin_care_product: {
      id: 13,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:28:48.000Z",
      productName: "Kresto® Cherry Wipes",
      productURL: "https://www.debgroup.com/us/products/kresto-cherry-wipes"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3535,
    hazzard: {
      id: 70,
      substanceWorkCondition: "Epoxy resin",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 23,
    locationOrder: 1,
    skin_care_product: {
      id: 13,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:28:48.000Z",
      productName: "Kresto® Cherry Wipes",
      productURL: "https://www.debgroup.com/us/products/kresto-cherry-wipes"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3536,
    hazzard: {
      id: 70,
      substanceWorkCondition: "Epoxy resin",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 25,
    locationOrder: 1,
    skin_care_product: {
      id: 17,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:32:06.000Z",
      productName: "Kresto® Special ULTRA",
      productURL: "https://www.debgroup.com/us/products/kresto-special-ultra"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3537,
    hazzard: {
      id: 71,
      substanceWorkCondition: "Etching (mordant)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 15,
    locationOrder: 1,
    skin_care_product: {
      id: 29,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:41:00.000Z",
      productName: "Solopol® Classic  (USDA)",
      productURL: "https://www.debgroup.com/us/products/solopol-classic"
    },
    rating: 4,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3538,
    hazzard: {
      id: 72,
      substanceWorkCondition: "Ethanol / methylated spirits ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 13,
    locationOrder: 1,
    skin_care_product: {
      id: 33,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:49.000Z",
      productName: "Solopol® Lime (UL)",
      productURL: "https://www.debgroup.com/us/products/solopol-lime"
    },
    rating: 3,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3539,
    hazzard: {
      id: 72,
      substanceWorkCondition: "Ethanol / methylated spirits ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 14,
    locationOrder: 1,
    skin_care_product: {
      id: 32,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:07.000Z",
      productName: "Solopol® GFX™ (USDA, UL, GS)",
      productURL: "https://www.debgroup.com/us/products/solopol-gfx"
    },
    rating: 3,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3540,
    hazzard: {
      id: 73,
      substanceWorkCondition: "Fat",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 15,
    locationOrder: 1,
    skin_care_product: {
      id: 29,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:41:00.000Z",
      productName: "Solopol® Classic  (USDA)",
      productURL: "https://www.debgroup.com/us/products/solopol-classic"
    },
    rating: 4,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3541,
    hazzard: {
      id: 73,
      substanceWorkCondition: "Fat",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 21,
    locationOrder: 1,
    skin_care_product: {
      id: 14,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:29:50.000Z",
      productName: "Kresto® Classic",
      productURL: "https://www.debgroup.com/us/products/kresto-classic"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: false,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3542,
    hazzard: {
      id: 74,
      substanceWorkCondition: "Fertilizer",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 13,
    locationOrder: 1,
    skin_care_product: {
      id: 33,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:49.000Z",
      productName: "Solopol® Lime (UL)",
      productURL: "https://www.debgroup.com/us/products/solopol-lime"
    },
    rating: 3,
    location: "Hands",
    level1: false,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3543,
    hazzard: {
      id: 74,
      substanceWorkCondition: "Fertilizer",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 15,
    locationOrder: 1,
    skin_care_product: {
      id: 29,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:41:00.000Z",
      productName: "Solopol® Classic  (USDA)",
      productURL: "https://www.debgroup.com/us/products/solopol-classic"
    },
    rating: 4,
    location: "Hands",
    level1: false,
    level2: false,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3544,
    hazzard: {
      id: 75,
      substanceWorkCondition: "fine anorganic/inorganic powders ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 14,
    locationOrder: 1,
    skin_care_product: {
      id: 32,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:07.000Z",
      productName: "Solopol® GFX™ (USDA, UL, GS)",
      productURL: "https://www.debgroup.com/us/products/solopol-gfx"
    },
    rating: 3,
    location: "Hands",
    level1: false,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3545,
    hazzard: {
      id: 75,
      substanceWorkCondition: "fine anorganic/inorganic powders ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 15,
    locationOrder: 1,
    skin_care_product: {
      id: 29,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:41:00.000Z",
      productName: "Solopol® Classic  (USDA)",
      productURL: "https://www.debgroup.com/us/products/solopol-classic"
    },
    rating: 4,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3546,
    hazzard: {
      id: 76,
      substanceWorkCondition: "Floor cleaner (water- based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 13,
    locationOrder: 1,
    skin_care_product: {
      id: 33,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:49.000Z",
      productName: "Solopol® Lime (UL)",
      productURL: "https://www.debgroup.com/us/products/solopol-lime"
    },
    rating: 3,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3547,
    hazzard: {
      id: 81,
      substanceWorkCondition: "fuel: gas / diesel",
      cas: null,
      glovesRecommended: true,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 15,
    locationOrder: 1,
    skin_care_product: {
      id: 30,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:41:32.000Z",
      productName: "Solopol® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/solopol-classic"
    },
    rating: 4,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3548,
    hazzard: {
      id: 81,
      substanceWorkCondition: "fuel: gas / diesel",
      cas: null,
      glovesRecommended: true,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 23,
    locationOrder: 1,
    skin_care_product: {
      id: 13,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:28:48.000Z",
      productName: "Kresto® Cherry Wipes",
      productURL: "https://www.debgroup.com/us/products/kresto-cherry-wipes"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3549,
    hazzard: {
      id: 83,
      substanceWorkCondition: "Glass fibre dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 15,
    locationOrder: 1,
    skin_care_product: {
      id: 29,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:41:00.000Z",
      productName: "Solopol® Classic  (USDA)",
      productURL: "https://www.debgroup.com/us/products/solopol-classic"
    },
    rating: 4,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3550,
    hazzard: {
      id: 84,
      substanceWorkCondition: "Glass wool dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 15,
    locationOrder: 1,
    skin_care_product: {
      id: 29,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:41:00.000Z",
      productName: "Solopol® Classic  (USDA)",
      productURL: "https://www.debgroup.com/us/products/solopol-classic"
    },
    rating: 4,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3551,
    hazzard: {
      id: 85,
      substanceWorkCondition: "Glycols",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 13,
    locationOrder: 1,
    skin_care_product: {
      id: 33,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:49.000Z",
      productName: "Solopol® Lime (UL)",
      productURL: "https://www.debgroup.com/us/products/solopol-lime"
    },
    rating: 3,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3552,
    hazzard: {
      id: 86,
      substanceWorkCondition: "Graphite",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 15,
    locationOrder: 1,
    skin_care_product: {
      id: 29,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:41:00.000Z",
      productName: "Solopol® Classic  (USDA)",
      productURL: "https://www.debgroup.com/us/products/solopol-classic"
    },
    rating: 4,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3553,
    hazzard: {
      id: 86,
      substanceWorkCondition: "Graphite",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 21,
    locationOrder: 1,
    skin_care_product: {
      id: 14,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:29:50.000Z",
      productName: "Kresto® Classic",
      productURL: "https://www.debgroup.com/us/products/kresto-classic"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3554,
    hazzard: {
      id: 86,
      substanceWorkCondition: "Graphite",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 23,
    locationOrder: 1,
    skin_care_product: {
      id: 13,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:28:48.000Z",
      productName: "Kresto® Cherry Wipes",
      productURL: "https://www.debgroup.com/us/products/kresto-cherry-wipes"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3555,
    hazzard: {
      id: 87,
      substanceWorkCondition: "Grease",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 15,
    locationOrder: 1,
    skin_care_product: {
      id: 29,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:41:00.000Z",
      productName: "Solopol® Classic  (USDA)",
      productURL: "https://www.debgroup.com/us/products/solopol-classic"
    },
    rating: 4,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3556,
    hazzard: {
      id: 87,
      substanceWorkCondition: "Grease",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 21,
    locationOrder: 1,
    skin_care_product: {
      id: 14,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:29:50.000Z",
      productName: "Kresto® Classic",
      productURL: "https://www.debgroup.com/us/products/kresto-classic"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3557,
    hazzard: {
      id: 87,
      substanceWorkCondition: "Grease",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 23,
    locationOrder: 1,
    skin_care_product: {
      id: 13,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:28:48.000Z",
      productName: "Kresto® Cherry Wipes",
      productURL: "https://www.debgroup.com/us/products/kresto-cherry-wipes"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3558,
    hazzard: {
      id: 88,
      substanceWorkCondition: "Grinding oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 21,
    locationOrder: 1,
    skin_care_product: {
      id: 14,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:29:50.000Z",
      productName: "Kresto® Classic",
      productURL: "https://www.debgroup.com/us/products/kresto-classic"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3559,
    hazzard: {
      id: 89,
      substanceWorkCondition: "Grinding water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 15,
    locationOrder: 1,
    skin_care_product: {
      id: 29,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:41:00.000Z",
      productName: "Solopol® Classic  (USDA)",
      productURL: "https://www.debgroup.com/us/products/solopol-classic"
    },
    rating: 4,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3560,
    hazzard: {
      id: 90,
      substanceWorkCondition: "Gypsum",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 15,
    locationOrder: 1,
    skin_care_product: {
      id: 29,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:41:00.000Z",
      productName: "Solopol® Classic  (USDA)",
      productURL: "https://www.debgroup.com/us/products/solopol-classic"
    },
    rating: 4,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3561,
    hazzard: {
      id: 91,
      substanceWorkCondition: "Healthcare - Care Homes",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 21,
    locationOrder: 1,
    skin_care_product: {
      id: 14,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:29:50.000Z",
      productName: "Kresto® Classic",
      productURL: "https://www.debgroup.com/us/products/kresto-classic"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3562,
    hazzard: {
      id: 93,
      substanceWorkCondition: "Healthcare - Nursing Homes",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 21,
    locationOrder: 1,
    skin_care_product: {
      id: 14,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:29:50.000Z",
      productName: "Kresto® Classic",
      productURL: "https://www.debgroup.com/us/products/kresto-classic"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3563,
    hazzard: {
      id: 94,
      substanceWorkCondition: "Healthcare - Veterinary Services",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 21,
    locationOrder: 1,
    skin_care_product: {
      id: 14,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:29:50.000Z",
      productName: "Kresto® Classic",
      productURL: "https://www.debgroup.com/us/products/kresto-classic"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3564,
    hazzard: {
      id: 95,
      substanceWorkCondition: "Hydraulic fluid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 15,
    locationOrder: 1,
    skin_care_product: {
      id: 29,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:41:00.000Z",
      productName: "Solopol® Classic  (USDA)",
      productURL: "https://www.debgroup.com/us/products/solopol-classic"
    },
    rating: 4,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3565,
    hazzard: {
      id: 95,
      substanceWorkCondition: "Hydraulic fluid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 21,
    locationOrder: 1,
    skin_care_product: {
      id: 14,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:29:50.000Z",
      productName: "Kresto® Classic",
      productURL: "https://www.debgroup.com/us/products/kresto-classic"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3566,
    hazzard: {
      id: 96,
      substanceWorkCondition: "Hyrocarbons",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 14,
    locationOrder: 1,
    skin_care_product: {
      id: 32,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:07.000Z",
      productName: "Solopol® GFX™ (USDA, UL, GS)",
      productURL: "https://www.debgroup.com/us/products/solopol-gfx"
    },
    rating: 3,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3567,
    hazzard: {
      id: 97,
      substanceWorkCondition: "Industry Heavy - Building Materials",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 15,
    locationOrder: 1,
    skin_care_product: {
      id: 29,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:41:00.000Z",
      productName: "Solopol® Classic  (USDA)",
      productURL: "https://www.debgroup.com/us/products/solopol-classic"
    },
    rating: 4,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3568,
    hazzard: {
      id: 97,
      substanceWorkCondition: "Industry Heavy - Building Materials",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 25,
    locationOrder: 1,
    skin_care_product: {
      id: 17,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:32:06.000Z",
      productName: "Kresto® Special ULTRA",
      productURL: "https://www.debgroup.com/us/products/kresto-special-ultra"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: false,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3569,
    hazzard: {
      id: 98,
      substanceWorkCondition: "Industry Heavy - Construction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 15,
    locationOrder: 1,
    skin_care_product: {
      id: 29,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:41:00.000Z",
      productName: "Solopol® Classic  (USDA)",
      productURL: "https://www.debgroup.com/us/products/solopol-classic"
    },
    rating: 4,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3570,
    hazzard: {
      id: 98,
      substanceWorkCondition: "Industry Heavy - Construction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 21,
    locationOrder: 1,
    skin_care_product: {
      id: 14,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:29:50.000Z",
      productName: "Kresto® Classic",
      productURL: "https://www.debgroup.com/us/products/kresto-classic"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3571,
    hazzard: {
      id: 98,
      substanceWorkCondition: "Industry Heavy - Construction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 25,
    locationOrder: 1,
    skin_care_product: {
      id: 17,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:32:06.000Z",
      productName: "Kresto® Special ULTRA",
      productURL: "https://www.debgroup.com/us/products/kresto-special-ultra"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: false,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3572,
    hazzard: {
      id: 99,
      substanceWorkCondition: "Industry Heavy - Mining & Extraction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 15,
    locationOrder: 1,
    skin_care_product: {
      id: 29,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:41:00.000Z",
      productName: "Solopol® Classic  (USDA)",
      productURL: "https://www.debgroup.com/us/products/solopol-classic"
    },
    rating: 4,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3573,
    hazzard: {
      id: 99,
      substanceWorkCondition: "Industry Heavy - Mining & Extraction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 20,
    locationOrder: 1,
    skin_care_product: {
      id: 15,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:30:30.000Z",
      productName: "Kresto® Heritage (UL)",
      productURL: "https://www.debgroup.com/us/products/kresto-heritage"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3574,
    hazzard: {
      id: 99,
      substanceWorkCondition: "Industry Heavy - Mining & Extraction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 23,
    locationOrder: 1,
    skin_care_product: {
      id: 13,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:28:48.000Z",
      productName: "Kresto® Cherry Wipes",
      productURL: "https://www.debgroup.com/us/products/kresto-cherry-wipes"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3575,
    hazzard: {
      id: 99,
      substanceWorkCondition: "Industry Heavy - Mining & Extraction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 25,
    locationOrder: 1,
    skin_care_product: {
      id: 17,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:32:06.000Z",
      productName: "Kresto® Special ULTRA",
      productURL: "https://www.debgroup.com/us/products/kresto-special-ultra"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: false,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3576,
    hazzard: {
      id: 100,
      substanceWorkCondition: "Industry Heavy - Off-Shore Extraction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 15,
    locationOrder: 1,
    skin_care_product: {
      id: 29,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:41:00.000Z",
      productName: "Solopol® Classic  (USDA)",
      productURL: "https://www.debgroup.com/us/products/solopol-classic"
    },
    rating: 4,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3577,
    hazzard: {
      id: 100,
      substanceWorkCondition: "Industry Heavy - Off-Shore Extraction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 20,
    locationOrder: 1,
    skin_care_product: {
      id: 15,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:30:30.000Z",
      productName: "Kresto® Heritage (UL)",
      productURL: "https://www.debgroup.com/us/products/kresto-heritage"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3578,
    hazzard: {
      id: 100,
      substanceWorkCondition: "Industry Heavy - Off-Shore Extraction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 21,
    locationOrder: 1,
    skin_care_product: {
      id: 14,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:29:50.000Z",
      productName: "Kresto® Classic",
      productURL: "https://www.debgroup.com/us/products/kresto-classic"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3579,
    hazzard: {
      id: 101,
      substanceWorkCondition: "Industry Heavy - Printing Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 21,
    locationOrder: 1,
    skin_care_product: {
      id: 14,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:29:50.000Z",
      productName: "Kresto® Classic",
      productURL: "https://www.debgroup.com/us/products/kresto-classic"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3580,
    hazzard: {
      id: 101,
      substanceWorkCondition: "Industry Heavy - Printing Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 25,
    locationOrder: 1,
    skin_care_product: {
      id: 17,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:32:06.000Z",
      productName: "Kresto® Special ULTRA",
      productURL: "https://www.debgroup.com/us/products/kresto-special-ultra"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3581,
    hazzard: {
      id: 102,
      substanceWorkCondition:
        "Industry Heavy - Road & Transportation Construction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 15,
    locationOrder: 1,
    skin_care_product: {
      id: 29,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:41:00.000Z",
      productName: "Solopol® Classic  (USDA)",
      productURL: "https://www.debgroup.com/us/products/solopol-classic"
    },
    rating: 4,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3582,
    hazzard: {
      id: 102,
      substanceWorkCondition:
        "Industry Heavy - Road & Transportation Construction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 20,
    locationOrder: 1,
    skin_care_product: {
      id: 15,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:30:30.000Z",
      productName: "Kresto® Heritage (UL)",
      productURL: "https://www.debgroup.com/us/products/kresto-heritage"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3583,
    hazzard: {
      id: 102,
      substanceWorkCondition:
        "Industry Heavy - Road & Transportation Construction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 21,
    locationOrder: 1,
    skin_care_product: {
      id: 14,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:29:50.000Z",
      productName: "Kresto® Classic",
      productURL: "https://www.debgroup.com/us/products/kresto-classic"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3584,
    hazzard: {
      id: 102,
      substanceWorkCondition:
        "Industry Heavy - Road & Transportation Construction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 23,
    locationOrder: 1,
    skin_care_product: {
      id: 13,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:28:48.000Z",
      productName: "Kresto® Cherry Wipes",
      productURL: "https://www.debgroup.com/us/products/kresto-cherry-wipes"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3585,
    hazzard: {
      id: 102,
      substanceWorkCondition:
        "Industry Heavy - Road & Transportation Construction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 25,
    locationOrder: 1,
    skin_care_product: {
      id: 17,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:32:06.000Z",
      productName: "Kresto® Special ULTRA",
      productURL: "https://www.debgroup.com/us/products/kresto-special-ultra"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3586,
    hazzard: {
      id: 103,
      substanceWorkCondition: "Industry Heavy - Ship & Boat Building",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 15,
    locationOrder: 1,
    skin_care_product: {
      id: 29,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:41:00.000Z",
      productName: "Solopol® Classic  (USDA)",
      productURL: "https://www.debgroup.com/us/products/solopol-classic"
    },
    rating: 4,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3587,
    hazzard: {
      id: 103,
      substanceWorkCondition: "Industry Heavy - Ship & Boat Building",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 20,
    locationOrder: 1,
    skin_care_product: {
      id: 15,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:30:30.000Z",
      productName: "Kresto® Heritage (UL)",
      productURL: "https://www.debgroup.com/us/products/kresto-heritage"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3588,
    hazzard: {
      id: 103,
      substanceWorkCondition: "Industry Heavy - Ship & Boat Building",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 21,
    locationOrder: 1,
    skin_care_product: {
      id: 14,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:29:50.000Z",
      productName: "Kresto® Classic",
      productURL: "https://www.debgroup.com/us/products/kresto-classic"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3589,
    hazzard: {
      id: 103,
      substanceWorkCondition: "Industry Heavy - Ship & Boat Building",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 25,
    locationOrder: 1,
    skin_care_product: {
      id: 17,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:32:06.000Z",
      productName: "Kresto® Special ULTRA",
      productURL: "https://www.debgroup.com/us/products/kresto-special-ultra"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3590,
    hazzard: {
      id: 104,
      substanceWorkCondition: "Industry Heavy - Shipping & Marine",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 15,
    locationOrder: 1,
    skin_care_product: {
      id: 29,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:41:00.000Z",
      productName: "Solopol® Classic  (USDA)",
      productURL: "https://www.debgroup.com/us/products/solopol-classic"
    },
    rating: 4,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3591,
    hazzard: {
      id: 104,
      substanceWorkCondition: "Industry Heavy - Shipping & Marine",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 20,
    locationOrder: 1,
    skin_care_product: {
      id: 15,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:30:30.000Z",
      productName: "Kresto® Heritage (UL)",
      productURL: "https://www.debgroup.com/us/products/kresto-heritage"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3592,
    hazzard: {
      id: 104,
      substanceWorkCondition: "Industry Heavy - Shipping & Marine",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 21,
    locationOrder: 1,
    skin_care_product: {
      id: 14,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:29:50.000Z",
      productName: "Kresto® Classic",
      productURL: "https://www.debgroup.com/us/products/kresto-classic"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3593,
    hazzard: {
      id: 104,
      substanceWorkCondition: "Industry Heavy - Shipping & Marine",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 25,
    locationOrder: 1,
    skin_care_product: {
      id: 17,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:32:06.000Z",
      productName: "Kresto® Special ULTRA",
      productURL: "https://www.debgroup.com/us/products/kresto-special-ultra"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3594,
    hazzard: {
      id: 105,
      substanceWorkCondition:
        "Industry Heavy - Steel, Engineering & Heavy Manufacturing",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 15,
    locationOrder: 1,
    skin_care_product: {
      id: 29,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:41:00.000Z",
      productName: "Solopol® Classic  (USDA)",
      productURL: "https://www.debgroup.com/us/products/solopol-classic"
    },
    rating: 4,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3595,
    hazzard: {
      id: 105,
      substanceWorkCondition:
        "Industry Heavy - Steel, Engineering & Heavy Manufacturing",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 20,
    locationOrder: 1,
    skin_care_product: {
      id: 15,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:30:30.000Z",
      productName: "Kresto® Heritage (UL)",
      productURL: "https://www.debgroup.com/us/products/kresto-heritage"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3596,
    hazzard: {
      id: 105,
      substanceWorkCondition:
        "Industry Heavy - Steel, Engineering & Heavy Manufacturing",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 21,
    locationOrder: 1,
    skin_care_product: {
      id: 14,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:29:50.000Z",
      productName: "Kresto® Classic",
      productURL: "https://www.debgroup.com/us/products/kresto-classic"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: false,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3597,
    hazzard: {
      id: 105,
      substanceWorkCondition:
        "Industry Heavy - Steel, Engineering & Heavy Manufacturing",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 25,
    locationOrder: 1,
    skin_care_product: {
      id: 17,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:32:06.000Z",
      productName: "Kresto® Special ULTRA",
      productURL: "https://www.debgroup.com/us/products/kresto-special-ultra"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: false,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3598,
    hazzard: {
      id: 105,
      substanceWorkCondition:
        "Industry Heavy - Steel, Engineering & Heavy Manufacturing",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 23,
    locationOrder: 1,
    skin_care_product: {
      id: 13,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:28:48.000Z",
      productName: "Kresto® Cherry Wipes",
      productURL: "https://www.debgroup.com/us/products/kresto-cherry-wipes"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3599,
    hazzard: {
      id: 106,
      substanceWorkCondition:
        "Industry Light - Airports & Transportation Terminals - Services & Facility Maintenance",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 14,
    locationOrder: 1,
    skin_care_product: {
      id: 32,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:07.000Z",
      productName: "Solopol® GFX™ (USDA, UL, GS)",
      productURL: "https://www.debgroup.com/us/products/solopol-gfx"
    },
    rating: 3,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3600,
    hazzard: {
      id: 107,
      substanceWorkCondition: "Industry Light - Emergency Services",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 15,
    locationOrder: 1,
    skin_care_product: {
      id: 29,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:41:00.000Z",
      productName: "Solopol® Classic  (USDA)",
      productURL: "https://www.debgroup.com/us/products/solopol-classic"
    },
    rating: 4,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3601,
    hazzard: {
      id: 107,
      substanceWorkCondition: "Industry Light - Emergency Services",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 25,
    locationOrder: 1,
    skin_care_product: {
      id: 17,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:32:06.000Z",
      productName: "Kresto® Special ULTRA",
      productURL: "https://www.debgroup.com/us/products/kresto-special-ultra"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: false,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3602,
    hazzard: {
      id: 108,
      substanceWorkCondition:
        "Industry Light - General Manufacturing & Assembly",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 13,
    locationOrder: 1,
    skin_care_product: {
      id: 33,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:49.000Z",
      productName: "Solopol® Lime (UL)",
      productURL: "https://www.debgroup.com/us/products/solopol-lime"
    },
    rating: 3,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3603,
    hazzard: {
      id: 108,
      substanceWorkCondition:
        "Industry Light - General Manufacturing & Assembly",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 14,
    locationOrder: 1,
    skin_care_product: {
      id: 32,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:07.000Z",
      productName: "Solopol® GFX™ (USDA, UL, GS)",
      productURL: "https://www.debgroup.com/us/products/solopol-gfx"
    },
    rating: 3,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3604,
    hazzard: {
      id: 109,
      substanceWorkCondition:
        "Industry Light - Hosiery, Knitware, Fabric & Clothing Manufacturing",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 14,
    locationOrder: 1,
    skin_care_product: {
      id: 32,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:07.000Z",
      productName: "Solopol® GFX™ (USDA, UL, GS)",
      productURL: "https://www.debgroup.com/us/products/solopol-gfx"
    },
    rating: 3,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3605,
    hazzard: {
      id: 110,
      substanceWorkCondition: "Industry Light - Science & Laboratories",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 14,
    locationOrder: 1,
    skin_care_product: {
      id: 32,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:07.000Z",
      productName: "Solopol® GFX™ (USDA, UL, GS)",
      productURL: "https://www.debgroup.com/us/products/solopol-gfx"
    },
    rating: 3,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3606,
    hazzard: {
      id: 111,
      substanceWorkCondition: "Industry Light - Warehousing & Logistics",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 14,
    locationOrder: 1,
    skin_care_product: {
      id: 32,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:07.000Z",
      productName: "Solopol® GFX™ (USDA, UL, GS)",
      productURL: "https://www.debgroup.com/us/products/solopol-gfx"
    },
    rating: 3,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3607,
    hazzard: {
      id: 112,
      substanceWorkCondition: "Industry Medium - Ceramics & Glassware",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 14,
    locationOrder: 1,
    skin_care_product: {
      id: 32,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:07.000Z",
      productName: "Solopol® GFX™ (USDA, UL, GS)",
      productURL: "https://www.debgroup.com/us/products/solopol-gfx"
    },
    rating: 3,
    location: "Hands",
    level1: false,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3608,
    hazzard: {
      id: 112,
      substanceWorkCondition: "Industry Medium - Ceramics & Glassware",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 15,
    locationOrder: 1,
    skin_care_product: {
      id: 29,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:41:00.000Z",
      productName: "Solopol® Classic  (USDA)",
      productURL: "https://www.debgroup.com/us/products/solopol-classic"
    },
    rating: 4,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3609,
    hazzard: {
      id: 112,
      substanceWorkCondition: "Industry Medium - Ceramics & Glassware",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 25,
    locationOrder: 1,
    skin_care_product: {
      id: 17,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:32:06.000Z",
      productName: "Kresto® Special ULTRA",
      productURL: "https://www.debgroup.com/us/products/kresto-special-ultra"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: false,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3610,
    hazzard: {
      id: 113,
      substanceWorkCondition: "Industry Medium - Chemical Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 15,
    locationOrder: 1,
    skin_care_product: {
      id: 29,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:41:00.000Z",
      productName: "Solopol® Classic  (USDA)",
      productURL: "https://www.debgroup.com/us/products/solopol-classic"
    },
    rating: 4,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3611,
    hazzard: {
      id: 114,
      substanceWorkCondition: "Industry Medium - Electronics Industries",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 15,
    locationOrder: 1,
    skin_care_product: {
      id: 29,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:41:00.000Z",
      productName: "Solopol® Classic  (USDA)",
      productURL: "https://www.debgroup.com/us/products/solopol-classic"
    },
    rating: 4,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3612,
    hazzard: {
      id: 115,
      substanceWorkCondition: "Industry Medium - Metal Working & Fabrication",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 15,
    locationOrder: 1,
    skin_care_product: {
      id: 29,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:41:00.000Z",
      productName: "Solopol® Classic  (USDA)",
      productURL: "https://www.debgroup.com/us/products/solopol-classic"
    },
    rating: 4,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:53.000Z",
    updated_at: "2020-03-02T22:49:53.000Z"
  },
  {
    id: 3613,
    hazzard: {
      id: 115,
      substanceWorkCondition: "Industry Medium - Metal Working & Fabrication",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 21,
    locationOrder: 1,
    skin_care_product: {
      id: 14,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:29:50.000Z",
      productName: "Kresto® Classic",
      productURL: "https://www.debgroup.com/us/products/kresto-classic"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: false,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3614,
    hazzard: {
      id: 116,
      substanceWorkCondition: "Industry Medium - Outdoor Maintenance & Repair",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 15,
    locationOrder: 1,
    skin_care_product: {
      id: 29,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:41:00.000Z",
      productName: "Solopol® Classic  (USDA)",
      productURL: "https://www.debgroup.com/us/products/solopol-classic"
    },
    rating: 4,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3615,
    hazzard: {
      id: 118,
      substanceWorkCondition: "Industry Medium - Plastics Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 15,
    locationOrder: 1,
    skin_care_product: {
      id: 29,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:41:00.000Z",
      productName: "Solopol® Classic  (USDA)",
      productURL: "https://www.debgroup.com/us/products/solopol-classic"
    },
    rating: 4,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3616,
    hazzard: {
      id: 118,
      substanceWorkCondition: "Industry Medium - Plastics Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 25,
    locationOrder: 1,
    skin_care_product: {
      id: 17,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:32:06.000Z",
      productName: "Kresto® Special ULTRA",
      productURL: "https://www.debgroup.com/us/products/kresto-special-ultra"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3617,
    hazzard: {
      id: 119,
      substanceWorkCondition: "Ink / printing ink",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 23,
    locationOrder: 1,
    skin_care_product: {
      id: 13,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:28:48.000Z",
      productName: "Kresto® Cherry Wipes",
      productURL: "https://www.debgroup.com/us/products/kresto-cherry-wipes"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3618,
    hazzard: {
      id: 120,
      substanceWorkCondition: "Insulating material",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 15,
    locationOrder: 1,
    skin_care_product: {
      id: 29,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:41:00.000Z",
      productName: "Solopol® Classic  (USDA)",
      productURL: "https://www.debgroup.com/us/products/solopol-classic"
    },
    rating: 4,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3619,
    hazzard: {
      id: 121,
      substanceWorkCondition: "Kerosene",
      cas: null,
      glovesRecommended: true,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 13,
    locationOrder: 1,
    skin_care_product: {
      id: 33,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:49.000Z",
      productName: "Solopol® Lime (UL)",
      productURL: "https://www.debgroup.com/us/products/solopol-lime"
    },
    rating: 3,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3620,
    hazzard: {
      id: 121,
      substanceWorkCondition: "Kerosene",
      cas: null,
      glovesRecommended: true,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 15,
    locationOrder: 1,
    skin_care_product: {
      id: 29,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:41:00.000Z",
      productName: "Solopol® Classic  (USDA)",
      productURL: "https://www.debgroup.com/us/products/solopol-classic"
    },
    rating: 4,
    location: "Hands",
    level1: false,
    level2: false,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3621,
    hazzard: {
      id: 122,
      substanceWorkCondition: "Ketone",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 13,
    locationOrder: 1,
    skin_care_product: {
      id: 33,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:49.000Z",
      productName: "Solopol® Lime (UL)",
      productURL: "https://www.debgroup.com/us/products/solopol-lime"
    },
    rating: 3,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3622,
    hazzard: {
      id: 123,
      substanceWorkCondition: "Lacquers (nitro/solvent-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 21,
    locationOrder: 1,
    skin_care_product: {
      id: 14,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:29:50.000Z",
      productName: "Kresto® Classic",
      productURL: "https://www.debgroup.com/us/products/kresto-classic"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3623,
    hazzard: {
      id: 123,
      substanceWorkCondition: "Lacquers (nitro/solvent-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 23,
    locationOrder: 1,
    skin_care_product: {
      id: 13,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:28:48.000Z",
      productName: "Kresto® Cherry Wipes",
      productURL: "https://www.debgroup.com/us/products/kresto-cherry-wipes"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3624,
    hazzard: {
      id: 123,
      substanceWorkCondition: "Lacquers (nitro/solvent-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 25,
    locationOrder: 1,
    skin_care_product: {
      id: 17,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:32:06.000Z",
      productName: "Kresto® Special ULTRA",
      productURL: "https://www.debgroup.com/us/products/kresto-special-ultra"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3625,
    hazzard: {
      id: 124,
      substanceWorkCondition: "Lacquers (PU based, synthetic resin based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 15,
    locationOrder: 1,
    skin_care_product: {
      id: 29,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:41:00.000Z",
      productName: "Solopol® Classic  (USDA)",
      productURL: "https://www.debgroup.com/us/products/solopol-classic"
    },
    rating: 4,
    location: "Hands",
    level1: false,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3626,
    hazzard: {
      id: 124,
      substanceWorkCondition: "Lacquers (PU based, synthetic resin based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 21,
    locationOrder: 1,
    skin_care_product: {
      id: 14,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:29:50.000Z",
      productName: "Kresto® Classic",
      productURL: "https://www.debgroup.com/us/products/kresto-classic"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3627,
    hazzard: {
      id: 124,
      substanceWorkCondition: "Lacquers (PU based, synthetic resin based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 23,
    locationOrder: 1,
    skin_care_product: {
      id: 13,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:28:48.000Z",
      productName: "Kresto® Cherry Wipes",
      productURL: "https://www.debgroup.com/us/products/kresto-cherry-wipes"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3628,
    hazzard: {
      id: 124,
      substanceWorkCondition: "Lacquers (PU based, synthetic resin based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 25,
    locationOrder: 1,
    skin_care_product: {
      id: 17,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:32:06.000Z",
      productName: "Kresto® Special ULTRA",
      productURL: "https://www.debgroup.com/us/products/kresto-special-ultra"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3629,
    hazzard: {
      id: 125,
      substanceWorkCondition: "Lacquers (UV-hardening)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 21,
    locationOrder: 1,
    skin_care_product: {
      id: 14,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:29:50.000Z",
      productName: "Kresto® Classic",
      productURL: "https://www.debgroup.com/us/products/kresto-classic"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3630,
    hazzard: {
      id: 125,
      substanceWorkCondition: "Lacquers (UV-hardening)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 23,
    locationOrder: 1,
    skin_care_product: {
      id: 13,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:28:48.000Z",
      productName: "Kresto® Cherry Wipes",
      productURL: "https://www.debgroup.com/us/products/kresto-cherry-wipes"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3631,
    hazzard: {
      id: 125,
      substanceWorkCondition: "Lacquers (UV-hardening)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 25,
    locationOrder: 1,
    skin_care_product: {
      id: 17,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:32:06.000Z",
      productName: "Kresto® Special ULTRA",
      productURL: "https://www.debgroup.com/us/products/kresto-special-ultra"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3632,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Cleanse",
    productOrder: 15,
    locationOrder: 1,
    skin_care_product: {
      id: 29,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:41:00.000Z",
      productName: "Solopol® Classic  (USDA)",
      productURL: "https://www.debgroup.com/us/products/solopol-classic"
    },
    rating: 4,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3633,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Cleanse",
    productOrder: 21,
    locationOrder: 1,
    skin_care_product: {
      id: 14,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:29:50.000Z",
      productName: "Kresto® Classic",
      productURL: "https://www.debgroup.com/us/products/kresto-classic"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3634,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Cleanse",
    productOrder: 23,
    locationOrder: 1,
    skin_care_product: {
      id: 13,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:28:48.000Z",
      productName: "Kresto® Cherry Wipes",
      productURL: "https://www.debgroup.com/us/products/kresto-cherry-wipes"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3635,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Cleanse",
    productOrder: 25,
    locationOrder: 1,
    skin_care_product: {
      id: 17,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:32:06.000Z",
      productName: "Kresto® Special ULTRA",
      productURL: "https://www.debgroup.com/us/products/kresto-special-ultra"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: false,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3636,
    hazzard: {
      id: 127,
      substanceWorkCondition: "Latex paint",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 15,
    locationOrder: 1,
    skin_care_product: {
      id: 29,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:41:00.000Z",
      productName: "Solopol® Classic  (USDA)",
      productURL: "https://www.debgroup.com/us/products/solopol-classic"
    },
    rating: 4,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3637,
    hazzard: {
      id: 128,
      substanceWorkCondition: "Lime",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 15,
    locationOrder: 1,
    skin_care_product: {
      id: 29,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:41:00.000Z",
      productName: "Solopol® Classic  (USDA)",
      productURL: "https://www.debgroup.com/us/products/solopol-classic"
    },
    rating: 4,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3638,
    hazzard: {
      id: 130,
      substanceWorkCondition: "Metal dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 15,
    locationOrder: 1,
    skin_care_product: {
      id: 29,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:41:00.000Z",
      productName: "Solopol® Classic  (USDA)",
      productURL: "https://www.debgroup.com/us/products/solopol-classic"
    },
    rating: 4,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3639,
    hazzard: {
      id: 130,
      substanceWorkCondition: "Metal dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 21,
    locationOrder: 1,
    skin_care_product: {
      id: 14,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:29:50.000Z",
      productName: "Kresto® Classic",
      productURL: "https://www.debgroup.com/us/products/kresto-classic"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: false,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3640,
    hazzard: {
      id: 130,
      substanceWorkCondition: "Metal dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 23,
    locationOrder: 1,
    skin_care_product: {
      id: 13,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:28:48.000Z",
      productName: "Kresto® Cherry Wipes",
      productURL: "https://www.debgroup.com/us/products/kresto-cherry-wipes"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3641,
    hazzard: {
      id: 131,
      substanceWorkCondition: "Methanol / Methyl Alcohol",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 13,
    locationOrder: 1,
    skin_care_product: {
      id: 33,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:49.000Z",
      productName: "Solopol® Lime (UL)",
      productURL: "https://www.debgroup.com/us/products/solopol-lime"
    },
    rating: 3,
    location: "Hands",
    level1: false,
    level2: false,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3642,
    hazzard: {
      id: 131,
      substanceWorkCondition: "Methanol / Methyl Alcohol",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 14,
    locationOrder: 1,
    skin_care_product: {
      id: 32,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:07.000Z",
      productName: "Solopol® GFX™ (USDA, UL, GS)",
      productURL: "https://www.debgroup.com/us/products/solopol-gfx"
    },
    rating: 3,
    location: "Hands",
    level1: false,
    level2: false,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3643,
    hazzard: {
      id: 132,
      substanceWorkCondition: "Mineral oil / paraffin oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 21,
    locationOrder: 1,
    skin_care_product: {
      id: 14,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:29:50.000Z",
      productName: "Kresto® Classic",
      productURL: "https://www.debgroup.com/us/products/kresto-classic"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3644,
    hazzard: {
      id: 132,
      substanceWorkCondition: "Mineral oil / paraffin oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 23,
    locationOrder: 1,
    skin_care_product: {
      id: 13,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:28:48.000Z",
      productName: "Kresto® Cherry Wipes",
      productURL: "https://www.debgroup.com/us/products/kresto-cherry-wipes"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3645,
    hazzard: {
      id: 133,
      substanceWorkCondition: "Multi component adhesive",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 21,
    locationOrder: 1,
    skin_care_product: {
      id: 14,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:29:50.000Z",
      productName: "Kresto® Classic",
      productURL: "https://www.debgroup.com/us/products/kresto-classic"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3646,
    hazzard: {
      id: 133,
      substanceWorkCondition: "Multi component adhesive",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 23,
    locationOrder: 1,
    skin_care_product: {
      id: 13,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:28:48.000Z",
      productName: "Kresto® Cherry Wipes",
      productURL: "https://www.debgroup.com/us/products/kresto-cherry-wipes"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3647,
    hazzard: {
      id: 133,
      substanceWorkCondition: "Multi component adhesive",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 25,
    locationOrder: 1,
    skin_care_product: {
      id: 17,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:32:06.000Z",
      productName: "Kresto® Special ULTRA",
      productURL: "https://www.debgroup.com/us/products/kresto-special-ultra"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3648,
    hazzard: {
      id: 134,
      substanceWorkCondition: "Multi component resin",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 21,
    locationOrder: 1,
    skin_care_product: {
      id: 14,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:29:50.000Z",
      productName: "Kresto® Classic",
      productURL: "https://www.debgroup.com/us/products/kresto-classic"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3649,
    hazzard: {
      id: 134,
      substanceWorkCondition: "Multi component resin",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 23,
    locationOrder: 1,
    skin_care_product: {
      id: 13,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:28:48.000Z",
      productName: "Kresto® Cherry Wipes",
      productURL: "https://www.debgroup.com/us/products/kresto-cherry-wipes"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3650,
    hazzard: {
      id: 134,
      substanceWorkCondition: "Multi component resin",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 25,
    locationOrder: 1,
    skin_care_product: {
      id: 17,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:32:06.000Z",
      productName: "Kresto® Special ULTRA",
      productURL: "https://www.debgroup.com/us/products/kresto-special-ultra"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3651,
    hazzard: {
      id: 136,
      substanceWorkCondition: "Oil coke (carbon)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 15,
    locationOrder: 1,
    skin_care_product: {
      id: 29,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:41:00.000Z",
      productName: "Solopol® Classic  (USDA)",
      productURL: "https://www.debgroup.com/us/products/solopol-classic"
    },
    rating: 4,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3652,
    hazzard: {
      id: 136,
      substanceWorkCondition: "Oil coke (carbon)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 21,
    locationOrder: 1,
    skin_care_product: {
      id: 14,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:29:50.000Z",
      productName: "Kresto® Classic",
      productURL: "https://www.debgroup.com/us/products/kresto-classic"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3653,
    hazzard: {
      id: 137,
      substanceWorkCondition: "Oil-emulsions (water- mixed)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 15,
    locationOrder: 1,
    skin_care_product: {
      id: 29,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:41:00.000Z",
      productName: "Solopol® Classic  (USDA)",
      productURL: "https://www.debgroup.com/us/products/solopol-classic"
    },
    rating: 4,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3654,
    hazzard: {
      id: 137,
      substanceWorkCondition: "Oil-emulsions (water- mixed)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 21,
    locationOrder: 1,
    skin_care_product: {
      id: 14,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:29:50.000Z",
      productName: "Kresto® Classic",
      productURL: "https://www.debgroup.com/us/products/kresto-classic"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: false,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3655,
    hazzard: {
      id: 138,
      substanceWorkCondition: "Oils / Oil based lubricants",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 15,
    locationOrder: 1,
    skin_care_product: {
      id: 29,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:41:00.000Z",
      productName: "Solopol® Classic  (USDA)",
      productURL: "https://www.debgroup.com/us/products/solopol-classic"
    },
    rating: 4,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3656,
    hazzard: {
      id: 138,
      substanceWorkCondition: "Oils / Oil based lubricants",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 21,
    locationOrder: 1,
    skin_care_product: {
      id: 14,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:29:50.000Z",
      productName: "Kresto® Classic",
      productURL: "https://www.debgroup.com/us/products/kresto-classic"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: false,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3657,
    hazzard: {
      id: 140,
      substanceWorkCondition: "Paint Thinner ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 15,
    locationOrder: 1,
    skin_care_product: {
      id: 29,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:41:00.000Z",
      productName: "Solopol® Classic  (USDA)",
      productURL: "https://www.debgroup.com/us/products/solopol-classic"
    },
    rating: 4,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3658,
    hazzard: {
      id: 139,
      substanceWorkCondition: "Paint",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 21,
    locationOrder: 1,
    skin_care_product: {
      id: 14,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:29:50.000Z",
      productName: "Kresto® Classic",
      productURL: "https://www.debgroup.com/us/products/kresto-classic"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3659,
    hazzard: {
      id: 139,
      substanceWorkCondition: "Paint",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 23,
    locationOrder: 1,
    skin_care_product: {
      id: 13,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:28:48.000Z",
      productName: "Kresto® Cherry Wipes",
      productURL: "https://www.debgroup.com/us/products/kresto-cherry-wipes"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3660,
    hazzard: {
      id: 139,
      substanceWorkCondition: "Paint",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 25,
    locationOrder: 1,
    skin_care_product: {
      id: 17,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:32:06.000Z",
      productName: "Kresto® Special ULTRA",
      productURL: "https://www.debgroup.com/us/products/kresto-special-ultra"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: false,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3661,
    hazzard: {
      id: 142,
      substanceWorkCondition: "Pitch",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 21,
    locationOrder: 1,
    skin_care_product: {
      id: 14,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:29:50.000Z",
      productName: "Kresto® Classic",
      productURL: "https://www.debgroup.com/us/products/kresto-classic"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3662,
    hazzard: {
      id: 142,
      substanceWorkCondition: "Pitch",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 23,
    locationOrder: 1,
    skin_care_product: {
      id: 13,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:28:48.000Z",
      productName: "Kresto® Cherry Wipes",
      productURL: "https://www.debgroup.com/us/products/kresto-cherry-wipes"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3663,
    hazzard: {
      id: 142,
      substanceWorkCondition: "Pitch",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 25,
    locationOrder: 1,
    skin_care_product: {
      id: 17,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:32:06.000Z",
      productName: "Kresto® Special ULTRA",
      productURL: "https://www.debgroup.com/us/products/kresto-special-ultra"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3664,
    hazzard: {
      id: 143,
      substanceWorkCondition: "Polishes (water- soluble)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 13,
    locationOrder: 1,
    skin_care_product: {
      id: 33,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:49.000Z",
      productName: "Solopol® Lime (UL)",
      productURL: "https://www.debgroup.com/us/products/solopol-lime"
    },
    rating: 3,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3665,
    hazzard: {
      id: 143,
      substanceWorkCondition: "Polishes (water- soluble)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 15,
    locationOrder: 1,
    skin_care_product: {
      id: 29,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:41:00.000Z",
      productName: "Solopol® Classic  (USDA)",
      productURL: "https://www.debgroup.com/us/products/solopol-classic"
    },
    rating: 4,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3666,
    hazzard: {
      id: 144,
      substanceWorkCondition: "Polyester / Polyester resin",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 21,
    locationOrder: 1,
    skin_care_product: {
      id: 14,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:29:50.000Z",
      productName: "Kresto® Classic",
      productURL: "https://www.debgroup.com/us/products/kresto-classic"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3667,
    hazzard: {
      id: 144,
      substanceWorkCondition: "Polyester / Polyester resin",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 23,
    locationOrder: 1,
    skin_care_product: {
      id: 13,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:28:48.000Z",
      productName: "Kresto® Cherry Wipes",
      productURL: "https://www.debgroup.com/us/products/kresto-cherry-wipes"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3668,
    hazzard: {
      id: 144,
      substanceWorkCondition: "Polyester / Polyester resin",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 25,
    locationOrder: 1,
    skin_care_product: {
      id: 17,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:32:06.000Z",
      productName: "Kresto® Special ULTRA",
      productURL: "https://www.debgroup.com/us/products/kresto-special-ultra"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3669,
    hazzard: {
      id: 145,
      substanceWorkCondition: "Polyurethane",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 21,
    locationOrder: 1,
    skin_care_product: {
      id: 14,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:29:50.000Z",
      productName: "Kresto® Classic",
      productURL: "https://www.debgroup.com/us/products/kresto-classic"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3670,
    hazzard: {
      id: 145,
      substanceWorkCondition: "Polyurethane",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 23,
    locationOrder: 1,
    skin_care_product: {
      id: 13,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:28:48.000Z",
      productName: "Kresto® Cherry Wipes",
      productURL: "https://www.debgroup.com/us/products/kresto-cherry-wipes"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3671,
    hazzard: {
      id: 145,
      substanceWorkCondition: "Polyurethane",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 25,
    locationOrder: 1,
    skin_care_product: {
      id: 17,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:32:06.000Z",
      productName: "Kresto® Special ULTRA",
      productURL: "https://www.debgroup.com/us/products/kresto-special-ultra"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: false,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3672,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Cleanse",
    productOrder: 15,
    locationOrder: 1,
    skin_care_product: {
      id: 29,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:41:00.000Z",
      productName: "Solopol® Classic  (USDA)",
      productURL: "https://www.debgroup.com/us/products/solopol-classic"
    },
    rating: 4,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3673,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Cleanse",
    productOrder: 21,
    locationOrder: 1,
    skin_care_product: {
      id: 14,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:29:50.000Z",
      productName: "Kresto® Classic",
      productURL: "https://www.debgroup.com/us/products/kresto-classic"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: false,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3674,
    hazzard: {
      id: 148,
      substanceWorkCondition:
        "Propanols (propyl alcohols): n-Propanol, Isopropanol / 2-Propanol ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 13,
    locationOrder: 1,
    skin_care_product: {
      id: 33,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:49.000Z",
      productName: "Solopol® Lime (UL)",
      productURL: "https://www.debgroup.com/us/products/solopol-lime"
    },
    rating: 3,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3675,
    hazzard: {
      id: 148,
      substanceWorkCondition:
        "Propanols (propyl alcohols): n-Propanol, Isopropanol / 2-Propanol ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 14,
    locationOrder: 1,
    skin_care_product: {
      id: 32,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:07.000Z",
      productName: "Solopol® GFX™ (USDA, UL, GS)",
      productURL: "https://www.debgroup.com/us/products/solopol-gfx"
    },
    rating: 3,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3676,
    hazzard: {
      id: 149,
      substanceWorkCondition: "release agent, parting agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 15,
    locationOrder: 1,
    skin_care_product: {
      id: 29,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:41:00.000Z",
      productName: "Solopol® Classic  (USDA)",
      productURL: "https://www.debgroup.com/us/products/solopol-classic"
    },
    rating: 4,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3677,
    hazzard: {
      id: 150,
      substanceWorkCondition: "Resins / urea resins",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 21,
    locationOrder: 1,
    skin_care_product: {
      id: 14,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:29:50.000Z",
      productName: "Kresto® Classic",
      productURL: "https://www.debgroup.com/us/products/kresto-classic"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3678,
    hazzard: {
      id: 150,
      substanceWorkCondition: "Resins / urea resins",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 23,
    locationOrder: 1,
    skin_care_product: {
      id: 13,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:28:48.000Z",
      productName: "Kresto® Cherry Wipes",
      productURL: "https://www.debgroup.com/us/products/kresto-cherry-wipes"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3679,
    hazzard: {
      id: 150,
      substanceWorkCondition: "Resins / urea resins",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 25,
    locationOrder: 1,
    skin_care_product: {
      id: 17,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:32:06.000Z",
      productName: "Kresto® Special ULTRA",
      productURL: "https://www.debgroup.com/us/products/kresto-special-ultra"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3680,
    hazzard: {
      id: 151,
      substanceWorkCondition: "Rock wool",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 13,
    locationOrder: 1,
    skin_care_product: {
      id: 33,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:49.000Z",
      productName: "Solopol® Lime (UL)",
      productURL: "https://www.debgroup.com/us/products/solopol-lime"
    },
    rating: 3,
    location: "Hands",
    level1: false,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3681,
    hazzard: {
      id: 151,
      substanceWorkCondition: "Rock wool",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 15,
    locationOrder: 1,
    skin_care_product: {
      id: 29,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:41:00.000Z",
      productName: "Solopol® Classic  (USDA)",
      productURL: "https://www.debgroup.com/us/products/solopol-classic"
    },
    rating: 4,
    location: "Hands",
    level1: false,
    level2: false,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3682,
    hazzard: {
      id: 152,
      substanceWorkCondition: "Rubber",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 21,
    locationOrder: 1,
    skin_care_product: {
      id: 14,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:29:50.000Z",
      productName: "Kresto® Classic",
      productURL: "https://www.debgroup.com/us/products/kresto-classic"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3683,
    hazzard: {
      id: 152,
      substanceWorkCondition: "Rubber",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 23,
    locationOrder: 1,
    skin_care_product: {
      id: 13,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:28:48.000Z",
      productName: "Kresto® Cherry Wipes",
      productURL: "https://www.debgroup.com/us/products/kresto-cherry-wipes"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3684,
    hazzard: {
      id: 152,
      substanceWorkCondition: "Rubber",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 25,
    locationOrder: 1,
    skin_care_product: {
      id: 17,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:32:06.000Z",
      productName: "Kresto® Special ULTRA",
      productURL: "https://www.debgroup.com/us/products/kresto-special-ultra"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: false,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3685,
    hazzard: {
      id: 153,
      substanceWorkCondition: "Salts / Salt solution",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 13,
    locationOrder: 1,
    skin_care_product: {
      id: 33,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:49.000Z",
      productName: "Solopol® Lime (UL)",
      productURL: "https://www.debgroup.com/us/products/solopol-lime"
    },
    rating: 3,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3686,
    hazzard: {
      id: 154,
      substanceWorkCondition: "Sealing compound",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 21,
    locationOrder: 1,
    skin_care_product: {
      id: 14,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:29:50.000Z",
      productName: "Kresto® Classic",
      productURL: "https://www.debgroup.com/us/products/kresto-classic"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3687,
    hazzard: {
      id: 154,
      substanceWorkCondition: "Sealing compound",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 23,
    locationOrder: 1,
    skin_care_product: {
      id: 13,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:28:48.000Z",
      productName: "Kresto® Cherry Wipes",
      productURL: "https://www.debgroup.com/us/products/kresto-cherry-wipes"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3688,
    hazzard: {
      id: 154,
      substanceWorkCondition: "Sealing compound",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 25,
    locationOrder: 1,
    skin_care_product: {
      id: 17,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:32:06.000Z",
      productName: "Kresto® Special ULTRA",
      productURL: "https://www.debgroup.com/us/products/kresto-special-ultra"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: false,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3689,
    hazzard: {
      id: 155,
      substanceWorkCondition: "Sintering",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 15,
    locationOrder: 1,
    skin_care_product: {
      id: 29,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:41:00.000Z",
      productName: "Solopol® Classic  (USDA)",
      productURL: "https://www.debgroup.com/us/products/solopol-classic"
    },
    rating: 4,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3690,
    hazzard: {
      id: 155,
      substanceWorkCondition: "Sintering",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 21,
    locationOrder: 1,
    skin_care_product: {
      id: 14,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:29:50.000Z",
      productName: "Kresto® Classic",
      productURL: "https://www.debgroup.com/us/products/kresto-classic"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: false,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3691,
    hazzard: {
      id: 155,
      substanceWorkCondition: "Sintering",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 23,
    locationOrder: 1,
    skin_care_product: {
      id: 13,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:28:48.000Z",
      productName: "Kresto® Cherry Wipes",
      productURL: "https://www.debgroup.com/us/products/kresto-cherry-wipes"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3692,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Cleanse",
    productOrder: 15,
    locationOrder: 1,
    skin_care_product: {
      id: 29,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:41:00.000Z",
      productName: "Solopol® Classic  (USDA)",
      productURL: "https://www.debgroup.com/us/products/solopol-classic"
    },
    rating: 4,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3693,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Cleanse",
    productOrder: 17,
    locationOrder: 1,
    skin_care_product: {
      id: 31,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:42:23.000Z",
      productName: "Solopol® GFX™",
      productURL: "https://www.debgroup.com/us/products/solopol-gfx"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: null,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3694,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Cleanse",
    productOrder: 21,
    locationOrder: 1,
    skin_care_product: {
      id: 14,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:29:50.000Z",
      productName: "Kresto® Classic",
      productURL: "https://www.debgroup.com/us/products/kresto-classic"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: false,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3695,
    hazzard: {
      id: 158,
      substanceWorkCondition: "Soot",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 15,
    locationOrder: 1,
    skin_care_product: {
      id: 29,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:41:00.000Z",
      productName: "Solopol® Classic  (USDA)",
      productURL: "https://www.debgroup.com/us/products/solopol-classic"
    },
    rating: 4,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3696,
    hazzard: {
      id: 158,
      substanceWorkCondition: "Soot",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 21,
    locationOrder: 1,
    skin_care_product: {
      id: 14,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:29:50.000Z",
      productName: "Kresto® Classic",
      productURL: "https://www.debgroup.com/us/products/kresto-classic"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: false,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3697,
    hazzard: {
      id: 158,
      substanceWorkCondition: "Soot",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 23,
    locationOrder: 1,
    skin_care_product: {
      id: 13,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:28:48.000Z",
      productName: "Kresto® Cherry Wipes",
      productURL: "https://www.debgroup.com/us/products/kresto-cherry-wipes"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3698,
    hazzard: {
      id: 159,
      substanceWorkCondition: "Styrene",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 15,
    locationOrder: 1,
    skin_care_product: {
      id: 29,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:41:00.000Z",
      productName: "Solopol® Classic  (USDA)",
      productURL: "https://www.debgroup.com/us/products/solopol-classic"
    },
    rating: 4,
    location: "Hands",
    level1: false,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3699,
    hazzard: {
      id: 159,
      substanceWorkCondition: "Styrene",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 21,
    locationOrder: 1,
    skin_care_product: {
      id: 14,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:29:50.000Z",
      productName: "Kresto® Classic",
      productURL: "https://www.debgroup.com/us/products/kresto-classic"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: false,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3700,
    hazzard: {
      id: 159,
      substanceWorkCondition: "Styrene",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 23,
    locationOrder: 1,
    skin_care_product: {
      id: 13,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:28:48.000Z",
      productName: "Kresto® Cherry Wipes",
      productURL: "https://www.debgroup.com/us/products/kresto-cherry-wipes"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3701,
    hazzard: {
      id: 161,
      substanceWorkCondition: "Synthetic melting glaze",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 21,
    locationOrder: 1,
    skin_care_product: {
      id: 14,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:29:50.000Z",
      productName: "Kresto® Classic",
      productURL: "https://www.debgroup.com/us/products/kresto-classic"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3702,
    hazzard: {
      id: 161,
      substanceWorkCondition: "Synthetic melting glaze",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 23,
    locationOrder: 1,
    skin_care_product: {
      id: 13,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:28:48.000Z",
      productName: "Kresto® Cherry Wipes",
      productURL: "https://www.debgroup.com/us/products/kresto-cherry-wipes"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3703,
    hazzard: {
      id: 161,
      substanceWorkCondition: "Synthetic melting glaze",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 25,
    locationOrder: 1,
    skin_care_product: {
      id: 17,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:32:06.000Z",
      productName: "Kresto® Special ULTRA",
      productURL: "https://www.debgroup.com/us/products/kresto-special-ultra"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: false,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3704,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Cleanse",
    productOrder: 21,
    locationOrder: 1,
    skin_care_product: {
      id: 14,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:29:50.000Z",
      productName: "Kresto® Classic",
      productURL: "https://www.debgroup.com/us/products/kresto-classic"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3705,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Cleanse",
    productOrder: 23,
    locationOrder: 1,
    skin_care_product: {
      id: 13,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:28:48.000Z",
      productName: "Kresto® Cherry Wipes",
      productURL: "https://www.debgroup.com/us/products/kresto-cherry-wipes"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3706,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Cleanse",
    productOrder: 25,
    locationOrder: 1,
    skin_care_product: {
      id: 17,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:32:06.000Z",
      productName: "Kresto® Special ULTRA",
      productURL: "https://www.debgroup.com/us/products/kresto-special-ultra"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: false,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3707,
    hazzard: {
      id: 163,
      substanceWorkCondition: "Tanning agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 14,
    locationOrder: 1,
    skin_care_product: {
      id: 32,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:07.000Z",
      productName: "Solopol® GFX™ (USDA, UL, GS)",
      productURL: "https://www.debgroup.com/us/products/solopol-gfx"
    },
    rating: 3,
    location: "Hands",
    level1: false,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3708,
    hazzard: {
      id: 163,
      substanceWorkCondition: "Tanning agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 15,
    locationOrder: 1,
    skin_care_product: {
      id: 29,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:41:00.000Z",
      productName: "Solopol® Classic  (USDA)",
      productURL: "https://www.debgroup.com/us/products/solopol-classic"
    },
    rating: 4,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3709,
    hazzard: {
      id: 164,
      substanceWorkCondition: "Tar",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 21,
    locationOrder: 1,
    skin_care_product: {
      id: 14,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:29:50.000Z",
      productName: "Kresto® Classic",
      productURL: "https://www.debgroup.com/us/products/kresto-classic"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3710,
    hazzard: {
      id: 164,
      substanceWorkCondition: "Tar",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 23,
    locationOrder: 1,
    skin_care_product: {
      id: 13,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:28:48.000Z",
      productName: "Kresto® Cherry Wipes",
      productURL: "https://www.debgroup.com/us/products/kresto-cherry-wipes"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3711,
    hazzard: {
      id: 164,
      substanceWorkCondition: "Tar",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 25,
    locationOrder: 1,
    skin_care_product: {
      id: 17,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:32:06.000Z",
      productName: "Kresto® Special ULTRA",
      productURL: "https://www.debgroup.com/us/products/kresto-special-ultra"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: false,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3712,
    hazzard: {
      id: 166,
      substanceWorkCondition: "Thinner",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 14,
    locationOrder: 1,
    skin_care_product: {
      id: 32,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:07.000Z",
      productName: "Solopol® GFX™ (USDA, UL, GS)",
      productURL: "https://www.debgroup.com/us/products/solopol-gfx"
    },
    rating: 3,
    location: "Hands",
    level1: false,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3713,
    hazzard: {
      id: 166,
      substanceWorkCondition: "Thinner",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 15,
    locationOrder: 1,
    skin_care_product: {
      id: 29,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:41:00.000Z",
      productName: "Solopol® Classic  (USDA)",
      productURL: "https://www.debgroup.com/us/products/solopol-classic"
    },
    rating: 4,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3714,
    hazzard: {
      id: 167,
      substanceWorkCondition: "Toluene",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 13,
    locationOrder: 1,
    skin_care_product: {
      id: 33,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:49.000Z",
      productName: "Solopol® Lime (UL)",
      productURL: "https://www.debgroup.com/us/products/solopol-lime"
    },
    rating: 3,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3715,
    hazzard: {
      id: 167,
      substanceWorkCondition: "Toluene",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 14,
    locationOrder: 1,
    skin_care_product: {
      id: 32,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:07.000Z",
      productName: "Solopol® GFX™ (USDA, UL, GS)",
      productURL: "https://www.debgroup.com/us/products/solopol-gfx"
    },
    rating: 3,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3716,
    hazzard: {
      id: 168,
      substanceWorkCondition: "Turpentine oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 13,
    locationOrder: 1,
    skin_care_product: {
      id: 33,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:49.000Z",
      productName: "Solopol® Lime (UL)",
      productURL: "https://www.debgroup.com/us/products/solopol-lime"
    },
    rating: 3,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3717,
    hazzard: {
      id: 168,
      substanceWorkCondition: "Turpentine oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 15,
    locationOrder: 1,
    skin_care_product: {
      id: 29,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:41:00.000Z",
      productName: "Solopol® Classic  (USDA)",
      productURL: "https://www.debgroup.com/us/products/solopol-classic"
    },
    rating: 4,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3718,
    hazzard: {
      id: 170,
      substanceWorkCondition: "Washing bath, washing liquid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 13,
    locationOrder: 1,
    skin_care_product: {
      id: 33,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:49.000Z",
      productName: "Solopol® Lime (UL)",
      productURL: "https://www.debgroup.com/us/products/solopol-lime"
    },
    rating: 3,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3719,
    hazzard: {
      id: 170,
      substanceWorkCondition: "Washing bath, washing liquid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 14,
    locationOrder: 1,
    skin_care_product: {
      id: 32,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:07.000Z",
      productName: "Solopol® GFX™ (USDA, UL, GS)",
      productURL: "https://www.debgroup.com/us/products/solopol-gfx"
    },
    rating: 3,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3720,
    hazzard: {
      id: 171,
      substanceWorkCondition: "Waste oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 15,
    locationOrder: 1,
    skin_care_product: {
      id: 29,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:41:00.000Z",
      productName: "Solopol® Classic  (USDA)",
      productURL: "https://www.debgroup.com/us/products/solopol-classic"
    },
    rating: 4,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3721,
    hazzard: {
      id: 171,
      substanceWorkCondition: "Waste oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 21,
    locationOrder: 1,
    skin_care_product: {
      id: 14,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:29:50.000Z",
      productName: "Kresto® Classic",
      productURL: "https://www.debgroup.com/us/products/kresto-classic"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3722,
    hazzard: {
      id: 174,
      substanceWorkCondition: "wire drawing oil / wire drawing grease",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 15,
    locationOrder: 1,
    skin_care_product: {
      id: 29,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:41:00.000Z",
      productName: "Solopol® Classic  (USDA)",
      productURL: "https://www.debgroup.com/us/products/solopol-classic"
    },
    rating: 4,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3723,
    hazzard: {
      id: 174,
      substanceWorkCondition: "wire drawing oil / wire drawing grease",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 21,
    locationOrder: 1,
    skin_care_product: {
      id: 14,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:29:50.000Z",
      productName: "Kresto® Classic",
      productURL: "https://www.debgroup.com/us/products/kresto-classic"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: false,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3724,
    hazzard: {
      id: 999,
      substanceWorkCondition: "All Products",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:44:03.000Z",
      updated_at: "2020-03-02T17:44:03.000Z"
    },
    step: "Cleanse",
    productOrder: 13,
    locationOrder: 1,
    skin_care_product: {
      id: 33,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:49.000Z",
      productName: "Solopol® Lime (UL)",
      productURL: "https://www.debgroup.com/us/products/solopol-lime"
    },
    rating: 3,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3725,
    hazzard: {
      id: 999,
      substanceWorkCondition: "All Products",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:44:03.000Z",
      updated_at: "2020-03-02T17:44:03.000Z"
    },
    step: "Cleanse",
    productOrder: 14,
    locationOrder: 1,
    skin_care_product: {
      id: 32,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:07.000Z",
      productName: "Solopol® GFX™ (USDA, UL, GS)",
      productURL: "https://www.debgroup.com/us/products/solopol-gfx"
    },
    rating: 3,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3726,
    hazzard: {
      id: 999,
      substanceWorkCondition: "All Products",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:44:03.000Z",
      updated_at: "2020-03-02T17:44:03.000Z"
    },
    step: "Cleanse",
    productOrder: 15,
    locationOrder: 1,
    skin_care_product: {
      id: 29,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:41:00.000Z",
      productName: "Solopol® Classic  (USDA)",
      productURL: "https://www.debgroup.com/us/products/solopol-classic"
    },
    rating: 4,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3727,
    hazzard: {
      id: 999,
      substanceWorkCondition: "All Products",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:44:03.000Z",
      updated_at: "2020-03-02T17:44:03.000Z"
    },
    step: "Cleanse",
    productOrder: 18,
    locationOrder: 1,
    skin_care_product: {
      id: 16,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:31:28.000Z",
      productName: "Kresto® kwik wipes®",
      productURL: "https://www.debgroup.com/us/products/kresto-kwik-wipes"
    },
    rating: 4,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3728,
    hazzard: {
      id: 999,
      substanceWorkCondition: "All Products",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:44:03.000Z",
      updated_at: "2020-03-02T17:44:03.000Z"
    },
    step: "Cleanse",
    productOrder: 20,
    locationOrder: 1,
    skin_care_product: {
      id: 15,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:30:30.000Z",
      productName: "Kresto® Heritage (UL)",
      productURL: "https://www.debgroup.com/us/products/kresto-heritage"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3729,
    hazzard: {
      id: 999,
      substanceWorkCondition: "All Products",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:44:03.000Z",
      updated_at: "2020-03-02T17:44:03.000Z"
    },
    step: "Cleanse",
    productOrder: 21,
    locationOrder: 1,
    skin_care_product: {
      id: 14,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:29:50.000Z",
      productName: "Kresto® Classic",
      productURL: "https://www.debgroup.com/us/products/kresto-classic"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: false,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3730,
    hazzard: {
      id: 999,
      substanceWorkCondition: "All Products",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:44:03.000Z",
      updated_at: "2020-03-02T17:44:03.000Z"
    },
    step: "Cleanse",
    productOrder: 22,
    locationOrder: 4,
    skin_care_product: {
      id: 12,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:28:21.000Z",
      productName: "Kresto® Cherry",
      productURL: "https://www.debgroup.com/us/products/kresto-cherry"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3731,
    hazzard: {
      id: 999,
      substanceWorkCondition: "All Products",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:44:03.000Z",
      updated_at: "2020-03-02T17:44:03.000Z"
    },
    step: "Cleanse",
    productOrder: 23,
    locationOrder: 1,
    skin_care_product: {
      id: 13,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:28:48.000Z",
      productName: "Kresto® Cherry Wipes",
      productURL: "https://www.debgroup.com/us/products/kresto-cherry-wipes"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3732,
    hazzard: {
      id: 999,
      substanceWorkCondition: "All Products",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:44:03.000Z",
      updated_at: "2020-03-02T17:44:03.000Z"
    },
    step: "Cleanse",
    productOrder: 25,
    locationOrder: 1,
    skin_care_product: {
      id: 17,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:32:06.000Z",
      productName: "Kresto® Special ULTRA",
      productURL: "https://www.debgroup.com/us/products/kresto-special-ultra"
    },
    rating: 5,
    location: "Hands",
    level1: false,
    level2: false,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3733,
    hazzard: {
      id: 1,
      substanceWorkCondition: "2-Butanone (MEK)",
      cas: "78-93-3",
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: "oil",
      created_at: "2020-03-02T17:39:32.000Z",
      updated_at: "2020-03-05T14:48:01.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3734,
    hazzard: {
      id: 2,
      substanceWorkCondition: "Acetone / Dimethyl ketone",
      cas: "67-64-1",
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3735,
    hazzard: {
      id: 3,
      substanceWorkCondition: "Acids, diluted",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3736,
    hazzard: {
      id: 4,
      substanceWorkCondition: "Acrylates",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3737,
    hazzard: {
      id: 5,
      substanceWorkCondition: "Adhesive single-component",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3738,
    hazzard: {
      id: 6,
      substanceWorkCondition: "Adhesive, water-soluble",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3739,
    hazzard: {
      id: 7,
      substanceWorkCondition: "Adhesives, glues based on synthetic resin",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:54.000Z",
    updated_at: "2020-03-02T22:49:54.000Z"
  },
  {
    id: 3740,
    hazzard: {
      id: 8,
      substanceWorkCondition: "Aldehydes, diluted",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:55.000Z",
    updated_at: "2020-03-02T22:49:55.000Z"
  },
  {
    id: 3741,
    hazzard: {
      id: 9,
      substanceWorkCondition: "Alkaline solutions / lyes / thinned caustics",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:55.000Z",
    updated_at: "2020-03-02T22:49:55.000Z"
  },
  {
    id: 3742,
    hazzard: {
      id: 10,
      substanceWorkCondition: "Alkaline solutions, diluted",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:55.000Z",
    updated_at: "2020-03-02T22:49:55.000Z"
  },
  {
    id: 3743,
    hazzard: {
      id: 11,
      substanceWorkCondition: "Alkaline solutions, lyes (solvent based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:55.000Z",
    updated_at: "2020-03-02T22:49:55.000Z"
  },
  {
    id: 3744,
    hazzard: {
      id: 12,
      substanceWorkCondition: "Aluminium",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:55.000Z",
    updated_at: "2020-03-02T22:49:55.000Z"
  },
  {
    id: 3745,
    hazzard: {
      id: 14,
      substanceWorkCondition: "Antistatics",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:55.000Z",
    updated_at: "2020-03-02T22:49:55.000Z"
  },
  {
    id: 3746,
    hazzard: {
      id: 15,
      substanceWorkCondition: "Asphalt",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:55.000Z",
    updated_at: "2020-03-02T22:49:55.000Z"
  },
  {
    id: 3747,
    hazzard: {
      id: 18,
      substanceWorkCondition:
        "Automotive Aftermarket - Public Transport Maintenance&Repair",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:55.000Z",
    updated_at: "2020-03-02T22:49:55.000Z"
  },
  {
    id: 3748,
    hazzard: {
      id: 19,
      substanceWorkCondition: "Battery acid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:55.000Z",
    updated_at: "2020-03-02T22:49:55.000Z"
  },
  {
    id: 3749,
    hazzard: {
      id: 20,
      substanceWorkCondition: "Benzene",
      cas: null,
      glovesRecommended: true,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:55.000Z",
    updated_at: "2020-03-02T22:49:55.000Z"
  },
  {
    id: 3750,
    hazzard: {
      id: 22,
      substanceWorkCondition: "Bitumen emulsion",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:55.000Z",
    updated_at: "2020-03-02T22:49:55.000Z"
  },
  {
    id: 3751,
    hazzard: {
      id: 21,
      substanceWorkCondition: "Bitumen",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:55.000Z",
    updated_at: "2020-03-02T22:49:55.000Z"
  },
  {
    id: 3752,
    hazzard: {
      id: 23,
      substanceWorkCondition: "Bleaching agent (lye)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:55.000Z",
    updated_at: "2020-03-02T22:49:55.000Z"
  },
  {
    id: 3753,
    hazzard: {
      id: 24,
      substanceWorkCondition: "Brake fluid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:55.000Z",
    updated_at: "2020-03-02T22:49:55.000Z"
  },
  {
    id: 3754,
    hazzard: {
      id: 25,
      substanceWorkCondition: "Casting dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:55.000Z",
    updated_at: "2020-03-02T22:49:55.000Z"
  },
  {
    id: 3755,
    hazzard: {
      id: 26,
      substanceWorkCondition: "Cement (dry)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:55.000Z",
    updated_at: "2020-03-02T22:49:55.000Z"
  },
  {
    id: 3756,
    hazzard: {
      id: 27,
      substanceWorkCondition: "Cement (wet)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:55.000Z",
    updated_at: "2020-03-02T22:49:55.000Z"
  },
  {
    id: 3757,
    hazzard: {
      id: 28,
      substanceWorkCondition: "Cement, putty epoxy-based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:55.000Z",
    updated_at: "2020-03-02T22:49:55.000Z"
  },
  {
    id: 3758,
    hazzard: {
      id: 29,
      substanceWorkCondition:
        "Chromates (water- insoluble) = Chromium pigments",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:55.000Z",
    updated_at: "2020-03-02T22:49:55.000Z"
  },
  {
    id: 3759,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:55.000Z",
    updated_at: "2020-03-02T22:49:55.000Z"
  },
  {
    id: 3760,
    hazzard: {
      id: 31,
      substanceWorkCondition: "cleaning agent, detergent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:55.000Z",
    updated_at: "2020-03-02T22:49:55.000Z"
  },
  {
    id: 3761,
    hazzard: {
      id: 32,
      substanceWorkCondition: "Coal dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:55.000Z",
    updated_at: "2020-03-02T22:49:55.000Z"
  },
  {
    id: 3762,
    hazzard: {
      id: 33,
      substanceWorkCondition: "Coating materials, bituminous (bitumen varnish)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:55.000Z",
    updated_at: "2020-03-02T22:49:55.000Z"
  },
  {
    id: 3763,
    hazzard: {
      id: 34,
      substanceWorkCondition: "Coating materials, emulsified",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:55.000Z",
    updated_at: "2020-03-02T22:49:55.000Z"
  },
  {
    id: 3764,
    hazzard: {
      id: 35,
      substanceWorkCondition: "Coating materials, oily",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:55.000Z",
    updated_at: "2020-03-02T22:49:55.000Z"
  },
  {
    id: 3765,
    hazzard: {
      id: 36,
      substanceWorkCondition: "Cold degreasing agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:55.000Z",
    updated_at: "2020-03-02T22:49:55.000Z"
  },
  {
    id: 3766,
    hazzard: {
      id: 37,
      substanceWorkCondition: "cold detergent, solvent based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:55.000Z",
    updated_at: "2020-03-02T22:49:55.000Z"
  },
  {
    id: 3767,
    hazzard: {
      id: 38,
      substanceWorkCondition: "Cold working conditions",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:55.000Z",
    updated_at: "2020-03-02T22:49:55.000Z"
  },
  {
    id: 3768,
    hazzard: {
      id: 39,
      substanceWorkCondition:
        "Commercial - Airports & Transport Terminals - Public Area",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:55.000Z",
    updated_at: "2020-03-02T22:49:55.000Z"
  },
  {
    id: 3769,
    hazzard: {
      id: 40,
      substanceWorkCondition: "Commercial - Cleaning & Maintenance",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:55.000Z",
    updated_at: "2020-03-02T22:49:55.000Z"
  },
  {
    id: 3770,
    hazzard: {
      id: 41,
      substanceWorkCondition: "Commercial - Commerce & Office",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:55.000Z",
    updated_at: "2020-03-02T22:49:55.000Z"
  },
  {
    id: 3771,
    hazzard: {
      id: 42,
      substanceWorkCondition: "Commercial - Education",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:55.000Z",
    updated_at: "2020-03-02T22:49:55.000Z"
  },
  {
    id: 3772,
    hazzard: {
      id: 43,
      substanceWorkCondition: "Commercial - Hotel & Leisure",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:55.000Z",
    updated_at: "2020-03-02T22:49:55.000Z"
  },
  {
    id: 3773,
    hazzard: {
      id: 44,
      substanceWorkCondition: "Commercial - Prisons & Security Services",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:55.000Z",
    updated_at: "2020-03-02T22:49:55.000Z"
  },
  {
    id: 3774,
    hazzard: {
      id: 45,
      substanceWorkCondition:
        "Commercial - Public Entertainments & Theme Parks",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:55.000Z",
    updated_at: "2020-03-02T22:49:55.000Z"
  },
  {
    id: 3775,
    hazzard: {
      id: 46,
      substanceWorkCondition: "Commercial - Public Facilities",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:55.000Z",
    updated_at: "2020-03-02T22:49:55.000Z"
  },
  {
    id: 3776,
    hazzard: {
      id: 47,
      substanceWorkCondition: "Concrete",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:55.000Z",
    updated_at: "2020-03-02T22:49:55.000Z"
  },
  {
    id: 3777,
    hazzard: {
      id: 48,
      substanceWorkCondition: "Cooling lubricant (non-water-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:55.000Z",
    updated_at: "2020-03-02T22:49:55.000Z"
  },
  {
    id: 3778,
    hazzard: {
      id: 49,
      substanceWorkCondition: "Cooling lubricant (water- based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:55.000Z",
    updated_at: "2020-03-02T22:49:55.000Z"
  },
  {
    id: 3779,
    hazzard: {
      id: 50,
      substanceWorkCondition: "Cooling water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:55.000Z",
    updated_at: "2020-03-02T22:49:55.000Z"
  },
  {
    id: 3780,
    hazzard: {
      id: 51,
      substanceWorkCondition: "Copying ink",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:55.000Z",
    updated_at: "2020-03-02T22:49:55.000Z"
  },
  {
    id: 3781,
    hazzard: {
      id: 52,
      substanceWorkCondition: "Cutting oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:55.000Z",
    updated_at: "2020-03-02T22:49:55.000Z"
  },
  {
    id: 3782,
    hazzard: {
      id: 53,
      substanceWorkCondition: "Degreaser solvent - based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:55.000Z",
    updated_at: "2020-03-02T22:49:55.000Z"
  },
  {
    id: 3783,
    hazzard: {
      id: 54,
      substanceWorkCondition: "Degreaser without organic solvent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:55.000Z",
    updated_at: "2020-03-02T22:49:55.000Z"
  },
  {
    id: 3784,
    hazzard: {
      id: 55,
      substanceWorkCondition: "Detachment solution for concrete",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:55.000Z",
    updated_at: "2020-03-02T22:49:55.000Z"
  },
  {
    id: 3785,
    hazzard: {
      id: 56,
      substanceWorkCondition: "Detachments (alcohol-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:55.000Z",
    updated_at: "2020-03-02T22:49:55.000Z"
  },
  {
    id: 3786,
    hazzard: {
      id: 57,
      substanceWorkCondition: "Detachments (solvent-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:55.000Z",
    updated_at: "2020-03-02T22:49:55.000Z"
  },
  {
    id: 3787,
    hazzard: {
      id: 58,
      substanceWorkCondition: "Detachments (sour)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:55.000Z",
    updated_at: "2020-03-02T22:49:55.000Z"
  },
  {
    id: 3788,
    hazzard: {
      id: 59,
      substanceWorkCondition: "Disinfectants (alcohol-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:55.000Z",
    updated_at: "2020-03-02T22:49:55.000Z"
  },
  {
    id: 3789,
    hazzard: {
      id: 60,
      substanceWorkCondition: "Disinfectants (formaldehyd-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:55.000Z",
    updated_at: "2020-03-02T22:49:55.000Z"
  },
  {
    id: 3790,
    hazzard: {
      id: 61,
      substanceWorkCondition: "Disinfectants (water-based) ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:55.000Z",
    updated_at: "2020-03-02T22:49:55.000Z"
  },
  {
    id: 3791,
    hazzard: {
      id: 62,
      substanceWorkCondition: "Dispersing Agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:55.000Z",
    updated_at: "2020-03-02T22:49:55.000Z"
  },
  {
    id: 3792,
    hazzard: {
      id: 63,
      substanceWorkCondition: "Drilling emulsion (water based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:55.000Z",
    updated_at: "2020-03-02T22:49:55.000Z"
  },
  {
    id: 3793,
    hazzard: {
      id: 64,
      substanceWorkCondition: "Drilling oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:55.000Z",
    updated_at: "2020-03-02T22:49:55.000Z"
  },
  {
    id: 3794,
    hazzard: {
      id: 65,
      substanceWorkCondition: "Dryness",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:55.000Z",
    updated_at: "2020-03-02T22:49:55.000Z"
  },
  {
    id: 3795,
    hazzard: {
      id: 66,
      substanceWorkCondition: "Dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:55.000Z",
    updated_at: "2020-03-02T22:49:55.000Z"
  },
  {
    id: 3796,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:55.000Z",
    updated_at: "2020-03-02T22:49:55.000Z"
  },
  {
    id: 3797,
    hazzard: {
      id: 70,
      substanceWorkCondition: "Epoxy resin",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:55.000Z",
    updated_at: "2020-03-02T22:49:55.000Z"
  },
  {
    id: 3798,
    hazzard: {
      id: 71,
      substanceWorkCondition: "Etching (mordant)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:55.000Z",
    updated_at: "2020-03-02T22:49:55.000Z"
  },
  {
    id: 3799,
    hazzard: {
      id: 72,
      substanceWorkCondition: "Ethanol / methylated spirits ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:55.000Z",
    updated_at: "2020-03-02T22:49:55.000Z"
  },
  {
    id: 3800,
    hazzard: {
      id: 73,
      substanceWorkCondition: "Fat",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:55.000Z",
    updated_at: "2020-03-02T22:49:55.000Z"
  },
  {
    id: 3801,
    hazzard: {
      id: 74,
      substanceWorkCondition: "Fertilizer",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:55.000Z",
    updated_at: "2020-03-02T22:49:55.000Z"
  },
  {
    id: 3802,
    hazzard: {
      id: 75,
      substanceWorkCondition: "fine anorganic/inorganic powders ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:55.000Z",
    updated_at: "2020-03-02T22:49:55.000Z"
  },
  {
    id: 3803,
    hazzard: {
      id: 76,
      substanceWorkCondition: "Floor cleaner (water- based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:55.000Z",
    updated_at: "2020-03-02T22:49:55.000Z"
  },
  {
    id: 3804,
    hazzard: {
      id: 81,
      substanceWorkCondition: "fuel: gas / diesel",
      cas: null,
      glovesRecommended: true,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:55.000Z",
    updated_at: "2020-03-02T22:49:55.000Z"
  },
  {
    id: 3805,
    hazzard: {
      id: 82,
      substanceWorkCondition: "Galvanic bath",
      cas: null,
      glovesRecommended: true,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:55.000Z",
    updated_at: "2020-03-02T22:49:55.000Z"
  },
  {
    id: 3806,
    hazzard: {
      id: 83,
      substanceWorkCondition: "Glass fibre dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:55.000Z",
    updated_at: "2020-03-02T22:49:55.000Z"
  },
  {
    id: 3807,
    hazzard: {
      id: 84,
      substanceWorkCondition: "Glass wool dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:55.000Z",
    updated_at: "2020-03-02T22:49:55.000Z"
  },
  {
    id: 3808,
    hazzard: {
      id: 85,
      substanceWorkCondition: "Glycols",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:55.000Z",
    updated_at: "2020-03-02T22:49:55.000Z"
  },
  {
    id: 3809,
    hazzard: {
      id: 86,
      substanceWorkCondition: "Graphite",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3810,
    hazzard: {
      id: 87,
      substanceWorkCondition: "Grease",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3811,
    hazzard: {
      id: 88,
      substanceWorkCondition: "Grinding oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3812,
    hazzard: {
      id: 89,
      substanceWorkCondition: "Grinding water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3813,
    hazzard: {
      id: 90,
      substanceWorkCondition: "Gypsum",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3814,
    hazzard: {
      id: 91,
      substanceWorkCondition: "Healthcare - Care Homes",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3815,
    hazzard: {
      id: 92,
      substanceWorkCondition:
        "Healthcare - Medical Centers/Surgeries/General Practitioners",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3816,
    hazzard: {
      id: 93,
      substanceWorkCondition: "Healthcare - Nursing Homes",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3817,
    hazzard: {
      id: 94,
      substanceWorkCondition: "Healthcare - Veterinary Services",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3818,
    hazzard: {
      id: 95,
      substanceWorkCondition: "Hydraulic fluid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3819,
    hazzard: {
      id: 96,
      substanceWorkCondition: "Hyrocarbons",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3820,
    hazzard: {
      id: 97,
      substanceWorkCondition: "Industry Heavy - Building Materials",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3821,
    hazzard: {
      id: 98,
      substanceWorkCondition: "Industry Heavy - Construction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3822,
    hazzard: {
      id: 99,
      substanceWorkCondition: "Industry Heavy - Mining & Extraction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3823,
    hazzard: {
      id: 100,
      substanceWorkCondition: "Industry Heavy - Off-Shore Extraction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3824,
    hazzard: {
      id: 101,
      substanceWorkCondition: "Industry Heavy - Printing Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3825,
    hazzard: {
      id: 102,
      substanceWorkCondition:
        "Industry Heavy - Road & Transportation Construction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3826,
    hazzard: {
      id: 103,
      substanceWorkCondition: "Industry Heavy - Ship & Boat Building",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3827,
    hazzard: {
      id: 104,
      substanceWorkCondition: "Industry Heavy - Shipping & Marine",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3828,
    hazzard: {
      id: 105,
      substanceWorkCondition:
        "Industry Heavy - Steel, Engineering & Heavy Manufacturing",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3829,
    hazzard: {
      id: 106,
      substanceWorkCondition:
        "Industry Light - Airports & Transportation Terminals - Services & Facility Maintenance",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3830,
    hazzard: {
      id: 107,
      substanceWorkCondition: "Industry Light - Emergency Services",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3831,
    hazzard: {
      id: 108,
      substanceWorkCondition:
        "Industry Light - General Manufacturing & Assembly",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3832,
    hazzard: {
      id: 109,
      substanceWorkCondition:
        "Industry Light - Hosiery, Knitware, Fabric & Clothing Manufacturing",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3833,
    hazzard: {
      id: 110,
      substanceWorkCondition: "Industry Light - Science & Laboratories",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3834,
    hazzard: {
      id: 111,
      substanceWorkCondition: "Industry Light - Warehousing & Logistics",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3835,
    hazzard: {
      id: 112,
      substanceWorkCondition: "Industry Medium - Ceramics & Glassware",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3836,
    hazzard: {
      id: 113,
      substanceWorkCondition: "Industry Medium - Chemical Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3837,
    hazzard: {
      id: 114,
      substanceWorkCondition: "Industry Medium - Electronics Industries",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3838,
    hazzard: {
      id: 115,
      substanceWorkCondition: "Industry Medium - Metal Working & Fabrication",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3839,
    hazzard: {
      id: 116,
      substanceWorkCondition: "Industry Medium - Outdoor Maintenance & Repair",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3840,
    hazzard: {
      id: 117,
      substanceWorkCondition: "Industry Medium - Pharmaceutical Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3841,
    hazzard: {
      id: 118,
      substanceWorkCondition: "Industry Medium - Plastics Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3842,
    hazzard: {
      id: 119,
      substanceWorkCondition: "Ink / printing ink",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3843,
    hazzard: {
      id: 120,
      substanceWorkCondition: "Insulating material",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3844,
    hazzard: {
      id: 121,
      substanceWorkCondition: "Kerosene",
      cas: null,
      glovesRecommended: true,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3845,
    hazzard: {
      id: 122,
      substanceWorkCondition: "Ketone",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3846,
    hazzard: {
      id: 123,
      substanceWorkCondition: "Lacquers (nitro/solvent-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3847,
    hazzard: {
      id: 124,
      substanceWorkCondition: "Lacquers (PU based, synthetic resin based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3848,
    hazzard: {
      id: 125,
      substanceWorkCondition: "Lacquers (UV-hardening)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3849,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3850,
    hazzard: {
      id: 127,
      substanceWorkCondition: "Latex paint",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3851,
    hazzard: {
      id: 128,
      substanceWorkCondition: "Lime",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3852,
    hazzard: {
      id: 129,
      substanceWorkCondition: "Lubricants, water-based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3853,
    hazzard: {
      id: 130,
      substanceWorkCondition: "Metal dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3854,
    hazzard: {
      id: 131,
      substanceWorkCondition: "Methanol / Methyl Alcohol",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3855,
    hazzard: {
      id: 132,
      substanceWorkCondition: "Mineral oil / paraffin oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3856,
    hazzard: {
      id: 132,
      substanceWorkCondition: "Mineral oil / paraffin oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3857,
    hazzard: {
      id: 133,
      substanceWorkCondition: "Multi component adhesive",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3858,
    hazzard: {
      id: 134,
      substanceWorkCondition: "Multi component resin",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3859,
    hazzard: {
      id: 135,
      substanceWorkCondition: "Naphthalene",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3860,
    hazzard: {
      id: 136,
      substanceWorkCondition: "Oil coke (carbon)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3861,
    hazzard: {
      id: 137,
      substanceWorkCondition: "Oil-emulsions (water- mixed)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3862,
    hazzard: {
      id: 138,
      substanceWorkCondition: "Oils / Oil based lubricants",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3863,
    hazzard: {
      id: 140,
      substanceWorkCondition: "Paint Thinner ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3864,
    hazzard: {
      id: 139,
      substanceWorkCondition: "Paint",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3865,
    hazzard: {
      id: 141,
      substanceWorkCondition: "Pit water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3866,
    hazzard: {
      id: 142,
      substanceWorkCondition: "Pitch",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3867,
    hazzard: {
      id: 143,
      substanceWorkCondition: "Polishes (water- soluble)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3868,
    hazzard: {
      id: 144,
      substanceWorkCondition: "Polyester / Polyester resin",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3869,
    hazzard: {
      id: 145,
      substanceWorkCondition: "Polyurethane",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3870,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3871,
    hazzard: {
      id: 148,
      substanceWorkCondition:
        "Propanols (propyl alcohols): n-Propanol, Isopropanol / 2-Propanol ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3872,
    hazzard: {
      id: 149,
      substanceWorkCondition: "release agent, parting agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3873,
    hazzard: {
      id: 150,
      substanceWorkCondition: "Resins / urea resins",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3874,
    hazzard: {
      id: 151,
      substanceWorkCondition: "Rock wool",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3875,
    hazzard: {
      id: 152,
      substanceWorkCondition: "Rubber",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3876,
    hazzard: {
      id: 153,
      substanceWorkCondition: "Salts / Salt solution",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3877,
    hazzard: {
      id: 154,
      substanceWorkCondition: "Sealing compound",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3878,
    hazzard: {
      id: 155,
      substanceWorkCondition: "Sintering",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3879,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3880,
    hazzard: {
      id: 157,
      substanceWorkCondition: "Solvents, - organic",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3881,
    hazzard: {
      id: 158,
      substanceWorkCondition: "Soot",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3882,
    hazzard: {
      id: 159,
      substanceWorkCondition: "Styrene",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3883,
    hazzard: {
      id: 160,
      substanceWorkCondition: "Surface Cleanser (surfactant based), Detergents",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3884,
    hazzard: {
      id: 161,
      substanceWorkCondition: "Synthetic melting glaze",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3885,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3886,
    hazzard: {
      id: 163,
      substanceWorkCondition: "Tanning agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3887,
    hazzard: {
      id: 164,
      substanceWorkCondition: "Tar",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3888,
    hazzard: {
      id: 166,
      substanceWorkCondition: "Thinner",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3889,
    hazzard: {
      id: 167,
      substanceWorkCondition: "Toluene",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3890,
    hazzard: {
      id: 168,
      substanceWorkCondition: "Turpentine oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3891,
    hazzard: {
      id: 170,
      substanceWorkCondition: "Washing bath, washing liquid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3892,
    hazzard: {
      id: 171,
      substanceWorkCondition: "Waste oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3893,
    hazzard: {
      id: 172,
      substanceWorkCondition: "Water / wet work",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3894,
    hazzard: {
      id: 173,
      substanceWorkCondition:
        "Wearing protective clothing / Softening of the skin (Maceration)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3895,
    hazzard: {
      id: 174,
      substanceWorkCondition: "wire drawing oil / wire drawing grease",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3896,
    hazzard: {
      id: 175,
      substanceWorkCondition: "Xylene",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 1,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3897,
    hazzard: {
      id: 999,
      substanceWorkCondition: "All Products",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:44:03.000Z",
      updated_at: "2020-03-02T17:44:03.000Z"
    },
    step: "Restore",
    productOrder: 5,
    locationOrder: 3,
    skin_care_product: {
      id: 39,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:54.000Z",
      productName: "Stokolan® Classic (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-classic"
    },
    rating: 2,
    location: "Hands",
    level1: false,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3898,
    hazzard: {
      id: 1,
      substanceWorkCondition: "2-Butanone (MEK)",
      cas: "78-93-3",
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: "oil",
      created_at: "2020-03-02T17:39:32.000Z",
      updated_at: "2020-03-05T14:48:01.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3899,
    hazzard: {
      id: 2,
      substanceWorkCondition: "Acetone / Dimethyl ketone",
      cas: "67-64-1",
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3900,
    hazzard: {
      id: 4,
      substanceWorkCondition: "Acrylates",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3901,
    hazzard: {
      id: 5,
      substanceWorkCondition: "Adhesive single-component",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3902,
    hazzard: {
      id: 6,
      substanceWorkCondition: "Adhesive, water-soluble",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3903,
    hazzard: {
      id: 7,
      substanceWorkCondition: "Adhesives, glues based on synthetic resin",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3904,
    hazzard: {
      id: 8,
      substanceWorkCondition: "Aldehydes, diluted",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3905,
    hazzard: {
      id: 9,
      substanceWorkCondition: "Alkaline solutions / lyes / thinned caustics",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3906,
    hazzard: {
      id: 10,
      substanceWorkCondition: "Alkaline solutions, diluted",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3907,
    hazzard: {
      id: 11,
      substanceWorkCondition: "Alkaline solutions, lyes (solvent based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3908,
    hazzard: {
      id: 12,
      substanceWorkCondition: "Aluminium",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3909,
    hazzard: {
      id: 14,
      substanceWorkCondition: "Antistatics",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3910,
    hazzard: {
      id: 18,
      substanceWorkCondition:
        "Automotive Aftermarket - Public Transport Maintenance&Repair",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3911,
    hazzard: {
      id: 19,
      substanceWorkCondition: "Battery acid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3912,
    hazzard: {
      id: 22,
      substanceWorkCondition: "Bitumen emulsion",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3913,
    hazzard: {
      id: 21,
      substanceWorkCondition: "Bitumen",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3914,
    hazzard: {
      id: 23,
      substanceWorkCondition: "Bleaching agent (lye)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3915,
    hazzard: {
      id: 24,
      substanceWorkCondition: "Brake fluid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3916,
    hazzard: {
      id: 25,
      substanceWorkCondition: "Casting dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3917,
    hazzard: {
      id: 26,
      substanceWorkCondition: "Cement (dry)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3918,
    hazzard: {
      id: 27,
      substanceWorkCondition: "Cement (wet)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3919,
    hazzard: {
      id: 28,
      substanceWorkCondition: "Cement, putty epoxy-based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3920,
    hazzard: {
      id: 29,
      substanceWorkCondition:
        "Chromates (water- insoluble) = Chromium pigments",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3921,
    hazzard: {
      id: 31,
      substanceWorkCondition: "cleaning agent, detergent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3922,
    hazzard: {
      id: 32,
      substanceWorkCondition: "Coal dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3923,
    hazzard: {
      id: 33,
      substanceWorkCondition: "Coating materials, bituminous (bitumen varnish)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3924,
    hazzard: {
      id: 34,
      substanceWorkCondition: "Coating materials, emulsified",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3925,
    hazzard: {
      id: 35,
      substanceWorkCondition: "Coating materials, oily",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3926,
    hazzard: {
      id: 36,
      substanceWorkCondition: "Cold degreasing agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3927,
    hazzard: {
      id: 37,
      substanceWorkCondition: "cold detergent, solvent based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3928,
    hazzard: {
      id: 38,
      substanceWorkCondition: "Cold working conditions",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 4,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3929,
    hazzard: {
      id: 42,
      substanceWorkCondition: "Commercial - Education",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 7,
    locationOrder: 4,
    skin_care_product: {
      id: 38,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:22.000Z",
      productName: "Stokoderm® Sun Protect 30 PURE",
      productURL:
        "https://www.debgroup.com/us/products/stokoderm-sun-protect-30-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3930,
    hazzard: {
      id: 43,
      substanceWorkCondition: "Commercial - Hotel & Leisure",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 4,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3931,
    hazzard: {
      id: 44,
      substanceWorkCondition: "Commercial - Prisons & Security Services",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 4,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3932,
    hazzard: {
      id: 45,
      substanceWorkCondition:
        "Commercial - Public Entertainments & Theme Parks",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 4,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3933,
    hazzard: {
      id: 45,
      substanceWorkCondition:
        "Commercial - Public Entertainments & Theme Parks",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 7,
    locationOrder: 4,
    skin_care_product: {
      id: 38,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:22.000Z",
      productName: "Stokoderm® Sun Protect 30 PURE",
      productURL:
        "https://www.debgroup.com/us/products/stokoderm-sun-protect-30-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3934,
    hazzard: {
      id: 47,
      substanceWorkCondition: "Concrete",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 4,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3935,
    hazzard: {
      id: 48,
      substanceWorkCondition: "Cooling lubricant (non-water-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 4,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3936,
    hazzard: {
      id: 49,
      substanceWorkCondition: "Cooling lubricant (water- based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3937,
    hazzard: {
      id: 50,
      substanceWorkCondition: "Cooling water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3938,
    hazzard: {
      id: 51,
      substanceWorkCondition: "Copying ink",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 4,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3939,
    hazzard: {
      id: 51,
      substanceWorkCondition: "Copying ink",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 4,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3940,
    hazzard: {
      id: 54,
      substanceWorkCondition: "Degreaser without organic solvent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3941,
    hazzard: {
      id: 54,
      substanceWorkCondition: "Degreaser without organic solvent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 4,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3942,
    hazzard: {
      id: 55,
      substanceWorkCondition: "Detachment solution for concrete",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3943,
    hazzard: {
      id: 55,
      substanceWorkCondition: "Detachment solution for concrete",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 4,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3944,
    hazzard: {
      id: 56,
      substanceWorkCondition: "Detachments (alcohol-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3945,
    hazzard: {
      id: 56,
      substanceWorkCondition: "Detachments (alcohol-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 4,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3946,
    hazzard: {
      id: 57,
      substanceWorkCondition: "Detachments (solvent-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 4,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3947,
    hazzard: {
      id: 58,
      substanceWorkCondition: "Detachments (sour)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 4,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3948,
    hazzard: {
      id: 59,
      substanceWorkCondition: "Disinfectants (alcohol-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3949,
    hazzard: {
      id: 60,
      substanceWorkCondition: "Disinfectants (formaldehyd-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3950,
    hazzard: {
      id: 61,
      substanceWorkCondition: "Disinfectants (water-based) ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3951,
    hazzard: {
      id: 62,
      substanceWorkCondition: "Dispersing Agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3952,
    hazzard: {
      id: 63,
      substanceWorkCondition: "Drilling emulsion (water based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3953,
    hazzard: {
      id: 64,
      substanceWorkCondition: "Drilling oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3954,
    hazzard: {
      id: 65,
      substanceWorkCondition: "Dryness",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 4,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3955,
    hazzard: {
      id: 66,
      substanceWorkCondition: "Dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:56.000Z",
    updated_at: "2020-03-02T22:49:56.000Z"
  },
  {
    id: 3956,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 4,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 3957,
    hazzard: {
      id: 71,
      substanceWorkCondition: "Etching (mordant)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 4,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 3958,
    hazzard: {
      id: 72,
      substanceWorkCondition: "Ethanol / methylated spirits ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 3959,
    hazzard: {
      id: 73,
      substanceWorkCondition: "Fat",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 3960,
    hazzard: {
      id: 74,
      substanceWorkCondition: "Fertilizer",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 4,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 3961,
    hazzard: {
      id: 75,
      substanceWorkCondition: "fine anorganic/inorganic powders ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 3962,
    hazzard: {
      id: 76,
      substanceWorkCondition: "Floor cleaner (water- based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 4,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 3963,
    hazzard: {
      id: 77,
      substanceWorkCondition: "Food - Food Packaging",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 4,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 3964,
    hazzard: {
      id: 78,
      substanceWorkCondition: "Food - Food Processing & Manufacturing",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 4,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 3965,
    hazzard: {
      id: 79,
      substanceWorkCondition: "Food - Food Services & Catering",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 4,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 3966,
    hazzard: {
      id: 80,
      substanceWorkCondition: "Food Dyestuff",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 3967,
    hazzard: {
      id: 82,
      substanceWorkCondition: "Galvanic bath",
      cas: null,
      glovesRecommended: true,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 4,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 3968,
    hazzard: {
      id: 83,
      substanceWorkCondition: "Glass fibre dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 4,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 3969,
    hazzard: {
      id: 84,
      substanceWorkCondition: "Glass wool dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 4,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 3970,
    hazzard: {
      id: 85,
      substanceWorkCondition: "Glycols",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 3971,
    hazzard: {
      id: 86,
      substanceWorkCondition: "Graphite",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 3972,
    hazzard: {
      id: 89,
      substanceWorkCondition: "Grinding water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 3973,
    hazzard: {
      id: 90,
      substanceWorkCondition: "Gypsum",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 3974,
    hazzard: {
      id: 91,
      substanceWorkCondition: "Healthcare - Care Homes",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 4,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 3975,
    hazzard: {
      id: 92,
      substanceWorkCondition:
        "Healthcare - Medical Centers/Surgeries/General Practitioners",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 4,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 3976,
    hazzard: {
      id: 93,
      substanceWorkCondition: "Healthcare - Nursing Homes",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 4,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 3977,
    hazzard: {
      id: 94,
      substanceWorkCondition: "Healthcare - Veterinary Services",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 4,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 3978,
    hazzard: {
      id: 95,
      substanceWorkCondition: "Hydraulic fluid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 3979,
    hazzard: {
      id: 97,
      substanceWorkCondition: "Industry Heavy - Building Materials",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 3980,
    hazzard: {
      id: 97,
      substanceWorkCondition: "Industry Heavy - Building Materials",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 7,
    locationOrder: 4,
    skin_care_product: {
      id: 38,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:22.000Z",
      productName: "Stokoderm® Sun Protect 30 PURE",
      productURL:
        "https://www.debgroup.com/us/products/stokoderm-sun-protect-30-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 3981,
    hazzard: {
      id: 98,
      substanceWorkCondition: "Industry Heavy - Construction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 3982,
    hazzard: {
      id: 98,
      substanceWorkCondition: "Industry Heavy - Construction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 7,
    locationOrder: 4,
    skin_care_product: {
      id: 38,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:22.000Z",
      productName: "Stokoderm® Sun Protect 30 PURE",
      productURL:
        "https://www.debgroup.com/us/products/stokoderm-sun-protect-30-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 3983,
    hazzard: {
      id: 99,
      substanceWorkCondition: "Industry Heavy - Mining & Extraction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 3984,
    hazzard: {
      id: 99,
      substanceWorkCondition: "Industry Heavy - Mining & Extraction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 7,
    locationOrder: 4,
    skin_care_product: {
      id: 38,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:22.000Z",
      productName: "Stokoderm® Sun Protect 30 PURE",
      productURL:
        "https://www.debgroup.com/us/products/stokoderm-sun-protect-30-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 3985,
    hazzard: {
      id: 100,
      substanceWorkCondition: "Industry Heavy - Off-Shore Extraction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 3986,
    hazzard: {
      id: 100,
      substanceWorkCondition: "Industry Heavy - Off-Shore Extraction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 7,
    locationOrder: 4,
    skin_care_product: {
      id: 38,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:22.000Z",
      productName: "Stokoderm® Sun Protect 30 PURE",
      productURL:
        "https://www.debgroup.com/us/products/stokoderm-sun-protect-30-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 3987,
    hazzard: {
      id: 101,
      substanceWorkCondition: "Industry Heavy - Printing Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 3988,
    hazzard: {
      id: 102,
      substanceWorkCondition:
        "Industry Heavy - Road & Transportation Construction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 3989,
    hazzard: {
      id: 102,
      substanceWorkCondition:
        "Industry Heavy - Road & Transportation Construction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 7,
    locationOrder: 4,
    skin_care_product: {
      id: 38,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:22.000Z",
      productName: "Stokoderm® Sun Protect 30 PURE",
      productURL:
        "https://www.debgroup.com/us/products/stokoderm-sun-protect-30-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 3990,
    hazzard: {
      id: 103,
      substanceWorkCondition: "Industry Heavy - Ship & Boat Building",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 3991,
    hazzard: {
      id: 103,
      substanceWorkCondition: "Industry Heavy - Ship & Boat Building",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 7,
    locationOrder: 4,
    skin_care_product: {
      id: 38,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:22.000Z",
      productName: "Stokoderm® Sun Protect 30 PURE",
      productURL:
        "https://www.debgroup.com/us/products/stokoderm-sun-protect-30-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 3992,
    hazzard: {
      id: 104,
      substanceWorkCondition: "Industry Heavy - Shipping & Marine",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 3993,
    hazzard: {
      id: 104,
      substanceWorkCondition: "Industry Heavy - Shipping & Marine",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 7,
    locationOrder: 4,
    skin_care_product: {
      id: 38,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:22.000Z",
      productName: "Stokoderm® Sun Protect 30 PURE",
      productURL:
        "https://www.debgroup.com/us/products/stokoderm-sun-protect-30-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 3994,
    hazzard: {
      id: 105,
      substanceWorkCondition:
        "Industry Heavy - Steel, Engineering & Heavy Manufacturing",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 3995,
    hazzard: {
      id: 105,
      substanceWorkCondition:
        "Industry Heavy - Steel, Engineering & Heavy Manufacturing",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 7,
    locationOrder: 4,
    skin_care_product: {
      id: 38,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:22.000Z",
      productName: "Stokoderm® Sun Protect 30 PURE",
      productURL:
        "https://www.debgroup.com/us/products/stokoderm-sun-protect-30-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 3996,
    hazzard: {
      id: 106,
      substanceWorkCondition:
        "Industry Light - Airports & Transportation Terminals - Services & Facility Maintenance",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 3997,
    hazzard: {
      id: 106,
      substanceWorkCondition:
        "Industry Light - Airports & Transportation Terminals - Services & Facility Maintenance",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 7,
    locationOrder: 4,
    skin_care_product: {
      id: 38,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:22.000Z",
      productName: "Stokoderm® Sun Protect 30 PURE",
      productURL:
        "https://www.debgroup.com/us/products/stokoderm-sun-protect-30-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 3998,
    hazzard: {
      id: 107,
      substanceWorkCondition: "Industry Light - Emergency Services",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 3999,
    hazzard: {
      id: 107,
      substanceWorkCondition: "Industry Light - Emergency Services",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 7,
    locationOrder: 4,
    skin_care_product: {
      id: 38,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:22.000Z",
      productName: "Stokoderm® Sun Protect 30 PURE",
      productURL:
        "https://www.debgroup.com/us/products/stokoderm-sun-protect-30-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4000,
    hazzard: {
      id: 108,
      substanceWorkCondition:
        "Industry Light - General Manufacturing & Assembly",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4001,
    hazzard: {
      id: 108,
      substanceWorkCondition:
        "Industry Light - General Manufacturing & Assembly",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 7,
    locationOrder: 4,
    skin_care_product: {
      id: 38,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:22.000Z",
      productName: "Stokoderm® Sun Protect 30 PURE",
      productURL:
        "https://www.debgroup.com/us/products/stokoderm-sun-protect-30-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4002,
    hazzard: {
      id: 109,
      substanceWorkCondition:
        "Industry Light - Hosiery, Knitware, Fabric & Clothing Manufacturing",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4003,
    hazzard: {
      id: 110,
      substanceWorkCondition: "Industry Light - Science & Laboratories",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4004,
    hazzard: {
      id: 111,
      substanceWorkCondition: "Industry Light - Warehousing & Logistics",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4005,
    hazzard: {
      id: 112,
      substanceWorkCondition: "Industry Medium - Ceramics & Glassware",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4006,
    hazzard: {
      id: 113,
      substanceWorkCondition: "Industry Medium - Chemical Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 4,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4007,
    hazzard: {
      id: 113,
      substanceWorkCondition: "Industry Medium - Chemical Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 4,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4008,
    hazzard: {
      id: 114,
      substanceWorkCondition: "Industry Medium - Electronics Industries",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4009,
    hazzard: {
      id: 115,
      substanceWorkCondition: "Industry Medium - Metal Working & Fabrication",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4010,
    hazzard: {
      id: 115,
      substanceWorkCondition: "Industry Medium - Metal Working & Fabrication",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 7,
    locationOrder: 4,
    skin_care_product: {
      id: 38,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:22.000Z",
      productName: "Stokoderm® Sun Protect 30 PURE",
      productURL:
        "https://www.debgroup.com/us/products/stokoderm-sun-protect-30-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4011,
    hazzard: {
      id: 116,
      substanceWorkCondition: "Industry Medium - Outdoor Maintenance & Repair",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4012,
    hazzard: {
      id: 116,
      substanceWorkCondition: "Industry Medium - Outdoor Maintenance & Repair",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 7,
    locationOrder: 4,
    skin_care_product: {
      id: 38,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:22.000Z",
      productName: "Stokoderm® Sun Protect 30 PURE",
      productURL:
        "https://www.debgroup.com/us/products/stokoderm-sun-protect-30-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4013,
    hazzard: {
      id: 117,
      substanceWorkCondition: "Industry Medium - Pharmaceutical Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 4,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4014,
    hazzard: {
      id: 117,
      substanceWorkCondition: "Industry Medium - Pharmaceutical Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 4,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4015,
    hazzard: {
      id: 118,
      substanceWorkCondition: "Industry Medium - Plastics Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4016,
    hazzard: {
      id: 119,
      substanceWorkCondition: "Ink / printing ink",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 4,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4017,
    hazzard: {
      id: 120,
      substanceWorkCondition: "Insulating material",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 4,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4018,
    hazzard: {
      id: 122,
      substanceWorkCondition: "Ketone",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4019,
    hazzard: {
      id: 125,
      substanceWorkCondition: "Lacquers (UV-hardening)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4020,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4021,
    hazzard: {
      id: 127,
      substanceWorkCondition: "Latex paint",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4022,
    hazzard: {
      id: 128,
      substanceWorkCondition: "Lime",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4023,
    hazzard: {
      id: 129,
      substanceWorkCondition: "Lubricants, water-based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4024,
    hazzard: {
      id: 131,
      substanceWorkCondition: "Methanol / Methyl Alcohol",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4025,
    hazzard: {
      id: 132,
      substanceWorkCondition: "Mineral oil / paraffin oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4026,
    hazzard: {
      id: 133,
      substanceWorkCondition: "Multi component adhesive",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4027,
    hazzard: {
      id: 137,
      substanceWorkCondition: "Oil-emulsions (water- mixed)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4028,
    hazzard: {
      id: 138,
      substanceWorkCondition: "Oils / Oil based lubricants",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4029,
    hazzard: {
      id: 141,
      substanceWorkCondition: "Pit water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4030,
    hazzard: {
      id: 143,
      substanceWorkCondition: "Polishes (water- soluble)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4031,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4032,
    hazzard: {
      id: 148,
      substanceWorkCondition:
        "Propanols (propyl alcohols): n-Propanol, Isopropanol / 2-Propanol ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 4,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4033,
    hazzard: {
      id: 149,
      substanceWorkCondition: "release agent, parting agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4034,
    hazzard: {
      id: 151,
      substanceWorkCondition: "Rock wool",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 4,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4035,
    hazzard: {
      id: 152,
      substanceWorkCondition: "Rubber",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 4,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4036,
    hazzard: {
      id: 153,
      substanceWorkCondition: "Salts / Salt solution",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4037,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4038,
    hazzard: {
      id: 159,
      substanceWorkCondition: "Styrene",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4039,
    hazzard: {
      id: 160,
      substanceWorkCondition: "Surface Cleanser (surfactant based), Detergents",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4040,
    hazzard: {
      id: 161,
      substanceWorkCondition: "Synthetic melting glaze",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 4,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4041,
    hazzard: {
      id: 163,
      substanceWorkCondition: "Tanning agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4042,
    hazzard: {
      id: 169,
      substanceWorkCondition: "UV-rays (A/B) / Sunlight",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 7,
    locationOrder: 4,
    skin_care_product: {
      id: 38,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:22.000Z",
      productName: "Stokoderm® Sun Protect 30 PURE",
      productURL:
        "https://www.debgroup.com/us/products/stokoderm-sun-protect-30-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4043,
    hazzard: {
      id: 170,
      substanceWorkCondition: "Washing bath, washing liquid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4044,
    hazzard: {
      id: 172,
      substanceWorkCondition: "Water / wet work",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4045,
    hazzard: {
      id: 173,
      substanceWorkCondition:
        "Wearing protective clothing / Softening of the skin (Maceration)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 4,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4046,
    hazzard: {
      id: 173,
      substanceWorkCondition:
        "Wearing protective clothing / Softening of the skin (Maceration)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 4,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4047,
    hazzard: {
      id: 999,
      substanceWorkCondition: "All Products",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:44:03.000Z",
      updated_at: "2020-03-02T17:44:03.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4048,
    hazzard: {
      id: 999,
      substanceWorkCondition: "All Products",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:44:03.000Z",
      updated_at: "2020-03-02T17:44:03.000Z"
    },
    step: "Protect",
    productOrder: 7,
    locationOrder: 4,
    skin_care_product: {
      id: 38,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:22.000Z",
      productName: "Stokoderm® Sun Protect 30 PURE",
      productURL:
        "https://www.debgroup.com/us/products/stokoderm-sun-protect-30-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4049,
    hazzard: {
      id: 1,
      substanceWorkCondition: "2-Butanone (MEK)",
      cas: "78-93-3",
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: "oil",
      created_at: "2020-03-02T17:39:32.000Z",
      updated_at: "2020-03-05T14:48:01.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4050,
    hazzard: {
      id: 2,
      substanceWorkCondition: "Acetone / Dimethyl ketone",
      cas: "67-64-1",
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4051,
    hazzard: {
      id: 3,
      substanceWorkCondition: "Acids, diluted",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4052,
    hazzard: {
      id: 4,
      substanceWorkCondition: "Acrylates",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4053,
    hazzard: {
      id: 5,
      substanceWorkCondition: "Adhesive single-component",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4054,
    hazzard: {
      id: 6,
      substanceWorkCondition: "Adhesive, water-soluble",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4055,
    hazzard: {
      id: 7,
      substanceWorkCondition: "Adhesives, glues based on synthetic resin",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4056,
    hazzard: {
      id: 8,
      substanceWorkCondition: "Aldehydes, diluted",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4057,
    hazzard: {
      id: 9,
      substanceWorkCondition: "Alkaline solutions / lyes / thinned caustics",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4058,
    hazzard: {
      id: 10,
      substanceWorkCondition: "Alkaline solutions, diluted",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4059,
    hazzard: {
      id: 11,
      substanceWorkCondition: "Alkaline solutions, lyes (solvent based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4060,
    hazzard: {
      id: 12,
      substanceWorkCondition: "Aluminium",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4061,
    hazzard: {
      id: 14,
      substanceWorkCondition: "Antistatics",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4062,
    hazzard: {
      id: 15,
      substanceWorkCondition: "Asphalt",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4063,
    hazzard: {
      id: 19,
      substanceWorkCondition: "Battery acid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4064,
    hazzard: {
      id: 20,
      substanceWorkCondition: "Benzene",
      cas: null,
      glovesRecommended: true,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4065,
    hazzard: {
      id: 22,
      substanceWorkCondition: "Bitumen emulsion",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4066,
    hazzard: {
      id: 21,
      substanceWorkCondition: "Bitumen",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4067,
    hazzard: {
      id: 23,
      substanceWorkCondition: "Bleaching agent (lye)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4068,
    hazzard: {
      id: 24,
      substanceWorkCondition: "Brake fluid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4069,
    hazzard: {
      id: 25,
      substanceWorkCondition: "Casting dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4070,
    hazzard: {
      id: 26,
      substanceWorkCondition: "Cement (dry)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4071,
    hazzard: {
      id: 27,
      substanceWorkCondition: "Cement (wet)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4072,
    hazzard: {
      id: 28,
      substanceWorkCondition: "Cement, putty epoxy-based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4073,
    hazzard: {
      id: 29,
      substanceWorkCondition:
        "Chromates (water- insoluble) = Chromium pigments",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4074,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4075,
    hazzard: {
      id: 31,
      substanceWorkCondition: "cleaning agent, detergent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4076,
    hazzard: {
      id: 32,
      substanceWorkCondition: "Coal dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4077,
    hazzard: {
      id: 33,
      substanceWorkCondition: "Coating materials, bituminous (bitumen varnish)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4078,
    hazzard: {
      id: 34,
      substanceWorkCondition: "Coating materials, emulsified",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4079,
    hazzard: {
      id: 35,
      substanceWorkCondition: "Coating materials, oily",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4080,
    hazzard: {
      id: 36,
      substanceWorkCondition: "Cold degreasing agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4081,
    hazzard: {
      id: 37,
      substanceWorkCondition: "cold detergent, solvent based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4082,
    hazzard: {
      id: 38,
      substanceWorkCondition: "Cold working conditions",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4083,
    hazzard: {
      id: 43,
      substanceWorkCondition: "Commercial - Hotel & Leisure",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 5,
    locationOrder: 4,
    skin_care_product: {
      id: 25,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:38:15.000Z",
      productName: "Refresh™ Hair & Body",
      productURL: "https://www.debgroup.com/us/products/refresh-hair-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: false,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4084,
    hazzard: {
      id: 43,
      substanceWorkCondition: "Commercial - Hotel & Leisure",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 6,
    locationOrder: 4,
    skin_care_product: {
      id: 26,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:38:51.000Z",
      productName: "Refresh™ Luxury 3-in-1 Hair & Body",
      productURL:
        "https://www.debgroup.com/us/products/refresh-luxury-3-1-hair-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: null,
    ecoLogo: false,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4085,
    hazzard: {
      id: 44,
      substanceWorkCondition: "Commercial - Prisons & Security Services",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4086,
    hazzard: {
      id: 47,
      substanceWorkCondition: "Concrete",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4087,
    hazzard: {
      id: 48,
      substanceWorkCondition: "Cooling lubricant (non-water-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4088,
    hazzard: {
      id: 49,
      substanceWorkCondition: "Cooling lubricant (water- based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4089,
    hazzard: {
      id: 50,
      substanceWorkCondition: "Cooling water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4090,
    hazzard: {
      id: 51,
      substanceWorkCondition: "Copying ink",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4091,
    hazzard: {
      id: 52,
      substanceWorkCondition: "Cutting oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4092,
    hazzard: {
      id: 53,
      substanceWorkCondition: "Degreaser solvent - based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4093,
    hazzard: {
      id: 54,
      substanceWorkCondition: "Degreaser without organic solvent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4094,
    hazzard: {
      id: 55,
      substanceWorkCondition: "Detachment solution for concrete",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4095,
    hazzard: {
      id: 56,
      substanceWorkCondition: "Detachments (alcohol-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4096,
    hazzard: {
      id: 57,
      substanceWorkCondition: "Detachments (solvent-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4097,
    hazzard: {
      id: 58,
      substanceWorkCondition: "Detachments (sour)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4098,
    hazzard: {
      id: 59,
      substanceWorkCondition: "Disinfectants (alcohol-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4099,
    hazzard: {
      id: 60,
      substanceWorkCondition: "Disinfectants (formaldehyd-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4100,
    hazzard: {
      id: 61,
      substanceWorkCondition: "Disinfectants (water-based) ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4101,
    hazzard: {
      id: 62,
      substanceWorkCondition: "Dispersing Agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4102,
    hazzard: {
      id: 63,
      substanceWorkCondition: "Drilling emulsion (water based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4103,
    hazzard: {
      id: 64,
      substanceWorkCondition: "Drilling oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4104,
    hazzard: {
      id: 65,
      substanceWorkCondition: "Dryness",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4105,
    hazzard: {
      id: 66,
      substanceWorkCondition: "Dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4106,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4107,
    hazzard: {
      id: 70,
      substanceWorkCondition: "Epoxy resin",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4108,
    hazzard: {
      id: 71,
      substanceWorkCondition: "Etching (mordant)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4109,
    hazzard: {
      id: 71,
      substanceWorkCondition: "Etching (mordant)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4110,
    hazzard: {
      id: 72,
      substanceWorkCondition: "Ethanol / methylated spirits ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4111,
    hazzard: {
      id: 73,
      substanceWorkCondition: "Fat",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4112,
    hazzard: {
      id: 74,
      substanceWorkCondition: "Fertilizer",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4113,
    hazzard: {
      id: 75,
      substanceWorkCondition: "fine anorganic/inorganic powders ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4114,
    hazzard: {
      id: 76,
      substanceWorkCondition: "Floor cleaner (water- based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4115,
    hazzard: {
      id: 77,
      substanceWorkCondition: "Food - Food Packaging",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4116,
    hazzard: {
      id: 78,
      substanceWorkCondition: "Food - Food Processing & Manufacturing",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4117,
    hazzard: {
      id: 80,
      substanceWorkCondition: "Food Dyestuff",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4118,
    hazzard: {
      id: 81,
      substanceWorkCondition: "fuel: gas / diesel",
      cas: null,
      glovesRecommended: true,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4119,
    hazzard: {
      id: 82,
      substanceWorkCondition: "Galvanic bath",
      cas: null,
      glovesRecommended: true,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4120,
    hazzard: {
      id: 83,
      substanceWorkCondition: "Glass fibre dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4121,
    hazzard: {
      id: 84,
      substanceWorkCondition: "Glass wool dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:57.000Z",
    updated_at: "2020-03-02T22:49:57.000Z"
  },
  {
    id: 4122,
    hazzard: {
      id: 85,
      substanceWorkCondition: "Glycols",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4123,
    hazzard: {
      id: 86,
      substanceWorkCondition: "Graphite",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4124,
    hazzard: {
      id: 87,
      substanceWorkCondition: "Grease",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4125,
    hazzard: {
      id: 88,
      substanceWorkCondition: "Grinding oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4126,
    hazzard: {
      id: 89,
      substanceWorkCondition: "Grinding water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4127,
    hazzard: {
      id: 90,
      substanceWorkCondition: "Gypsum",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4128,
    hazzard: {
      id: 95,
      substanceWorkCondition: "Hydraulic fluid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4129,
    hazzard: {
      id: 96,
      substanceWorkCondition: "Hyrocarbons",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4130,
    hazzard: {
      id: 97,
      substanceWorkCondition: "Industry Heavy - Building Materials",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4131,
    hazzard: {
      id: 99,
      substanceWorkCondition: "Industry Heavy - Mining & Extraction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4132,
    hazzard: {
      id: 100,
      substanceWorkCondition: "Industry Heavy - Off-Shore Extraction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4133,
    hazzard: {
      id: 106,
      substanceWorkCondition:
        "Industry Light - Airports & Transportation Terminals - Services & Facility Maintenance",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4134,
    hazzard: {
      id: 106,
      substanceWorkCondition:
        "Industry Light - Airports & Transportation Terminals - Services & Facility Maintenance",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4135,
    hazzard: {
      id: 106,
      substanceWorkCondition:
        "Industry Light - Airports & Transportation Terminals - Services & Facility Maintenance",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4136,
    hazzard: {
      id: 107,
      substanceWorkCondition: "Industry Light - Emergency Services",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4137,
    hazzard: {
      id: 108,
      substanceWorkCondition:
        "Industry Light - General Manufacturing & Assembly",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4138,
    hazzard: {
      id: 108,
      substanceWorkCondition:
        "Industry Light - General Manufacturing & Assembly",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4139,
    hazzard: {
      id: 108,
      substanceWorkCondition:
        "Industry Light - General Manufacturing & Assembly",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4140,
    hazzard: {
      id: 109,
      substanceWorkCondition:
        "Industry Light - Hosiery, Knitware, Fabric & Clothing Manufacturing",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4141,
    hazzard: {
      id: 109,
      substanceWorkCondition:
        "Industry Light - Hosiery, Knitware, Fabric & Clothing Manufacturing",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4142,
    hazzard: {
      id: 109,
      substanceWorkCondition:
        "Industry Light - Hosiery, Knitware, Fabric & Clothing Manufacturing",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4143,
    hazzard: {
      id: 110,
      substanceWorkCondition: "Industry Light - Science & Laboratories",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4144,
    hazzard: {
      id: 110,
      substanceWorkCondition: "Industry Light - Science & Laboratories",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4145,
    hazzard: {
      id: 110,
      substanceWorkCondition: "Industry Light - Science & Laboratories",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4146,
    hazzard: {
      id: 111,
      substanceWorkCondition: "Industry Light - Warehousing & Logistics",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4147,
    hazzard: {
      id: 111,
      substanceWorkCondition: "Industry Light - Warehousing & Logistics",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4148,
    hazzard: {
      id: 111,
      substanceWorkCondition: "Industry Light - Warehousing & Logistics",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4149,
    hazzard: {
      id: 112,
      substanceWorkCondition: "Industry Medium - Ceramics & Glassware",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4150,
    hazzard: {
      id: 113,
      substanceWorkCondition: "Industry Medium - Chemical Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4151,
    hazzard: {
      id: 115,
      substanceWorkCondition: "Industry Medium - Metal Working & Fabrication",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4152,
    hazzard: {
      id: 116,
      substanceWorkCondition: "Industry Medium - Outdoor Maintenance & Repair",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4153,
    hazzard: {
      id: 117,
      substanceWorkCondition: "Industry Medium - Pharmaceutical Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4154,
    hazzard: {
      id: 117,
      substanceWorkCondition: "Industry Medium - Pharmaceutical Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4155,
    hazzard: {
      id: 117,
      substanceWorkCondition: "Industry Medium - Pharmaceutical Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4156,
    hazzard: {
      id: 117,
      substanceWorkCondition: "Industry Medium - Pharmaceutical Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 23,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:36:39.000Z",
      productName: "Refresh™ Clear FOAM (UL, GS)",
      productURL: "https://www.debgroup.com/us/products/refresh-clear-foam"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: true,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4157,
    hazzard: {
      id: 118,
      substanceWorkCondition: "Industry Medium - Plastics Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4158,
    hazzard: {
      id: 118,
      substanceWorkCondition: "Industry Medium - Plastics Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4159,
    hazzard: {
      id: 119,
      substanceWorkCondition: "Ink / printing ink",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4160,
    hazzard: {
      id: 120,
      substanceWorkCondition: "Insulating material",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4161,
    hazzard: {
      id: 121,
      substanceWorkCondition: "Kerosene",
      cas: null,
      glovesRecommended: true,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4162,
    hazzard: {
      id: 122,
      substanceWorkCondition: "Ketone",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4163,
    hazzard: {
      id: 123,
      substanceWorkCondition: "Lacquers (nitro/solvent-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4164,
    hazzard: {
      id: 124,
      substanceWorkCondition: "Lacquers (PU based, synthetic resin based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4165,
    hazzard: {
      id: 125,
      substanceWorkCondition: "Lacquers (UV-hardening)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4166,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4167,
    hazzard: {
      id: 127,
      substanceWorkCondition: "Latex paint",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4168,
    hazzard: {
      id: 128,
      substanceWorkCondition: "Lime",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4169,
    hazzard: {
      id: 129,
      substanceWorkCondition: "Lubricants, water-based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4170,
    hazzard: {
      id: 130,
      substanceWorkCondition: "Metal dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4171,
    hazzard: {
      id: 131,
      substanceWorkCondition: "Methanol / Methyl Alcohol",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4172,
    hazzard: {
      id: 132,
      substanceWorkCondition: "Mineral oil / paraffin oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4173,
    hazzard: {
      id: 133,
      substanceWorkCondition: "Multi component adhesive",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4174,
    hazzard: {
      id: 134,
      substanceWorkCondition: "Multi component resin",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4175,
    hazzard: {
      id: 135,
      substanceWorkCondition: "Naphthalene",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4176,
    hazzard: {
      id: 136,
      substanceWorkCondition: "Oil coke (carbon)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4177,
    hazzard: {
      id: 137,
      substanceWorkCondition: "Oil-emulsions (water- mixed)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4178,
    hazzard: {
      id: 138,
      substanceWorkCondition: "Oils / Oil based lubricants",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4179,
    hazzard: {
      id: 140,
      substanceWorkCondition: "Paint Thinner ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4180,
    hazzard: {
      id: 139,
      substanceWorkCondition: "Paint",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4181,
    hazzard: {
      id: 141,
      substanceWorkCondition: "Pit water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4182,
    hazzard: {
      id: 142,
      substanceWorkCondition: "Pitch",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4183,
    hazzard: {
      id: 143,
      substanceWorkCondition: "Polishes (water- soluble)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4184,
    hazzard: {
      id: 144,
      substanceWorkCondition: "Polyester / Polyester resin",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4185,
    hazzard: {
      id: 145,
      substanceWorkCondition: "Polyurethane",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4186,
    hazzard: {
      id: 148,
      substanceWorkCondition:
        "Propanols (propyl alcohols): n-Propanol, Isopropanol / 2-Propanol ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4187,
    hazzard: {
      id: 149,
      substanceWorkCondition: "release agent, parting agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4188,
    hazzard: {
      id: 150,
      substanceWorkCondition: "Resins / urea resins",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4189,
    hazzard: {
      id: 151,
      substanceWorkCondition: "Rock wool",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4190,
    hazzard: {
      id: 152,
      substanceWorkCondition: "Rubber",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4191,
    hazzard: {
      id: 153,
      substanceWorkCondition: "Salts / Salt solution",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4192,
    hazzard: {
      id: 154,
      substanceWorkCondition: "Sealing compound",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4193,
    hazzard: {
      id: 155,
      substanceWorkCondition: "Sintering",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4194,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4195,
    hazzard: {
      id: 157,
      substanceWorkCondition: "Solvents, - organic",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4196,
    hazzard: {
      id: 158,
      substanceWorkCondition: "Soot",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4197,
    hazzard: {
      id: 159,
      substanceWorkCondition: "Styrene",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4198,
    hazzard: {
      id: 160,
      substanceWorkCondition: "Surface Cleanser (surfactant based), Detergents",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4199,
    hazzard: {
      id: 161,
      substanceWorkCondition: "Synthetic melting glaze",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4200,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4201,
    hazzard: {
      id: 163,
      substanceWorkCondition: "Tanning agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4202,
    hazzard: {
      id: 164,
      substanceWorkCondition: "Tar",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4203,
    hazzard: {
      id: 166,
      substanceWorkCondition: "Thinner",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4204,
    hazzard: {
      id: 167,
      substanceWorkCondition: "Toluene",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4205,
    hazzard: {
      id: 168,
      substanceWorkCondition: "Turpentine oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4206,
    hazzard: {
      id: 169,
      substanceWorkCondition: "UV-rays (A/B) / Sunlight",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4207,
    hazzard: {
      id: 170,
      substanceWorkCondition: "Washing bath, washing liquid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4208,
    hazzard: {
      id: 171,
      substanceWorkCondition: "Waste oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4209,
    hazzard: {
      id: 172,
      substanceWorkCondition: "Water / wet work",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4210,
    hazzard: {
      id: 173,
      substanceWorkCondition:
        "Wearing protective clothing / Softening of the skin (Maceration)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4211,
    hazzard: {
      id: 174,
      substanceWorkCondition: "wire drawing oil / wire drawing grease",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4212,
    hazzard: {
      id: 175,
      substanceWorkCondition: "Xylene",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 4,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4213,
    hazzard: {
      id: 999,
      substanceWorkCondition: "All Products",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:44:03.000Z",
      updated_at: "2020-03-02T17:44:03.000Z"
    },
    step: "Cleanse",
    productOrder: 5,
    locationOrder: 4,
    skin_care_product: {
      id: 25,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:38:15.000Z",
      productName: "Refresh™ Hair & Body",
      productURL: "https://www.debgroup.com/us/products/refresh-hair-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: false,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4214,
    hazzard: {
      id: 999,
      substanceWorkCondition: "All Products",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:44:03.000Z",
      updated_at: "2020-03-02T17:44:03.000Z"
    },
    step: "Cleanse",
    productOrder: 6,
    locationOrder: 4,
    skin_care_product: {
      id: 26,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:38:51.000Z",
      productName: "Refresh™ Luxury 3-in-1 Hair & Body",
      productURL:
        "https://www.debgroup.com/us/products/refresh-luxury-3-1-hair-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: null,
    ecoLogo: false,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4215,
    hazzard: {
      id: 999,
      substanceWorkCondition: "All Products",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:44:03.000Z",
      updated_at: "2020-03-02T17:44:03.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4216,
    hazzard: {
      id: 1,
      substanceWorkCondition: "2-Butanone (MEK)",
      cas: "78-93-3",
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: "oil",
      created_at: "2020-03-02T17:39:32.000Z",
      updated_at: "2020-03-05T14:48:01.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4217,
    hazzard: {
      id: 2,
      substanceWorkCondition: "Acetone / Dimethyl ketone",
      cas: "67-64-1",
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4218,
    hazzard: {
      id: 3,
      substanceWorkCondition: "Acids, diluted",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4219,
    hazzard: {
      id: 4,
      substanceWorkCondition: "Acrylates",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4220,
    hazzard: {
      id: 5,
      substanceWorkCondition: "Adhesive single-component",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4221,
    hazzard: {
      id: 6,
      substanceWorkCondition: "Adhesive, water-soluble",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4222,
    hazzard: {
      id: 7,
      substanceWorkCondition: "Adhesives, glues based on synthetic resin",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4223,
    hazzard: {
      id: 8,
      substanceWorkCondition: "Aldehydes, diluted",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4224,
    hazzard: {
      id: 9,
      substanceWorkCondition: "Alkaline solutions / lyes / thinned caustics",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4225,
    hazzard: {
      id: 10,
      substanceWorkCondition: "Alkaline solutions, diluted",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4226,
    hazzard: {
      id: 11,
      substanceWorkCondition: "Alkaline solutions, lyes (solvent based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4227,
    hazzard: {
      id: 12,
      substanceWorkCondition: "Aluminium",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4228,
    hazzard: {
      id: 14,
      substanceWorkCondition: "Antistatics",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4229,
    hazzard: {
      id: 15,
      substanceWorkCondition: "Asphalt",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4230,
    hazzard: {
      id: 18,
      substanceWorkCondition:
        "Automotive Aftermarket - Public Transport Maintenance&Repair",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4231,
    hazzard: {
      id: 18,
      substanceWorkCondition:
        "Automotive Aftermarket - Public Transport Maintenance&Repair",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4232,
    hazzard: {
      id: 19,
      substanceWorkCondition: "Battery acid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4233,
    hazzard: {
      id: 20,
      substanceWorkCondition: "Benzene",
      cas: null,
      glovesRecommended: true,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4234,
    hazzard: {
      id: 22,
      substanceWorkCondition: "Bitumen emulsion",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4235,
    hazzard: {
      id: 21,
      substanceWorkCondition: "Bitumen",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4236,
    hazzard: {
      id: 23,
      substanceWorkCondition: "Bleaching agent (lye)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4237,
    hazzard: {
      id: 24,
      substanceWorkCondition: "Brake fluid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4238,
    hazzard: {
      id: 25,
      substanceWorkCondition: "Casting dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4239,
    hazzard: {
      id: 26,
      substanceWorkCondition: "Cement (dry)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4240,
    hazzard: {
      id: 27,
      substanceWorkCondition: "Cement (wet)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4241,
    hazzard: {
      id: 28,
      substanceWorkCondition: "Cement, putty epoxy-based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4242,
    hazzard: {
      id: 29,
      substanceWorkCondition:
        "Chromates (water- insoluble) = Chromium pigments",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4243,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4244,
    hazzard: {
      id: 31,
      substanceWorkCondition: "cleaning agent, detergent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4245,
    hazzard: {
      id: 32,
      substanceWorkCondition: "Coal dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4246,
    hazzard: {
      id: 33,
      substanceWorkCondition: "Coating materials, bituminous (bitumen varnish)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4247,
    hazzard: {
      id: 34,
      substanceWorkCondition: "Coating materials, emulsified",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4248,
    hazzard: {
      id: 35,
      substanceWorkCondition: "Coating materials, oily",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4249,
    hazzard: {
      id: 36,
      substanceWorkCondition: "Cold degreasing agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4250,
    hazzard: {
      id: 37,
      substanceWorkCondition: "cold detergent, solvent based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4251,
    hazzard: {
      id: 38,
      substanceWorkCondition: "Cold working conditions",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4252,
    hazzard: {
      id: 47,
      substanceWorkCondition: "Concrete",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4253,
    hazzard: {
      id: 48,
      substanceWorkCondition: "Cooling lubricant (non-water-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4254,
    hazzard: {
      id: 49,
      substanceWorkCondition: "Cooling lubricant (water- based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4255,
    hazzard: {
      id: 50,
      substanceWorkCondition: "Cooling water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4256,
    hazzard: {
      id: 51,
      substanceWorkCondition: "Copying ink",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4257,
    hazzard: {
      id: 52,
      substanceWorkCondition: "Cutting oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4258,
    hazzard: {
      id: 53,
      substanceWorkCondition: "Degreaser solvent - based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4259,
    hazzard: {
      id: 54,
      substanceWorkCondition: "Degreaser without organic solvent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4260,
    hazzard: {
      id: 55,
      substanceWorkCondition: "Detachment solution for concrete",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4261,
    hazzard: {
      id: 56,
      substanceWorkCondition: "Detachments (alcohol-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4262,
    hazzard: {
      id: 57,
      substanceWorkCondition: "Detachments (solvent-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4263,
    hazzard: {
      id: 58,
      substanceWorkCondition: "Detachments (sour)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4264,
    hazzard: {
      id: 59,
      substanceWorkCondition: "Disinfectants (alcohol-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4265,
    hazzard: {
      id: 60,
      substanceWorkCondition: "Disinfectants (formaldehyd-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4266,
    hazzard: {
      id: 61,
      substanceWorkCondition: "Disinfectants (water-based) ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4267,
    hazzard: {
      id: 62,
      substanceWorkCondition: "Dispersing Agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4268,
    hazzard: {
      id: 63,
      substanceWorkCondition: "Drilling emulsion (water based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4269,
    hazzard: {
      id: 64,
      substanceWorkCondition: "Drilling oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4270,
    hazzard: {
      id: 65,
      substanceWorkCondition: "Dryness",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4271,
    hazzard: {
      id: 66,
      substanceWorkCondition: "Dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4272,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4273,
    hazzard: {
      id: 70,
      substanceWorkCondition: "Epoxy resin",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4274,
    hazzard: {
      id: 71,
      substanceWorkCondition: "Etching (mordant)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4275,
    hazzard: {
      id: 72,
      substanceWorkCondition: "Ethanol / methylated spirits ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4276,
    hazzard: {
      id: 73,
      substanceWorkCondition: "Fat",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4277,
    hazzard: {
      id: 74,
      substanceWorkCondition: "Fertilizer",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4278,
    hazzard: {
      id: 75,
      substanceWorkCondition: "fine anorganic/inorganic powders ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4279,
    hazzard: {
      id: 76,
      substanceWorkCondition: "Floor cleaner (water- based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4280,
    hazzard: {
      id: 77,
      substanceWorkCondition: "Food - Food Packaging",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4281,
    hazzard: {
      id: 78,
      substanceWorkCondition: "Food - Food Processing & Manufacturing",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4282,
    hazzard: {
      id: 79,
      substanceWorkCondition: "Food - Food Services & Catering",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4283,
    hazzard: {
      id: 80,
      substanceWorkCondition: "Food Dyestuff",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:58.000Z",
    updated_at: "2020-03-02T22:49:58.000Z"
  },
  {
    id: 4284,
    hazzard: {
      id: 81,
      substanceWorkCondition: "fuel: gas / diesel",
      cas: null,
      glovesRecommended: true,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4285,
    hazzard: {
      id: 82,
      substanceWorkCondition: "Galvanic bath",
      cas: null,
      glovesRecommended: true,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4286,
    hazzard: {
      id: 83,
      substanceWorkCondition: "Glass fibre dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4287,
    hazzard: {
      id: 84,
      substanceWorkCondition: "Glass wool dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4288,
    hazzard: {
      id: 85,
      substanceWorkCondition: "Glycols",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4289,
    hazzard: {
      id: 86,
      substanceWorkCondition: "Graphite",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4290,
    hazzard: {
      id: 87,
      substanceWorkCondition: "Grease",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4291,
    hazzard: {
      id: 88,
      substanceWorkCondition: "Grinding oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4292,
    hazzard: {
      id: 89,
      substanceWorkCondition: "Grinding water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4293,
    hazzard: {
      id: 90,
      substanceWorkCondition: "Gypsum",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4294,
    hazzard: {
      id: 91,
      substanceWorkCondition: "Healthcare - Care Homes",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4295,
    hazzard: {
      id: 91,
      substanceWorkCondition: "Healthcare - Care Homes",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 4,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4296,
    hazzard: {
      id: 93,
      substanceWorkCondition: "Healthcare - Nursing Homes",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4297,
    hazzard: {
      id: 93,
      substanceWorkCondition: "Healthcare - Nursing Homes",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 4,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4298,
    hazzard: {
      id: 95,
      substanceWorkCondition: "Hydraulic fluid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4299,
    hazzard: {
      id: 96,
      substanceWorkCondition: "Hyrocarbons",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4300,
    hazzard: {
      id: 97,
      substanceWorkCondition: "Industry Heavy - Building Materials",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4301,
    hazzard: {
      id: 97,
      substanceWorkCondition: "Industry Heavy - Building Materials",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 4,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4302,
    hazzard: {
      id: 98,
      substanceWorkCondition: "Industry Heavy - Construction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4303,
    hazzard: {
      id: 98,
      substanceWorkCondition: "Industry Heavy - Construction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 4,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4304,
    hazzard: {
      id: 99,
      substanceWorkCondition: "Industry Heavy - Mining & Extraction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4305,
    hazzard: {
      id: 99,
      substanceWorkCondition: "Industry Heavy - Mining & Extraction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 4,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4306,
    hazzard: {
      id: 100,
      substanceWorkCondition: "Industry Heavy - Off-Shore Extraction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4307,
    hazzard: {
      id: 100,
      substanceWorkCondition: "Industry Heavy - Off-Shore Extraction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 4,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4308,
    hazzard: {
      id: 101,
      substanceWorkCondition: "Industry Heavy - Printing Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4309,
    hazzard: {
      id: 101,
      substanceWorkCondition: "Industry Heavy - Printing Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 4,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4310,
    hazzard: {
      id: 102,
      substanceWorkCondition:
        "Industry Heavy - Road & Transportation Construction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4311,
    hazzard: {
      id: 102,
      substanceWorkCondition:
        "Industry Heavy - Road & Transportation Construction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 4,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4312,
    hazzard: {
      id: 103,
      substanceWorkCondition: "Industry Heavy - Ship & Boat Building",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4313,
    hazzard: {
      id: 103,
      substanceWorkCondition: "Industry Heavy - Ship & Boat Building",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 4,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4314,
    hazzard: {
      id: 104,
      substanceWorkCondition: "Industry Heavy - Shipping & Marine",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4315,
    hazzard: {
      id: 104,
      substanceWorkCondition: "Industry Heavy - Shipping & Marine",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 4,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4316,
    hazzard: {
      id: 105,
      substanceWorkCondition:
        "Industry Heavy - Steel, Engineering & Heavy Manufacturing",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4317,
    hazzard: {
      id: 105,
      substanceWorkCondition:
        "Industry Heavy - Steel, Engineering & Heavy Manufacturing",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 4,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4318,
    hazzard: {
      id: 107,
      substanceWorkCondition: "Industry Light - Emergency Services",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4319,
    hazzard: {
      id: 107,
      substanceWorkCondition: "Industry Light - Emergency Services",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 4,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4320,
    hazzard: {
      id: 107,
      substanceWorkCondition: "Industry Light - Emergency Services",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4321,
    hazzard: {
      id: 108,
      substanceWorkCondition:
        "Industry Light - General Manufacturing & Assembly",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4322,
    hazzard: {
      id: 108,
      substanceWorkCondition:
        "Industry Light - General Manufacturing & Assembly",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 4,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4323,
    hazzard: {
      id: 108,
      substanceWorkCondition:
        "Industry Light - General Manufacturing & Assembly",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4324,
    hazzard: {
      id: 109,
      substanceWorkCondition:
        "Industry Light - Hosiery, Knitware, Fabric & Clothing Manufacturing",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4325,
    hazzard: {
      id: 109,
      substanceWorkCondition:
        "Industry Light - Hosiery, Knitware, Fabric & Clothing Manufacturing",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 4,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4326,
    hazzard: {
      id: 109,
      substanceWorkCondition:
        "Industry Light - Hosiery, Knitware, Fabric & Clothing Manufacturing",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4327,
    hazzard: {
      id: 110,
      substanceWorkCondition: "Industry Light - Science & Laboratories",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4328,
    hazzard: {
      id: 110,
      substanceWorkCondition: "Industry Light - Science & Laboratories",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 4,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4329,
    hazzard: {
      id: 110,
      substanceWorkCondition: "Industry Light - Science & Laboratories",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4330,
    hazzard: {
      id: 112,
      substanceWorkCondition: "Industry Medium - Ceramics & Glassware",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4331,
    hazzard: {
      id: 112,
      substanceWorkCondition: "Industry Medium - Ceramics & Glassware",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 4,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4332,
    hazzard: {
      id: 113,
      substanceWorkCondition: "Industry Medium - Chemical Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4333,
    hazzard: {
      id: 113,
      substanceWorkCondition: "Industry Medium - Chemical Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 4,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4334,
    hazzard: {
      id: 114,
      substanceWorkCondition: "Industry Medium - Electronics Industries",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4335,
    hazzard: {
      id: 114,
      substanceWorkCondition: "Industry Medium - Electronics Industries",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 4,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4336,
    hazzard: {
      id: 115,
      substanceWorkCondition: "Industry Medium - Metal Working & Fabrication",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4337,
    hazzard: {
      id: 115,
      substanceWorkCondition: "Industry Medium - Metal Working & Fabrication",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 4,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4338,
    hazzard: {
      id: 116,
      substanceWorkCondition: "Industry Medium - Outdoor Maintenance & Repair",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4339,
    hazzard: {
      id: 116,
      substanceWorkCondition: "Industry Medium - Outdoor Maintenance & Repair",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 4,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4340,
    hazzard: {
      id: 117,
      substanceWorkCondition: "Industry Medium - Pharmaceutical Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4341,
    hazzard: {
      id: 117,
      substanceWorkCondition: "Industry Medium - Pharmaceutical Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 4,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4342,
    hazzard: {
      id: 118,
      substanceWorkCondition: "Industry Medium - Plastics Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4343,
    hazzard: {
      id: 118,
      substanceWorkCondition: "Industry Medium - Plastics Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 4,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4344,
    hazzard: {
      id: 119,
      substanceWorkCondition: "Ink / printing ink",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4345,
    hazzard: {
      id: 120,
      substanceWorkCondition: "Insulating material",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4346,
    hazzard: {
      id: 121,
      substanceWorkCondition: "Kerosene",
      cas: null,
      glovesRecommended: true,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4347,
    hazzard: {
      id: 122,
      substanceWorkCondition: "Ketone",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4348,
    hazzard: {
      id: 123,
      substanceWorkCondition: "Lacquers (nitro/solvent-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4349,
    hazzard: {
      id: 124,
      substanceWorkCondition: "Lacquers (PU based, synthetic resin based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4350,
    hazzard: {
      id: 125,
      substanceWorkCondition: "Lacquers (UV-hardening)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4351,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4352,
    hazzard: {
      id: 127,
      substanceWorkCondition: "Latex paint",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4353,
    hazzard: {
      id: 128,
      substanceWorkCondition: "Lime",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4354,
    hazzard: {
      id: 129,
      substanceWorkCondition: "Lubricants, water-based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4355,
    hazzard: {
      id: 130,
      substanceWorkCondition: "Metal dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4356,
    hazzard: {
      id: 131,
      substanceWorkCondition: "Methanol / Methyl Alcohol",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4357,
    hazzard: {
      id: 132,
      substanceWorkCondition: "Mineral oil / paraffin oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4358,
    hazzard: {
      id: 132,
      substanceWorkCondition: "Mineral oil / paraffin oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4359,
    hazzard: {
      id: 133,
      substanceWorkCondition: "Multi component adhesive",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4360,
    hazzard: {
      id: 134,
      substanceWorkCondition: "Multi component resin",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4361,
    hazzard: {
      id: 135,
      substanceWorkCondition: "Naphthalene",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4362,
    hazzard: {
      id: 136,
      substanceWorkCondition: "Oil coke (carbon)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4363,
    hazzard: {
      id: 137,
      substanceWorkCondition: "Oil-emulsions (water- mixed)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4364,
    hazzard: {
      id: 138,
      substanceWorkCondition: "Oils / Oil based lubricants",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4365,
    hazzard: {
      id: 140,
      substanceWorkCondition: "Paint Thinner ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4366,
    hazzard: {
      id: 139,
      substanceWorkCondition: "Paint",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4367,
    hazzard: {
      id: 141,
      substanceWorkCondition: "Pit water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4368,
    hazzard: {
      id: 142,
      substanceWorkCondition: "Pitch",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4369,
    hazzard: {
      id: 143,
      substanceWorkCondition: "Polishes (water- soluble)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4370,
    hazzard: {
      id: 144,
      substanceWorkCondition: "Polyester / Polyester resin",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4371,
    hazzard: {
      id: 145,
      substanceWorkCondition: "Polyurethane",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4372,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4373,
    hazzard: {
      id: 148,
      substanceWorkCondition:
        "Propanols (propyl alcohols): n-Propanol, Isopropanol / 2-Propanol ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4374,
    hazzard: {
      id: 149,
      substanceWorkCondition: "release agent, parting agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4375,
    hazzard: {
      id: 150,
      substanceWorkCondition: "Resins / urea resins",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4376,
    hazzard: {
      id: 151,
      substanceWorkCondition: "Rock wool",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4377,
    hazzard: {
      id: 152,
      substanceWorkCondition: "Rubber",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4378,
    hazzard: {
      id: 153,
      substanceWorkCondition: "Salts / Salt solution",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4379,
    hazzard: {
      id: 154,
      substanceWorkCondition: "Sealing compound",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4380,
    hazzard: {
      id: 155,
      substanceWorkCondition: "Sintering",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4381,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4382,
    hazzard: {
      id: 157,
      substanceWorkCondition: "Solvents, - organic",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4383,
    hazzard: {
      id: 158,
      substanceWorkCondition: "Soot",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4384,
    hazzard: {
      id: 159,
      substanceWorkCondition: "Styrene",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4385,
    hazzard: {
      id: 160,
      substanceWorkCondition: "Surface Cleanser (surfactant based), Detergents",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4386,
    hazzard: {
      id: 161,
      substanceWorkCondition: "Synthetic melting glaze",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4387,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4388,
    hazzard: {
      id: 163,
      substanceWorkCondition: "Tanning agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4389,
    hazzard: {
      id: 164,
      substanceWorkCondition: "Tar",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4390,
    hazzard: {
      id: 166,
      substanceWorkCondition: "Thinner",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4391,
    hazzard: {
      id: 167,
      substanceWorkCondition: "Toluene",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4392,
    hazzard: {
      id: 168,
      substanceWorkCondition: "Turpentine oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4393,
    hazzard: {
      id: 169,
      substanceWorkCondition: "UV-rays (A/B) / Sunlight",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 4,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4394,
    hazzard: {
      id: 169,
      substanceWorkCondition: "UV-rays (A/B) / Sunlight",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4395,
    hazzard: {
      id: 170,
      substanceWorkCondition: "Washing bath, washing liquid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4396,
    hazzard: {
      id: 171,
      substanceWorkCondition: "Waste oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4397,
    hazzard: {
      id: 172,
      substanceWorkCondition: "Water / wet work",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4398,
    hazzard: {
      id: 173,
      substanceWorkCondition:
        "Wearing protective clothing / Softening of the skin (Maceration)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4399,
    hazzard: {
      id: 174,
      substanceWorkCondition: "wire drawing oil / wire drawing grease",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4400,
    hazzard: {
      id: 175,
      substanceWorkCondition: "Xylene",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4401,
    hazzard: {
      id: 999,
      substanceWorkCondition: "All Products",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:44:03.000Z",
      updated_at: "2020-03-02T17:44:03.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4402,
    hazzard: {
      id: 999,
      substanceWorkCondition: "All Products",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:44:03.000Z",
      updated_at: "2020-03-02T17:44:03.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4403,
    hazzard: {
      id: 999,
      substanceWorkCondition: "All Products",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:44:03.000Z",
      updated_at: "2020-03-02T17:44:03.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4404,
    hazzard: {
      id: 999,
      substanceWorkCondition: "All Products",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:44:03.000Z",
      updated_at: "2020-03-02T17:44:03.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Body",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4405,
    hazzard: {
      id: 1,
      substanceWorkCondition: "2-Butanone (MEK)",
      cas: "78-93-3",
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: "oil",
      created_at: "2020-03-02T17:39:32.000Z",
      updated_at: "2020-03-05T14:48:01.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4406,
    hazzard: {
      id: 2,
      substanceWorkCondition: "Acetone / Dimethyl ketone",
      cas: "67-64-1",
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4407,
    hazzard: {
      id: 3,
      substanceWorkCondition: "Acids, diluted",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4408,
    hazzard: {
      id: 4,
      substanceWorkCondition: "Acrylates",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4409,
    hazzard: {
      id: 5,
      substanceWorkCondition: "Adhesive single-component",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4410,
    hazzard: {
      id: 6,
      substanceWorkCondition: "Adhesive, water-soluble",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4411,
    hazzard: {
      id: 7,
      substanceWorkCondition: "Adhesives, glues based on synthetic resin",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4412,
    hazzard: {
      id: 8,
      substanceWorkCondition: "Aldehydes, diluted",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4413,
    hazzard: {
      id: 9,
      substanceWorkCondition: "Alkaline solutions / lyes / thinned caustics",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4414,
    hazzard: {
      id: 10,
      substanceWorkCondition: "Alkaline solutions, diluted",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4415,
    hazzard: {
      id: 11,
      substanceWorkCondition: "Alkaline solutions, lyes (solvent based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4416,
    hazzard: {
      id: 12,
      substanceWorkCondition: "Aluminium",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4417,
    hazzard: {
      id: 14,
      substanceWorkCondition: "Antistatics",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4418,
    hazzard: {
      id: 18,
      substanceWorkCondition:
        "Automotive Aftermarket - Public Transport Maintenance&Repair",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4419,
    hazzard: {
      id: 19,
      substanceWorkCondition: "Battery acid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4420,
    hazzard: {
      id: 22,
      substanceWorkCondition: "Bitumen emulsion",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4421,
    hazzard: {
      id: 21,
      substanceWorkCondition: "Bitumen",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4422,
    hazzard: {
      id: 23,
      substanceWorkCondition: "Bleaching agent (lye)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4423,
    hazzard: {
      id: 24,
      substanceWorkCondition: "Brake fluid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4424,
    hazzard: {
      id: 25,
      substanceWorkCondition: "Casting dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4425,
    hazzard: {
      id: 26,
      substanceWorkCondition: "Cement (dry)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4426,
    hazzard: {
      id: 27,
      substanceWorkCondition: "Cement (wet)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4427,
    hazzard: {
      id: 28,
      substanceWorkCondition: "Cement, putty epoxy-based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4428,
    hazzard: {
      id: 29,
      substanceWorkCondition:
        "Chromates (water- insoluble) = Chromium pigments",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4429,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4430,
    hazzard: {
      id: 31,
      substanceWorkCondition: "cleaning agent, detergent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4431,
    hazzard: {
      id: 32,
      substanceWorkCondition: "Coal dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4432,
    hazzard: {
      id: 33,
      substanceWorkCondition: "Coating materials, bituminous (bitumen varnish)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4433,
    hazzard: {
      id: 34,
      substanceWorkCondition: "Coating materials, emulsified",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4434,
    hazzard: {
      id: 35,
      substanceWorkCondition: "Coating materials, oily",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:49:59.000Z",
    updated_at: "2020-03-02T22:49:59.000Z"
  },
  {
    id: 4435,
    hazzard: {
      id: 36,
      substanceWorkCondition: "Cold degreasing agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4436,
    hazzard: {
      id: 37,
      substanceWorkCondition: "cold detergent, solvent based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4437,
    hazzard: {
      id: 38,
      substanceWorkCondition: "Cold working conditions",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4438,
    hazzard: {
      id: 42,
      substanceWorkCondition: "Commercial - Education",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 7,
    locationOrder: 3,
    skin_care_product: {
      id: 38,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:22.000Z",
      productName: "Stokoderm® Sun Protect 30 PURE",
      productURL:
        "https://www.debgroup.com/us/products/stokoderm-sun-protect-30-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4439,
    hazzard: {
      id: 43,
      substanceWorkCondition: "Commercial - Hotel & Leisure",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 3,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4440,
    hazzard: {
      id: 44,
      substanceWorkCondition: "Commercial - Prisons & Security Services",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 3,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4441,
    hazzard: {
      id: 45,
      substanceWorkCondition:
        "Commercial - Public Entertainments & Theme Parks",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 3,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4442,
    hazzard: {
      id: 45,
      substanceWorkCondition:
        "Commercial - Public Entertainments & Theme Parks",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 7,
    locationOrder: 3,
    skin_care_product: {
      id: 38,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:22.000Z",
      productName: "Stokoderm® Sun Protect 30 PURE",
      productURL:
        "https://www.debgroup.com/us/products/stokoderm-sun-protect-30-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4443,
    hazzard: {
      id: 47,
      substanceWorkCondition: "Concrete",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 3,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4444,
    hazzard: {
      id: 48,
      substanceWorkCondition: "Cooling lubricant (non-water-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4445,
    hazzard: {
      id: 49,
      substanceWorkCondition: "Cooling lubricant (water- based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4446,
    hazzard: {
      id: 50,
      substanceWorkCondition: "Cooling water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4447,
    hazzard: {
      id: 51,
      substanceWorkCondition: "Copying ink",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4448,
    hazzard: {
      id: 51,
      substanceWorkCondition: "Copying ink",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 3,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4449,
    hazzard: {
      id: 54,
      substanceWorkCondition: "Degreaser without organic solvent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4450,
    hazzard: {
      id: 54,
      substanceWorkCondition: "Degreaser without organic solvent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4451,
    hazzard: {
      id: 55,
      substanceWorkCondition: "Detachment solution for concrete",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4452,
    hazzard: {
      id: 55,
      substanceWorkCondition: "Detachment solution for concrete",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4453,
    hazzard: {
      id: 56,
      substanceWorkCondition: "Detachments (alcohol-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4454,
    hazzard: {
      id: 56,
      substanceWorkCondition: "Detachments (alcohol-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4455,
    hazzard: {
      id: 57,
      substanceWorkCondition: "Detachments (solvent-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4456,
    hazzard: {
      id: 58,
      substanceWorkCondition: "Detachments (sour)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4457,
    hazzard: {
      id: 59,
      substanceWorkCondition: "Disinfectants (alcohol-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4458,
    hazzard: {
      id: 60,
      substanceWorkCondition: "Disinfectants (formaldehyd-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4459,
    hazzard: {
      id: 61,
      substanceWorkCondition: "Disinfectants (water-based) ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4460,
    hazzard: {
      id: 62,
      substanceWorkCondition: "Dispersing Agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4461,
    hazzard: {
      id: 63,
      substanceWorkCondition: "Drilling emulsion (water based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4462,
    hazzard: {
      id: 64,
      substanceWorkCondition: "Drilling oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4463,
    hazzard: {
      id: 65,
      substanceWorkCondition: "Dryness",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 3,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4464,
    hazzard: {
      id: 66,
      substanceWorkCondition: "Dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4465,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4466,
    hazzard: {
      id: 71,
      substanceWorkCondition: "Etching (mordant)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4467,
    hazzard: {
      id: 72,
      substanceWorkCondition: "Ethanol / methylated spirits ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4468,
    hazzard: {
      id: 73,
      substanceWorkCondition: "Fat",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4469,
    hazzard: {
      id: 74,
      substanceWorkCondition: "Fertilizer",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 3,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4470,
    hazzard: {
      id: 75,
      substanceWorkCondition: "fine anorganic/inorganic powders ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4471,
    hazzard: {
      id: 76,
      substanceWorkCondition: "Floor cleaner (water- based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 3,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4472,
    hazzard: {
      id: 77,
      substanceWorkCondition: "Food - Food Packaging",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 3,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4473,
    hazzard: {
      id: 78,
      substanceWorkCondition: "Food - Food Processing & Manufacturing",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 3,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4474,
    hazzard: {
      id: 79,
      substanceWorkCondition: "Food - Food Services & Catering",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 3,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4475,
    hazzard: {
      id: 80,
      substanceWorkCondition: "Food Dyestuff",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4476,
    hazzard: {
      id: 82,
      substanceWorkCondition: "Galvanic bath",
      cas: null,
      glovesRecommended: true,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 3,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4477,
    hazzard: {
      id: 83,
      substanceWorkCondition: "Glass fibre dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4478,
    hazzard: {
      id: 84,
      substanceWorkCondition: "Glass wool dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4479,
    hazzard: {
      id: 85,
      substanceWorkCondition: "Glycols",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4480,
    hazzard: {
      id: 86,
      substanceWorkCondition: "Graphite",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4481,
    hazzard: {
      id: 89,
      substanceWorkCondition: "Grinding water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4482,
    hazzard: {
      id: 90,
      substanceWorkCondition: "Gypsum",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4483,
    hazzard: {
      id: 91,
      substanceWorkCondition: "Healthcare - Care Homes",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 3,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4484,
    hazzard: {
      id: 92,
      substanceWorkCondition:
        "Healthcare - Medical Centers/Surgeries/General Practitioners",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 3,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4485,
    hazzard: {
      id: 93,
      substanceWorkCondition: "Healthcare - Nursing Homes",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 3,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4486,
    hazzard: {
      id: 94,
      substanceWorkCondition: "Healthcare - Veterinary Services",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 3,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4487,
    hazzard: {
      id: 95,
      substanceWorkCondition: "Hydraulic fluid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4488,
    hazzard: {
      id: 97,
      substanceWorkCondition: "Industry Heavy - Building Materials",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4489,
    hazzard: {
      id: 97,
      substanceWorkCondition: "Industry Heavy - Building Materials",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 7,
    locationOrder: 3,
    skin_care_product: {
      id: 38,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:22.000Z",
      productName: "Stokoderm® Sun Protect 30 PURE",
      productURL:
        "https://www.debgroup.com/us/products/stokoderm-sun-protect-30-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4490,
    hazzard: {
      id: 98,
      substanceWorkCondition: "Industry Heavy - Construction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4491,
    hazzard: {
      id: 98,
      substanceWorkCondition: "Industry Heavy - Construction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 7,
    locationOrder: 3,
    skin_care_product: {
      id: 38,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:22.000Z",
      productName: "Stokoderm® Sun Protect 30 PURE",
      productURL:
        "https://www.debgroup.com/us/products/stokoderm-sun-protect-30-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4492,
    hazzard: {
      id: 99,
      substanceWorkCondition: "Industry Heavy - Mining & Extraction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4493,
    hazzard: {
      id: 99,
      substanceWorkCondition: "Industry Heavy - Mining & Extraction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 7,
    locationOrder: 3,
    skin_care_product: {
      id: 38,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:22.000Z",
      productName: "Stokoderm® Sun Protect 30 PURE",
      productURL:
        "https://www.debgroup.com/us/products/stokoderm-sun-protect-30-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4494,
    hazzard: {
      id: 100,
      substanceWorkCondition: "Industry Heavy - Off-Shore Extraction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4495,
    hazzard: {
      id: 100,
      substanceWorkCondition: "Industry Heavy - Off-Shore Extraction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 7,
    locationOrder: 3,
    skin_care_product: {
      id: 38,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:22.000Z",
      productName: "Stokoderm® Sun Protect 30 PURE",
      productURL:
        "https://www.debgroup.com/us/products/stokoderm-sun-protect-30-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4496,
    hazzard: {
      id: 101,
      substanceWorkCondition: "Industry Heavy - Printing Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4497,
    hazzard: {
      id: 102,
      substanceWorkCondition:
        "Industry Heavy - Road & Transportation Construction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4498,
    hazzard: {
      id: 102,
      substanceWorkCondition:
        "Industry Heavy - Road & Transportation Construction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 7,
    locationOrder: 3,
    skin_care_product: {
      id: 38,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:22.000Z",
      productName: "Stokoderm® Sun Protect 30 PURE",
      productURL:
        "https://www.debgroup.com/us/products/stokoderm-sun-protect-30-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4499,
    hazzard: {
      id: 103,
      substanceWorkCondition: "Industry Heavy - Ship & Boat Building",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4500,
    hazzard: {
      id: 103,
      substanceWorkCondition: "Industry Heavy - Ship & Boat Building",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 7,
    locationOrder: 3,
    skin_care_product: {
      id: 38,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:22.000Z",
      productName: "Stokoderm® Sun Protect 30 PURE",
      productURL:
        "https://www.debgroup.com/us/products/stokoderm-sun-protect-30-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4501,
    hazzard: {
      id: 104,
      substanceWorkCondition: "Industry Heavy - Shipping & Marine",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4502,
    hazzard: {
      id: 104,
      substanceWorkCondition: "Industry Heavy - Shipping & Marine",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 7,
    locationOrder: 3,
    skin_care_product: {
      id: 38,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:22.000Z",
      productName: "Stokoderm® Sun Protect 30 PURE",
      productURL:
        "https://www.debgroup.com/us/products/stokoderm-sun-protect-30-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4503,
    hazzard: {
      id: 105,
      substanceWorkCondition:
        "Industry Heavy - Steel, Engineering & Heavy Manufacturing",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4504,
    hazzard: {
      id: 105,
      substanceWorkCondition:
        "Industry Heavy - Steel, Engineering & Heavy Manufacturing",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 7,
    locationOrder: 3,
    skin_care_product: {
      id: 38,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:22.000Z",
      productName: "Stokoderm® Sun Protect 30 PURE",
      productURL:
        "https://www.debgroup.com/us/products/stokoderm-sun-protect-30-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4505,
    hazzard: {
      id: 106,
      substanceWorkCondition:
        "Industry Light - Airports & Transportation Terminals - Services & Facility Maintenance",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4506,
    hazzard: {
      id: 106,
      substanceWorkCondition:
        "Industry Light - Airports & Transportation Terminals - Services & Facility Maintenance",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 7,
    locationOrder: 3,
    skin_care_product: {
      id: 38,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:22.000Z",
      productName: "Stokoderm® Sun Protect 30 PURE",
      productURL:
        "https://www.debgroup.com/us/products/stokoderm-sun-protect-30-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4507,
    hazzard: {
      id: 107,
      substanceWorkCondition: "Industry Light - Emergency Services",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4508,
    hazzard: {
      id: 107,
      substanceWorkCondition: "Industry Light - Emergency Services",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 7,
    locationOrder: 3,
    skin_care_product: {
      id: 38,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:22.000Z",
      productName: "Stokoderm® Sun Protect 30 PURE",
      productURL:
        "https://www.debgroup.com/us/products/stokoderm-sun-protect-30-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4509,
    hazzard: {
      id: 108,
      substanceWorkCondition:
        "Industry Light - General Manufacturing & Assembly",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4510,
    hazzard: {
      id: 108,
      substanceWorkCondition:
        "Industry Light - General Manufacturing & Assembly",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 7,
    locationOrder: 3,
    skin_care_product: {
      id: 38,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:22.000Z",
      productName: "Stokoderm® Sun Protect 30 PURE",
      productURL:
        "https://www.debgroup.com/us/products/stokoderm-sun-protect-30-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4511,
    hazzard: {
      id: 109,
      substanceWorkCondition:
        "Industry Light - Hosiery, Knitware, Fabric & Clothing Manufacturing",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4512,
    hazzard: {
      id: 110,
      substanceWorkCondition: "Industry Light - Science & Laboratories",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4513,
    hazzard: {
      id: 111,
      substanceWorkCondition: "Industry Light - Warehousing & Logistics",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4514,
    hazzard: {
      id: 112,
      substanceWorkCondition: "Industry Medium - Ceramics & Glassware",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4515,
    hazzard: {
      id: 113,
      substanceWorkCondition: "Industry Medium - Chemical Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4516,
    hazzard: {
      id: 113,
      substanceWorkCondition: "Industry Medium - Chemical Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 3,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4517,
    hazzard: {
      id: 114,
      substanceWorkCondition: "Industry Medium - Electronics Industries",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4518,
    hazzard: {
      id: 115,
      substanceWorkCondition: "Industry Medium - Metal Working & Fabrication",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4519,
    hazzard: {
      id: 115,
      substanceWorkCondition: "Industry Medium - Metal Working & Fabrication",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 7,
    locationOrder: 3,
    skin_care_product: {
      id: 38,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:22.000Z",
      productName: "Stokoderm® Sun Protect 30 PURE",
      productURL:
        "https://www.debgroup.com/us/products/stokoderm-sun-protect-30-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4520,
    hazzard: {
      id: 116,
      substanceWorkCondition: "Industry Medium - Outdoor Maintenance & Repair",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4521,
    hazzard: {
      id: 116,
      substanceWorkCondition: "Industry Medium - Outdoor Maintenance & Repair",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 7,
    locationOrder: 3,
    skin_care_product: {
      id: 38,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:22.000Z",
      productName: "Stokoderm® Sun Protect 30 PURE",
      productURL:
        "https://www.debgroup.com/us/products/stokoderm-sun-protect-30-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4522,
    hazzard: {
      id: 117,
      substanceWorkCondition: "Industry Medium - Pharmaceutical Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4523,
    hazzard: {
      id: 117,
      substanceWorkCondition: "Industry Medium - Pharmaceutical Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 3,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4524,
    hazzard: {
      id: 118,
      substanceWorkCondition: "Industry Medium - Plastics Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4525,
    hazzard: {
      id: 119,
      substanceWorkCondition: "Ink / printing ink",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4526,
    hazzard: {
      id: 120,
      substanceWorkCondition: "Insulating material",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4527,
    hazzard: {
      id: 122,
      substanceWorkCondition: "Ketone",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4528,
    hazzard: {
      id: 125,
      substanceWorkCondition: "Lacquers (UV-hardening)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4529,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4530,
    hazzard: {
      id: 127,
      substanceWorkCondition: "Latex paint",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4531,
    hazzard: {
      id: 128,
      substanceWorkCondition: "Lime",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4532,
    hazzard: {
      id: 129,
      substanceWorkCondition: "Lubricants, water-based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4533,
    hazzard: {
      id: 131,
      substanceWorkCondition: "Methanol / Methyl Alcohol",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4534,
    hazzard: {
      id: 132,
      substanceWorkCondition: "Mineral oil / paraffin oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4535,
    hazzard: {
      id: 133,
      substanceWorkCondition: "Multi component adhesive",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4536,
    hazzard: {
      id: 137,
      substanceWorkCondition: "Oil-emulsions (water- mixed)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4537,
    hazzard: {
      id: 138,
      substanceWorkCondition: "Oils / Oil based lubricants",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4538,
    hazzard: {
      id: 141,
      substanceWorkCondition: "Pit water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4539,
    hazzard: {
      id: 143,
      substanceWorkCondition: "Polishes (water- soluble)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4540,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4541,
    hazzard: {
      id: 148,
      substanceWorkCondition:
        "Propanols (propyl alcohols): n-Propanol, Isopropanol / 2-Propanol ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4542,
    hazzard: {
      id: 149,
      substanceWorkCondition: "release agent, parting agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4543,
    hazzard: {
      id: 151,
      substanceWorkCondition: "Rock wool",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4544,
    hazzard: {
      id: 152,
      substanceWorkCondition: "Rubber",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4545,
    hazzard: {
      id: 153,
      substanceWorkCondition: "Salts / Salt solution",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4546,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4547,
    hazzard: {
      id: 159,
      substanceWorkCondition: "Styrene",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4548,
    hazzard: {
      id: 160,
      substanceWorkCondition: "Surface Cleanser (surfactant based), Detergents",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4549,
    hazzard: {
      id: 161,
      substanceWorkCondition: "Synthetic melting glaze",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4550,
    hazzard: {
      id: 163,
      substanceWorkCondition: "Tanning agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4551,
    hazzard: {
      id: 169,
      substanceWorkCondition: "UV-rays (A/B) / Sunlight",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 7,
    locationOrder: 3,
    skin_care_product: {
      id: 38,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:22.000Z",
      productName: "Stokoderm® Sun Protect 30 PURE",
      productURL:
        "https://www.debgroup.com/us/products/stokoderm-sun-protect-30-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4552,
    hazzard: {
      id: 170,
      substanceWorkCondition: "Washing bath, washing liquid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4553,
    hazzard: {
      id: 172,
      substanceWorkCondition: "Water / wet work",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4554,
    hazzard: {
      id: 173,
      substanceWorkCondition:
        "Wearing protective clothing / Softening of the skin (Maceration)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4555,
    hazzard: {
      id: 173,
      substanceWorkCondition:
        "Wearing protective clothing / Softening of the skin (Maceration)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 3,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4556,
    hazzard: {
      id: 999,
      substanceWorkCondition: "All Products",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:44:03.000Z",
      updated_at: "2020-03-02T17:44:03.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4557,
    hazzard: {
      id: 999,
      substanceWorkCondition: "All Products",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:44:03.000Z",
      updated_at: "2020-03-02T17:44:03.000Z"
    },
    step: "Protect",
    productOrder: 7,
    locationOrder: 4,
    skin_care_product: {
      id: 38,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:22.000Z",
      productName: "Stokoderm® Sun Protect 30 PURE",
      productURL:
        "https://www.debgroup.com/us/products/stokoderm-sun-protect-30-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4558,
    hazzard: {
      id: 1,
      substanceWorkCondition: "2-Butanone (MEK)",
      cas: "78-93-3",
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: "oil",
      created_at: "2020-03-02T17:39:32.000Z",
      updated_at: "2020-03-05T14:48:01.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4559,
    hazzard: {
      id: 1,
      substanceWorkCondition: "2-Butanone (MEK)",
      cas: "78-93-3",
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: "oil",
      created_at: "2020-03-02T17:39:32.000Z",
      updated_at: "2020-03-05T14:48:01.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4560,
    hazzard: {
      id: 2,
      substanceWorkCondition: "Acetone / Dimethyl ketone",
      cas: "67-64-1",
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4561,
    hazzard: {
      id: 2,
      substanceWorkCondition: "Acetone / Dimethyl ketone",
      cas: "67-64-1",
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4562,
    hazzard: {
      id: 3,
      substanceWorkCondition: "Acids, diluted",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4563,
    hazzard: {
      id: 3,
      substanceWorkCondition: "Acids, diluted",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4564,
    hazzard: {
      id: 4,
      substanceWorkCondition: "Acrylates",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4565,
    hazzard: {
      id: 4,
      substanceWorkCondition: "Acrylates",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4566,
    hazzard: {
      id: 6,
      substanceWorkCondition: "Adhesive, water-soluble",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4567,
    hazzard: {
      id: 6,
      substanceWorkCondition: "Adhesive, water-soluble",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4568,
    hazzard: {
      id: 7,
      substanceWorkCondition: "Adhesives, glues based on synthetic resin",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4569,
    hazzard: {
      id: 7,
      substanceWorkCondition: "Adhesives, glues based on synthetic resin",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4570,
    hazzard: {
      id: 8,
      substanceWorkCondition: "Aldehydes, diluted",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:00.000Z",
    updated_at: "2020-03-02T22:50:00.000Z"
  },
  {
    id: 4571,
    hazzard: {
      id: 8,
      substanceWorkCondition: "Aldehydes, diluted",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:01.000Z",
    updated_at: "2020-03-02T22:50:01.000Z"
  },
  {
    id: 4572,
    hazzard: {
      id: 9,
      substanceWorkCondition: "Alkaline solutions / lyes / thinned caustics",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:01.000Z",
    updated_at: "2020-03-02T22:50:01.000Z"
  },
  {
    id: 4573,
    hazzard: {
      id: 9,
      substanceWorkCondition: "Alkaline solutions / lyes / thinned caustics",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:01.000Z",
    updated_at: "2020-03-02T22:50:01.000Z"
  },
  {
    id: 4574,
    hazzard: {
      id: 10,
      substanceWorkCondition: "Alkaline solutions, diluted",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:01.000Z",
    updated_at: "2020-03-02T22:50:01.000Z"
  },
  {
    id: 4575,
    hazzard: {
      id: 10,
      substanceWorkCondition: "Alkaline solutions, diluted",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:01.000Z",
    updated_at: "2020-03-02T22:50:01.000Z"
  },
  {
    id: 4576,
    hazzard: {
      id: 11,
      substanceWorkCondition: "Alkaline solutions, lyes (solvent based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:01.000Z",
    updated_at: "2020-03-02T22:50:01.000Z"
  },
  {
    id: 4577,
    hazzard: {
      id: 11,
      substanceWorkCondition: "Alkaline solutions, lyes (solvent based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:01.000Z",
    updated_at: "2020-03-02T22:50:01.000Z"
  },
  {
    id: 4578,
    hazzard: {
      id: 12,
      substanceWorkCondition: "Aluminium",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:01.000Z",
    updated_at: "2020-03-02T22:50:01.000Z"
  },
  {
    id: 4579,
    hazzard: {
      id: 12,
      substanceWorkCondition: "Aluminium",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:01.000Z",
    updated_at: "2020-03-02T22:50:01.000Z"
  },
  {
    id: 4580,
    hazzard: {
      id: 14,
      substanceWorkCondition: "Antistatics",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:01.000Z",
    updated_at: "2020-03-02T22:50:01.000Z"
  },
  {
    id: 4581,
    hazzard: {
      id: 14,
      substanceWorkCondition: "Antistatics",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:01.000Z",
    updated_at: "2020-03-02T22:50:01.000Z"
  },
  {
    id: 4582,
    hazzard: {
      id: 19,
      substanceWorkCondition: "Battery acid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:01.000Z",
    updated_at: "2020-03-02T22:50:01.000Z"
  },
  {
    id: 4583,
    hazzard: {
      id: 19,
      substanceWorkCondition: "Battery acid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:01.000Z",
    updated_at: "2020-03-02T22:50:01.000Z"
  },
  {
    id: 4584,
    hazzard: {
      id: 20,
      substanceWorkCondition: "Benzene",
      cas: null,
      glovesRecommended: true,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:01.000Z",
    updated_at: "2020-03-02T22:50:01.000Z"
  },
  {
    id: 4585,
    hazzard: {
      id: 20,
      substanceWorkCondition: "Benzene",
      cas: null,
      glovesRecommended: true,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:01.000Z",
    updated_at: "2020-03-02T22:50:01.000Z"
  },
  {
    id: 4586,
    hazzard: {
      id: 22,
      substanceWorkCondition: "Bitumen emulsion",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:01.000Z",
    updated_at: "2020-03-02T22:50:01.000Z"
  },
  {
    id: 4587,
    hazzard: {
      id: 22,
      substanceWorkCondition: "Bitumen emulsion",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:01.000Z",
    updated_at: "2020-03-02T22:50:01.000Z"
  },
  {
    id: 4588,
    hazzard: {
      id: 21,
      substanceWorkCondition: "Bitumen",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:01.000Z",
    updated_at: "2020-03-02T22:50:01.000Z"
  },
  {
    id: 4589,
    hazzard: {
      id: 21,
      substanceWorkCondition: "Bitumen",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:01.000Z",
    updated_at: "2020-03-02T22:50:01.000Z"
  },
  {
    id: 4590,
    hazzard: {
      id: 23,
      substanceWorkCondition: "Bleaching agent (lye)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:01.000Z",
    updated_at: "2020-03-02T22:50:01.000Z"
  },
  {
    id: 4591,
    hazzard: {
      id: 23,
      substanceWorkCondition: "Bleaching agent (lye)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:01.000Z",
    updated_at: "2020-03-02T22:50:01.000Z"
  },
  {
    id: 4592,
    hazzard: {
      id: 24,
      substanceWorkCondition: "Brake fluid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:01.000Z",
    updated_at: "2020-03-02T22:50:01.000Z"
  },
  {
    id: 4593,
    hazzard: {
      id: 24,
      substanceWorkCondition: "Brake fluid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:01.000Z",
    updated_at: "2020-03-02T22:50:01.000Z"
  },
  {
    id: 4594,
    hazzard: {
      id: 25,
      substanceWorkCondition: "Casting dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:01.000Z",
    updated_at: "2020-03-02T22:50:01.000Z"
  },
  {
    id: 4595,
    hazzard: {
      id: 25,
      substanceWorkCondition: "Casting dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:01.000Z",
    updated_at: "2020-03-02T22:50:01.000Z"
  },
  {
    id: 4596,
    hazzard: {
      id: 26,
      substanceWorkCondition: "Cement (dry)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:01.000Z",
    updated_at: "2020-03-02T22:50:01.000Z"
  },
  {
    id: 4597,
    hazzard: {
      id: 26,
      substanceWorkCondition: "Cement (dry)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:01.000Z",
    updated_at: "2020-03-02T22:50:01.000Z"
  },
  {
    id: 4598,
    hazzard: {
      id: 27,
      substanceWorkCondition: "Cement (wet)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:01.000Z",
    updated_at: "2020-03-02T22:50:01.000Z"
  },
  {
    id: 4599,
    hazzard: {
      id: 27,
      substanceWorkCondition: "Cement (wet)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:01.000Z",
    updated_at: "2020-03-02T22:50:01.000Z"
  },
  {
    id: 4600,
    hazzard: {
      id: 28,
      substanceWorkCondition: "Cement, putty epoxy-based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:01.000Z",
    updated_at: "2020-03-02T22:50:01.000Z"
  },
  {
    id: 4601,
    hazzard: {
      id: 28,
      substanceWorkCondition: "Cement, putty epoxy-based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:01.000Z",
    updated_at: "2020-03-02T22:50:01.000Z"
  },
  {
    id: 4602,
    hazzard: {
      id: 29,
      substanceWorkCondition:
        "Chromates (water- insoluble) = Chromium pigments",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:01.000Z",
    updated_at: "2020-03-02T22:50:01.000Z"
  },
  {
    id: 4603,
    hazzard: {
      id: 29,
      substanceWorkCondition:
        "Chromates (water- insoluble) = Chromium pigments",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:01.000Z",
    updated_at: "2020-03-02T22:50:01.000Z"
  },
  {
    id: 4604,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:01.000Z",
    updated_at: "2020-03-02T22:50:01.000Z"
  },
  {
    id: 4605,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:01.000Z",
    updated_at: "2020-03-02T22:50:01.000Z"
  },
  {
    id: 4606,
    hazzard: {
      id: 31,
      substanceWorkCondition: "cleaning agent, detergent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:01.000Z",
    updated_at: "2020-03-02T22:50:01.000Z"
  },
  {
    id: 4607,
    hazzard: {
      id: 31,
      substanceWorkCondition: "cleaning agent, detergent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:01.000Z",
    updated_at: "2020-03-02T22:50:01.000Z"
  },
  {
    id: 4608,
    hazzard: {
      id: 32,
      substanceWorkCondition: "Coal dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:01.000Z",
    updated_at: "2020-03-02T22:50:01.000Z"
  },
  {
    id: 4609,
    hazzard: {
      id: 32,
      substanceWorkCondition: "Coal dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:01.000Z",
    updated_at: "2020-03-02T22:50:01.000Z"
  },
  {
    id: 4610,
    hazzard: {
      id: 35,
      substanceWorkCondition: "Coating materials, oily",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:01.000Z",
    updated_at: "2020-03-02T22:50:01.000Z"
  },
  {
    id: 4611,
    hazzard: {
      id: 35,
      substanceWorkCondition: "Coating materials, oily",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:01.000Z",
    updated_at: "2020-03-02T22:50:01.000Z"
  },
  {
    id: 4612,
    hazzard: {
      id: 36,
      substanceWorkCondition: "Cold degreasing agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:01.000Z",
    updated_at: "2020-03-02T22:50:01.000Z"
  },
  {
    id: 4613,
    hazzard: {
      id: 36,
      substanceWorkCondition: "Cold degreasing agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:01.000Z",
    updated_at: "2020-03-02T22:50:01.000Z"
  },
  {
    id: 4614,
    hazzard: {
      id: 37,
      substanceWorkCondition: "cold detergent, solvent based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:01.000Z",
    updated_at: "2020-03-02T22:50:01.000Z"
  },
  {
    id: 4615,
    hazzard: {
      id: 37,
      substanceWorkCondition: "cold detergent, solvent based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:01.000Z",
    updated_at: "2020-03-02T22:50:01.000Z"
  },
  {
    id: 4616,
    hazzard: {
      id: 38,
      substanceWorkCondition: "Cold working conditions",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 3,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:01.000Z",
    updated_at: "2020-03-02T22:50:01.000Z"
  },
  {
    id: 4617,
    hazzard: {
      id: 38,
      substanceWorkCondition: "Cold working conditions",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:01.000Z",
    updated_at: "2020-03-02T22:50:01.000Z"
  },
  {
    id: 4618,
    hazzard: {
      id: 38,
      substanceWorkCondition: "Cold working conditions",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:01.000Z",
    updated_at: "2020-03-02T22:50:01.000Z"
  },
  {
    id: 4619,
    hazzard: {
      id: 47,
      substanceWorkCondition: "Concrete",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:01.000Z",
    updated_at: "2020-03-02T22:50:01.000Z"
  },
  {
    id: 4620,
    hazzard: {
      id: 47,
      substanceWorkCondition: "Concrete",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:01.000Z",
    updated_at: "2020-03-02T22:50:01.000Z"
  },
  {
    id: 4621,
    hazzard: {
      id: 48,
      substanceWorkCondition: "Cooling lubricant (non-water-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:01.000Z",
    updated_at: "2020-03-02T22:50:01.000Z"
  },
  {
    id: 4622,
    hazzard: {
      id: 48,
      substanceWorkCondition: "Cooling lubricant (non-water-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:01.000Z",
    updated_at: "2020-03-02T22:50:01.000Z"
  },
  {
    id: 4623,
    hazzard: {
      id: 49,
      substanceWorkCondition: "Cooling lubricant (water- based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:01.000Z",
    updated_at: "2020-03-02T22:50:01.000Z"
  },
  {
    id: 4624,
    hazzard: {
      id: 49,
      substanceWorkCondition: "Cooling lubricant (water- based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:01.000Z",
    updated_at: "2020-03-02T22:50:01.000Z"
  },
  {
    id: 4625,
    hazzard: {
      id: 50,
      substanceWorkCondition: "Cooling water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:01.000Z",
    updated_at: "2020-03-02T22:50:01.000Z"
  },
  {
    id: 4626,
    hazzard: {
      id: 50,
      substanceWorkCondition: "Cooling water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:01.000Z",
    updated_at: "2020-03-02T22:50:01.000Z"
  },
  {
    id: 4627,
    hazzard: {
      id: 51,
      substanceWorkCondition: "Copying ink",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:01.000Z",
    updated_at: "2020-03-02T22:50:01.000Z"
  },
  {
    id: 4628,
    hazzard: {
      id: 51,
      substanceWorkCondition: "Copying ink",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:01.000Z",
    updated_at: "2020-03-02T22:50:01.000Z"
  },
  {
    id: 4629,
    hazzard: {
      id: 52,
      substanceWorkCondition: "Cutting oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:01.000Z",
    updated_at: "2020-03-02T22:50:01.000Z"
  },
  {
    id: 4630,
    hazzard: {
      id: 52,
      substanceWorkCondition: "Cutting oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:01.000Z",
    updated_at: "2020-03-02T22:50:01.000Z"
  },
  {
    id: 4631,
    hazzard: {
      id: 53,
      substanceWorkCondition: "Degreaser solvent - based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:01.000Z",
    updated_at: "2020-03-02T22:50:01.000Z"
  },
  {
    id: 4632,
    hazzard: {
      id: 53,
      substanceWorkCondition: "Degreaser solvent - based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:01.000Z",
    updated_at: "2020-03-02T22:50:01.000Z"
  },
  {
    id: 4633,
    hazzard: {
      id: 54,
      substanceWorkCondition: "Degreaser without organic solvent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:01.000Z",
    updated_at: "2020-03-02T22:50:01.000Z"
  },
  {
    id: 4634,
    hazzard: {
      id: 54,
      substanceWorkCondition: "Degreaser without organic solvent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:01.000Z",
    updated_at: "2020-03-02T22:50:01.000Z"
  },
  {
    id: 4635,
    hazzard: {
      id: 55,
      substanceWorkCondition: "Detachment solution for concrete",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:01.000Z",
    updated_at: "2020-03-02T22:50:01.000Z"
  },
  {
    id: 4636,
    hazzard: {
      id: 55,
      substanceWorkCondition: "Detachment solution for concrete",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:01.000Z",
    updated_at: "2020-03-02T22:50:01.000Z"
  },
  {
    id: 4637,
    hazzard: {
      id: 56,
      substanceWorkCondition: "Detachments (alcohol-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:01.000Z",
    updated_at: "2020-03-02T22:50:01.000Z"
  },
  {
    id: 4638,
    hazzard: {
      id: 56,
      substanceWorkCondition: "Detachments (alcohol-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:01.000Z",
    updated_at: "2020-03-02T22:50:01.000Z"
  },
  {
    id: 4639,
    hazzard: {
      id: 57,
      substanceWorkCondition: "Detachments (solvent-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:01.000Z",
    updated_at: "2020-03-02T22:50:01.000Z"
  },
  {
    id: 4640,
    hazzard: {
      id: 57,
      substanceWorkCondition: "Detachments (solvent-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:02.000Z",
    updated_at: "2020-03-02T22:50:02.000Z"
  },
  {
    id: 4641,
    hazzard: {
      id: 58,
      substanceWorkCondition: "Detachments (sour)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:02.000Z",
    updated_at: "2020-03-02T22:50:02.000Z"
  },
  {
    id: 4642,
    hazzard: {
      id: 58,
      substanceWorkCondition: "Detachments (sour)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:02.000Z",
    updated_at: "2020-03-02T22:50:02.000Z"
  },
  {
    id: 4643,
    hazzard: {
      id: 59,
      substanceWorkCondition: "Disinfectants (alcohol-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:02.000Z",
    updated_at: "2020-03-02T22:50:02.000Z"
  },
  {
    id: 4644,
    hazzard: {
      id: 59,
      substanceWorkCondition: "Disinfectants (alcohol-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:02.000Z",
    updated_at: "2020-03-02T22:50:02.000Z"
  },
  {
    id: 4645,
    hazzard: {
      id: 60,
      substanceWorkCondition: "Disinfectants (formaldehyd-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:02.000Z",
    updated_at: "2020-03-02T22:50:02.000Z"
  },
  {
    id: 4646,
    hazzard: {
      id: 60,
      substanceWorkCondition: "Disinfectants (formaldehyd-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:02.000Z",
    updated_at: "2020-03-02T22:50:02.000Z"
  },
  {
    id: 4647,
    hazzard: {
      id: 61,
      substanceWorkCondition: "Disinfectants (water-based) ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:02.000Z",
    updated_at: "2020-03-02T22:50:02.000Z"
  },
  {
    id: 4648,
    hazzard: {
      id: 61,
      substanceWorkCondition: "Disinfectants (water-based) ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:02.000Z",
    updated_at: "2020-03-02T22:50:02.000Z"
  },
  {
    id: 4649,
    hazzard: {
      id: 62,
      substanceWorkCondition: "Dispersing Agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:02.000Z",
    updated_at: "2020-03-02T22:50:02.000Z"
  },
  {
    id: 4650,
    hazzard: {
      id: 62,
      substanceWorkCondition: "Dispersing Agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:02.000Z",
    updated_at: "2020-03-02T22:50:02.000Z"
  },
  {
    id: 4651,
    hazzard: {
      id: 63,
      substanceWorkCondition: "Drilling emulsion (water based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:02.000Z",
    updated_at: "2020-03-02T22:50:02.000Z"
  },
  {
    id: 4652,
    hazzard: {
      id: 63,
      substanceWorkCondition: "Drilling emulsion (water based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:02.000Z",
    updated_at: "2020-03-02T22:50:02.000Z"
  },
  {
    id: 4653,
    hazzard: {
      id: 64,
      substanceWorkCondition: "Drilling oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:02.000Z",
    updated_at: "2020-03-02T22:50:02.000Z"
  },
  {
    id: 4654,
    hazzard: {
      id: 64,
      substanceWorkCondition: "Drilling oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:02.000Z",
    updated_at: "2020-03-02T22:50:02.000Z"
  },
  {
    id: 4655,
    hazzard: {
      id: 65,
      substanceWorkCondition: "Dryness",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:02.000Z",
    updated_at: "2020-03-02T22:50:02.000Z"
  },
  {
    id: 4656,
    hazzard: {
      id: 65,
      substanceWorkCondition: "Dryness",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:02.000Z",
    updated_at: "2020-03-02T22:50:02.000Z"
  },
  {
    id: 4657,
    hazzard: {
      id: 66,
      substanceWorkCondition: "Dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:02.000Z",
    updated_at: "2020-03-02T22:50:02.000Z"
  },
  {
    id: 4658,
    hazzard: {
      id: 66,
      substanceWorkCondition: "Dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:02.000Z",
    updated_at: "2020-03-02T22:50:02.000Z"
  },
  {
    id: 4659,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:02.000Z",
    updated_at: "2020-03-02T22:50:02.000Z"
  },
  {
    id: 4660,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:02.000Z",
    updated_at: "2020-03-02T22:50:02.000Z"
  },
  {
    id: 4661,
    hazzard: {
      id: 70,
      substanceWorkCondition: "Epoxy resin",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:02.000Z",
    updated_at: "2020-03-02T22:50:02.000Z"
  },
  {
    id: 4662,
    hazzard: {
      id: 70,
      substanceWorkCondition: "Epoxy resin",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:02.000Z",
    updated_at: "2020-03-02T22:50:02.000Z"
  },
  {
    id: 4663,
    hazzard: {
      id: 72,
      substanceWorkCondition: "Ethanol / methylated spirits ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:02.000Z",
    updated_at: "2020-03-02T22:50:02.000Z"
  },
  {
    id: 4664,
    hazzard: {
      id: 72,
      substanceWorkCondition: "Ethanol / methylated spirits ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:03.000Z",
    updated_at: "2020-03-02T22:50:03.000Z"
  },
  {
    id: 4665,
    hazzard: {
      id: 73,
      substanceWorkCondition: "Fat",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:03.000Z",
    updated_at: "2020-03-02T22:50:03.000Z"
  },
  {
    id: 4666,
    hazzard: {
      id: 73,
      substanceWorkCondition: "Fat",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:03.000Z",
    updated_at: "2020-03-02T22:50:03.000Z"
  },
  {
    id: 4667,
    hazzard: {
      id: 74,
      substanceWorkCondition: "Fertilizer",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:03.000Z",
    updated_at: "2020-03-02T22:50:03.000Z"
  },
  {
    id: 4668,
    hazzard: {
      id: 74,
      substanceWorkCondition: "Fertilizer",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:03.000Z",
    updated_at: "2020-03-02T22:50:03.000Z"
  },
  {
    id: 4669,
    hazzard: {
      id: 75,
      substanceWorkCondition: "fine anorganic/inorganic powders ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:03.000Z",
    updated_at: "2020-03-02T22:50:03.000Z"
  },
  {
    id: 4670,
    hazzard: {
      id: 75,
      substanceWorkCondition: "fine anorganic/inorganic powders ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:03.000Z",
    updated_at: "2020-03-02T22:50:03.000Z"
  },
  {
    id: 4671,
    hazzard: {
      id: 76,
      substanceWorkCondition: "Floor cleaner (water- based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:03.000Z",
    updated_at: "2020-03-02T22:50:03.000Z"
  },
  {
    id: 4672,
    hazzard: {
      id: 76,
      substanceWorkCondition: "Floor cleaner (water- based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:03.000Z",
    updated_at: "2020-03-02T22:50:03.000Z"
  },
  {
    id: 4673,
    hazzard: {
      id: 77,
      substanceWorkCondition: "Food - Food Packaging",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:03.000Z",
    updated_at: "2020-03-02T22:50:03.000Z"
  },
  {
    id: 4674,
    hazzard: {
      id: 78,
      substanceWorkCondition: "Food - Food Processing & Manufacturing",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:03.000Z",
    updated_at: "2020-03-02T22:50:03.000Z"
  },
  {
    id: 4675,
    hazzard: {
      id: 80,
      substanceWorkCondition: "Food Dyestuff",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:03.000Z",
    updated_at: "2020-03-02T22:50:03.000Z"
  },
  {
    id: 4676,
    hazzard: {
      id: 81,
      substanceWorkCondition: "fuel: gas / diesel",
      cas: null,
      glovesRecommended: true,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:03.000Z",
    updated_at: "2020-03-02T22:50:03.000Z"
  },
  {
    id: 4677,
    hazzard: {
      id: 81,
      substanceWorkCondition: "fuel: gas / diesel",
      cas: null,
      glovesRecommended: true,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:03.000Z",
    updated_at: "2020-03-02T22:50:03.000Z"
  },
  {
    id: 4678,
    hazzard: {
      id: 82,
      substanceWorkCondition: "Galvanic bath",
      cas: null,
      glovesRecommended: true,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:03.000Z",
    updated_at: "2020-03-02T22:50:03.000Z"
  },
  {
    id: 4679,
    hazzard: {
      id: 82,
      substanceWorkCondition: "Galvanic bath",
      cas: null,
      glovesRecommended: true,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:03.000Z",
    updated_at: "2020-03-02T22:50:03.000Z"
  },
  {
    id: 4680,
    hazzard: {
      id: 83,
      substanceWorkCondition: "Glass fibre dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:03.000Z",
    updated_at: "2020-03-02T22:50:03.000Z"
  },
  {
    id: 4681,
    hazzard: {
      id: 83,
      substanceWorkCondition: "Glass fibre dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:03.000Z",
    updated_at: "2020-03-02T22:50:03.000Z"
  },
  {
    id: 4682,
    hazzard: {
      id: 84,
      substanceWorkCondition: "Glass wool dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:03.000Z",
    updated_at: "2020-03-02T22:50:03.000Z"
  },
  {
    id: 4683,
    hazzard: {
      id: 84,
      substanceWorkCondition: "Glass wool dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:03.000Z",
    updated_at: "2020-03-02T22:50:03.000Z"
  },
  {
    id: 4684,
    hazzard: {
      id: 85,
      substanceWorkCondition: "Glycols",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:03.000Z",
    updated_at: "2020-03-02T22:50:03.000Z"
  },
  {
    id: 4685,
    hazzard: {
      id: 85,
      substanceWorkCondition: "Glycols",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:03.000Z",
    updated_at: "2020-03-02T22:50:03.000Z"
  },
  {
    id: 4686,
    hazzard: {
      id: 86,
      substanceWorkCondition: "Graphite",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:03.000Z",
    updated_at: "2020-03-02T22:50:03.000Z"
  },
  {
    id: 4687,
    hazzard: {
      id: 86,
      substanceWorkCondition: "Graphite",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:03.000Z",
    updated_at: "2020-03-02T22:50:03.000Z"
  },
  {
    id: 4688,
    hazzard: {
      id: 87,
      substanceWorkCondition: "Grease",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:04.000Z",
    updated_at: "2020-03-02T22:50:04.000Z"
  },
  {
    id: 4689,
    hazzard: {
      id: 87,
      substanceWorkCondition: "Grease",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:04.000Z",
    updated_at: "2020-03-02T22:50:04.000Z"
  },
  {
    id: 4690,
    hazzard: {
      id: 88,
      substanceWorkCondition: "Grinding oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:04.000Z",
    updated_at: "2020-03-02T22:50:04.000Z"
  },
  {
    id: 4691,
    hazzard: {
      id: 88,
      substanceWorkCondition: "Grinding oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:04.000Z",
    updated_at: "2020-03-02T22:50:04.000Z"
  },
  {
    id: 4692,
    hazzard: {
      id: 89,
      substanceWorkCondition: "Grinding water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:04.000Z",
    updated_at: "2020-03-02T22:50:04.000Z"
  },
  {
    id: 4693,
    hazzard: {
      id: 89,
      substanceWorkCondition: "Grinding water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:04.000Z",
    updated_at: "2020-03-02T22:50:04.000Z"
  },
  {
    id: 4694,
    hazzard: {
      id: 90,
      substanceWorkCondition: "Gypsum",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:04.000Z",
    updated_at: "2020-03-02T22:50:04.000Z"
  },
  {
    id: 4695,
    hazzard: {
      id: 90,
      substanceWorkCondition: "Gypsum",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:04.000Z",
    updated_at: "2020-03-02T22:50:04.000Z"
  },
  {
    id: 4696,
    hazzard: {
      id: 95,
      substanceWorkCondition: "Hydraulic fluid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:04.000Z",
    updated_at: "2020-03-02T22:50:04.000Z"
  },
  {
    id: 4697,
    hazzard: {
      id: 95,
      substanceWorkCondition: "Hydraulic fluid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:04.000Z",
    updated_at: "2020-03-02T22:50:04.000Z"
  },
  {
    id: 4698,
    hazzard: {
      id: 96,
      substanceWorkCondition: "Hyrocarbons",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:04.000Z",
    updated_at: "2020-03-02T22:50:04.000Z"
  },
  {
    id: 4699,
    hazzard: {
      id: 96,
      substanceWorkCondition: "Hyrocarbons",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:04.000Z",
    updated_at: "2020-03-02T22:50:04.000Z"
  },
  {
    id: 4700,
    hazzard: {
      id: 106,
      substanceWorkCondition:
        "Industry Light - Airports & Transportation Terminals - Services & Facility Maintenance",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:04.000Z",
    updated_at: "2020-03-02T22:50:04.000Z"
  },
  {
    id: 4701,
    hazzard: {
      id: 106,
      substanceWorkCondition:
        "Industry Light - Airports & Transportation Terminals - Services & Facility Maintenance",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:04.000Z",
    updated_at: "2020-03-02T22:50:04.000Z"
  },
  {
    id: 4702,
    hazzard: {
      id: 108,
      substanceWorkCondition:
        "Industry Light - General Manufacturing & Assembly",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:04.000Z",
    updated_at: "2020-03-02T22:50:04.000Z"
  },
  {
    id: 4703,
    hazzard: {
      id: 108,
      substanceWorkCondition:
        "Industry Light - General Manufacturing & Assembly",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:04.000Z",
    updated_at: "2020-03-02T22:50:04.000Z"
  },
  {
    id: 4704,
    hazzard: {
      id: 109,
      substanceWorkCondition:
        "Industry Light - Hosiery, Knitware, Fabric & Clothing Manufacturing",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:04.000Z",
    updated_at: "2020-03-02T22:50:04.000Z"
  },
  {
    id: 4705,
    hazzard: {
      id: 109,
      substanceWorkCondition:
        "Industry Light - Hosiery, Knitware, Fabric & Clothing Manufacturing",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:04.000Z",
    updated_at: "2020-03-02T22:50:04.000Z"
  },
  {
    id: 4706,
    hazzard: {
      id: 110,
      substanceWorkCondition: "Industry Light - Science & Laboratories",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:04.000Z",
    updated_at: "2020-03-02T22:50:04.000Z"
  },
  {
    id: 4707,
    hazzard: {
      id: 110,
      substanceWorkCondition: "Industry Light - Science & Laboratories",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:04.000Z",
    updated_at: "2020-03-02T22:50:04.000Z"
  },
  {
    id: 4708,
    hazzard: {
      id: 111,
      substanceWorkCondition: "Industry Light - Warehousing & Logistics",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:04.000Z",
    updated_at: "2020-03-02T22:50:04.000Z"
  },
  {
    id: 4709,
    hazzard: {
      id: 111,
      substanceWorkCondition: "Industry Light - Warehousing & Logistics",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:04.000Z",
    updated_at: "2020-03-02T22:50:04.000Z"
  },
  {
    id: 4710,
    hazzard: {
      id: 117,
      substanceWorkCondition: "Industry Medium - Pharmaceutical Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:04.000Z",
    updated_at: "2020-03-02T22:50:04.000Z"
  },
  {
    id: 4711,
    hazzard: {
      id: 117,
      substanceWorkCondition: "Industry Medium - Pharmaceutical Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:04.000Z",
    updated_at: "2020-03-02T22:50:04.000Z"
  },
  {
    id: 4712,
    hazzard: {
      id: 117,
      substanceWorkCondition: "Industry Medium - Pharmaceutical Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 23,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:36:39.000Z",
      productName: "Refresh™ Clear FOAM (UL, GS)",
      productURL: "https://www.debgroup.com/us/products/refresh-clear-foam"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: true,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:04.000Z",
    updated_at: "2020-03-02T22:50:04.000Z"
  },
  {
    id: 4713,
    hazzard: {
      id: 118,
      substanceWorkCondition: "Industry Medium - Plastics Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:04.000Z",
    updated_at: "2020-03-02T22:50:04.000Z"
  },
  {
    id: 4714,
    hazzard: {
      id: 118,
      substanceWorkCondition: "Industry Medium - Plastics Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:04.000Z",
    updated_at: "2020-03-02T22:50:04.000Z"
  },
  {
    id: 4715,
    hazzard: {
      id: 119,
      substanceWorkCondition: "Ink / printing ink",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:04.000Z",
    updated_at: "2020-03-02T22:50:04.000Z"
  },
  {
    id: 4716,
    hazzard: {
      id: 119,
      substanceWorkCondition: "Ink / printing ink",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:04.000Z",
    updated_at: "2020-03-02T22:50:04.000Z"
  },
  {
    id: 4717,
    hazzard: {
      id: 120,
      substanceWorkCondition: "Insulating material",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:04.000Z",
    updated_at: "2020-03-02T22:50:04.000Z"
  },
  {
    id: 4718,
    hazzard: {
      id: 120,
      substanceWorkCondition: "Insulating material",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:04.000Z",
    updated_at: "2020-03-02T22:50:04.000Z"
  },
  {
    id: 4719,
    hazzard: {
      id: 124,
      substanceWorkCondition: "Lacquers (PU based, synthetic resin based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:04.000Z",
    updated_at: "2020-03-02T22:50:04.000Z"
  },
  {
    id: 4720,
    hazzard: {
      id: 124,
      substanceWorkCondition: "Lacquers (PU based, synthetic resin based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:04.000Z",
    updated_at: "2020-03-02T22:50:04.000Z"
  },
  {
    id: 4721,
    hazzard: {
      id: 125,
      substanceWorkCondition: "Lacquers (UV-hardening)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:04.000Z",
    updated_at: "2020-03-02T22:50:04.000Z"
  },
  {
    id: 4722,
    hazzard: {
      id: 125,
      substanceWorkCondition: "Lacquers (UV-hardening)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:04.000Z",
    updated_at: "2020-03-02T22:50:04.000Z"
  },
  {
    id: 4723,
    hazzard: {
      id: 127,
      substanceWorkCondition: "Latex paint",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:04.000Z",
    updated_at: "2020-03-02T22:50:04.000Z"
  },
  {
    id: 4724,
    hazzard: {
      id: 127,
      substanceWorkCondition: "Latex paint",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:04.000Z",
    updated_at: "2020-03-02T22:50:04.000Z"
  },
  {
    id: 4725,
    hazzard: {
      id: 128,
      substanceWorkCondition: "Lime",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:04.000Z",
    updated_at: "2020-03-02T22:50:04.000Z"
  },
  {
    id: 4726,
    hazzard: {
      id: 128,
      substanceWorkCondition: "Lime",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:04.000Z",
    updated_at: "2020-03-02T22:50:04.000Z"
  },
  {
    id: 4727,
    hazzard: {
      id: 129,
      substanceWorkCondition: "Lubricants, water-based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:04.000Z",
    updated_at: "2020-03-02T22:50:04.000Z"
  },
  {
    id: 4728,
    hazzard: {
      id: 129,
      substanceWorkCondition: "Lubricants, water-based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:04.000Z",
    updated_at: "2020-03-02T22:50:04.000Z"
  },
  {
    id: 4729,
    hazzard: {
      id: 130,
      substanceWorkCondition: "Metal dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:04.000Z",
    updated_at: "2020-03-02T22:50:04.000Z"
  },
  {
    id: 4730,
    hazzard: {
      id: 130,
      substanceWorkCondition: "Metal dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:04.000Z",
    updated_at: "2020-03-02T22:50:04.000Z"
  },
  {
    id: 4731,
    hazzard: {
      id: 131,
      substanceWorkCondition: "Methanol / Methyl Alcohol",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:04.000Z",
    updated_at: "2020-03-02T22:50:04.000Z"
  },
  {
    id: 4732,
    hazzard: {
      id: 131,
      substanceWorkCondition: "Methanol / Methyl Alcohol",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:04.000Z",
    updated_at: "2020-03-02T22:50:04.000Z"
  },
  {
    id: 4733,
    hazzard: {
      id: 132,
      substanceWorkCondition: "Mineral oil / paraffin oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:04.000Z",
    updated_at: "2020-03-02T22:50:04.000Z"
  },
  {
    id: 4734,
    hazzard: {
      id: 132,
      substanceWorkCondition: "Mineral oil / paraffin oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:04.000Z",
    updated_at: "2020-03-02T22:50:04.000Z"
  },
  {
    id: 4735,
    hazzard: {
      id: 133,
      substanceWorkCondition: "Multi component adhesive",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:04.000Z",
    updated_at: "2020-03-02T22:50:04.000Z"
  },
  {
    id: 4736,
    hazzard: {
      id: 133,
      substanceWorkCondition: "Multi component adhesive",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:04.000Z",
    updated_at: "2020-03-02T22:50:04.000Z"
  },
  {
    id: 4737,
    hazzard: {
      id: 134,
      substanceWorkCondition: "Multi component resin",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:04.000Z",
    updated_at: "2020-03-02T22:50:04.000Z"
  },
  {
    id: 4738,
    hazzard: {
      id: 134,
      substanceWorkCondition: "Multi component resin",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:04.000Z",
    updated_at: "2020-03-02T22:50:04.000Z"
  },
  {
    id: 4739,
    hazzard: {
      id: 135,
      substanceWorkCondition: "Naphthalene",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:04.000Z",
    updated_at: "2020-03-02T22:50:04.000Z"
  },
  {
    id: 4740,
    hazzard: {
      id: 135,
      substanceWorkCondition: "Naphthalene",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:04.000Z",
    updated_at: "2020-03-02T22:50:04.000Z"
  },
  {
    id: 4741,
    hazzard: {
      id: 136,
      substanceWorkCondition: "Oil coke (carbon)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:04.000Z",
    updated_at: "2020-03-02T22:50:04.000Z"
  },
  {
    id: 4742,
    hazzard: {
      id: 136,
      substanceWorkCondition: "Oil coke (carbon)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:04.000Z",
    updated_at: "2020-03-02T22:50:04.000Z"
  },
  {
    id: 4743,
    hazzard: {
      id: 137,
      substanceWorkCondition: "Oil-emulsions (water- mixed)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:04.000Z",
    updated_at: "2020-03-02T22:50:04.000Z"
  },
  {
    id: 4744,
    hazzard: {
      id: 137,
      substanceWorkCondition: "Oil-emulsions (water- mixed)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:04.000Z",
    updated_at: "2020-03-02T22:50:04.000Z"
  },
  {
    id: 4745,
    hazzard: {
      id: 138,
      substanceWorkCondition: "Oils / Oil based lubricants",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:04.000Z",
    updated_at: "2020-03-02T22:50:04.000Z"
  },
  {
    id: 4746,
    hazzard: {
      id: 138,
      substanceWorkCondition: "Oils / Oil based lubricants",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:04.000Z",
    updated_at: "2020-03-02T22:50:04.000Z"
  },
  {
    id: 4747,
    hazzard: {
      id: 140,
      substanceWorkCondition: "Paint Thinner ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:04.000Z",
    updated_at: "2020-03-02T22:50:04.000Z"
  },
  {
    id: 4748,
    hazzard: {
      id: 140,
      substanceWorkCondition: "Paint Thinner ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:04.000Z",
    updated_at: "2020-03-02T22:50:04.000Z"
  },
  {
    id: 4749,
    hazzard: {
      id: 141,
      substanceWorkCondition: "Pit water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:04.000Z",
    updated_at: "2020-03-02T22:50:04.000Z"
  },
  {
    id: 4750,
    hazzard: {
      id: 141,
      substanceWorkCondition: "Pit water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:04.000Z",
    updated_at: "2020-03-02T22:50:04.000Z"
  },
  {
    id: 4751,
    hazzard: {
      id: 142,
      substanceWorkCondition: "Pitch",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:04.000Z",
    updated_at: "2020-03-02T22:50:04.000Z"
  },
  {
    id: 4752,
    hazzard: {
      id: 142,
      substanceWorkCondition: "Pitch",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:04.000Z",
    updated_at: "2020-03-02T22:50:04.000Z"
  },
  {
    id: 4753,
    hazzard: {
      id: 143,
      substanceWorkCondition: "Polishes (water- soluble)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:04.000Z",
    updated_at: "2020-03-02T22:50:04.000Z"
  },
  {
    id: 4754,
    hazzard: {
      id: 143,
      substanceWorkCondition: "Polishes (water- soluble)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:04.000Z",
    updated_at: "2020-03-02T22:50:04.000Z"
  },
  {
    id: 4755,
    hazzard: {
      id: 144,
      substanceWorkCondition: "Polyester / Polyester resin",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:04.000Z",
    updated_at: "2020-03-02T22:50:04.000Z"
  },
  {
    id: 4756,
    hazzard: {
      id: 144,
      substanceWorkCondition: "Polyester / Polyester resin",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:04.000Z",
    updated_at: "2020-03-02T22:50:04.000Z"
  },
  {
    id: 4757,
    hazzard: {
      id: 145,
      substanceWorkCondition: "Polyurethane",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:04.000Z",
    updated_at: "2020-03-02T22:50:04.000Z"
  },
  {
    id: 4758,
    hazzard: {
      id: 145,
      substanceWorkCondition: "Polyurethane",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:04.000Z",
    updated_at: "2020-03-02T22:50:04.000Z"
  },
  {
    id: 4759,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:04.000Z",
    updated_at: "2020-03-02T22:50:04.000Z"
  },
  {
    id: 4760,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:04.000Z",
    updated_at: "2020-03-02T22:50:04.000Z"
  },
  {
    id: 4761,
    hazzard: {
      id: 148,
      substanceWorkCondition:
        "Propanols (propyl alcohols): n-Propanol, Isopropanol / 2-Propanol ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:04.000Z",
    updated_at: "2020-03-02T22:50:04.000Z"
  },
  {
    id: 4762,
    hazzard: {
      id: 148,
      substanceWorkCondition:
        "Propanols (propyl alcohols): n-Propanol, Isopropanol / 2-Propanol ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:04.000Z",
    updated_at: "2020-03-02T22:50:04.000Z"
  },
  {
    id: 4763,
    hazzard: {
      id: 149,
      substanceWorkCondition: "release agent, parting agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:04.000Z",
    updated_at: "2020-03-02T22:50:04.000Z"
  },
  {
    id: 4764,
    hazzard: {
      id: 149,
      substanceWorkCondition: "release agent, parting agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:04.000Z",
    updated_at: "2020-03-02T22:50:04.000Z"
  },
  {
    id: 4765,
    hazzard: {
      id: 150,
      substanceWorkCondition: "Resins / urea resins",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4766,
    hazzard: {
      id: 150,
      substanceWorkCondition: "Resins / urea resins",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4767,
    hazzard: {
      id: 151,
      substanceWorkCondition: "Rock wool",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4768,
    hazzard: {
      id: 151,
      substanceWorkCondition: "Rock wool",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4769,
    hazzard: {
      id: 152,
      substanceWorkCondition: "Rubber",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4770,
    hazzard: {
      id: 152,
      substanceWorkCondition: "Rubber",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4771,
    hazzard: {
      id: 153,
      substanceWorkCondition: "Salts / Salt solution",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4772,
    hazzard: {
      id: 153,
      substanceWorkCondition: "Salts / Salt solution",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4773,
    hazzard: {
      id: 154,
      substanceWorkCondition: "Sealing compound",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4774,
    hazzard: {
      id: 154,
      substanceWorkCondition: "Sealing compound",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4775,
    hazzard: {
      id: 155,
      substanceWorkCondition: "Sintering",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4776,
    hazzard: {
      id: 155,
      substanceWorkCondition: "Sintering",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4777,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4778,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4779,
    hazzard: {
      id: 157,
      substanceWorkCondition: "Solvents, - organic",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4780,
    hazzard: {
      id: 157,
      substanceWorkCondition: "Solvents, - organic",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4781,
    hazzard: {
      id: 158,
      substanceWorkCondition: "Soot",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4782,
    hazzard: {
      id: 158,
      substanceWorkCondition: "Soot",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4783,
    hazzard: {
      id: 159,
      substanceWorkCondition: "Styrene",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4784,
    hazzard: {
      id: 159,
      substanceWorkCondition: "Styrene",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4785,
    hazzard: {
      id: 160,
      substanceWorkCondition: "Surface Cleanser (surfactant based), Detergents",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4786,
    hazzard: {
      id: 160,
      substanceWorkCondition: "Surface Cleanser (surfactant based), Detergents",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4787,
    hazzard: {
      id: 161,
      substanceWorkCondition: "Synthetic melting glaze",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4788,
    hazzard: {
      id: 161,
      substanceWorkCondition: "Synthetic melting glaze",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4789,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4790,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4791,
    hazzard: {
      id: 163,
      substanceWorkCondition: "Tanning agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4792,
    hazzard: {
      id: 163,
      substanceWorkCondition: "Tanning agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4793,
    hazzard: {
      id: 164,
      substanceWorkCondition: "Tar",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4794,
    hazzard: {
      id: 164,
      substanceWorkCondition: "Tar",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4795,
    hazzard: {
      id: 166,
      substanceWorkCondition: "Thinner",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4796,
    hazzard: {
      id: 166,
      substanceWorkCondition: "Thinner",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4797,
    hazzard: {
      id: 167,
      substanceWorkCondition: "Toluene",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4798,
    hazzard: {
      id: 167,
      substanceWorkCondition: "Toluene",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4799,
    hazzard: {
      id: 168,
      substanceWorkCondition: "Turpentine oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4800,
    hazzard: {
      id: 168,
      substanceWorkCondition: "Turpentine oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4801,
    hazzard: {
      id: 169,
      substanceWorkCondition: "UV-rays (A/B) / Sunlight",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4802,
    hazzard: {
      id: 169,
      substanceWorkCondition: "UV-rays (A/B) / Sunlight",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4803,
    hazzard: {
      id: 170,
      substanceWorkCondition: "Washing bath, washing liquid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4804,
    hazzard: {
      id: 170,
      substanceWorkCondition: "Washing bath, washing liquid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4805,
    hazzard: {
      id: 171,
      substanceWorkCondition: "Waste oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4806,
    hazzard: {
      id: 171,
      substanceWorkCondition: "Waste oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4807,
    hazzard: {
      id: 172,
      substanceWorkCondition: "Water / wet work",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4808,
    hazzard: {
      id: 172,
      substanceWorkCondition: "Water / wet work",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4809,
    hazzard: {
      id: 173,
      substanceWorkCondition:
        "Wearing protective clothing / Softening of the skin (Maceration)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4810,
    hazzard: {
      id: 173,
      substanceWorkCondition:
        "Wearing protective clothing / Softening of the skin (Maceration)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4811,
    hazzard: {
      id: 174,
      substanceWorkCondition: "wire drawing oil / wire drawing grease",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4812,
    hazzard: {
      id: 174,
      substanceWorkCondition: "wire drawing oil / wire drawing grease",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4813,
    hazzard: {
      id: 175,
      substanceWorkCondition: "Xylene",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4814,
    hazzard: {
      id: 175,
      substanceWorkCondition: "Xylene",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4815,
    hazzard: {
      id: 999,
      substanceWorkCondition: "All Products",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:44:03.000Z",
      updated_at: "2020-03-02T17:44:03.000Z"
    },
    step: "Cleanse",
    productOrder: 5,
    locationOrder: 4,
    skin_care_product: {
      id: 25,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:38:15.000Z",
      productName: "Refresh™ Hair & Body",
      productURL: "https://www.debgroup.com/us/products/refresh-hair-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: false,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4816,
    hazzard: {
      id: 999,
      substanceWorkCondition: "All Products",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:44:03.000Z",
      updated_at: "2020-03-02T17:44:03.000Z"
    },
    step: "Cleanse",
    productOrder: 6,
    locationOrder: 4,
    skin_care_product: {
      id: 26,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:38:51.000Z",
      productName: "Refresh™ Luxury 3-in-1 Hair & Body",
      productURL:
        "https://www.debgroup.com/us/products/refresh-luxury-3-1-hair-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: null,
    ecoLogo: false,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4817,
    hazzard: {
      id: 999,
      substanceWorkCondition: "All Products",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:44:03.000Z",
      updated_at: "2020-03-02T17:44:03.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Face",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4818,
    hazzard: {
      id: 1,
      substanceWorkCondition: "2-Butanone (MEK)",
      cas: "78-93-3",
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: "oil",
      created_at: "2020-03-02T17:39:32.000Z",
      updated_at: "2020-03-05T14:48:01.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4819,
    hazzard: {
      id: 1,
      substanceWorkCondition: "2-Butanone (MEK)",
      cas: "78-93-3",
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: "oil",
      created_at: "2020-03-02T17:39:32.000Z",
      updated_at: "2020-03-05T14:48:01.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4820,
    hazzard: {
      id: 1,
      substanceWorkCondition: "2-Butanone (MEK)",
      cas: "78-93-3",
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: "oil",
      created_at: "2020-03-02T17:39:32.000Z",
      updated_at: "2020-03-05T14:48:01.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4821,
    hazzard: {
      id: 1,
      substanceWorkCondition: "2-Butanone (MEK)",
      cas: "78-93-3",
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: "oil",
      created_at: "2020-03-02T17:39:32.000Z",
      updated_at: "2020-03-05T14:48:01.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4822,
    hazzard: {
      id: 1,
      substanceWorkCondition: "2-Butanone (MEK)",
      cas: "78-93-3",
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: "oil",
      created_at: "2020-03-02T17:39:32.000Z",
      updated_at: "2020-03-05T14:48:01.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4823,
    hazzard: {
      id: 2,
      substanceWorkCondition: "Acetone / Dimethyl ketone",
      cas: "67-64-1",
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4824,
    hazzard: {
      id: 2,
      substanceWorkCondition: "Acetone / Dimethyl ketone",
      cas: "67-64-1",
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4825,
    hazzard: {
      id: 2,
      substanceWorkCondition: "Acetone / Dimethyl ketone",
      cas: "67-64-1",
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4826,
    hazzard: {
      id: 2,
      substanceWorkCondition: "Acetone / Dimethyl ketone",
      cas: "67-64-1",
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4827,
    hazzard: {
      id: 3,
      substanceWorkCondition: "Acids, diluted",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4828,
    hazzard: {
      id: 3,
      substanceWorkCondition: "Acids, diluted",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4829,
    hazzard: {
      id: 3,
      substanceWorkCondition: "Acids, diluted",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4830,
    hazzard: {
      id: 3,
      substanceWorkCondition: "Acids, diluted",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4831,
    hazzard: {
      id: 4,
      substanceWorkCondition: "Acrylates",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4832,
    hazzard: {
      id: 4,
      substanceWorkCondition: "Acrylates",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4833,
    hazzard: {
      id: 4,
      substanceWorkCondition: "Acrylates",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4834,
    hazzard: {
      id: 4,
      substanceWorkCondition: "Acrylates",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4835,
    hazzard: {
      id: 5,
      substanceWorkCondition: "Adhesive single-component",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4836,
    hazzard: {
      id: 5,
      substanceWorkCondition: "Adhesive single-component",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4837,
    hazzard: {
      id: 5,
      substanceWorkCondition: "Adhesive single-component",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4838,
    hazzard: {
      id: 5,
      substanceWorkCondition: "Adhesive single-component",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4839,
    hazzard: {
      id: 6,
      substanceWorkCondition: "Adhesive, water-soluble",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4840,
    hazzard: {
      id: 6,
      substanceWorkCondition: "Adhesive, water-soluble",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4841,
    hazzard: {
      id: 6,
      substanceWorkCondition: "Adhesive, water-soluble",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4842,
    hazzard: {
      id: 6,
      substanceWorkCondition: "Adhesive, water-soluble",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4843,
    hazzard: {
      id: 7,
      substanceWorkCondition: "Adhesives, glues based on synthetic resin",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4844,
    hazzard: {
      id: 7,
      substanceWorkCondition: "Adhesives, glues based on synthetic resin",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4845,
    hazzard: {
      id: 7,
      substanceWorkCondition: "Adhesives, glues based on synthetic resin",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4846,
    hazzard: {
      id: 7,
      substanceWorkCondition: "Adhesives, glues based on synthetic resin",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4847,
    hazzard: {
      id: 8,
      substanceWorkCondition: "Aldehydes, diluted",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4848,
    hazzard: {
      id: 8,
      substanceWorkCondition: "Aldehydes, diluted",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4849,
    hazzard: {
      id: 8,
      substanceWorkCondition: "Aldehydes, diluted",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4850,
    hazzard: {
      id: 8,
      substanceWorkCondition: "Aldehydes, diluted",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4851,
    hazzard: {
      id: 9,
      substanceWorkCondition: "Alkaline solutions / lyes / thinned caustics",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4852,
    hazzard: {
      id: 9,
      substanceWorkCondition: "Alkaline solutions / lyes / thinned caustics",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4853,
    hazzard: {
      id: 9,
      substanceWorkCondition: "Alkaline solutions / lyes / thinned caustics",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4854,
    hazzard: {
      id: 9,
      substanceWorkCondition: "Alkaline solutions / lyes / thinned caustics",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4855,
    hazzard: {
      id: 10,
      substanceWorkCondition: "Alkaline solutions, diluted",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4856,
    hazzard: {
      id: 10,
      substanceWorkCondition: "Alkaline solutions, diluted",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4857,
    hazzard: {
      id: 10,
      substanceWorkCondition: "Alkaline solutions, diluted",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4858,
    hazzard: {
      id: 10,
      substanceWorkCondition: "Alkaline solutions, diluted",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4859,
    hazzard: {
      id: 11,
      substanceWorkCondition: "Alkaline solutions, lyes (solvent based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4860,
    hazzard: {
      id: 11,
      substanceWorkCondition: "Alkaline solutions, lyes (solvent based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4861,
    hazzard: {
      id: 11,
      substanceWorkCondition: "Alkaline solutions, lyes (solvent based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4862,
    hazzard: {
      id: 11,
      substanceWorkCondition: "Alkaline solutions, lyes (solvent based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4863,
    hazzard: {
      id: 12,
      substanceWorkCondition: "Aluminium",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4864,
    hazzard: {
      id: 12,
      substanceWorkCondition: "Aluminium",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4865,
    hazzard: {
      id: 12,
      substanceWorkCondition: "Aluminium",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4866,
    hazzard: {
      id: 12,
      substanceWorkCondition: "Aluminium",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4867,
    hazzard: {
      id: 14,
      substanceWorkCondition: "Antistatics",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4868,
    hazzard: {
      id: 14,
      substanceWorkCondition: "Antistatics",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4869,
    hazzard: {
      id: 14,
      substanceWorkCondition: "Antistatics",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4870,
    hazzard: {
      id: 14,
      substanceWorkCondition: "Antistatics",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4871,
    hazzard: {
      id: 15,
      substanceWorkCondition: "Asphalt",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4872,
    hazzard: {
      id: 15,
      substanceWorkCondition: "Asphalt",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4873,
    hazzard: {
      id: 15,
      substanceWorkCondition: "Asphalt",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4874,
    hazzard: {
      id: 15,
      substanceWorkCondition: "Asphalt",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4875,
    hazzard: {
      id: 18,
      substanceWorkCondition:
        "Automotive Aftermarket - Public Transport Maintenance&Repair",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4876,
    hazzard: {
      id: 18,
      substanceWorkCondition:
        "Automotive Aftermarket - Public Transport Maintenance&Repair",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4877,
    hazzard: {
      id: 19,
      substanceWorkCondition: "Battery acid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4878,
    hazzard: {
      id: 19,
      substanceWorkCondition: "Battery acid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4879,
    hazzard: {
      id: 19,
      substanceWorkCondition: "Battery acid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4880,
    hazzard: {
      id: 19,
      substanceWorkCondition: "Battery acid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4881,
    hazzard: {
      id: 20,
      substanceWorkCondition: "Benzene",
      cas: null,
      glovesRecommended: true,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4882,
    hazzard: {
      id: 20,
      substanceWorkCondition: "Benzene",
      cas: null,
      glovesRecommended: true,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4883,
    hazzard: {
      id: 20,
      substanceWorkCondition: "Benzene",
      cas: null,
      glovesRecommended: true,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4884,
    hazzard: {
      id: 20,
      substanceWorkCondition: "Benzene",
      cas: null,
      glovesRecommended: true,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4885,
    hazzard: {
      id: 22,
      substanceWorkCondition: "Bitumen emulsion",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4886,
    hazzard: {
      id: 22,
      substanceWorkCondition: "Bitumen emulsion",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4887,
    hazzard: {
      id: 22,
      substanceWorkCondition: "Bitumen emulsion",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4888,
    hazzard: {
      id: 22,
      substanceWorkCondition: "Bitumen emulsion",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4889,
    hazzard: {
      id: 21,
      substanceWorkCondition: "Bitumen",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4890,
    hazzard: {
      id: 21,
      substanceWorkCondition: "Bitumen",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4891,
    hazzard: {
      id: 21,
      substanceWorkCondition: "Bitumen",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4892,
    hazzard: {
      id: 21,
      substanceWorkCondition: "Bitumen",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4893,
    hazzard: {
      id: 23,
      substanceWorkCondition: "Bleaching agent (lye)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4894,
    hazzard: {
      id: 23,
      substanceWorkCondition: "Bleaching agent (lye)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4895,
    hazzard: {
      id: 23,
      substanceWorkCondition: "Bleaching agent (lye)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4896,
    hazzard: {
      id: 23,
      substanceWorkCondition: "Bleaching agent (lye)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4897,
    hazzard: {
      id: 24,
      substanceWorkCondition: "Brake fluid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4898,
    hazzard: {
      id: 24,
      substanceWorkCondition: "Brake fluid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4899,
    hazzard: {
      id: 24,
      substanceWorkCondition: "Brake fluid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4900,
    hazzard: {
      id: 24,
      substanceWorkCondition: "Brake fluid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4901,
    hazzard: {
      id: 25,
      substanceWorkCondition: "Casting dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4902,
    hazzard: {
      id: 25,
      substanceWorkCondition: "Casting dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:05.000Z",
    updated_at: "2020-03-02T22:50:05.000Z"
  },
  {
    id: 4903,
    hazzard: {
      id: 25,
      substanceWorkCondition: "Casting dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 4904,
    hazzard: {
      id: 25,
      substanceWorkCondition: "Casting dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 4905,
    hazzard: {
      id: 26,
      substanceWorkCondition: "Cement (dry)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 4906,
    hazzard: {
      id: 26,
      substanceWorkCondition: "Cement (dry)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 4907,
    hazzard: {
      id: 26,
      substanceWorkCondition: "Cement (dry)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 4908,
    hazzard: {
      id: 26,
      substanceWorkCondition: "Cement (dry)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 4909,
    hazzard: {
      id: 27,
      substanceWorkCondition: "Cement (wet)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 4910,
    hazzard: {
      id: 27,
      substanceWorkCondition: "Cement (wet)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 4911,
    hazzard: {
      id: 27,
      substanceWorkCondition: "Cement (wet)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 4912,
    hazzard: {
      id: 27,
      substanceWorkCondition: "Cement (wet)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 4913,
    hazzard: {
      id: 28,
      substanceWorkCondition: "Cement, putty epoxy-based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 4914,
    hazzard: {
      id: 28,
      substanceWorkCondition: "Cement, putty epoxy-based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 4915,
    hazzard: {
      id: 28,
      substanceWorkCondition: "Cement, putty epoxy-based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 4916,
    hazzard: {
      id: 28,
      substanceWorkCondition: "Cement, putty epoxy-based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 4917,
    hazzard: {
      id: 29,
      substanceWorkCondition:
        "Chromates (water- insoluble) = Chromium pigments",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 4918,
    hazzard: {
      id: 29,
      substanceWorkCondition:
        "Chromates (water- insoluble) = Chromium pigments",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 4919,
    hazzard: {
      id: 29,
      substanceWorkCondition:
        "Chromates (water- insoluble) = Chromium pigments",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 4920,
    hazzard: {
      id: 29,
      substanceWorkCondition:
        "Chromates (water- insoluble) = Chromium pigments",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 4921,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 4922,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 4923,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 4924,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 4925,
    hazzard: {
      id: 31,
      substanceWorkCondition: "cleaning agent, detergent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 4926,
    hazzard: {
      id: 31,
      substanceWorkCondition: "cleaning agent, detergent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 4927,
    hazzard: {
      id: 31,
      substanceWorkCondition: "cleaning agent, detergent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 4928,
    hazzard: {
      id: 31,
      substanceWorkCondition: "cleaning agent, detergent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 4929,
    hazzard: {
      id: 32,
      substanceWorkCondition: "Coal dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 4930,
    hazzard: {
      id: 32,
      substanceWorkCondition: "Coal dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 4931,
    hazzard: {
      id: 32,
      substanceWorkCondition: "Coal dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 4932,
    hazzard: {
      id: 32,
      substanceWorkCondition: "Coal dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 4933,
    hazzard: {
      id: 33,
      substanceWorkCondition: "Coating materials, bituminous (bitumen varnish)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 4934,
    hazzard: {
      id: 33,
      substanceWorkCondition: "Coating materials, bituminous (bitumen varnish)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 4935,
    hazzard: {
      id: 33,
      substanceWorkCondition: "Coating materials, bituminous (bitumen varnish)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 4936,
    hazzard: {
      id: 33,
      substanceWorkCondition: "Coating materials, bituminous (bitumen varnish)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 4937,
    hazzard: {
      id: 34,
      substanceWorkCondition: "Coating materials, emulsified",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 4938,
    hazzard: {
      id: 34,
      substanceWorkCondition: "Coating materials, emulsified",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 4939,
    hazzard: {
      id: 34,
      substanceWorkCondition: "Coating materials, emulsified",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 4940,
    hazzard: {
      id: 34,
      substanceWorkCondition: "Coating materials, emulsified",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 4941,
    hazzard: {
      id: 35,
      substanceWorkCondition: "Coating materials, oily",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 4942,
    hazzard: {
      id: 35,
      substanceWorkCondition: "Coating materials, oily",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 4943,
    hazzard: {
      id: 35,
      substanceWorkCondition: "Coating materials, oily",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 4944,
    hazzard: {
      id: 35,
      substanceWorkCondition: "Coating materials, oily",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 4945,
    hazzard: {
      id: 36,
      substanceWorkCondition: "Cold degreasing agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 4946,
    hazzard: {
      id: 36,
      substanceWorkCondition: "Cold degreasing agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 4947,
    hazzard: {
      id: 36,
      substanceWorkCondition: "Cold degreasing agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 4948,
    hazzard: {
      id: 36,
      substanceWorkCondition: "Cold degreasing agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 4949,
    hazzard: {
      id: 37,
      substanceWorkCondition: "cold detergent, solvent based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 4950,
    hazzard: {
      id: 37,
      substanceWorkCondition: "cold detergent, solvent based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 4951,
    hazzard: {
      id: 37,
      substanceWorkCondition: "cold detergent, solvent based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 4952,
    hazzard: {
      id: 37,
      substanceWorkCondition: "cold detergent, solvent based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 4953,
    hazzard: {
      id: 38,
      substanceWorkCondition: "Cold working conditions",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 4954,
    hazzard: {
      id: 38,
      substanceWorkCondition: "Cold working conditions",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 4955,
    hazzard: {
      id: 38,
      substanceWorkCondition: "Cold working conditions",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 4956,
    hazzard: {
      id: 38,
      substanceWorkCondition: "Cold working conditions",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 4957,
    hazzard: {
      id: 40,
      substanceWorkCondition: "Commercial - Cleaning & Maintenance",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 4958,
    hazzard: {
      id: 40,
      substanceWorkCondition: "Commercial - Cleaning & Maintenance",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 4959,
    hazzard: {
      id: 41,
      substanceWorkCondition: "Commercial - Commerce & Office",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 4960,
    hazzard: {
      id: 41,
      substanceWorkCondition: "Commercial - Commerce & Office",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 4961,
    hazzard: {
      id: 42,
      substanceWorkCondition: "Commercial - Education",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 4962,
    hazzard: {
      id: 42,
      substanceWorkCondition: "Commercial - Education",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 4963,
    hazzard: {
      id: 43,
      substanceWorkCondition: "Commercial - Hotel & Leisure",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 4964,
    hazzard: {
      id: 43,
      substanceWorkCondition: "Commercial - Hotel & Leisure",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 4965,
    hazzard: {
      id: 44,
      substanceWorkCondition: "Commercial - Prisons & Security Services",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 4966,
    hazzard: {
      id: 44,
      substanceWorkCondition: "Commercial - Prisons & Security Services",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 4967,
    hazzard: {
      id: 47,
      substanceWorkCondition: "Concrete",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 4968,
    hazzard: {
      id: 47,
      substanceWorkCondition: "Concrete",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 4969,
    hazzard: {
      id: 47,
      substanceWorkCondition: "Concrete",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 4970,
    hazzard: {
      id: 47,
      substanceWorkCondition: "Concrete",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 4971,
    hazzard: {
      id: 48,
      substanceWorkCondition: "Cooling lubricant (non-water-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 4972,
    hazzard: {
      id: 48,
      substanceWorkCondition: "Cooling lubricant (non-water-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 4973,
    hazzard: {
      id: 48,
      substanceWorkCondition: "Cooling lubricant (non-water-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 4974,
    hazzard: {
      id: 48,
      substanceWorkCondition: "Cooling lubricant (non-water-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 4975,
    hazzard: {
      id: 49,
      substanceWorkCondition: "Cooling lubricant (water- based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 4976,
    hazzard: {
      id: 49,
      substanceWorkCondition: "Cooling lubricant (water- based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 4977,
    hazzard: {
      id: 49,
      substanceWorkCondition: "Cooling lubricant (water- based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 4978,
    hazzard: {
      id: 49,
      substanceWorkCondition: "Cooling lubricant (water- based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 4979,
    hazzard: {
      id: 50,
      substanceWorkCondition: "Cooling water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 4980,
    hazzard: {
      id: 50,
      substanceWorkCondition: "Cooling water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 4981,
    hazzard: {
      id: 50,
      substanceWorkCondition: "Cooling water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 4982,
    hazzard: {
      id: 50,
      substanceWorkCondition: "Cooling water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 4983,
    hazzard: {
      id: 51,
      substanceWorkCondition: "Copying ink",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 4984,
    hazzard: {
      id: 51,
      substanceWorkCondition: "Copying ink",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 4985,
    hazzard: {
      id: 51,
      substanceWorkCondition: "Copying ink",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 4986,
    hazzard: {
      id: 51,
      substanceWorkCondition: "Copying ink",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 4987,
    hazzard: {
      id: 52,
      substanceWorkCondition: "Cutting oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 4988,
    hazzard: {
      id: 52,
      substanceWorkCondition: "Cutting oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 4989,
    hazzard: {
      id: 52,
      substanceWorkCondition: "Cutting oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 4990,
    hazzard: {
      id: 52,
      substanceWorkCondition: "Cutting oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 4991,
    hazzard: {
      id: 53,
      substanceWorkCondition: "Degreaser solvent - based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 4992,
    hazzard: {
      id: 53,
      substanceWorkCondition: "Degreaser solvent - based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 4993,
    hazzard: {
      id: 53,
      substanceWorkCondition: "Degreaser solvent - based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 4994,
    hazzard: {
      id: 53,
      substanceWorkCondition: "Degreaser solvent - based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 4995,
    hazzard: {
      id: 54,
      substanceWorkCondition: "Degreaser without organic solvent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 4996,
    hazzard: {
      id: 54,
      substanceWorkCondition: "Degreaser without organic solvent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 4997,
    hazzard: {
      id: 54,
      substanceWorkCondition: "Degreaser without organic solvent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 4998,
    hazzard: {
      id: 54,
      substanceWorkCondition: "Degreaser without organic solvent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 4999,
    hazzard: {
      id: 55,
      substanceWorkCondition: "Detachment solution for concrete",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 5000,
    hazzard: {
      id: 55,
      substanceWorkCondition: "Detachment solution for concrete",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 5001,
    hazzard: {
      id: 55,
      substanceWorkCondition: "Detachment solution for concrete",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 5002,
    hazzard: {
      id: 55,
      substanceWorkCondition: "Detachment solution for concrete",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 5003,
    hazzard: {
      id: 56,
      substanceWorkCondition: "Detachments (alcohol-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 5004,
    hazzard: {
      id: 56,
      substanceWorkCondition: "Detachments (alcohol-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 5005,
    hazzard: {
      id: 56,
      substanceWorkCondition: "Detachments (alcohol-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 5006,
    hazzard: {
      id: 56,
      substanceWorkCondition: "Detachments (alcohol-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 5007,
    hazzard: {
      id: 57,
      substanceWorkCondition: "Detachments (solvent-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 5008,
    hazzard: {
      id: 57,
      substanceWorkCondition: "Detachments (solvent-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 5009,
    hazzard: {
      id: 57,
      substanceWorkCondition: "Detachments (solvent-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 5010,
    hazzard: {
      id: 57,
      substanceWorkCondition: "Detachments (solvent-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 5011,
    hazzard: {
      id: 58,
      substanceWorkCondition: "Detachments (sour)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 5012,
    hazzard: {
      id: 58,
      substanceWorkCondition: "Detachments (sour)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 5013,
    hazzard: {
      id: 58,
      substanceWorkCondition: "Detachments (sour)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 5014,
    hazzard: {
      id: 58,
      substanceWorkCondition: "Detachments (sour)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 5015,
    hazzard: {
      id: 59,
      substanceWorkCondition: "Disinfectants (alcohol-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 5016,
    hazzard: {
      id: 59,
      substanceWorkCondition: "Disinfectants (alcohol-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 5017,
    hazzard: {
      id: 59,
      substanceWorkCondition: "Disinfectants (alcohol-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 5018,
    hazzard: {
      id: 59,
      substanceWorkCondition: "Disinfectants (alcohol-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 5019,
    hazzard: {
      id: 60,
      substanceWorkCondition: "Disinfectants (formaldehyd-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 5020,
    hazzard: {
      id: 60,
      substanceWorkCondition: "Disinfectants (formaldehyd-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 5021,
    hazzard: {
      id: 60,
      substanceWorkCondition: "Disinfectants (formaldehyd-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 5022,
    hazzard: {
      id: 60,
      substanceWorkCondition: "Disinfectants (formaldehyd-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 5023,
    hazzard: {
      id: 61,
      substanceWorkCondition: "Disinfectants (water-based) ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 5024,
    hazzard: {
      id: 61,
      substanceWorkCondition: "Disinfectants (water-based) ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 5025,
    hazzard: {
      id: 61,
      substanceWorkCondition: "Disinfectants (water-based) ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 5026,
    hazzard: {
      id: 61,
      substanceWorkCondition: "Disinfectants (water-based) ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 5027,
    hazzard: {
      id: 62,
      substanceWorkCondition: "Dispersing Agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 5028,
    hazzard: {
      id: 62,
      substanceWorkCondition: "Dispersing Agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 5029,
    hazzard: {
      id: 62,
      substanceWorkCondition: "Dispersing Agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 5030,
    hazzard: {
      id: 62,
      substanceWorkCondition: "Dispersing Agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 5031,
    hazzard: {
      id: 63,
      substanceWorkCondition: "Drilling emulsion (water based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 5032,
    hazzard: {
      id: 63,
      substanceWorkCondition: "Drilling emulsion (water based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 5033,
    hazzard: {
      id: 63,
      substanceWorkCondition: "Drilling emulsion (water based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 5034,
    hazzard: {
      id: 63,
      substanceWorkCondition: "Drilling emulsion (water based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 5035,
    hazzard: {
      id: 64,
      substanceWorkCondition: "Drilling oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 5036,
    hazzard: {
      id: 64,
      substanceWorkCondition: "Drilling oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 5037,
    hazzard: {
      id: 64,
      substanceWorkCondition: "Drilling oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 5038,
    hazzard: {
      id: 64,
      substanceWorkCondition: "Drilling oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 5039,
    hazzard: {
      id: 65,
      substanceWorkCondition: "Dryness",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 5040,
    hazzard: {
      id: 65,
      substanceWorkCondition: "Dryness",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 5041,
    hazzard: {
      id: 65,
      substanceWorkCondition: "Dryness",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:06.000Z",
    updated_at: "2020-03-02T22:50:06.000Z"
  },
  {
    id: 5042,
    hazzard: {
      id: 65,
      substanceWorkCondition: "Dryness",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5043,
    hazzard: {
      id: 66,
      substanceWorkCondition: "Dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5044,
    hazzard: {
      id: 66,
      substanceWorkCondition: "Dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5045,
    hazzard: {
      id: 66,
      substanceWorkCondition: "Dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5046,
    hazzard: {
      id: 66,
      substanceWorkCondition: "Dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5047,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5048,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5049,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5050,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5051,
    hazzard: {
      id: 70,
      substanceWorkCondition: "Epoxy resin",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5052,
    hazzard: {
      id: 70,
      substanceWorkCondition: "Epoxy resin",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5053,
    hazzard: {
      id: 70,
      substanceWorkCondition: "Epoxy resin",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5054,
    hazzard: {
      id: 70,
      substanceWorkCondition: "Epoxy resin",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5055,
    hazzard: {
      id: 71,
      substanceWorkCondition: "Etching (mordant)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5056,
    hazzard: {
      id: 71,
      substanceWorkCondition: "Etching (mordant)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5057,
    hazzard: {
      id: 71,
      substanceWorkCondition: "Etching (mordant)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5058,
    hazzard: {
      id: 71,
      substanceWorkCondition: "Etching (mordant)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5059,
    hazzard: {
      id: 72,
      substanceWorkCondition: "Ethanol / methylated spirits ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5060,
    hazzard: {
      id: 72,
      substanceWorkCondition: "Ethanol / methylated spirits ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5061,
    hazzard: {
      id: 72,
      substanceWorkCondition: "Ethanol / methylated spirits ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5062,
    hazzard: {
      id: 72,
      substanceWorkCondition: "Ethanol / methylated spirits ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5063,
    hazzard: {
      id: 73,
      substanceWorkCondition: "Fat",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5064,
    hazzard: {
      id: 73,
      substanceWorkCondition: "Fat",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5065,
    hazzard: {
      id: 73,
      substanceWorkCondition: "Fat",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5066,
    hazzard: {
      id: 73,
      substanceWorkCondition: "Fat",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5067,
    hazzard: {
      id: 74,
      substanceWorkCondition: "Fertilizer",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5068,
    hazzard: {
      id: 74,
      substanceWorkCondition: "Fertilizer",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5069,
    hazzard: {
      id: 74,
      substanceWorkCondition: "Fertilizer",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5070,
    hazzard: {
      id: 74,
      substanceWorkCondition: "Fertilizer",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5071,
    hazzard: {
      id: 75,
      substanceWorkCondition: "fine anorganic/inorganic powders ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5072,
    hazzard: {
      id: 75,
      substanceWorkCondition: "fine anorganic/inorganic powders ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5073,
    hazzard: {
      id: 75,
      substanceWorkCondition: "fine anorganic/inorganic powders ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5074,
    hazzard: {
      id: 75,
      substanceWorkCondition: "fine anorganic/inorganic powders ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5075,
    hazzard: {
      id: 76,
      substanceWorkCondition: "Floor cleaner (water- based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5076,
    hazzard: {
      id: 76,
      substanceWorkCondition: "Floor cleaner (water- based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5077,
    hazzard: {
      id: 76,
      substanceWorkCondition: "Floor cleaner (water- based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5078,
    hazzard: {
      id: 76,
      substanceWorkCondition: "Floor cleaner (water- based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5079,
    hazzard: {
      id: 77,
      substanceWorkCondition: "Food - Food Packaging",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5080,
    hazzard: {
      id: 78,
      substanceWorkCondition: "Food - Food Processing & Manufacturing",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5081,
    hazzard: {
      id: 79,
      substanceWorkCondition: "Food - Food Services & Catering",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5082,
    hazzard: {
      id: 80,
      substanceWorkCondition: "Food Dyestuff",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5083,
    hazzard: {
      id: 81,
      substanceWorkCondition: "fuel: gas / diesel",
      cas: null,
      glovesRecommended: true,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5084,
    hazzard: {
      id: 81,
      substanceWorkCondition: "fuel: gas / diesel",
      cas: null,
      glovesRecommended: true,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5085,
    hazzard: {
      id: 81,
      substanceWorkCondition: "fuel: gas / diesel",
      cas: null,
      glovesRecommended: true,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5086,
    hazzard: {
      id: 81,
      substanceWorkCondition: "fuel: gas / diesel",
      cas: null,
      glovesRecommended: true,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5087,
    hazzard: {
      id: 82,
      substanceWorkCondition: "Galvanic bath",
      cas: null,
      glovesRecommended: true,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5088,
    hazzard: {
      id: 82,
      substanceWorkCondition: "Galvanic bath",
      cas: null,
      glovesRecommended: true,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5089,
    hazzard: {
      id: 82,
      substanceWorkCondition: "Galvanic bath",
      cas: null,
      glovesRecommended: true,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5090,
    hazzard: {
      id: 82,
      substanceWorkCondition: "Galvanic bath",
      cas: null,
      glovesRecommended: true,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5091,
    hazzard: {
      id: 83,
      substanceWorkCondition: "Glass fibre dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5092,
    hazzard: {
      id: 83,
      substanceWorkCondition: "Glass fibre dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5093,
    hazzard: {
      id: 83,
      substanceWorkCondition: "Glass fibre dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5094,
    hazzard: {
      id: 83,
      substanceWorkCondition: "Glass fibre dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5095,
    hazzard: {
      id: 84,
      substanceWorkCondition: "Glass wool dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5096,
    hazzard: {
      id: 84,
      substanceWorkCondition: "Glass wool dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5097,
    hazzard: {
      id: 84,
      substanceWorkCondition: "Glass wool dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5098,
    hazzard: {
      id: 84,
      substanceWorkCondition: "Glass wool dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5099,
    hazzard: {
      id: 85,
      substanceWorkCondition: "Glycols",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5100,
    hazzard: {
      id: 85,
      substanceWorkCondition: "Glycols",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5101,
    hazzard: {
      id: 85,
      substanceWorkCondition: "Glycols",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5102,
    hazzard: {
      id: 85,
      substanceWorkCondition: "Glycols",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5103,
    hazzard: {
      id: 86,
      substanceWorkCondition: "Graphite",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5104,
    hazzard: {
      id: 86,
      substanceWorkCondition: "Graphite",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5105,
    hazzard: {
      id: 86,
      substanceWorkCondition: "Graphite",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5106,
    hazzard: {
      id: 86,
      substanceWorkCondition: "Graphite",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5107,
    hazzard: {
      id: 87,
      substanceWorkCondition: "Grease",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5108,
    hazzard: {
      id: 87,
      substanceWorkCondition: "Grease",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5109,
    hazzard: {
      id: 87,
      substanceWorkCondition: "Grease",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5110,
    hazzard: {
      id: 87,
      substanceWorkCondition: "Grease",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5111,
    hazzard: {
      id: 88,
      substanceWorkCondition: "Grinding oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5112,
    hazzard: {
      id: 88,
      substanceWorkCondition: "Grinding oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5113,
    hazzard: {
      id: 88,
      substanceWorkCondition: "Grinding oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5114,
    hazzard: {
      id: 88,
      substanceWorkCondition: "Grinding oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5115,
    hazzard: {
      id: 89,
      substanceWorkCondition: "Grinding water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5116,
    hazzard: {
      id: 89,
      substanceWorkCondition: "Grinding water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5117,
    hazzard: {
      id: 89,
      substanceWorkCondition: "Grinding water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5118,
    hazzard: {
      id: 89,
      substanceWorkCondition: "Grinding water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5119,
    hazzard: {
      id: 90,
      substanceWorkCondition: "Gypsum",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5120,
    hazzard: {
      id: 90,
      substanceWorkCondition: "Gypsum",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5121,
    hazzard: {
      id: 90,
      substanceWorkCondition: "Gypsum",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5122,
    hazzard: {
      id: 90,
      substanceWorkCondition: "Gypsum",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5123,
    hazzard: {
      id: 91,
      substanceWorkCondition: "Healthcare - Care Homes",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5124,
    hazzard: {
      id: 91,
      substanceWorkCondition: "Healthcare - Care Homes",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5125,
    hazzard: {
      id: 92,
      substanceWorkCondition:
        "Healthcare - Medical Centers/Surgeries/General Practitioners",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5126,
    hazzard: {
      id: 92,
      substanceWorkCondition:
        "Healthcare - Medical Centers/Surgeries/General Practitioners",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5127,
    hazzard: {
      id: 93,
      substanceWorkCondition: "Healthcare - Nursing Homes",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5128,
    hazzard: {
      id: 93,
      substanceWorkCondition: "Healthcare - Nursing Homes",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5129,
    hazzard: {
      id: 94,
      substanceWorkCondition: "Healthcare - Veterinary Services",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5130,
    hazzard: {
      id: 94,
      substanceWorkCondition: "Healthcare - Veterinary Services",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5131,
    hazzard: {
      id: 95,
      substanceWorkCondition: "Hydraulic fluid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5132,
    hazzard: {
      id: 95,
      substanceWorkCondition: "Hydraulic fluid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5133,
    hazzard: {
      id: 95,
      substanceWorkCondition: "Hydraulic fluid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5134,
    hazzard: {
      id: 95,
      substanceWorkCondition: "Hydraulic fluid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5135,
    hazzard: {
      id: 96,
      substanceWorkCondition: "Hyrocarbons",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5136,
    hazzard: {
      id: 96,
      substanceWorkCondition: "Hyrocarbons",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5137,
    hazzard: {
      id: 96,
      substanceWorkCondition: "Hyrocarbons",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5138,
    hazzard: {
      id: 96,
      substanceWorkCondition: "Hyrocarbons",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5139,
    hazzard: {
      id: 97,
      substanceWorkCondition: "Industry Heavy - Building Materials",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5140,
    hazzard: {
      id: 97,
      substanceWorkCondition: "Industry Heavy - Building Materials",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5141,
    hazzard: {
      id: 98,
      substanceWorkCondition: "Industry Heavy - Construction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5142,
    hazzard: {
      id: 98,
      substanceWorkCondition: "Industry Heavy - Construction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5143,
    hazzard: {
      id: 99,
      substanceWorkCondition: "Industry Heavy - Mining & Extraction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5144,
    hazzard: {
      id: 99,
      substanceWorkCondition: "Industry Heavy - Mining & Extraction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5145,
    hazzard: {
      id: 100,
      substanceWorkCondition: "Industry Heavy - Off-Shore Extraction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5146,
    hazzard: {
      id: 100,
      substanceWorkCondition: "Industry Heavy - Off-Shore Extraction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5147,
    hazzard: {
      id: 101,
      substanceWorkCondition: "Industry Heavy - Printing Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5148,
    hazzard: {
      id: 101,
      substanceWorkCondition: "Industry Heavy - Printing Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5149,
    hazzard: {
      id: 102,
      substanceWorkCondition:
        "Industry Heavy - Road & Transportation Construction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5150,
    hazzard: {
      id: 102,
      substanceWorkCondition:
        "Industry Heavy - Road & Transportation Construction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5151,
    hazzard: {
      id: 103,
      substanceWorkCondition: "Industry Heavy - Ship & Boat Building",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5152,
    hazzard: {
      id: 103,
      substanceWorkCondition: "Industry Heavy - Ship & Boat Building",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5153,
    hazzard: {
      id: 104,
      substanceWorkCondition: "Industry Heavy - Shipping & Marine",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5154,
    hazzard: {
      id: 104,
      substanceWorkCondition: "Industry Heavy - Shipping & Marine",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5155,
    hazzard: {
      id: 105,
      substanceWorkCondition:
        "Industry Heavy - Steel, Engineering & Heavy Manufacturing",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5156,
    hazzard: {
      id: 105,
      substanceWorkCondition:
        "Industry Heavy - Steel, Engineering & Heavy Manufacturing",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5157,
    hazzard: {
      id: 106,
      substanceWorkCondition:
        "Industry Light - Airports & Transportation Terminals - Services & Facility Maintenance",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5158,
    hazzard: {
      id: 106,
      substanceWorkCondition:
        "Industry Light - Airports & Transportation Terminals - Services & Facility Maintenance",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5159,
    hazzard: {
      id: 106,
      substanceWorkCondition:
        "Industry Light - Airports & Transportation Terminals - Services & Facility Maintenance",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5160,
    hazzard: {
      id: 107,
      substanceWorkCondition: "Industry Light - Emergency Services",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5161,
    hazzard: {
      id: 107,
      substanceWorkCondition: "Industry Light - Emergency Services",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5162,
    hazzard: {
      id: 107,
      substanceWorkCondition: "Industry Light - Emergency Services",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5163,
    hazzard: {
      id: 108,
      substanceWorkCondition:
        "Industry Light - General Manufacturing & Assembly",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5164,
    hazzard: {
      id: 108,
      substanceWorkCondition:
        "Industry Light - General Manufacturing & Assembly",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5165,
    hazzard: {
      id: 108,
      substanceWorkCondition:
        "Industry Light - General Manufacturing & Assembly",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5166,
    hazzard: {
      id: 109,
      substanceWorkCondition:
        "Industry Light - Hosiery, Knitware, Fabric & Clothing Manufacturing",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5167,
    hazzard: {
      id: 109,
      substanceWorkCondition:
        "Industry Light - Hosiery, Knitware, Fabric & Clothing Manufacturing",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5168,
    hazzard: {
      id: 109,
      substanceWorkCondition:
        "Industry Light - Hosiery, Knitware, Fabric & Clothing Manufacturing",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5169,
    hazzard: {
      id: 110,
      substanceWorkCondition: "Industry Light - Science & Laboratories",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5170,
    hazzard: {
      id: 110,
      substanceWorkCondition: "Industry Light - Science & Laboratories",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5171,
    hazzard: {
      id: 110,
      substanceWorkCondition: "Industry Light - Science & Laboratories",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5172,
    hazzard: {
      id: 111,
      substanceWorkCondition: "Industry Light - Warehousing & Logistics",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5173,
    hazzard: {
      id: 111,
      substanceWorkCondition: "Industry Light - Warehousing & Logistics",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5174,
    hazzard: {
      id: 111,
      substanceWorkCondition: "Industry Light - Warehousing & Logistics",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5175,
    hazzard: {
      id: 112,
      substanceWorkCondition: "Industry Medium - Ceramics & Glassware",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5176,
    hazzard: {
      id: 112,
      substanceWorkCondition: "Industry Medium - Ceramics & Glassware",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5177,
    hazzard: {
      id: 113,
      substanceWorkCondition: "Industry Medium - Chemical Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5178,
    hazzard: {
      id: 113,
      substanceWorkCondition: "Industry Medium - Chemical Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5179,
    hazzard: {
      id: 114,
      substanceWorkCondition: "Industry Medium - Electronics Industries",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5180,
    hazzard: {
      id: 114,
      substanceWorkCondition: "Industry Medium - Electronics Industries",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5181,
    hazzard: {
      id: 115,
      substanceWorkCondition: "Industry Medium - Metal Working & Fabrication",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5182,
    hazzard: {
      id: 115,
      substanceWorkCondition: "Industry Medium - Metal Working & Fabrication",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5183,
    hazzard: {
      id: 116,
      substanceWorkCondition: "Industry Medium - Outdoor Maintenance & Repair",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5184,
    hazzard: {
      id: 116,
      substanceWorkCondition: "Industry Medium - Outdoor Maintenance & Repair",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5185,
    hazzard: {
      id: 117,
      substanceWorkCondition: "Industry Medium - Pharmaceutical Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:07.000Z",
    updated_at: "2020-03-02T22:50:07.000Z"
  },
  {
    id: 5186,
    hazzard: {
      id: 117,
      substanceWorkCondition: "Industry Medium - Pharmaceutical Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5187,
    hazzard: {
      id: 118,
      substanceWorkCondition: "Industry Medium - Plastics Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5188,
    hazzard: {
      id: 118,
      substanceWorkCondition: "Industry Medium - Plastics Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5189,
    hazzard: {
      id: 119,
      substanceWorkCondition: "Ink / printing ink",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5190,
    hazzard: {
      id: 119,
      substanceWorkCondition: "Ink / printing ink",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5191,
    hazzard: {
      id: 119,
      substanceWorkCondition: "Ink / printing ink",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5192,
    hazzard: {
      id: 119,
      substanceWorkCondition: "Ink / printing ink",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5193,
    hazzard: {
      id: 120,
      substanceWorkCondition: "Insulating material",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5194,
    hazzard: {
      id: 120,
      substanceWorkCondition: "Insulating material",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5195,
    hazzard: {
      id: 120,
      substanceWorkCondition: "Insulating material",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5196,
    hazzard: {
      id: 120,
      substanceWorkCondition: "Insulating material",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5197,
    hazzard: {
      id: 121,
      substanceWorkCondition: "Kerosene",
      cas: null,
      glovesRecommended: true,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5198,
    hazzard: {
      id: 121,
      substanceWorkCondition: "Kerosene",
      cas: null,
      glovesRecommended: true,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5199,
    hazzard: {
      id: 121,
      substanceWorkCondition: "Kerosene",
      cas: null,
      glovesRecommended: true,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5200,
    hazzard: {
      id: 121,
      substanceWorkCondition: "Kerosene",
      cas: null,
      glovesRecommended: true,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5201,
    hazzard: {
      id: 122,
      substanceWorkCondition: "Ketone",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5202,
    hazzard: {
      id: 122,
      substanceWorkCondition: "Ketone",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5203,
    hazzard: {
      id: 122,
      substanceWorkCondition: "Ketone",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5204,
    hazzard: {
      id: 122,
      substanceWorkCondition: "Ketone",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5205,
    hazzard: {
      id: 123,
      substanceWorkCondition: "Lacquers (nitro/solvent-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5206,
    hazzard: {
      id: 123,
      substanceWorkCondition: "Lacquers (nitro/solvent-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5207,
    hazzard: {
      id: 123,
      substanceWorkCondition: "Lacquers (nitro/solvent-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5208,
    hazzard: {
      id: 123,
      substanceWorkCondition: "Lacquers (nitro/solvent-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5209,
    hazzard: {
      id: 124,
      substanceWorkCondition: "Lacquers (PU based, synthetic resin based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5210,
    hazzard: {
      id: 124,
      substanceWorkCondition: "Lacquers (PU based, synthetic resin based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5211,
    hazzard: {
      id: 124,
      substanceWorkCondition: "Lacquers (PU based, synthetic resin based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5212,
    hazzard: {
      id: 124,
      substanceWorkCondition: "Lacquers (PU based, synthetic resin based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5213,
    hazzard: {
      id: 125,
      substanceWorkCondition: "Lacquers (UV-hardening)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5214,
    hazzard: {
      id: 125,
      substanceWorkCondition: "Lacquers (UV-hardening)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5215,
    hazzard: {
      id: 125,
      substanceWorkCondition: "Lacquers (UV-hardening)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5216,
    hazzard: {
      id: 125,
      substanceWorkCondition: "Lacquers (UV-hardening)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5217,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5218,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5219,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5220,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5221,
    hazzard: {
      id: 127,
      substanceWorkCondition: "Latex paint",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5222,
    hazzard: {
      id: 127,
      substanceWorkCondition: "Latex paint",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5223,
    hazzard: {
      id: 127,
      substanceWorkCondition: "Latex paint",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5224,
    hazzard: {
      id: 127,
      substanceWorkCondition: "Latex paint",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5225,
    hazzard: {
      id: 128,
      substanceWorkCondition: "Lime",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5226,
    hazzard: {
      id: 128,
      substanceWorkCondition: "Lime",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5227,
    hazzard: {
      id: 128,
      substanceWorkCondition: "Lime",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5228,
    hazzard: {
      id: 128,
      substanceWorkCondition: "Lime",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5229,
    hazzard: {
      id: 129,
      substanceWorkCondition: "Lubricants, water-based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5230,
    hazzard: {
      id: 129,
      substanceWorkCondition: "Lubricants, water-based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5231,
    hazzard: {
      id: 129,
      substanceWorkCondition: "Lubricants, water-based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5232,
    hazzard: {
      id: 129,
      substanceWorkCondition: "Lubricants, water-based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5233,
    hazzard: {
      id: 130,
      substanceWorkCondition: "Metal dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5234,
    hazzard: {
      id: 130,
      substanceWorkCondition: "Metal dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5235,
    hazzard: {
      id: 130,
      substanceWorkCondition: "Metal dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5236,
    hazzard: {
      id: 130,
      substanceWorkCondition: "Metal dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5237,
    hazzard: {
      id: 131,
      substanceWorkCondition: "Methanol / Methyl Alcohol",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5238,
    hazzard: {
      id: 131,
      substanceWorkCondition: "Methanol / Methyl Alcohol",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5239,
    hazzard: {
      id: 131,
      substanceWorkCondition: "Methanol / Methyl Alcohol",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5240,
    hazzard: {
      id: 131,
      substanceWorkCondition: "Methanol / Methyl Alcohol",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5241,
    hazzard: {
      id: 132,
      substanceWorkCondition: "Mineral oil / paraffin oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5242,
    hazzard: {
      id: 132,
      substanceWorkCondition: "Mineral oil / paraffin oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5243,
    hazzard: {
      id: 132,
      substanceWorkCondition: "Mineral oil / paraffin oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5244,
    hazzard: {
      id: 132,
      substanceWorkCondition: "Mineral oil / paraffin oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5245,
    hazzard: {
      id: 132,
      substanceWorkCondition: "Mineral oil / paraffin oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5246,
    hazzard: {
      id: 132,
      substanceWorkCondition: "Mineral oil / paraffin oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5247,
    hazzard: {
      id: 132,
      substanceWorkCondition: "Mineral oil / paraffin oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5248,
    hazzard: {
      id: 132,
      substanceWorkCondition: "Mineral oil / paraffin oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5249,
    hazzard: {
      id: 133,
      substanceWorkCondition: "Multi component adhesive",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5250,
    hazzard: {
      id: 133,
      substanceWorkCondition: "Multi component adhesive",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5251,
    hazzard: {
      id: 133,
      substanceWorkCondition: "Multi component adhesive",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5252,
    hazzard: {
      id: 133,
      substanceWorkCondition: "Multi component adhesive",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5253,
    hazzard: {
      id: 134,
      substanceWorkCondition: "Multi component resin",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5254,
    hazzard: {
      id: 134,
      substanceWorkCondition: "Multi component resin",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5255,
    hazzard: {
      id: 134,
      substanceWorkCondition: "Multi component resin",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5256,
    hazzard: {
      id: 134,
      substanceWorkCondition: "Multi component resin",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5257,
    hazzard: {
      id: 135,
      substanceWorkCondition: "Naphthalene",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5258,
    hazzard: {
      id: 135,
      substanceWorkCondition: "Naphthalene",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5259,
    hazzard: {
      id: 135,
      substanceWorkCondition: "Naphthalene",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5260,
    hazzard: {
      id: 135,
      substanceWorkCondition: "Naphthalene",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5261,
    hazzard: {
      id: 136,
      substanceWorkCondition: "Oil coke (carbon)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5262,
    hazzard: {
      id: 136,
      substanceWorkCondition: "Oil coke (carbon)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5263,
    hazzard: {
      id: 136,
      substanceWorkCondition: "Oil coke (carbon)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5264,
    hazzard: {
      id: 136,
      substanceWorkCondition: "Oil coke (carbon)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5265,
    hazzard: {
      id: 137,
      substanceWorkCondition: "Oil-emulsions (water- mixed)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5266,
    hazzard: {
      id: 137,
      substanceWorkCondition: "Oil-emulsions (water- mixed)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5267,
    hazzard: {
      id: 137,
      substanceWorkCondition: "Oil-emulsions (water- mixed)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5268,
    hazzard: {
      id: 137,
      substanceWorkCondition: "Oil-emulsions (water- mixed)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5269,
    hazzard: {
      id: 138,
      substanceWorkCondition: "Oils / Oil based lubricants",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5270,
    hazzard: {
      id: 138,
      substanceWorkCondition: "Oils / Oil based lubricants",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5271,
    hazzard: {
      id: 138,
      substanceWorkCondition: "Oils / Oil based lubricants",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5272,
    hazzard: {
      id: 138,
      substanceWorkCondition: "Oils / Oil based lubricants",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5273,
    hazzard: {
      id: 140,
      substanceWorkCondition: "Paint Thinner ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5274,
    hazzard: {
      id: 140,
      substanceWorkCondition: "Paint Thinner ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5275,
    hazzard: {
      id: 140,
      substanceWorkCondition: "Paint Thinner ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5276,
    hazzard: {
      id: 140,
      substanceWorkCondition: "Paint Thinner ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5277,
    hazzard: {
      id: 139,
      substanceWorkCondition: "Paint",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5278,
    hazzard: {
      id: 139,
      substanceWorkCondition: "Paint",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5279,
    hazzard: {
      id: 139,
      substanceWorkCondition: "Paint",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5280,
    hazzard: {
      id: 139,
      substanceWorkCondition: "Paint",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5281,
    hazzard: {
      id: 141,
      substanceWorkCondition: "Pit water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5282,
    hazzard: {
      id: 141,
      substanceWorkCondition: "Pit water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5283,
    hazzard: {
      id: 141,
      substanceWorkCondition: "Pit water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5284,
    hazzard: {
      id: 141,
      substanceWorkCondition: "Pit water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5285,
    hazzard: {
      id: 142,
      substanceWorkCondition: "Pitch",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5286,
    hazzard: {
      id: 142,
      substanceWorkCondition: "Pitch",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5287,
    hazzard: {
      id: 142,
      substanceWorkCondition: "Pitch",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5288,
    hazzard: {
      id: 142,
      substanceWorkCondition: "Pitch",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5289,
    hazzard: {
      id: 143,
      substanceWorkCondition: "Polishes (water- soluble)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5290,
    hazzard: {
      id: 143,
      substanceWorkCondition: "Polishes (water- soluble)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5291,
    hazzard: {
      id: 143,
      substanceWorkCondition: "Polishes (water- soluble)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5292,
    hazzard: {
      id: 143,
      substanceWorkCondition: "Polishes (water- soluble)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5293,
    hazzard: {
      id: 144,
      substanceWorkCondition: "Polyester / Polyester resin",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5294,
    hazzard: {
      id: 144,
      substanceWorkCondition: "Polyester / Polyester resin",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5295,
    hazzard: {
      id: 144,
      substanceWorkCondition: "Polyester / Polyester resin",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5296,
    hazzard: {
      id: 144,
      substanceWorkCondition: "Polyester / Polyester resin",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5297,
    hazzard: {
      id: 145,
      substanceWorkCondition: "Polyurethane",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5298,
    hazzard: {
      id: 145,
      substanceWorkCondition: "Polyurethane",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5299,
    hazzard: {
      id: 145,
      substanceWorkCondition: "Polyurethane",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5300,
    hazzard: {
      id: 145,
      substanceWorkCondition: "Polyurethane",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5301,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5302,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5303,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5304,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5305,
    hazzard: {
      id: 148,
      substanceWorkCondition:
        "Propanols (propyl alcohols): n-Propanol, Isopropanol / 2-Propanol ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5306,
    hazzard: {
      id: 148,
      substanceWorkCondition:
        "Propanols (propyl alcohols): n-Propanol, Isopropanol / 2-Propanol ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5307,
    hazzard: {
      id: 148,
      substanceWorkCondition:
        "Propanols (propyl alcohols): n-Propanol, Isopropanol / 2-Propanol ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5308,
    hazzard: {
      id: 148,
      substanceWorkCondition:
        "Propanols (propyl alcohols): n-Propanol, Isopropanol / 2-Propanol ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5309,
    hazzard: {
      id: 149,
      substanceWorkCondition: "release agent, parting agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5310,
    hazzard: {
      id: 149,
      substanceWorkCondition: "release agent, parting agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5311,
    hazzard: {
      id: 149,
      substanceWorkCondition: "release agent, parting agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5312,
    hazzard: {
      id: 149,
      substanceWorkCondition: "release agent, parting agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5313,
    hazzard: {
      id: 150,
      substanceWorkCondition: "Resins / urea resins",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5314,
    hazzard: {
      id: 150,
      substanceWorkCondition: "Resins / urea resins",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5315,
    hazzard: {
      id: 150,
      substanceWorkCondition: "Resins / urea resins",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5316,
    hazzard: {
      id: 150,
      substanceWorkCondition: "Resins / urea resins",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5317,
    hazzard: {
      id: 151,
      substanceWorkCondition: "Rock wool",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5318,
    hazzard: {
      id: 151,
      substanceWorkCondition: "Rock wool",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5319,
    hazzard: {
      id: 151,
      substanceWorkCondition: "Rock wool",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5320,
    hazzard: {
      id: 151,
      substanceWorkCondition: "Rock wool",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5321,
    hazzard: {
      id: 152,
      substanceWorkCondition: "Rubber",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5322,
    hazzard: {
      id: 152,
      substanceWorkCondition: "Rubber",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5323,
    hazzard: {
      id: 152,
      substanceWorkCondition: "Rubber",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5324,
    hazzard: {
      id: 152,
      substanceWorkCondition: "Rubber",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5325,
    hazzard: {
      id: 153,
      substanceWorkCondition: "Salts / Salt solution",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5326,
    hazzard: {
      id: 153,
      substanceWorkCondition: "Salts / Salt solution",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5327,
    hazzard: {
      id: 153,
      substanceWorkCondition: "Salts / Salt solution",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5328,
    hazzard: {
      id: 153,
      substanceWorkCondition: "Salts / Salt solution",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5329,
    hazzard: {
      id: 154,
      substanceWorkCondition: "Sealing compound",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5330,
    hazzard: {
      id: 154,
      substanceWorkCondition: "Sealing compound",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5331,
    hazzard: {
      id: 154,
      substanceWorkCondition: "Sealing compound",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5332,
    hazzard: {
      id: 154,
      substanceWorkCondition: "Sealing compound",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5333,
    hazzard: {
      id: 155,
      substanceWorkCondition: "Sintering",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5334,
    hazzard: {
      id: 155,
      substanceWorkCondition: "Sintering",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5335,
    hazzard: {
      id: 155,
      substanceWorkCondition: "Sintering",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5336,
    hazzard: {
      id: 155,
      substanceWorkCondition: "Sintering",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5337,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:08.000Z",
    updated_at: "2020-03-02T22:50:08.000Z"
  },
  {
    id: 5338,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5339,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5340,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5341,
    hazzard: {
      id: 157,
      substanceWorkCondition: "Solvents, - organic",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5342,
    hazzard: {
      id: 157,
      substanceWorkCondition: "Solvents, - organic",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5343,
    hazzard: {
      id: 157,
      substanceWorkCondition: "Solvents, - organic",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5344,
    hazzard: {
      id: 157,
      substanceWorkCondition: "Solvents, - organic",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5345,
    hazzard: {
      id: 158,
      substanceWorkCondition: "Soot",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5346,
    hazzard: {
      id: 158,
      substanceWorkCondition: "Soot",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5347,
    hazzard: {
      id: 158,
      substanceWorkCondition: "Soot",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5348,
    hazzard: {
      id: 158,
      substanceWorkCondition: "Soot",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5349,
    hazzard: {
      id: 159,
      substanceWorkCondition: "Styrene",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5350,
    hazzard: {
      id: 159,
      substanceWorkCondition: "Styrene",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5351,
    hazzard: {
      id: 159,
      substanceWorkCondition: "Styrene",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5352,
    hazzard: {
      id: 159,
      substanceWorkCondition: "Styrene",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5353,
    hazzard: {
      id: 160,
      substanceWorkCondition: "Surface Cleanser (surfactant based), Detergents",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5354,
    hazzard: {
      id: 160,
      substanceWorkCondition: "Surface Cleanser (surfactant based), Detergents",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5355,
    hazzard: {
      id: 160,
      substanceWorkCondition: "Surface Cleanser (surfactant based), Detergents",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5356,
    hazzard: {
      id: 160,
      substanceWorkCondition: "Surface Cleanser (surfactant based), Detergents",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5357,
    hazzard: {
      id: 161,
      substanceWorkCondition: "Synthetic melting glaze",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5358,
    hazzard: {
      id: 161,
      substanceWorkCondition: "Synthetic melting glaze",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5359,
    hazzard: {
      id: 161,
      substanceWorkCondition: "Synthetic melting glaze",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5360,
    hazzard: {
      id: 161,
      substanceWorkCondition: "Synthetic melting glaze",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5361,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5362,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5363,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5364,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5365,
    hazzard: {
      id: 163,
      substanceWorkCondition: "Tanning agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5366,
    hazzard: {
      id: 163,
      substanceWorkCondition: "Tanning agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5367,
    hazzard: {
      id: 163,
      substanceWorkCondition: "Tanning agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5368,
    hazzard: {
      id: 163,
      substanceWorkCondition: "Tanning agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5369,
    hazzard: {
      id: 164,
      substanceWorkCondition: "Tar",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5370,
    hazzard: {
      id: 164,
      substanceWorkCondition: "Tar",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5371,
    hazzard: {
      id: 164,
      substanceWorkCondition: "Tar",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5372,
    hazzard: {
      id: 164,
      substanceWorkCondition: "Tar",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5373,
    hazzard: {
      id: 166,
      substanceWorkCondition: "Thinner",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5374,
    hazzard: {
      id: 166,
      substanceWorkCondition: "Thinner",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5375,
    hazzard: {
      id: 166,
      substanceWorkCondition: "Thinner",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5376,
    hazzard: {
      id: 166,
      substanceWorkCondition: "Thinner",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5377,
    hazzard: {
      id: 167,
      substanceWorkCondition: "Toluene",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5378,
    hazzard: {
      id: 167,
      substanceWorkCondition: "Toluene",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5379,
    hazzard: {
      id: 167,
      substanceWorkCondition: "Toluene",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5380,
    hazzard: {
      id: 167,
      substanceWorkCondition: "Toluene",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5381,
    hazzard: {
      id: 168,
      substanceWorkCondition: "Turpentine oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5382,
    hazzard: {
      id: 168,
      substanceWorkCondition: "Turpentine oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5383,
    hazzard: {
      id: 168,
      substanceWorkCondition: "Turpentine oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5384,
    hazzard: {
      id: 168,
      substanceWorkCondition: "Turpentine oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5385,
    hazzard: {
      id: 169,
      substanceWorkCondition: "UV-rays (A/B) / Sunlight",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5386,
    hazzard: {
      id: 169,
      substanceWorkCondition: "UV-rays (A/B) / Sunlight",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5387,
    hazzard: {
      id: 170,
      substanceWorkCondition: "Washing bath, washing liquid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5388,
    hazzard: {
      id: 170,
      substanceWorkCondition: "Washing bath, washing liquid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5389,
    hazzard: {
      id: 170,
      substanceWorkCondition: "Washing bath, washing liquid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5390,
    hazzard: {
      id: 170,
      substanceWorkCondition: "Washing bath, washing liquid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5391,
    hazzard: {
      id: 171,
      substanceWorkCondition: "Waste oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5392,
    hazzard: {
      id: 171,
      substanceWorkCondition: "Waste oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5393,
    hazzard: {
      id: 171,
      substanceWorkCondition: "Waste oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5394,
    hazzard: {
      id: 171,
      substanceWorkCondition: "Waste oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5395,
    hazzard: {
      id: 172,
      substanceWorkCondition: "Water / wet work",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5396,
    hazzard: {
      id: 172,
      substanceWorkCondition: "Water / wet work",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5397,
    hazzard: {
      id: 172,
      substanceWorkCondition: "Water / wet work",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5398,
    hazzard: {
      id: 172,
      substanceWorkCondition: "Water / wet work",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5399,
    hazzard: {
      id: 173,
      substanceWorkCondition:
        "Wearing protective clothing / Softening of the skin (Maceration)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5400,
    hazzard: {
      id: 173,
      substanceWorkCondition:
        "Wearing protective clothing / Softening of the skin (Maceration)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5401,
    hazzard: {
      id: 173,
      substanceWorkCondition:
        "Wearing protective clothing / Softening of the skin (Maceration)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5402,
    hazzard: {
      id: 173,
      substanceWorkCondition:
        "Wearing protective clothing / Softening of the skin (Maceration)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5403,
    hazzard: {
      id: 174,
      substanceWorkCondition: "wire drawing oil / wire drawing grease",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5404,
    hazzard: {
      id: 174,
      substanceWorkCondition: "wire drawing oil / wire drawing grease",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5405,
    hazzard: {
      id: 174,
      substanceWorkCondition: "wire drawing oil / wire drawing grease",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5406,
    hazzard: {
      id: 174,
      substanceWorkCondition: "wire drawing oil / wire drawing grease",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5407,
    hazzard: {
      id: 175,
      substanceWorkCondition: "Xylene",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5408,
    hazzard: {
      id: 175,
      substanceWorkCondition: "Xylene",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5409,
    hazzard: {
      id: 175,
      substanceWorkCondition: "Xylene",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5410,
    hazzard: {
      id: 175,
      substanceWorkCondition: "Xylene",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 3,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5411,
    hazzard: {
      id: 999,
      substanceWorkCondition: "All Products",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:44:03.000Z",
      updated_at: "2020-03-02T17:44:03.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5412,
    hazzard: {
      id: 999,
      substanceWorkCondition: "All Products",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:44:03.000Z",
      updated_at: "2020-03-02T17:44:03.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5413,
    hazzard: {
      id: 999,
      substanceWorkCondition: "All Products",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:44:03.000Z",
      updated_at: "2020-03-02T17:44:03.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5414,
    hazzard: {
      id: 999,
      substanceWorkCondition: "All Products",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:44:03.000Z",
      updated_at: "2020-03-02T17:44:03.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Face",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5415,
    hazzard: {
      id: 1,
      substanceWorkCondition: "2-Butanone (MEK)",
      cas: "78-93-3",
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: "oil",
      created_at: "2020-03-02T17:39:32.000Z",
      updated_at: "2020-03-05T14:48:01.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5416,
    hazzard: {
      id: 2,
      substanceWorkCondition: "Acetone / Dimethyl ketone",
      cas: "67-64-1",
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5417,
    hazzard: {
      id: 3,
      substanceWorkCondition: "Acids, diluted",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5418,
    hazzard: {
      id: 4,
      substanceWorkCondition: "Acrylates",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5419,
    hazzard: {
      id: 5,
      substanceWorkCondition: "Adhesive single-component",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5420,
    hazzard: {
      id: 6,
      substanceWorkCondition: "Adhesive, water-soluble",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5421,
    hazzard: {
      id: 7,
      substanceWorkCondition: "Adhesives, glues based on synthetic resin",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5422,
    hazzard: {
      id: 8,
      substanceWorkCondition: "Aldehydes, diluted",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5423,
    hazzard: {
      id: 9,
      substanceWorkCondition: "Alkaline solutions / lyes / thinned caustics",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5424,
    hazzard: {
      id: 10,
      substanceWorkCondition: "Alkaline solutions, diluted",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5425,
    hazzard: {
      id: 11,
      substanceWorkCondition: "Alkaline solutions, lyes (solvent based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5426,
    hazzard: {
      id: 12,
      substanceWorkCondition: "Aluminium",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5427,
    hazzard: {
      id: 14,
      substanceWorkCondition: "Antistatics",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5428,
    hazzard: {
      id: 19,
      substanceWorkCondition: "Battery acid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5429,
    hazzard: {
      id: 20,
      substanceWorkCondition: "Benzene",
      cas: null,
      glovesRecommended: true,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5430,
    hazzard: {
      id: 22,
      substanceWorkCondition: "Bitumen emulsion",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5431,
    hazzard: {
      id: 21,
      substanceWorkCondition: "Bitumen",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5432,
    hazzard: {
      id: 23,
      substanceWorkCondition: "Bleaching agent (lye)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5433,
    hazzard: {
      id: 24,
      substanceWorkCondition: "Brake fluid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5434,
    hazzard: {
      id: 25,
      substanceWorkCondition: "Casting dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5435,
    hazzard: {
      id: 26,
      substanceWorkCondition: "Cement (dry)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5436,
    hazzard: {
      id: 27,
      substanceWorkCondition: "Cement (wet)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5437,
    hazzard: {
      id: 28,
      substanceWorkCondition: "Cement, putty epoxy-based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5438,
    hazzard: {
      id: 29,
      substanceWorkCondition:
        "Chromates (water- insoluble) = Chromium pigments",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5439,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5440,
    hazzard: {
      id: 31,
      substanceWorkCondition: "cleaning agent, detergent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5441,
    hazzard: {
      id: 32,
      substanceWorkCondition: "Coal dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5442,
    hazzard: {
      id: 33,
      substanceWorkCondition: "Coating materials, bituminous (bitumen varnish)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5443,
    hazzard: {
      id: 34,
      substanceWorkCondition: "Coating materials, emulsified",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5444,
    hazzard: {
      id: 35,
      substanceWorkCondition: "Coating materials, oily",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5445,
    hazzard: {
      id: 36,
      substanceWorkCondition: "Cold degreasing agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5446,
    hazzard: {
      id: 37,
      substanceWorkCondition: "cold detergent, solvent based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5447,
    hazzard: {
      id: 38,
      substanceWorkCondition: "Cold working conditions",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5448,
    hazzard: {
      id: 38,
      substanceWorkCondition: "Cold working conditions",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5449,
    hazzard: {
      id: 43,
      substanceWorkCondition: "Commercial - Hotel & Leisure",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5450,
    hazzard: {
      id: 44,
      substanceWorkCondition: "Commercial - Prisons & Security Services",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5451,
    hazzard: {
      id: 45,
      substanceWorkCondition:
        "Commercial - Public Entertainments & Theme Parks",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5452,
    hazzard: {
      id: 47,
      substanceWorkCondition: "Concrete",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5453,
    hazzard: {
      id: 48,
      substanceWorkCondition: "Cooling lubricant (non-water-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5454,
    hazzard: {
      id: 49,
      substanceWorkCondition: "Cooling lubricant (water- based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5455,
    hazzard: {
      id: 50,
      substanceWorkCondition: "Cooling water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5456,
    hazzard: {
      id: 51,
      substanceWorkCondition: "Copying ink",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5457,
    hazzard: {
      id: 51,
      substanceWorkCondition: "Copying ink",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 2,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5458,
    hazzard: {
      id: 52,
      substanceWorkCondition: "Cutting oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5459,
    hazzard: {
      id: 53,
      substanceWorkCondition: "Degreaser solvent - based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5460,
    hazzard: {
      id: 54,
      substanceWorkCondition: "Degreaser without organic solvent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5461,
    hazzard: {
      id: 55,
      substanceWorkCondition: "Detachment solution for concrete",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5462,
    hazzard: {
      id: 56,
      substanceWorkCondition: "Detachments (alcohol-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5463,
    hazzard: {
      id: 57,
      substanceWorkCondition: "Detachments (solvent-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5464,
    hazzard: {
      id: 58,
      substanceWorkCondition: "Detachments (sour)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5465,
    hazzard: {
      id: 59,
      substanceWorkCondition: "Disinfectants (alcohol-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5466,
    hazzard: {
      id: 60,
      substanceWorkCondition: "Disinfectants (formaldehyd-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5467,
    hazzard: {
      id: 61,
      substanceWorkCondition: "Disinfectants (water-based) ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5468,
    hazzard: {
      id: 62,
      substanceWorkCondition: "Dispersing Agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5469,
    hazzard: {
      id: 63,
      substanceWorkCondition: "Drilling emulsion (water based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5470,
    hazzard: {
      id: 64,
      substanceWorkCondition: "Drilling oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5471,
    hazzard: {
      id: 65,
      substanceWorkCondition: "Dryness",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5472,
    hazzard: {
      id: 65,
      substanceWorkCondition: "Dryness",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 2,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5473,
    hazzard: {
      id: 66,
      substanceWorkCondition: "Dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5474,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5475,
    hazzard: {
      id: 70,
      substanceWorkCondition: "Epoxy resin",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5476,
    hazzard: {
      id: 71,
      substanceWorkCondition: "Etching (mordant)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5477,
    hazzard: {
      id: 72,
      substanceWorkCondition: "Ethanol / methylated spirits ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5478,
    hazzard: {
      id: 73,
      substanceWorkCondition: "Fat",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5479,
    hazzard: {
      id: 74,
      substanceWorkCondition: "Fertilizer",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5480,
    hazzard: {
      id: 75,
      substanceWorkCondition: "fine anorganic/inorganic powders ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5481,
    hazzard: {
      id: 76,
      substanceWorkCondition: "Floor cleaner (water- based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5482,
    hazzard: {
      id: 77,
      substanceWorkCondition: "Food - Food Packaging",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 2,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:09.000Z",
    updated_at: "2020-03-02T22:50:09.000Z"
  },
  {
    id: 5483,
    hazzard: {
      id: 78,
      substanceWorkCondition: "Food - Food Processing & Manufacturing",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 2,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5484,
    hazzard: {
      id: 79,
      substanceWorkCondition: "Food - Food Services & Catering",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 2,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5485,
    hazzard: {
      id: 80,
      substanceWorkCondition: "Food Dyestuff",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5486,
    hazzard: {
      id: 81,
      substanceWorkCondition: "fuel: gas / diesel",
      cas: null,
      glovesRecommended: true,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5487,
    hazzard: {
      id: 82,
      substanceWorkCondition: "Galvanic bath",
      cas: null,
      glovesRecommended: true,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5488,
    hazzard: {
      id: 83,
      substanceWorkCondition: "Glass fibre dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5489,
    hazzard: {
      id: 84,
      substanceWorkCondition: "Glass wool dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5490,
    hazzard: {
      id: 85,
      substanceWorkCondition: "Glycols",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5491,
    hazzard: {
      id: 86,
      substanceWorkCondition: "Graphite",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5492,
    hazzard: {
      id: 87,
      substanceWorkCondition: "Grease",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5493,
    hazzard: {
      id: 88,
      substanceWorkCondition: "Grinding oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5494,
    hazzard: {
      id: 89,
      substanceWorkCondition: "Grinding water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5495,
    hazzard: {
      id: 90,
      substanceWorkCondition: "Gypsum",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5496,
    hazzard: {
      id: 91,
      substanceWorkCondition: "Healthcare - Care Homes",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 2,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5497,
    hazzard: {
      id: 92,
      substanceWorkCondition:
        "Healthcare - Medical Centers/Surgeries/General Practitioners",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 2,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5498,
    hazzard: {
      id: 93,
      substanceWorkCondition: "Healthcare - Nursing Homes",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 2,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5499,
    hazzard: {
      id: 94,
      substanceWorkCondition: "Healthcare - Veterinary Services",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 2,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5500,
    hazzard: {
      id: 95,
      substanceWorkCondition: "Hydraulic fluid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5501,
    hazzard: {
      id: 96,
      substanceWorkCondition: "Hyrocarbons",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5502,
    hazzard: {
      id: 97,
      substanceWorkCondition: "Industry Heavy - Building Materials",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5503,
    hazzard: {
      id: 98,
      substanceWorkCondition: "Industry Heavy - Construction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5504,
    hazzard: {
      id: 99,
      substanceWorkCondition: "Industry Heavy - Mining & Extraction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5505,
    hazzard: {
      id: 100,
      substanceWorkCondition: "Industry Heavy - Off-Shore Extraction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5506,
    hazzard: {
      id: 101,
      substanceWorkCondition: "Industry Heavy - Printing Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5507,
    hazzard: {
      id: 102,
      substanceWorkCondition:
        "Industry Heavy - Road & Transportation Construction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5508,
    hazzard: {
      id: 103,
      substanceWorkCondition: "Industry Heavy - Ship & Boat Building",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5509,
    hazzard: {
      id: 104,
      substanceWorkCondition: "Industry Heavy - Shipping & Marine",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5510,
    hazzard: {
      id: 105,
      substanceWorkCondition:
        "Industry Heavy - Steel, Engineering & Heavy Manufacturing",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5511,
    hazzard: {
      id: 106,
      substanceWorkCondition:
        "Industry Light - Airports & Transportation Terminals - Services & Facility Maintenance",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5512,
    hazzard: {
      id: 107,
      substanceWorkCondition: "Industry Light - Emergency Services",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5513,
    hazzard: {
      id: 108,
      substanceWorkCondition:
        "Industry Light - General Manufacturing & Assembly",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5514,
    hazzard: {
      id: 109,
      substanceWorkCondition:
        "Industry Light - Hosiery, Knitware, Fabric & Clothing Manufacturing",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5515,
    hazzard: {
      id: 110,
      substanceWorkCondition: "Industry Light - Science & Laboratories",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5516,
    hazzard: {
      id: 111,
      substanceWorkCondition: "Industry Light - Warehousing & Logistics",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5517,
    hazzard: {
      id: 112,
      substanceWorkCondition: "Industry Medium - Ceramics & Glassware",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5518,
    hazzard: {
      id: 113,
      substanceWorkCondition: "Industry Medium - Chemical Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5519,
    hazzard: {
      id: 113,
      substanceWorkCondition: "Industry Medium - Chemical Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 2,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5520,
    hazzard: {
      id: 114,
      substanceWorkCondition: "Industry Medium - Electronics Industries",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5521,
    hazzard: {
      id: 115,
      substanceWorkCondition: "Industry Medium - Metal Working & Fabrication",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5522,
    hazzard: {
      id: 116,
      substanceWorkCondition: "Industry Medium - Outdoor Maintenance & Repair",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5523,
    hazzard: {
      id: 117,
      substanceWorkCondition: "Industry Medium - Pharmaceutical Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5524,
    hazzard: {
      id: 117,
      substanceWorkCondition: "Industry Medium - Pharmaceutical Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 2,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5525,
    hazzard: {
      id: 118,
      substanceWorkCondition: "Industry Medium - Plastics Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5526,
    hazzard: {
      id: 119,
      substanceWorkCondition: "Ink / printing ink",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5527,
    hazzard: {
      id: 119,
      substanceWorkCondition: "Ink / printing ink",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5528,
    hazzard: {
      id: 120,
      substanceWorkCondition: "Insulating material",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5529,
    hazzard: {
      id: 120,
      substanceWorkCondition: "Insulating material",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5530,
    hazzard: {
      id: 121,
      substanceWorkCondition: "Kerosene",
      cas: null,
      glovesRecommended: true,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5531,
    hazzard: {
      id: 122,
      substanceWorkCondition: "Ketone",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5532,
    hazzard: {
      id: 123,
      substanceWorkCondition: "Lacquers (nitro/solvent-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5533,
    hazzard: {
      id: 124,
      substanceWorkCondition: "Lacquers (PU based, synthetic resin based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5534,
    hazzard: {
      id: 125,
      substanceWorkCondition: "Lacquers (UV-hardening)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5535,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5536,
    hazzard: {
      id: 127,
      substanceWorkCondition: "Latex paint",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5537,
    hazzard: {
      id: 128,
      substanceWorkCondition: "Lime",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5538,
    hazzard: {
      id: 129,
      substanceWorkCondition: "Lubricants, water-based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5539,
    hazzard: {
      id: 130,
      substanceWorkCondition: "Metal dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5540,
    hazzard: {
      id: 131,
      substanceWorkCondition: "Methanol / Methyl Alcohol",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5541,
    hazzard: {
      id: 132,
      substanceWorkCondition: "Mineral oil / paraffin oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5542,
    hazzard: {
      id: 133,
      substanceWorkCondition: "Multi component adhesive",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5543,
    hazzard: {
      id: 134,
      substanceWorkCondition: "Multi component resin",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5544,
    hazzard: {
      id: 135,
      substanceWorkCondition: "Naphthalene",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5545,
    hazzard: {
      id: 136,
      substanceWorkCondition: "Oil coke (carbon)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5546,
    hazzard: {
      id: 137,
      substanceWorkCondition: "Oil-emulsions (water- mixed)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5547,
    hazzard: {
      id: 138,
      substanceWorkCondition: "Oils / Oil based lubricants",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5548,
    hazzard: {
      id: 140,
      substanceWorkCondition: "Paint Thinner ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5549,
    hazzard: {
      id: 139,
      substanceWorkCondition: "Paint",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5550,
    hazzard: {
      id: 141,
      substanceWorkCondition: "Pit water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5551,
    hazzard: {
      id: 142,
      substanceWorkCondition: "Pitch",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5552,
    hazzard: {
      id: 144,
      substanceWorkCondition: "Polyester / Polyester resin",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5553,
    hazzard: {
      id: 145,
      substanceWorkCondition: "Polyurethane",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5554,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5555,
    hazzard: {
      id: 148,
      substanceWorkCondition:
        "Propanols (propyl alcohols): n-Propanol, Isopropanol / 2-Propanol ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5556,
    hazzard: {
      id: 149,
      substanceWorkCondition: "release agent, parting agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5557,
    hazzard: {
      id: 150,
      substanceWorkCondition: "Resins / urea resins",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5558,
    hazzard: {
      id: 151,
      substanceWorkCondition: "Rock wool",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5559,
    hazzard: {
      id: 152,
      substanceWorkCondition: "Rubber",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5560,
    hazzard: {
      id: 153,
      substanceWorkCondition: "Salts / Salt solution",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5561,
    hazzard: {
      id: 154,
      substanceWorkCondition: "Sealing compound",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5562,
    hazzard: {
      id: 155,
      substanceWorkCondition: "Sintering",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5563,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5564,
    hazzard: {
      id: 157,
      substanceWorkCondition: "Solvents, - organic",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5565,
    hazzard: {
      id: 158,
      substanceWorkCondition: "Soot",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5566,
    hazzard: {
      id: 159,
      substanceWorkCondition: "Styrene",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5567,
    hazzard: {
      id: 160,
      substanceWorkCondition: "Surface Cleanser (surfactant based), Detergents",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5568,
    hazzard: {
      id: 161,
      substanceWorkCondition: "Synthetic melting glaze",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5569,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5570,
    hazzard: {
      id: 163,
      substanceWorkCondition: "Tanning agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5571,
    hazzard: {
      id: 164,
      substanceWorkCondition: "Tar",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5572,
    hazzard: {
      id: 166,
      substanceWorkCondition: "Thinner",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5573,
    hazzard: {
      id: 167,
      substanceWorkCondition: "Toluene",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5574,
    hazzard: {
      id: 168,
      substanceWorkCondition: "Turpentine oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5575,
    hazzard: {
      id: 170,
      substanceWorkCondition: "Washing bath, washing liquid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5576,
    hazzard: {
      id: 171,
      substanceWorkCondition: "Waste oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5577,
    hazzard: {
      id: 172,
      substanceWorkCondition: "Water / wet work",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5578,
    hazzard: {
      id: 173,
      substanceWorkCondition:
        "Wearing protective clothing / Softening of the skin (Maceration)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5579,
    hazzard: {
      id: 173,
      substanceWorkCondition:
        "Wearing protective clothing / Softening of the skin (Maceration)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 2,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5580,
    hazzard: {
      id: 174,
      substanceWorkCondition: "wire drawing oil / wire drawing grease",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5581,
    hazzard: {
      id: 175,
      substanceWorkCondition: "Xylene",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5582,
    hazzard: {
      id: 999,
      substanceWorkCondition: "All Products",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:44:03.000Z",
      updated_at: "2020-03-02T17:44:03.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Gloves",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5583,
    hazzard: {
      id: 2,
      substanceWorkCondition: "Acetone / Dimethyl ketone",
      cas: "67-64-1",
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5584,
    hazzard: {
      id: 2,
      substanceWorkCondition: "Acetone / Dimethyl ketone",
      cas: "67-64-1",
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5585,
    hazzard: {
      id: 3,
      substanceWorkCondition: "Acids, diluted",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5586,
    hazzard: {
      id: 3,
      substanceWorkCondition: "Acids, diluted",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5587,
    hazzard: {
      id: 4,
      substanceWorkCondition: "Acrylates",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5588,
    hazzard: {
      id: 4,
      substanceWorkCondition: "Acrylates",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5589,
    hazzard: {
      id: 5,
      substanceWorkCondition: "Adhesive single-component",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5590,
    hazzard: {
      id: 5,
      substanceWorkCondition: "Adhesive single-component",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5591,
    hazzard: {
      id: 6,
      substanceWorkCondition: "Adhesive, water-soluble",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5592,
    hazzard: {
      id: 6,
      substanceWorkCondition: "Adhesive, water-soluble",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5593,
    hazzard: {
      id: 7,
      substanceWorkCondition: "Adhesives, glues based on synthetic resin",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5594,
    hazzard: {
      id: 7,
      substanceWorkCondition: "Adhesives, glues based on synthetic resin",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5595,
    hazzard: {
      id: 8,
      substanceWorkCondition: "Aldehydes, diluted",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5596,
    hazzard: {
      id: 8,
      substanceWorkCondition: "Aldehydes, diluted",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5597,
    hazzard: {
      id: 9,
      substanceWorkCondition: "Alkaline solutions / lyes / thinned caustics",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5598,
    hazzard: {
      id: 9,
      substanceWorkCondition: "Alkaline solutions / lyes / thinned caustics",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5599,
    hazzard: {
      id: 10,
      substanceWorkCondition: "Alkaline solutions, diluted",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5600,
    hazzard: {
      id: 10,
      substanceWorkCondition: "Alkaline solutions, diluted",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5601,
    hazzard: {
      id: 11,
      substanceWorkCondition: "Alkaline solutions, lyes (solvent based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5602,
    hazzard: {
      id: 11,
      substanceWorkCondition: "Alkaline solutions, lyes (solvent based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5603,
    hazzard: {
      id: 12,
      substanceWorkCondition: "Aluminium",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5604,
    hazzard: {
      id: 12,
      substanceWorkCondition: "Aluminium",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5605,
    hazzard: {
      id: 14,
      substanceWorkCondition: "Antistatics",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5606,
    hazzard: {
      id: 14,
      substanceWorkCondition: "Antistatics",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5607,
    hazzard: {
      id: 15,
      substanceWorkCondition: "Asphalt",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5608,
    hazzard: {
      id: 15,
      substanceWorkCondition: "Asphalt",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:10.000Z",
    updated_at: "2020-03-02T22:50:10.000Z"
  },
  {
    id: 5609,
    hazzard: {
      id: 19,
      substanceWorkCondition: "Battery acid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5610,
    hazzard: {
      id: 19,
      substanceWorkCondition: "Battery acid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5611,
    hazzard: {
      id: 20,
      substanceWorkCondition: "Benzene",
      cas: null,
      glovesRecommended: true,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5612,
    hazzard: {
      id: 20,
      substanceWorkCondition: "Benzene",
      cas: null,
      glovesRecommended: true,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5613,
    hazzard: {
      id: 22,
      substanceWorkCondition: "Bitumen emulsion",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5614,
    hazzard: {
      id: 22,
      substanceWorkCondition: "Bitumen emulsion",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5615,
    hazzard: {
      id: 21,
      substanceWorkCondition: "Bitumen",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5616,
    hazzard: {
      id: 21,
      substanceWorkCondition: "Bitumen",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5617,
    hazzard: {
      id: 23,
      substanceWorkCondition: "Bleaching agent (lye)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5618,
    hazzard: {
      id: 23,
      substanceWorkCondition: "Bleaching agent (lye)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5619,
    hazzard: {
      id: 24,
      substanceWorkCondition: "Brake fluid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5620,
    hazzard: {
      id: 24,
      substanceWorkCondition: "Brake fluid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5621,
    hazzard: {
      id: 25,
      substanceWorkCondition: "Casting dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5622,
    hazzard: {
      id: 25,
      substanceWorkCondition: "Casting dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5623,
    hazzard: {
      id: 26,
      substanceWorkCondition: "Cement (dry)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5624,
    hazzard: {
      id: 26,
      substanceWorkCondition: "Cement (dry)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5625,
    hazzard: {
      id: 27,
      substanceWorkCondition: "Cement (wet)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5626,
    hazzard: {
      id: 27,
      substanceWorkCondition: "Cement (wet)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5627,
    hazzard: {
      id: 28,
      substanceWorkCondition: "Cement, putty epoxy-based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5628,
    hazzard: {
      id: 28,
      substanceWorkCondition: "Cement, putty epoxy-based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5629,
    hazzard: {
      id: 29,
      substanceWorkCondition:
        "Chromates (water- insoluble) = Chromium pigments",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5630,
    hazzard: {
      id: 29,
      substanceWorkCondition:
        "Chromates (water- insoluble) = Chromium pigments",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5631,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5632,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5633,
    hazzard: {
      id: 31,
      substanceWorkCondition: "cleaning agent, detergent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5634,
    hazzard: {
      id: 31,
      substanceWorkCondition: "cleaning agent, detergent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5635,
    hazzard: {
      id: 32,
      substanceWorkCondition: "Coal dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5636,
    hazzard: {
      id: 32,
      substanceWorkCondition: "Coal dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5637,
    hazzard: {
      id: 33,
      substanceWorkCondition: "Coating materials, bituminous (bitumen varnish)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5638,
    hazzard: {
      id: 33,
      substanceWorkCondition: "Coating materials, bituminous (bitumen varnish)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5639,
    hazzard: {
      id: 34,
      substanceWorkCondition: "Coating materials, emulsified",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5640,
    hazzard: {
      id: 34,
      substanceWorkCondition: "Coating materials, emulsified",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5641,
    hazzard: {
      id: 35,
      substanceWorkCondition: "Coating materials, oily",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5642,
    hazzard: {
      id: 35,
      substanceWorkCondition: "Coating materials, oily",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5643,
    hazzard: {
      id: 36,
      substanceWorkCondition: "Cold degreasing agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5644,
    hazzard: {
      id: 36,
      substanceWorkCondition: "Cold degreasing agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5645,
    hazzard: {
      id: 37,
      substanceWorkCondition: "cold detergent, solvent based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5646,
    hazzard: {
      id: 37,
      substanceWorkCondition: "cold detergent, solvent based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5647,
    hazzard: {
      id: 38,
      substanceWorkCondition: "Cold working conditions",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5648,
    hazzard: {
      id: 38,
      substanceWorkCondition: "Cold working conditions",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5649,
    hazzard: {
      id: 47,
      substanceWorkCondition: "Concrete",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5650,
    hazzard: {
      id: 47,
      substanceWorkCondition: "Concrete",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5651,
    hazzard: {
      id: 48,
      substanceWorkCondition: "Cooling lubricant (non-water-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5652,
    hazzard: {
      id: 48,
      substanceWorkCondition: "Cooling lubricant (non-water-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5653,
    hazzard: {
      id: 49,
      substanceWorkCondition: "Cooling lubricant (water- based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5654,
    hazzard: {
      id: 49,
      substanceWorkCondition: "Cooling lubricant (water- based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5655,
    hazzard: {
      id: 50,
      substanceWorkCondition: "Cooling water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5656,
    hazzard: {
      id: 50,
      substanceWorkCondition: "Cooling water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5657,
    hazzard: {
      id: 51,
      substanceWorkCondition: "Copying ink",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5658,
    hazzard: {
      id: 51,
      substanceWorkCondition: "Copying ink",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5659,
    hazzard: {
      id: 52,
      substanceWorkCondition: "Cutting oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5660,
    hazzard: {
      id: 52,
      substanceWorkCondition: "Cutting oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5661,
    hazzard: {
      id: 53,
      substanceWorkCondition: "Degreaser solvent - based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5662,
    hazzard: {
      id: 53,
      substanceWorkCondition: "Degreaser solvent - based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5663,
    hazzard: {
      id: 54,
      substanceWorkCondition: "Degreaser without organic solvent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5664,
    hazzard: {
      id: 54,
      substanceWorkCondition: "Degreaser without organic solvent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5665,
    hazzard: {
      id: 55,
      substanceWorkCondition: "Detachment solution for concrete",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5666,
    hazzard: {
      id: 55,
      substanceWorkCondition: "Detachment solution for concrete",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5667,
    hazzard: {
      id: 56,
      substanceWorkCondition: "Detachments (alcohol-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5668,
    hazzard: {
      id: 56,
      substanceWorkCondition: "Detachments (alcohol-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5669,
    hazzard: {
      id: 57,
      substanceWorkCondition: "Detachments (solvent-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5670,
    hazzard: {
      id: 57,
      substanceWorkCondition: "Detachments (solvent-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5671,
    hazzard: {
      id: 58,
      substanceWorkCondition: "Detachments (sour)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5672,
    hazzard: {
      id: 58,
      substanceWorkCondition: "Detachments (sour)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5673,
    hazzard: {
      id: 59,
      substanceWorkCondition: "Disinfectants (alcohol-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5674,
    hazzard: {
      id: 59,
      substanceWorkCondition: "Disinfectants (alcohol-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5675,
    hazzard: {
      id: 60,
      substanceWorkCondition: "Disinfectants (formaldehyd-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5676,
    hazzard: {
      id: 60,
      substanceWorkCondition: "Disinfectants (formaldehyd-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5677,
    hazzard: {
      id: 61,
      substanceWorkCondition: "Disinfectants (water-based) ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5678,
    hazzard: {
      id: 61,
      substanceWorkCondition: "Disinfectants (water-based) ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5679,
    hazzard: {
      id: 62,
      substanceWorkCondition: "Dispersing Agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5680,
    hazzard: {
      id: 62,
      substanceWorkCondition: "Dispersing Agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5681,
    hazzard: {
      id: 63,
      substanceWorkCondition: "Drilling emulsion (water based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5682,
    hazzard: {
      id: 63,
      substanceWorkCondition: "Drilling emulsion (water based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5683,
    hazzard: {
      id: 64,
      substanceWorkCondition: "Drilling oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5684,
    hazzard: {
      id: 64,
      substanceWorkCondition: "Drilling oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5685,
    hazzard: {
      id: 65,
      substanceWorkCondition: "Dryness",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5686,
    hazzard: {
      id: 65,
      substanceWorkCondition: "Dryness",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5687,
    hazzard: {
      id: 66,
      substanceWorkCondition: "Dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5688,
    hazzard: {
      id: 66,
      substanceWorkCondition: "Dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5689,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5690,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5691,
    hazzard: {
      id: 70,
      substanceWorkCondition: "Epoxy resin",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5692,
    hazzard: {
      id: 70,
      substanceWorkCondition: "Epoxy resin",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5693,
    hazzard: {
      id: 71,
      substanceWorkCondition: "Etching (mordant)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5694,
    hazzard: {
      id: 71,
      substanceWorkCondition: "Etching (mordant)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5695,
    hazzard: {
      id: 72,
      substanceWorkCondition: "Ethanol / methylated spirits ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5696,
    hazzard: {
      id: 72,
      substanceWorkCondition: "Ethanol / methylated spirits ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5697,
    hazzard: {
      id: 73,
      substanceWorkCondition: "Fat",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5698,
    hazzard: {
      id: 73,
      substanceWorkCondition: "Fat",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5699,
    hazzard: {
      id: 74,
      substanceWorkCondition: "Fertilizer",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5700,
    hazzard: {
      id: 74,
      substanceWorkCondition: "Fertilizer",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5701,
    hazzard: {
      id: 75,
      substanceWorkCondition: "fine anorganic/inorganic powders ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5702,
    hazzard: {
      id: 75,
      substanceWorkCondition: "fine anorganic/inorganic powders ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5703,
    hazzard: {
      id: 76,
      substanceWorkCondition: "Floor cleaner (water- based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5704,
    hazzard: {
      id: 76,
      substanceWorkCondition: "Floor cleaner (water- based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5705,
    hazzard: {
      id: 80,
      substanceWorkCondition: "Food Dyestuff",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5706,
    hazzard: {
      id: 81,
      substanceWorkCondition: "fuel: gas / diesel",
      cas: null,
      glovesRecommended: true,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5707,
    hazzard: {
      id: 81,
      substanceWorkCondition: "fuel: gas / diesel",
      cas: null,
      glovesRecommended: true,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5708,
    hazzard: {
      id: 82,
      substanceWorkCondition: "Galvanic bath",
      cas: null,
      glovesRecommended: true,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5709,
    hazzard: {
      id: 82,
      substanceWorkCondition: "Galvanic bath",
      cas: null,
      glovesRecommended: true,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5710,
    hazzard: {
      id: 83,
      substanceWorkCondition: "Glass fibre dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5711,
    hazzard: {
      id: 83,
      substanceWorkCondition: "Glass fibre dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5712,
    hazzard: {
      id: 84,
      substanceWorkCondition: "Glass wool dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5713,
    hazzard: {
      id: 84,
      substanceWorkCondition: "Glass wool dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5714,
    hazzard: {
      id: 85,
      substanceWorkCondition: "Glycols",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5715,
    hazzard: {
      id: 85,
      substanceWorkCondition: "Glycols",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5716,
    hazzard: {
      id: 86,
      substanceWorkCondition: "Graphite",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5717,
    hazzard: {
      id: 86,
      substanceWorkCondition: "Graphite",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5718,
    hazzard: {
      id: 87,
      substanceWorkCondition: "Grease",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5719,
    hazzard: {
      id: 87,
      substanceWorkCondition: "Grease",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5720,
    hazzard: {
      id: 88,
      substanceWorkCondition: "Grinding oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5721,
    hazzard: {
      id: 88,
      substanceWorkCondition: "Grinding oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5722,
    hazzard: {
      id: 89,
      substanceWorkCondition: "Grinding water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5723,
    hazzard: {
      id: 89,
      substanceWorkCondition: "Grinding water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5724,
    hazzard: {
      id: 90,
      substanceWorkCondition: "Gypsum",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5725,
    hazzard: {
      id: 90,
      substanceWorkCondition: "Gypsum",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5726,
    hazzard: {
      id: 95,
      substanceWorkCondition: "Hydraulic fluid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5727,
    hazzard: {
      id: 95,
      substanceWorkCondition: "Hydraulic fluid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5728,
    hazzard: {
      id: 96,
      substanceWorkCondition: "Hyrocarbons",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5729,
    hazzard: {
      id: 96,
      substanceWorkCondition: "Hyrocarbons",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5730,
    hazzard: {
      id: 119,
      substanceWorkCondition: "Ink / printing ink",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5731,
    hazzard: {
      id: 119,
      substanceWorkCondition: "Ink / printing ink",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5732,
    hazzard: {
      id: 120,
      substanceWorkCondition: "Insulating material",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5733,
    hazzard: {
      id: 120,
      substanceWorkCondition: "Insulating material",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5734,
    hazzard: {
      id: 121,
      substanceWorkCondition: "Kerosene",
      cas: null,
      glovesRecommended: true,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5735,
    hazzard: {
      id: 121,
      substanceWorkCondition: "Kerosene",
      cas: null,
      glovesRecommended: true,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5736,
    hazzard: {
      id: 122,
      substanceWorkCondition: "Ketone",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5737,
    hazzard: {
      id: 122,
      substanceWorkCondition: "Ketone",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5738,
    hazzard: {
      id: 123,
      substanceWorkCondition: "Lacquers (nitro/solvent-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5739,
    hazzard: {
      id: 123,
      substanceWorkCondition: "Lacquers (nitro/solvent-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5740,
    hazzard: {
      id: 124,
      substanceWorkCondition: "Lacquers (PU based, synthetic resin based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5741,
    hazzard: {
      id: 124,
      substanceWorkCondition: "Lacquers (PU based, synthetic resin based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5742,
    hazzard: {
      id: 125,
      substanceWorkCondition: "Lacquers (UV-hardening)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5743,
    hazzard: {
      id: 125,
      substanceWorkCondition: "Lacquers (UV-hardening)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5744,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5745,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5746,
    hazzard: {
      id: 127,
      substanceWorkCondition: "Latex paint",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5747,
    hazzard: {
      id: 127,
      substanceWorkCondition: "Latex paint",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5748,
    hazzard: {
      id: 128,
      substanceWorkCondition: "Lime",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5749,
    hazzard: {
      id: 128,
      substanceWorkCondition: "Lime",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5750,
    hazzard: {
      id: 129,
      substanceWorkCondition: "Lubricants, water-based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5751,
    hazzard: {
      id: 129,
      substanceWorkCondition: "Lubricants, water-based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5752,
    hazzard: {
      id: 130,
      substanceWorkCondition: "Metal dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5753,
    hazzard: {
      id: 130,
      substanceWorkCondition: "Metal dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5754,
    hazzard: {
      id: 131,
      substanceWorkCondition: "Methanol / Methyl Alcohol",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5755,
    hazzard: {
      id: 131,
      substanceWorkCondition: "Methanol / Methyl Alcohol",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5756,
    hazzard: {
      id: 132,
      substanceWorkCondition: "Mineral oil / paraffin oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5757,
    hazzard: {
      id: 132,
      substanceWorkCondition: "Mineral oil / paraffin oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5758,
    hazzard: {
      id: 133,
      substanceWorkCondition: "Multi component adhesive",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:11.000Z",
    updated_at: "2020-03-02T22:50:11.000Z"
  },
  {
    id: 5759,
    hazzard: {
      id: 133,
      substanceWorkCondition: "Multi component adhesive",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5760,
    hazzard: {
      id: 134,
      substanceWorkCondition: "Multi component resin",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5761,
    hazzard: {
      id: 134,
      substanceWorkCondition: "Multi component resin",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5762,
    hazzard: {
      id: 135,
      substanceWorkCondition: "Naphthalene",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5763,
    hazzard: {
      id: 135,
      substanceWorkCondition: "Naphthalene",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5764,
    hazzard: {
      id: 136,
      substanceWorkCondition: "Oil coke (carbon)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5765,
    hazzard: {
      id: 136,
      substanceWorkCondition: "Oil coke (carbon)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5766,
    hazzard: {
      id: 137,
      substanceWorkCondition: "Oil-emulsions (water- mixed)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5767,
    hazzard: {
      id: 137,
      substanceWorkCondition: "Oil-emulsions (water- mixed)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5768,
    hazzard: {
      id: 138,
      substanceWorkCondition: "Oils / Oil based lubricants",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5769,
    hazzard: {
      id: 138,
      substanceWorkCondition: "Oils / Oil based lubricants",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5770,
    hazzard: {
      id: 140,
      substanceWorkCondition: "Paint Thinner ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5771,
    hazzard: {
      id: 140,
      substanceWorkCondition: "Paint Thinner ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5772,
    hazzard: {
      id: 139,
      substanceWorkCondition: "Paint",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5773,
    hazzard: {
      id: 139,
      substanceWorkCondition: "Paint",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5774,
    hazzard: {
      id: 141,
      substanceWorkCondition: "Pit water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5775,
    hazzard: {
      id: 141,
      substanceWorkCondition: "Pit water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5776,
    hazzard: {
      id: 142,
      substanceWorkCondition: "Pitch",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5777,
    hazzard: {
      id: 142,
      substanceWorkCondition: "Pitch",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5778,
    hazzard: {
      id: 143,
      substanceWorkCondition: "Polishes (water- soluble)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5779,
    hazzard: {
      id: 143,
      substanceWorkCondition: "Polishes (water- soluble)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5780,
    hazzard: {
      id: 144,
      substanceWorkCondition: "Polyester / Polyester resin",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5781,
    hazzard: {
      id: 144,
      substanceWorkCondition: "Polyester / Polyester resin",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5782,
    hazzard: {
      id: 145,
      substanceWorkCondition: "Polyurethane",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5783,
    hazzard: {
      id: 145,
      substanceWorkCondition: "Polyurethane",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5784,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5785,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5786,
    hazzard: {
      id: 148,
      substanceWorkCondition:
        "Propanols (propyl alcohols): n-Propanol, Isopropanol / 2-Propanol ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5787,
    hazzard: {
      id: 148,
      substanceWorkCondition:
        "Propanols (propyl alcohols): n-Propanol, Isopropanol / 2-Propanol ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5788,
    hazzard: {
      id: 149,
      substanceWorkCondition: "release agent, parting agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5789,
    hazzard: {
      id: 149,
      substanceWorkCondition: "release agent, parting agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5790,
    hazzard: {
      id: 150,
      substanceWorkCondition: "Resins / urea resins",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5791,
    hazzard: {
      id: 150,
      substanceWorkCondition: "Resins / urea resins",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5792,
    hazzard: {
      id: 151,
      substanceWorkCondition: "Rock wool",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5793,
    hazzard: {
      id: 151,
      substanceWorkCondition: "Rock wool",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5794,
    hazzard: {
      id: 152,
      substanceWorkCondition: "Rubber",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5795,
    hazzard: {
      id: 152,
      substanceWorkCondition: "Rubber",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5796,
    hazzard: {
      id: 153,
      substanceWorkCondition: "Salts / Salt solution",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5797,
    hazzard: {
      id: 153,
      substanceWorkCondition: "Salts / Salt solution",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5798,
    hazzard: {
      id: 154,
      substanceWorkCondition: "Sealing compound",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5799,
    hazzard: {
      id: 154,
      substanceWorkCondition: "Sealing compound",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5800,
    hazzard: {
      id: 155,
      substanceWorkCondition: "Sintering",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5801,
    hazzard: {
      id: 155,
      substanceWorkCondition: "Sintering",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5802,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5803,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5804,
    hazzard: {
      id: 157,
      substanceWorkCondition: "Solvents, - organic",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5805,
    hazzard: {
      id: 157,
      substanceWorkCondition: "Solvents, - organic",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5806,
    hazzard: {
      id: 158,
      substanceWorkCondition: "Soot",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5807,
    hazzard: {
      id: 158,
      substanceWorkCondition: "Soot",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5808,
    hazzard: {
      id: 159,
      substanceWorkCondition: "Styrene",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5809,
    hazzard: {
      id: 159,
      substanceWorkCondition: "Styrene",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5810,
    hazzard: {
      id: 160,
      substanceWorkCondition: "Surface Cleanser (surfactant based), Detergents",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5811,
    hazzard: {
      id: 160,
      substanceWorkCondition: "Surface Cleanser (surfactant based), Detergents",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5812,
    hazzard: {
      id: 161,
      substanceWorkCondition: "Synthetic melting glaze",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5813,
    hazzard: {
      id: 161,
      substanceWorkCondition: "Synthetic melting glaze",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5814,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5815,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5816,
    hazzard: {
      id: 163,
      substanceWorkCondition: "Tanning agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5817,
    hazzard: {
      id: 163,
      substanceWorkCondition: "Tanning agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5818,
    hazzard: {
      id: 164,
      substanceWorkCondition: "Tar",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5819,
    hazzard: {
      id: 164,
      substanceWorkCondition: "Tar",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5820,
    hazzard: {
      id: 166,
      substanceWorkCondition: "Thinner",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5821,
    hazzard: {
      id: 166,
      substanceWorkCondition: "Thinner",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5822,
    hazzard: {
      id: 167,
      substanceWorkCondition: "Toluene",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5823,
    hazzard: {
      id: 167,
      substanceWorkCondition: "Toluene",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5824,
    hazzard: {
      id: 168,
      substanceWorkCondition: "Turpentine oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5825,
    hazzard: {
      id: 168,
      substanceWorkCondition: "Turpentine oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5826,
    hazzard: {
      id: 170,
      substanceWorkCondition: "Washing bath, washing liquid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5827,
    hazzard: {
      id: 170,
      substanceWorkCondition: "Washing bath, washing liquid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5828,
    hazzard: {
      id: 171,
      substanceWorkCondition: "Waste oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5829,
    hazzard: {
      id: 171,
      substanceWorkCondition: "Waste oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5830,
    hazzard: {
      id: 172,
      substanceWorkCondition: "Water / wet work",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5831,
    hazzard: {
      id: 172,
      substanceWorkCondition: "Water / wet work",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5832,
    hazzard: {
      id: 173,
      substanceWorkCondition:
        "Wearing protective clothing / Softening of the skin (Maceration)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5833,
    hazzard: {
      id: 173,
      substanceWorkCondition:
        "Wearing protective clothing / Softening of the skin (Maceration)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5834,
    hazzard: {
      id: 174,
      substanceWorkCondition: "wire drawing oil / wire drawing grease",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5835,
    hazzard: {
      id: 174,
      substanceWorkCondition: "wire drawing oil / wire drawing grease",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5836,
    hazzard: {
      id: 175,
      substanceWorkCondition: "Xylene",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 2,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5837,
    hazzard: {
      id: 175,
      substanceWorkCondition: "Xylene",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 2,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Gloves",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5838,
    hazzard: {
      id: 1,
      substanceWorkCondition: "2-Butanone (MEK)",
      cas: "78-93-3",
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: "oil",
      created_at: "2020-03-02T17:39:32.000Z",
      updated_at: "2020-03-05T14:48:01.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5839,
    hazzard: {
      id: 2,
      substanceWorkCondition: "Acetone / Dimethyl ketone",
      cas: "67-64-1",
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5840,
    hazzard: {
      id: 3,
      substanceWorkCondition: "Acids, diluted",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5841,
    hazzard: {
      id: 4,
      substanceWorkCondition: "Acrylates",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5842,
    hazzard: {
      id: 5,
      substanceWorkCondition: "Adhesive single-component",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5843,
    hazzard: {
      id: 6,
      substanceWorkCondition: "Adhesive, water-soluble",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5844,
    hazzard: {
      id: 7,
      substanceWorkCondition: "Adhesives, glues based on synthetic resin",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5845,
    hazzard: {
      id: 8,
      substanceWorkCondition: "Aldehydes, diluted",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5846,
    hazzard: {
      id: 9,
      substanceWorkCondition: "Alkaline solutions / lyes / thinned caustics",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5847,
    hazzard: {
      id: 10,
      substanceWorkCondition: "Alkaline solutions, diluted",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5848,
    hazzard: {
      id: 11,
      substanceWorkCondition: "Alkaline solutions, lyes (solvent based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5849,
    hazzard: {
      id: 12,
      substanceWorkCondition: "Aluminium",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5850,
    hazzard: {
      id: 14,
      substanceWorkCondition: "Antistatics",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5851,
    hazzard: {
      id: 15,
      substanceWorkCondition: "Asphalt",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5852,
    hazzard: {
      id: 19,
      substanceWorkCondition: "Battery acid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5853,
    hazzard: {
      id: 20,
      substanceWorkCondition: "Benzene",
      cas: null,
      glovesRecommended: true,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5854,
    hazzard: {
      id: 22,
      substanceWorkCondition: "Bitumen emulsion",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5855,
    hazzard: {
      id: 21,
      substanceWorkCondition: "Bitumen",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5856,
    hazzard: {
      id: 23,
      substanceWorkCondition: "Bleaching agent (lye)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5857,
    hazzard: {
      id: 24,
      substanceWorkCondition: "Brake fluid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5858,
    hazzard: {
      id: 25,
      substanceWorkCondition: "Casting dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5859,
    hazzard: {
      id: 26,
      substanceWorkCondition: "Cement (dry)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5860,
    hazzard: {
      id: 27,
      substanceWorkCondition: "Cement (wet)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5861,
    hazzard: {
      id: 28,
      substanceWorkCondition: "Cement, putty epoxy-based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5862,
    hazzard: {
      id: 29,
      substanceWorkCondition:
        "Chromates (water- insoluble) = Chromium pigments",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5863,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5864,
    hazzard: {
      id: 31,
      substanceWorkCondition: "cleaning agent, detergent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5865,
    hazzard: {
      id: 32,
      substanceWorkCondition: "Coal dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5866,
    hazzard: {
      id: 33,
      substanceWorkCondition: "Coating materials, bituminous (bitumen varnish)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5867,
    hazzard: {
      id: 34,
      substanceWorkCondition: "Coating materials, emulsified",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5868,
    hazzard: {
      id: 35,
      substanceWorkCondition: "Coating materials, oily",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5869,
    hazzard: {
      id: 36,
      substanceWorkCondition: "Cold degreasing agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5870,
    hazzard: {
      id: 37,
      substanceWorkCondition: "cold detergent, solvent based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5871,
    hazzard: {
      id: 47,
      substanceWorkCondition: "Concrete",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5872,
    hazzard: {
      id: 48,
      substanceWorkCondition: "Cooling lubricant (non-water-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5873,
    hazzard: {
      id: 49,
      substanceWorkCondition: "Cooling lubricant (water- based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5874,
    hazzard: {
      id: 50,
      substanceWorkCondition: "Cooling water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5875,
    hazzard: {
      id: 51,
      substanceWorkCondition: "Copying ink",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5876,
    hazzard: {
      id: 52,
      substanceWorkCondition: "Cutting oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5877,
    hazzard: {
      id: 53,
      substanceWorkCondition: "Degreaser solvent - based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5878,
    hazzard: {
      id: 54,
      substanceWorkCondition: "Degreaser without organic solvent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5879,
    hazzard: {
      id: 55,
      substanceWorkCondition: "Detachment solution for concrete",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5880,
    hazzard: {
      id: 56,
      substanceWorkCondition: "Detachments (alcohol-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5881,
    hazzard: {
      id: 57,
      substanceWorkCondition: "Detachments (solvent-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5882,
    hazzard: {
      id: 58,
      substanceWorkCondition: "Detachments (sour)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5883,
    hazzard: {
      id: 59,
      substanceWorkCondition: "Disinfectants (alcohol-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5884,
    hazzard: {
      id: 60,
      substanceWorkCondition: "Disinfectants (formaldehyd-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5885,
    hazzard: {
      id: 61,
      substanceWorkCondition: "Disinfectants (water-based) ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5886,
    hazzard: {
      id: 62,
      substanceWorkCondition: "Dispersing Agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5887,
    hazzard: {
      id: 63,
      substanceWorkCondition: "Drilling emulsion (water based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:12.000Z",
    updated_at: "2020-03-02T22:50:12.000Z"
  },
  {
    id: 5888,
    hazzard: {
      id: 64,
      substanceWorkCondition: "Drilling oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5889,
    hazzard: {
      id: 65,
      substanceWorkCondition: "Dryness",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5890,
    hazzard: {
      id: 66,
      substanceWorkCondition: "Dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5891,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5892,
    hazzard: {
      id: 70,
      substanceWorkCondition: "Epoxy resin",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5893,
    hazzard: {
      id: 71,
      substanceWorkCondition: "Etching (mordant)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5894,
    hazzard: {
      id: 72,
      substanceWorkCondition: "Ethanol / methylated spirits ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5895,
    hazzard: {
      id: 73,
      substanceWorkCondition: "Fat",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5896,
    hazzard: {
      id: 74,
      substanceWorkCondition: "Fertilizer",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5897,
    hazzard: {
      id: 75,
      substanceWorkCondition: "fine anorganic/inorganic powders ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5898,
    hazzard: {
      id: 76,
      substanceWorkCondition: "Floor cleaner (water- based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5899,
    hazzard: {
      id: 80,
      substanceWorkCondition: "Food Dyestuff",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5900,
    hazzard: {
      id: 81,
      substanceWorkCondition: "fuel: gas / diesel",
      cas: null,
      glovesRecommended: true,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5901,
    hazzard: {
      id: 82,
      substanceWorkCondition: "Galvanic bath",
      cas: null,
      glovesRecommended: true,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5902,
    hazzard: {
      id: 83,
      substanceWorkCondition: "Glass fibre dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5903,
    hazzard: {
      id: 84,
      substanceWorkCondition: "Glass wool dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5904,
    hazzard: {
      id: 85,
      substanceWorkCondition: "Glycols",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5905,
    hazzard: {
      id: 86,
      substanceWorkCondition: "Graphite",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5906,
    hazzard: {
      id: 87,
      substanceWorkCondition: "Grease",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5907,
    hazzard: {
      id: 88,
      substanceWorkCondition: "Grinding oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5908,
    hazzard: {
      id: 89,
      substanceWorkCondition: "Grinding water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5909,
    hazzard: {
      id: 90,
      substanceWorkCondition: "Gypsum",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5910,
    hazzard: {
      id: 95,
      substanceWorkCondition: "Hydraulic fluid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5911,
    hazzard: {
      id: 96,
      substanceWorkCondition: "Hyrocarbons",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5912,
    hazzard: {
      id: 97,
      substanceWorkCondition: "Industry Heavy - Building Materials",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5913,
    hazzard: {
      id: 99,
      substanceWorkCondition: "Industry Heavy - Mining & Extraction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5914,
    hazzard: {
      id: 100,
      substanceWorkCondition: "Industry Heavy - Off-Shore Extraction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5915,
    hazzard: {
      id: 106,
      substanceWorkCondition:
        "Industry Light - Airports & Transportation Terminals - Services & Facility Maintenance",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5916,
    hazzard: {
      id: 107,
      substanceWorkCondition: "Industry Light - Emergency Services",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5917,
    hazzard: {
      id: 108,
      substanceWorkCondition:
        "Industry Light - General Manufacturing & Assembly",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5918,
    hazzard: {
      id: 109,
      substanceWorkCondition:
        "Industry Light - Hosiery, Knitware, Fabric & Clothing Manufacturing",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5919,
    hazzard: {
      id: 110,
      substanceWorkCondition: "Industry Light - Science & Laboratories",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5920,
    hazzard: {
      id: 111,
      substanceWorkCondition: "Industry Light - Warehousing & Logistics",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5921,
    hazzard: {
      id: 112,
      substanceWorkCondition: "Industry Medium - Ceramics & Glassware",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5922,
    hazzard: {
      id: 113,
      substanceWorkCondition: "Industry Medium - Chemical Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5923,
    hazzard: {
      id: 115,
      substanceWorkCondition: "Industry Medium - Metal Working & Fabrication",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5924,
    hazzard: {
      id: 116,
      substanceWorkCondition: "Industry Medium - Outdoor Maintenance & Repair",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5925,
    hazzard: {
      id: 117,
      substanceWorkCondition: "Industry Medium - Pharmaceutical Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5926,
    hazzard: {
      id: 119,
      substanceWorkCondition: "Ink / printing ink",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5927,
    hazzard: {
      id: 120,
      substanceWorkCondition: "Insulating material",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5928,
    hazzard: {
      id: 121,
      substanceWorkCondition: "Kerosene",
      cas: null,
      glovesRecommended: true,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5929,
    hazzard: {
      id: 122,
      substanceWorkCondition: "Ketone",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5930,
    hazzard: {
      id: 123,
      substanceWorkCondition: "Lacquers (nitro/solvent-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5931,
    hazzard: {
      id: 124,
      substanceWorkCondition: "Lacquers (PU based, synthetic resin based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5932,
    hazzard: {
      id: 125,
      substanceWorkCondition: "Lacquers (UV-hardening)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5933,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5934,
    hazzard: {
      id: 127,
      substanceWorkCondition: "Latex paint",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5935,
    hazzard: {
      id: 128,
      substanceWorkCondition: "Lime",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5936,
    hazzard: {
      id: 129,
      substanceWorkCondition: "Lubricants, water-based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5937,
    hazzard: {
      id: 130,
      substanceWorkCondition: "Metal dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5938,
    hazzard: {
      id: 131,
      substanceWorkCondition: "Methanol / Methyl Alcohol",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5939,
    hazzard: {
      id: 132,
      substanceWorkCondition: "Mineral oil / paraffin oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5940,
    hazzard: {
      id: 133,
      substanceWorkCondition: "Multi component adhesive",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5941,
    hazzard: {
      id: 134,
      substanceWorkCondition: "Multi component resin",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5942,
    hazzard: {
      id: 135,
      substanceWorkCondition: "Naphthalene",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5943,
    hazzard: {
      id: 136,
      substanceWorkCondition: "Oil coke (carbon)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5944,
    hazzard: {
      id: 137,
      substanceWorkCondition: "Oil-emulsions (water- mixed)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5945,
    hazzard: {
      id: 138,
      substanceWorkCondition: "Oils / Oil based lubricants",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5946,
    hazzard: {
      id: 140,
      substanceWorkCondition: "Paint Thinner ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5947,
    hazzard: {
      id: 139,
      substanceWorkCondition: "Paint",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5948,
    hazzard: {
      id: 141,
      substanceWorkCondition: "Pit water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5949,
    hazzard: {
      id: 142,
      substanceWorkCondition: "Pitch",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5950,
    hazzard: {
      id: 143,
      substanceWorkCondition: "Polishes (water- soluble)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5951,
    hazzard: {
      id: 144,
      substanceWorkCondition: "Polyester / Polyester resin",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5952,
    hazzard: {
      id: 145,
      substanceWorkCondition: "Polyurethane",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5953,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5954,
    hazzard: {
      id: 148,
      substanceWorkCondition:
        "Propanols (propyl alcohols): n-Propanol, Isopropanol / 2-Propanol ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5955,
    hazzard: {
      id: 149,
      substanceWorkCondition: "release agent, parting agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5956,
    hazzard: {
      id: 150,
      substanceWorkCondition: "Resins / urea resins",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5957,
    hazzard: {
      id: 151,
      substanceWorkCondition: "Rock wool",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5958,
    hazzard: {
      id: 152,
      substanceWorkCondition: "Rubber",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5959,
    hazzard: {
      id: 153,
      substanceWorkCondition: "Salts / Salt solution",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5960,
    hazzard: {
      id: 154,
      substanceWorkCondition: "Sealing compound",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5961,
    hazzard: {
      id: 155,
      substanceWorkCondition: "Sintering",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5962,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5963,
    hazzard: {
      id: 157,
      substanceWorkCondition: "Solvents, - organic",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5964,
    hazzard: {
      id: 158,
      substanceWorkCondition: "Soot",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5965,
    hazzard: {
      id: 159,
      substanceWorkCondition: "Styrene",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5966,
    hazzard: {
      id: 160,
      substanceWorkCondition: "Surface Cleanser (surfactant based), Detergents",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5967,
    hazzard: {
      id: 161,
      substanceWorkCondition: "Synthetic melting glaze",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5968,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5969,
    hazzard: {
      id: 163,
      substanceWorkCondition: "Tanning agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5970,
    hazzard: {
      id: 164,
      substanceWorkCondition: "Tar",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5971,
    hazzard: {
      id: 166,
      substanceWorkCondition: "Thinner",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5972,
    hazzard: {
      id: 167,
      substanceWorkCondition: "Toluene",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5973,
    hazzard: {
      id: 168,
      substanceWorkCondition: "Turpentine oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5974,
    hazzard: {
      id: 169,
      substanceWorkCondition: "UV-rays (A/B) / Sunlight",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5975,
    hazzard: {
      id: 170,
      substanceWorkCondition: "Washing bath, washing liquid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5976,
    hazzard: {
      id: 171,
      substanceWorkCondition: "Waste oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5977,
    hazzard: {
      id: 172,
      substanceWorkCondition: "Water / wet work",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5978,
    hazzard: {
      id: 173,
      substanceWorkCondition:
        "Wearing protective clothing / Softening of the skin (Maceration)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5979,
    hazzard: {
      id: 174,
      substanceWorkCondition: "wire drawing oil / wire drawing grease",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5980,
    hazzard: {
      id: 175,
      substanceWorkCondition: "Xylene",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5981,
    hazzard: {
      id: 999,
      substanceWorkCondition: "All Products",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:44:03.000Z",
      updated_at: "2020-03-02T17:44:03.000Z"
    },
    step: "Cleanse",
    productOrder: 5,
    locationOrder: 4,
    skin_care_product: {
      id: 25,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:38:15.000Z",
      productName: "Refresh™ Hair & Body",
      productURL: "https://www.debgroup.com/us/products/refresh-hair-body"
    },
    rating: 1,
    location: "Hair",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: false,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5982,
    hazzard: {
      id: 999,
      substanceWorkCondition: "All Products",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:44:03.000Z",
      updated_at: "2020-03-02T17:44:03.000Z"
    },
    step: "Cleanse",
    productOrder: 6,
    locationOrder: 4,
    skin_care_product: {
      id: 26,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:38:51.000Z",
      productName: "Refresh™ Luxury 3-in-1 Hair & Body",
      productURL:
        "https://www.debgroup.com/us/products/refresh-luxury-3-1-hair-body"
    },
    rating: 1,
    location: "Hair",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: null,
    ecoLogo: false,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5983,
    hazzard: {
      id: 999,
      substanceWorkCondition: "All Products",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:44:03.000Z",
      updated_at: "2020-03-02T17:44:03.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hair",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5984,
    hazzard: {
      id: 1,
      substanceWorkCondition: "2-Butanone (MEK)",
      cas: "78-93-3",
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: "oil",
      created_at: "2020-03-02T17:39:32.000Z",
      updated_at: "2020-03-05T14:48:01.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5985,
    hazzard: {
      id: 2,
      substanceWorkCondition: "Acetone / Dimethyl ketone",
      cas: "67-64-1",
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5986,
    hazzard: {
      id: 3,
      substanceWorkCondition: "Acids, diluted",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5987,
    hazzard: {
      id: 4,
      substanceWorkCondition: "Acrylates",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Protect",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 43,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:50.000Z",
      productName: "Travabon® Classic",
      productURL: "https://www.debgroup.com/us/products/travabon-classic"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: null,
    hair: false,
    glove: false,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5988,
    hazzard: {
      id: 6,
      substanceWorkCondition: "Adhesive, water-soluble",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5989,
    hazzard: {
      id: 8,
      substanceWorkCondition: "Aldehydes, diluted",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5990,
    hazzard: {
      id: 9,
      substanceWorkCondition: "Alkaline solutions / lyes / thinned caustics",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5991,
    hazzard: {
      id: 10,
      substanceWorkCondition: "Alkaline solutions, diluted",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5992,
    hazzard: {
      id: 11,
      substanceWorkCondition: "Alkaline solutions, lyes (solvent based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5993,
    hazzard: {
      id: 12,
      substanceWorkCondition: "Aluminium",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 34,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:44:37.000Z",
      productName: "Stokoderm® Advanced",
      productURL: "https://www.debgroup.com/us/products/stokoderm-advanced-0"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: true,
    hair: false,
    glove: false,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5994,
    hazzard: {
      id: 14,
      substanceWorkCondition: "Antistatics",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5995,
    hazzard: {
      id: 14,
      substanceWorkCondition: "Antistatics",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 34,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:44:37.000Z",
      productName: "Stokoderm® Advanced",
      productURL: "https://www.debgroup.com/us/products/stokoderm-advanced-0"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: true,
    hair: false,
    glove: false,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5996,
    hazzard: {
      id: 15,
      substanceWorkCondition: "Asphalt",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5997,
    hazzard: {
      id: 15,
      substanceWorkCondition: "Asphalt",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 34,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:44:37.000Z",
      productName: "Stokoderm® Advanced",
      productURL: "https://www.debgroup.com/us/products/stokoderm-advanced-0"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: true,
    hair: false,
    glove: false,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5998,
    hazzard: {
      id: 15,
      substanceWorkCondition: "Asphalt",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 43,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:50.000Z",
      productName: "Travabon® Classic",
      productURL: "https://www.debgroup.com/us/products/travabon-classic"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: null,
    hair: false,
    glove: false,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 5999,
    hazzard: {
      id: 18,
      substanceWorkCondition:
        "Automotive Aftermarket - Public Transport Maintenance&Repair",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 6000,
    hazzard: {
      id: 18,
      substanceWorkCondition:
        "Automotive Aftermarket - Public Transport Maintenance&Repair",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 34,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:44:37.000Z",
      productName: "Stokoderm® Advanced",
      productURL: "https://www.debgroup.com/us/products/stokoderm-advanced-0"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: true,
    hair: false,
    glove: false,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 6001,
    hazzard: {
      id: 19,
      substanceWorkCondition: "Battery acid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 6002,
    hazzard: {
      id: 22,
      substanceWorkCondition: "Bitumen emulsion",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 6003,
    hazzard: {
      id: 22,
      substanceWorkCondition: "Bitumen emulsion",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 34,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:44:37.000Z",
      productName: "Stokoderm® Advanced",
      productURL: "https://www.debgroup.com/us/products/stokoderm-advanced-0"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: true,
    hair: false,
    glove: false,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 6004,
    hazzard: {
      id: 21,
      substanceWorkCondition: "Bitumen",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 34,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:44:37.000Z",
      productName: "Stokoderm® Advanced",
      productURL: "https://www.debgroup.com/us/products/stokoderm-advanced-0"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: true,
    hair: false,
    glove: false,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 6005,
    hazzard: {
      id: 23,
      substanceWorkCondition: "Bleaching agent (lye)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 6006,
    hazzard: {
      id: 24,
      substanceWorkCondition: "Brake fluid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 6007,
    hazzard: {
      id: 25,
      substanceWorkCondition: "Casting dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 34,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:44:37.000Z",
      productName: "Stokoderm® Advanced",
      productURL: "https://www.debgroup.com/us/products/stokoderm-advanced-0"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: true,
    hair: false,
    glove: false,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 6008,
    hazzard: {
      id: 26,
      substanceWorkCondition: "Cement (dry)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 6009,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 6010,
    hazzard: {
      id: 31,
      substanceWorkCondition: "cleaning agent, detergent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 6011,
    hazzard: {
      id: 32,
      substanceWorkCondition: "Coal dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 34,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:44:37.000Z",
      productName: "Stokoderm® Advanced",
      productURL: "https://www.debgroup.com/us/products/stokoderm-advanced-0"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: true,
    hair: false,
    glove: false,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 6012,
    hazzard: {
      id: 33,
      substanceWorkCondition: "Coating materials, bituminous (bitumen varnish)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 34,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:44:37.000Z",
      productName: "Stokoderm® Advanced",
      productURL: "https://www.debgroup.com/us/products/stokoderm-advanced-0"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: true,
    hair: false,
    glove: false,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 6013,
    hazzard: {
      id: 34,
      substanceWorkCondition: "Coating materials, emulsified",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 34,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:44:37.000Z",
      productName: "Stokoderm® Advanced",
      productURL: "https://www.debgroup.com/us/products/stokoderm-advanced-0"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: true,
    hair: false,
    glove: false,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 6014,
    hazzard: {
      id: 35,
      substanceWorkCondition: "Coating materials, oily",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 34,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:44:37.000Z",
      productName: "Stokoderm® Advanced",
      productURL: "https://www.debgroup.com/us/products/stokoderm-advanced-0"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: true,
    hair: false,
    glove: false,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 6015,
    hazzard: {
      id: 36,
      substanceWorkCondition: "Cold degreasing agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 43,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:50.000Z",
      productName: "Travabon® Classic",
      productURL: "https://www.debgroup.com/us/products/travabon-classic"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: null,
    hair: false,
    glove: false,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 6016,
    hazzard: {
      id: 37,
      substanceWorkCondition: "cold detergent, solvent based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 43,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:50.000Z",
      productName: "Travabon® Classic",
      productURL: "https://www.debgroup.com/us/products/travabon-classic"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: null,
    hair: false,
    glove: false,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 6017,
    hazzard: {
      id: 38,
      substanceWorkCondition: "Cold working conditions",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 6018,
    hazzard: {
      id: 43,
      substanceWorkCondition: "Commercial - Hotel & Leisure",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 6019,
    hazzard: {
      id: 44,
      substanceWorkCondition: "Commercial - Prisons & Security Services",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 6020,
    hazzard: {
      id: 45,
      substanceWorkCondition:
        "Commercial - Public Entertainments & Theme Parks",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 6021,
    hazzard: {
      id: 48,
      substanceWorkCondition: "Cooling lubricant (non-water-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 6022,
    hazzard: {
      id: 48,
      substanceWorkCondition: "Cooling lubricant (non-water-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 43,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:50.000Z",
      productName: "Travabon® Classic",
      productURL: "https://www.debgroup.com/us/products/travabon-classic"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: null,
    hair: false,
    glove: false,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 6023,
    hazzard: {
      id: 49,
      substanceWorkCondition: "Cooling lubricant (water- based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 6024,
    hazzard: {
      id: 50,
      substanceWorkCondition: "Cooling water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 6025,
    hazzard: {
      id: 51,
      substanceWorkCondition: "Copying ink",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 6026,
    hazzard: {
      id: 51,
      substanceWorkCondition: "Copying ink",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 1,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 6027,
    hazzard: {
      id: 52,
      substanceWorkCondition: "Cutting oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 43,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:50.000Z",
      productName: "Travabon® Classic",
      productURL: "https://www.debgroup.com/us/products/travabon-classic"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: null,
    hair: false,
    glove: false,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 6028,
    hazzard: {
      id: 53,
      substanceWorkCondition: "Degreaser solvent - based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 43,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:50.000Z",
      productName: "Travabon® Classic",
      productURL: "https://www.debgroup.com/us/products/travabon-classic"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: null,
    hair: false,
    glove: false,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 6029,
    hazzard: {
      id: 54,
      substanceWorkCondition: "Degreaser without organic solvent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 6030,
    hazzard: {
      id: 54,
      substanceWorkCondition: "Degreaser without organic solvent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 34,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:44:37.000Z",
      productName: "Stokoderm® Advanced",
      productURL: "https://www.debgroup.com/us/products/stokoderm-advanced-0"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: true,
    hair: false,
    glove: false,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 6031,
    hazzard: {
      id: 55,
      substanceWorkCondition: "Detachment solution for concrete",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 6032,
    hazzard: {
      id: 55,
      substanceWorkCondition: "Detachment solution for concrete",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 34,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:44:37.000Z",
      productName: "Stokoderm® Advanced",
      productURL: "https://www.debgroup.com/us/products/stokoderm-advanced-0"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: true,
    hair: false,
    glove: false,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 6033,
    hazzard: {
      id: 56,
      substanceWorkCondition: "Detachments (alcohol-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 6034,
    hazzard: {
      id: 56,
      substanceWorkCondition: "Detachments (alcohol-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 34,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:44:37.000Z",
      productName: "Stokoderm® Advanced",
      productURL: "https://www.debgroup.com/us/products/stokoderm-advanced-0"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: true,
    hair: false,
    glove: false,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 6035,
    hazzard: {
      id: 57,
      substanceWorkCondition: "Detachments (solvent-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 34,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:44:37.000Z",
      productName: "Stokoderm® Advanced",
      productURL: "https://www.debgroup.com/us/products/stokoderm-advanced-0"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: true,
    hair: false,
    glove: false,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 6036,
    hazzard: {
      id: 57,
      substanceWorkCondition: "Detachments (solvent-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 6037,
    hazzard: {
      id: 58,
      substanceWorkCondition: "Detachments (sour)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 34,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:44:37.000Z",
      productName: "Stokoderm® Advanced",
      productURL: "https://www.debgroup.com/us/products/stokoderm-advanced-0"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: true,
    hair: false,
    glove: false,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 6038,
    hazzard: {
      id: 58,
      substanceWorkCondition: "Detachments (sour)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 6039,
    hazzard: {
      id: 59,
      substanceWorkCondition: "Disinfectants (alcohol-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:13.000Z",
    updated_at: "2020-03-02T22:50:13.000Z"
  },
  {
    id: 6040,
    hazzard: {
      id: 60,
      substanceWorkCondition: "Disinfectants (formaldehyd-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6041,
    hazzard: {
      id: 61,
      substanceWorkCondition: "Disinfectants (water-based) ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6042,
    hazzard: {
      id: 62,
      substanceWorkCondition: "Dispersing Agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6043,
    hazzard: {
      id: 63,
      substanceWorkCondition: "Drilling emulsion (water based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6044,
    hazzard: {
      id: 64,
      substanceWorkCondition: "Drilling oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 43,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:50.000Z",
      productName: "Travabon® Classic",
      productURL: "https://www.debgroup.com/us/products/travabon-classic"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: null,
    hair: false,
    glove: false,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6045,
    hazzard: {
      id: 65,
      substanceWorkCondition: "Dryness",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 1,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6046,
    hazzard: {
      id: 66,
      substanceWorkCondition: "Dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6047,
    hazzard: {
      id: 66,
      substanceWorkCondition: "Dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 43,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:50.000Z",
      productName: "Travabon® Classic",
      productURL: "https://www.debgroup.com/us/products/travabon-classic"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: null,
    hair: false,
    glove: false,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6048,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Protect",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 34,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:44:37.000Z",
      productName: "Stokoderm® Advanced",
      productURL: "https://www.debgroup.com/us/products/stokoderm-advanced-0"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: true,
    hair: false,
    glove: false,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6049,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6050,
    hazzard: {
      id: 71,
      substanceWorkCondition: "Etching (mordant)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6051,
    hazzard: {
      id: 72,
      substanceWorkCondition: "Ethanol / methylated spirits ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6052,
    hazzard: {
      id: 73,
      substanceWorkCondition: "Fat",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6053,
    hazzard: {
      id: 73,
      substanceWorkCondition: "Fat",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 43,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:50.000Z",
      productName: "Travabon® Classic",
      productURL: "https://www.debgroup.com/us/products/travabon-classic"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: null,
    hair: false,
    glove: false,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6054,
    hazzard: {
      id: 74,
      substanceWorkCondition: "Fertilizer",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 1,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6055,
    hazzard: {
      id: 75,
      substanceWorkCondition: "fine anorganic/inorganic powders ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6056,
    hazzard: {
      id: 75,
      substanceWorkCondition: "fine anorganic/inorganic powders ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 34,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:44:37.000Z",
      productName: "Stokoderm® Advanced",
      productURL: "https://www.debgroup.com/us/products/stokoderm-advanced-0"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: true,
    hair: false,
    glove: false,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6057,
    hazzard: {
      id: 76,
      substanceWorkCondition: "Floor cleaner (water- based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 1,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6058,
    hazzard: {
      id: 77,
      substanceWorkCondition: "Food - Food Packaging",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 1,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6059,
    hazzard: {
      id: 78,
      substanceWorkCondition: "Food - Food Processing & Manufacturing",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 1,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6060,
    hazzard: {
      id: 79,
      substanceWorkCondition: "Food - Food Services & Catering",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 1,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6061,
    hazzard: {
      id: 80,
      substanceWorkCondition: "Food Dyestuff",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6062,
    hazzard: {
      id: 83,
      substanceWorkCondition: "Glass fibre dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 34,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:44:37.000Z",
      productName: "Stokoderm® Advanced",
      productURL: "https://www.debgroup.com/us/products/stokoderm-advanced-0"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: true,
    hair: false,
    glove: false,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6063,
    hazzard: {
      id: 83,
      substanceWorkCondition: "Glass fibre dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6064,
    hazzard: {
      id: 84,
      substanceWorkCondition: "Glass wool dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 34,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:44:37.000Z",
      productName: "Stokoderm® Advanced",
      productURL: "https://www.debgroup.com/us/products/stokoderm-advanced-0"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: true,
    hair: false,
    glove: false,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6065,
    hazzard: {
      id: 84,
      substanceWorkCondition: "Glass wool dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6066,
    hazzard: {
      id: 85,
      substanceWorkCondition: "Glycols",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6067,
    hazzard: {
      id: 86,
      substanceWorkCondition: "Graphite",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 43,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:50.000Z",
      productName: "Travabon® Classic",
      productURL: "https://www.debgroup.com/us/products/travabon-classic"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: null,
    hair: false,
    glove: false,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6068,
    hazzard: {
      id: 87,
      substanceWorkCondition: "Grease",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 43,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:50.000Z",
      productName: "Travabon® Classic",
      productURL: "https://www.debgroup.com/us/products/travabon-classic"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: null,
    hair: false,
    glove: false,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6069,
    hazzard: {
      id: 88,
      substanceWorkCondition: "Grinding oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 43,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:50.000Z",
      productName: "Travabon® Classic",
      productURL: "https://www.debgroup.com/us/products/travabon-classic"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: null,
    hair: false,
    glove: false,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6070,
    hazzard: {
      id: 89,
      substanceWorkCondition: "Grinding water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6071,
    hazzard: {
      id: 90,
      substanceWorkCondition: "Gypsum",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6072,
    hazzard: {
      id: 91,
      substanceWorkCondition: "Healthcare - Care Homes",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 1,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6073,
    hazzard: {
      id: 92,
      substanceWorkCondition:
        "Healthcare - Medical Centers/Surgeries/General Practitioners",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 1,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6074,
    hazzard: {
      id: 93,
      substanceWorkCondition: "Healthcare - Nursing Homes",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 1,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6075,
    hazzard: {
      id: 94,
      substanceWorkCondition: "Healthcare - Veterinary Services",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 1,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6076,
    hazzard: {
      id: 95,
      substanceWorkCondition: "Hydraulic fluid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 43,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:50.000Z",
      productName: "Travabon® Classic",
      productURL: "https://www.debgroup.com/us/products/travabon-classic"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: null,
    hair: false,
    glove: false,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6077,
    hazzard: {
      id: 97,
      substanceWorkCondition: "Industry Heavy - Building Materials",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6078,
    hazzard: {
      id: 97,
      substanceWorkCondition: "Industry Heavy - Building Materials",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 34,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:44:37.000Z",
      productName: "Stokoderm® Advanced",
      productURL: "https://www.debgroup.com/us/products/stokoderm-advanced-0"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: true,
    hair: false,
    glove: false,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6079,
    hazzard: {
      id: 98,
      substanceWorkCondition: "Industry Heavy - Construction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6080,
    hazzard: {
      id: 98,
      substanceWorkCondition: "Industry Heavy - Construction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 34,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:44:37.000Z",
      productName: "Stokoderm® Advanced",
      productURL: "https://www.debgroup.com/us/products/stokoderm-advanced-0"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: true,
    hair: false,
    glove: false,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6081,
    hazzard: {
      id: 99,
      substanceWorkCondition: "Industry Heavy - Mining & Extraction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6082,
    hazzard: {
      id: 99,
      substanceWorkCondition: "Industry Heavy - Mining & Extraction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 34,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:44:37.000Z",
      productName: "Stokoderm® Advanced",
      productURL: "https://www.debgroup.com/us/products/stokoderm-advanced-0"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: true,
    hair: false,
    glove: false,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6083,
    hazzard: {
      id: 100,
      substanceWorkCondition: "Industry Heavy - Off-Shore Extraction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6084,
    hazzard: {
      id: 100,
      substanceWorkCondition: "Industry Heavy - Off-Shore Extraction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 34,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:44:37.000Z",
      productName: "Stokoderm® Advanced",
      productURL: "https://www.debgroup.com/us/products/stokoderm-advanced-0"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: true,
    hair: false,
    glove: false,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6085,
    hazzard: {
      id: 100,
      substanceWorkCondition: "Industry Heavy - Off-Shore Extraction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 43,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:50.000Z",
      productName: "Travabon® Classic",
      productURL: "https://www.debgroup.com/us/products/travabon-classic"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: null,
    hair: false,
    glove: false,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6086,
    hazzard: {
      id: 101,
      substanceWorkCondition: "Industry Heavy - Printing Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6087,
    hazzard: {
      id: 101,
      substanceWorkCondition: "Industry Heavy - Printing Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 34,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:44:37.000Z",
      productName: "Stokoderm® Advanced",
      productURL: "https://www.debgroup.com/us/products/stokoderm-advanced-0"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: true,
    hair: false,
    glove: false,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6088,
    hazzard: {
      id: 102,
      substanceWorkCondition:
        "Industry Heavy - Road & Transportation Construction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6089,
    hazzard: {
      id: 102,
      substanceWorkCondition:
        "Industry Heavy - Road & Transportation Construction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 34,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:44:37.000Z",
      productName: "Stokoderm® Advanced",
      productURL: "https://www.debgroup.com/us/products/stokoderm-advanced-0"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: true,
    hair: false,
    glove: false,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6090,
    hazzard: {
      id: 103,
      substanceWorkCondition: "Industry Heavy - Ship & Boat Building",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6091,
    hazzard: {
      id: 103,
      substanceWorkCondition: "Industry Heavy - Ship & Boat Building",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 34,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:44:37.000Z",
      productName: "Stokoderm® Advanced",
      productURL: "https://www.debgroup.com/us/products/stokoderm-advanced-0"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: true,
    hair: false,
    glove: false,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6092,
    hazzard: {
      id: 104,
      substanceWorkCondition: "Industry Heavy - Shipping & Marine",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6093,
    hazzard: {
      id: 104,
      substanceWorkCondition: "Industry Heavy - Shipping & Marine",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 34,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:44:37.000Z",
      productName: "Stokoderm® Advanced",
      productURL: "https://www.debgroup.com/us/products/stokoderm-advanced-0"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: true,
    hair: false,
    glove: false,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6094,
    hazzard: {
      id: 105,
      substanceWorkCondition:
        "Industry Heavy - Steel, Engineering & Heavy Manufacturing",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6095,
    hazzard: {
      id: 105,
      substanceWorkCondition:
        "Industry Heavy - Steel, Engineering & Heavy Manufacturing",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 34,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:44:37.000Z",
      productName: "Stokoderm® Advanced",
      productURL: "https://www.debgroup.com/us/products/stokoderm-advanced-0"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: true,
    hair: false,
    glove: false,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6096,
    hazzard: {
      id: 106,
      substanceWorkCondition:
        "Industry Light - Airports & Transportation Terminals - Services & Facility Maintenance",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6097,
    hazzard: {
      id: 106,
      substanceWorkCondition:
        "Industry Light - Airports & Transportation Terminals - Services & Facility Maintenance",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 34,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:44:37.000Z",
      productName: "Stokoderm® Advanced",
      productURL: "https://www.debgroup.com/us/products/stokoderm-advanced-0"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: true,
    hair: false,
    glove: false,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6098,
    hazzard: {
      id: 107,
      substanceWorkCondition: "Industry Light - Emergency Services",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6099,
    hazzard: {
      id: 107,
      substanceWorkCondition: "Industry Light - Emergency Services",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Protect",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 34,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:44:37.000Z",
      productName: "Stokoderm® Advanced",
      productURL: "https://www.debgroup.com/us/products/stokoderm-advanced-0"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: true,
    hair: false,
    glove: false,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6100,
    hazzard: {
      id: 108,
      substanceWorkCondition:
        "Industry Light - General Manufacturing & Assembly",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6101,
    hazzard: {
      id: 108,
      substanceWorkCondition:
        "Industry Light - General Manufacturing & Assembly",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 34,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:44:37.000Z",
      productName: "Stokoderm® Advanced",
      productURL: "https://www.debgroup.com/us/products/stokoderm-advanced-0"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: true,
    hair: false,
    glove: false,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6102,
    hazzard: {
      id: 108,
      substanceWorkCondition:
        "Industry Light - General Manufacturing & Assembly",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 43,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:50.000Z",
      productName: "Travabon® Classic",
      productURL: "https://www.debgroup.com/us/products/travabon-classic"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: null,
    hair: false,
    glove: false,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6103,
    hazzard: {
      id: 109,
      substanceWorkCondition:
        "Industry Light - Hosiery, Knitware, Fabric & Clothing Manufacturing",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6104,
    hazzard: {
      id: 109,
      substanceWorkCondition:
        "Industry Light - Hosiery, Knitware, Fabric & Clothing Manufacturing",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 34,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:44:37.000Z",
      productName: "Stokoderm® Advanced",
      productURL: "https://www.debgroup.com/us/products/stokoderm-advanced-0"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: true,
    hair: false,
    glove: false,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6105,
    hazzard: {
      id: 110,
      substanceWorkCondition: "Industry Light - Science & Laboratories",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6106,
    hazzard: {
      id: 111,
      substanceWorkCondition: "Industry Light - Warehousing & Logistics",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6107,
    hazzard: {
      id: 111,
      substanceWorkCondition: "Industry Light - Warehousing & Logistics",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 34,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:44:37.000Z",
      productName: "Stokoderm® Advanced",
      productURL: "https://www.debgroup.com/us/products/stokoderm-advanced-0"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: true,
    hair: false,
    glove: false,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6108,
    hazzard: {
      id: 112,
      substanceWorkCondition: "Industry Medium - Ceramics & Glassware",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6109,
    hazzard: {
      id: 112,
      substanceWorkCondition: "Industry Medium - Ceramics & Glassware",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 34,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:44:37.000Z",
      productName: "Stokoderm® Advanced",
      productURL: "https://www.debgroup.com/us/products/stokoderm-advanced-0"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: true,
    hair: false,
    glove: false,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6110,
    hazzard: {
      id: 113,
      substanceWorkCondition: "Industry Medium - Chemical Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 34,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:44:37.000Z",
      productName: "Stokoderm® Advanced",
      productURL: "https://www.debgroup.com/us/products/stokoderm-advanced-0"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: true,
    hair: false,
    glove: false,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6111,
    hazzard: {
      id: 113,
      substanceWorkCondition: "Industry Medium - Chemical Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6112,
    hazzard: {
      id: 113,
      substanceWorkCondition: "Industry Medium - Chemical Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 1,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6113,
    hazzard: {
      id: 114,
      substanceWorkCondition: "Industry Medium - Electronics Industries",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6114,
    hazzard: {
      id: 114,
      substanceWorkCondition: "Industry Medium - Electronics Industries",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 34,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:44:37.000Z",
      productName: "Stokoderm® Advanced",
      productURL: "https://www.debgroup.com/us/products/stokoderm-advanced-0"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: true,
    hair: false,
    glove: false,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6115,
    hazzard: {
      id: 115,
      substanceWorkCondition: "Industry Medium - Metal Working & Fabrication",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6116,
    hazzard: {
      id: 115,
      substanceWorkCondition: "Industry Medium - Metal Working & Fabrication",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 34,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:44:37.000Z",
      productName: "Stokoderm® Advanced",
      productURL: "https://www.debgroup.com/us/products/stokoderm-advanced-0"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: true,
    hair: false,
    glove: false,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6117,
    hazzard: {
      id: 116,
      substanceWorkCondition: "Industry Medium - Outdoor Maintenance & Repair",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6118,
    hazzard: {
      id: 116,
      substanceWorkCondition: "Industry Medium - Outdoor Maintenance & Repair",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 34,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:44:37.000Z",
      productName: "Stokoderm® Advanced",
      productURL: "https://www.debgroup.com/us/products/stokoderm-advanced-0"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: true,
    hair: false,
    glove: false,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6119,
    hazzard: {
      id: 117,
      substanceWorkCondition: "Industry Medium - Pharmaceutical Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6120,
    hazzard: {
      id: 117,
      substanceWorkCondition: "Industry Medium - Pharmaceutical Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 1,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6121,
    hazzard: {
      id: 118,
      substanceWorkCondition: "Industry Medium - Plastics Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6122,
    hazzard: {
      id: 118,
      substanceWorkCondition: "Industry Medium - Plastics Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 34,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:44:37.000Z",
      productName: "Stokoderm® Advanced",
      productURL: "https://www.debgroup.com/us/products/stokoderm-advanced-0"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: true,
    hair: false,
    glove: false,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6123,
    hazzard: {
      id: 119,
      substanceWorkCondition: "Ink / printing ink",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 34,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:44:37.000Z",
      productName: "Stokoderm® Advanced",
      productURL: "https://www.debgroup.com/us/products/stokoderm-advanced-0"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: true,
    hair: false,
    glove: false,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6124,
    hazzard: {
      id: 119,
      substanceWorkCondition: "Ink / printing ink",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6125,
    hazzard: {
      id: 120,
      substanceWorkCondition: "Insulating material",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6126,
    hazzard: {
      id: 122,
      substanceWorkCondition: "Ketone",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6127,
    hazzard: {
      id: 123,
      substanceWorkCondition: "Lacquers (nitro/solvent-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 43,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:50.000Z",
      productName: "Travabon® Classic",
      productURL: "https://www.debgroup.com/us/products/travabon-classic"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: null,
    hair: false,
    glove: false,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6128,
    hazzard: {
      id: 124,
      substanceWorkCondition: "Lacquers (PU based, synthetic resin based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 43,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:50.000Z",
      productName: "Travabon® Classic",
      productURL: "https://www.debgroup.com/us/products/travabon-classic"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: null,
    hair: false,
    glove: false,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6129,
    hazzard: {
      id: 125,
      substanceWorkCondition: "Lacquers (UV-hardening)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 34,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:44:37.000Z",
      productName: "Stokoderm® Advanced",
      productURL: "https://www.debgroup.com/us/products/stokoderm-advanced-0"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: true,
    hair: false,
    glove: false,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6130,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Protect",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 34,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:44:37.000Z",
      productName: "Stokoderm® Advanced",
      productURL: "https://www.debgroup.com/us/products/stokoderm-advanced-0"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: true,
    hair: false,
    glove: false,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6131,
    hazzard: {
      id: 127,
      substanceWorkCondition: "Latex paint",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6132,
    hazzard: {
      id: 128,
      substanceWorkCondition: "Lime",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6133,
    hazzard: {
      id: 129,
      substanceWorkCondition: "Lubricants, water-based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6134,
    hazzard: {
      id: 130,
      substanceWorkCondition: "Metal dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 43,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:50.000Z",
      productName: "Travabon® Classic",
      productURL: "https://www.debgroup.com/us/products/travabon-classic"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: null,
    hair: false,
    glove: false,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6135,
    hazzard: {
      id: 131,
      substanceWorkCondition: "Methanol / Methyl Alcohol",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6136,
    hazzard: {
      id: 133,
      substanceWorkCondition: "Multi component adhesive",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 43,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:50.000Z",
      productName: "Travabon® Classic",
      productURL: "https://www.debgroup.com/us/products/travabon-classic"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: null,
    hair: false,
    glove: false,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6137,
    hazzard: {
      id: 136,
      substanceWorkCondition: "Oil coke (carbon)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 43,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:50.000Z",
      productName: "Travabon® Classic",
      productURL: "https://www.debgroup.com/us/products/travabon-classic"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: null,
    hair: false,
    glove: false,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6138,
    hazzard: {
      id: 137,
      substanceWorkCondition: "Oil-emulsions (water- mixed)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6139,
    hazzard: {
      id: 138,
      substanceWorkCondition: "Oils / Oil based lubricants",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 43,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:50.000Z",
      productName: "Travabon® Classic",
      productURL: "https://www.debgroup.com/us/products/travabon-classic"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: null,
    hair: false,
    glove: false,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6140,
    hazzard: {
      id: 140,
      substanceWorkCondition: "Paint Thinner ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 43,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:50.000Z",
      productName: "Travabon® Classic",
      productURL: "https://www.debgroup.com/us/products/travabon-classic"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: null,
    hair: false,
    glove: false,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6141,
    hazzard: {
      id: 139,
      substanceWorkCondition: "Paint",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 43,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:50.000Z",
      productName: "Travabon® Classic",
      productURL: "https://www.debgroup.com/us/products/travabon-classic"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: null,
    hair: false,
    glove: false,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6142,
    hazzard: {
      id: 141,
      substanceWorkCondition: "Pit water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6143,
    hazzard: {
      id: 142,
      substanceWorkCondition: "Pitch",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 43,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:50.000Z",
      productName: "Travabon® Classic",
      productURL: "https://www.debgroup.com/us/products/travabon-classic"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: null,
    hair: false,
    glove: false,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6144,
    hazzard: {
      id: 143,
      substanceWorkCondition: "Polishes (water- soluble)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6145,
    hazzard: {
      id: 143,
      substanceWorkCondition: "Polishes (water- soluble)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 34,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:44:37.000Z",
      productName: "Stokoderm® Advanced",
      productURL: "https://www.debgroup.com/us/products/stokoderm-advanced-0"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: true,
    hair: false,
    glove: false,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6146,
    hazzard: {
      id: 144,
      substanceWorkCondition: "Polyester / Polyester resin",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 43,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:50.000Z",
      productName: "Travabon® Classic",
      productURL: "https://www.debgroup.com/us/products/travabon-classic"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: null,
    hair: false,
    glove: false,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6147,
    hazzard: {
      id: 145,
      substanceWorkCondition: "Polyurethane",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Protect",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 43,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:50.000Z",
      productName: "Travabon® Classic",
      productURL: "https://www.debgroup.com/us/products/travabon-classic"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: null,
    hair: false,
    glove: false,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6148,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Protect",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 34,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:44:37.000Z",
      productName: "Stokoderm® Advanced",
      productURL: "https://www.debgroup.com/us/products/stokoderm-advanced-0"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: true,
    hair: false,
    glove: false,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6149,
    hazzard: {
      id: 148,
      substanceWorkCondition:
        "Propanols (propyl alcohols): n-Propanol, Isopropanol / 2-Propanol ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6150,
    hazzard: {
      id: 149,
      substanceWorkCondition: "release agent, parting agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6151,
    hazzard: {
      id: 151,
      substanceWorkCondition: "Rock wool",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6152,
    hazzard: {
      id: 152,
      substanceWorkCondition: "Rubber",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 34,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:44:37.000Z",
      productName: "Stokoderm® Advanced",
      productURL: "https://www.debgroup.com/us/products/stokoderm-advanced-0"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: true,
    hair: false,
    glove: false,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6153,
    hazzard: {
      id: 153,
      substanceWorkCondition: "Salts / Salt solution",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6154,
    hazzard: {
      id: 154,
      substanceWorkCondition: "Sealing compound",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 43,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:50.000Z",
      productName: "Travabon® Classic",
      productURL: "https://www.debgroup.com/us/products/travabon-classic"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: null,
    hair: false,
    glove: false,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6155,
    hazzard: {
      id: 155,
      substanceWorkCondition: "Sintering",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 43,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:50.000Z",
      productName: "Travabon® Classic",
      productURL: "https://www.debgroup.com/us/products/travabon-classic"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: null,
    hair: false,
    glove: false,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6156,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Protect",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 34,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:44:37.000Z",
      productName: "Stokoderm® Advanced",
      productURL: "https://www.debgroup.com/us/products/stokoderm-advanced-0"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: true,
    hair: false,
    glove: false,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6157,
    hazzard: {
      id: 158,
      substanceWorkCondition: "Soot",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 34,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:44:37.000Z",
      productName: "Stokoderm® Advanced",
      productURL: "https://www.debgroup.com/us/products/stokoderm-advanced-0"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: true,
    hair: false,
    glove: false,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6158,
    hazzard: {
      id: 160,
      substanceWorkCondition: "Surface Cleanser (surfactant based), Detergents",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6159,
    hazzard: {
      id: 161,
      substanceWorkCondition: "Synthetic melting glaze",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 43,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:50.000Z",
      productName: "Travabon® Classic",
      productURL: "https://www.debgroup.com/us/products/travabon-classic"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: null,
    hair: false,
    glove: false,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6160,
    hazzard: {
      id: 163,
      substanceWorkCondition: "Tanning agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6161,
    hazzard: {
      id: 164,
      substanceWorkCondition: "Tar",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 43,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:50.000Z",
      productName: "Travabon® Classic",
      productURL: "https://www.debgroup.com/us/products/travabon-classic"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: null,
    hair: false,
    glove: false,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6162,
    hazzard: {
      id: 166,
      substanceWorkCondition: "Thinner",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 43,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:50.000Z",
      productName: "Travabon® Classic",
      productURL: "https://www.debgroup.com/us/products/travabon-classic"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: null,
    hair: false,
    glove: false,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6163,
    hazzard: {
      id: 168,
      substanceWorkCondition: "Turpentine oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 43,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:50.000Z",
      productName: "Travabon® Classic",
      productURL: "https://www.debgroup.com/us/products/travabon-classic"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: null,
    hair: false,
    glove: false,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6164,
    hazzard: {
      id: 169,
      substanceWorkCondition: "UV-rays (A/B) / Sunlight",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 7,
    locationOrder: 1,
    skin_care_product: {
      id: 38,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:22.000Z",
      productName: "Stokoderm® Sun Protect 30 PURE",
      productURL:
        "https://www.debgroup.com/us/products/stokoderm-sun-protect-30-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6165,
    hazzard: {
      id: 170,
      substanceWorkCondition: "Washing bath, washing liquid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6166,
    hazzard: {
      id: 171,
      substanceWorkCondition: "Waste oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 43,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:50.000Z",
      productName: "Travabon® Classic",
      productURL: "https://www.debgroup.com/us/products/travabon-classic"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: null,
    hair: false,
    glove: false,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6167,
    hazzard: {
      id: 172,
      substanceWorkCondition: "Water / wet work",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6168,
    hazzard: {
      id: 173,
      substanceWorkCondition:
        "Wearing protective clothing / Softening of the skin (Maceration)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 36,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:46.000Z",
      productName: "Stokoderm® Grip PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-grip-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6169,
    hazzard: {
      id: 173,
      substanceWorkCondition:
        "Wearing protective clothing / Softening of the skin (Maceration)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 6,
    locationOrder: 1,
    skin_care_product: {
      id: 35,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:45:12.000Z",
      productName: "Stokoderm® Aqua PURE",
      productURL: "https://www.debgroup.com/us/products/stokoderm-aqua-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6170,
    hazzard: {
      id: 174,
      substanceWorkCondition: "wire drawing oil / wire drawing grease",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Protect",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 34,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:44:37.000Z",
      productName: "Stokoderm® Advanced",
      productURL: "https://www.debgroup.com/us/products/stokoderm-advanced-0"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: true,
    hair: false,
    glove: false,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6171,
    hazzard: {
      id: 999,
      substanceWorkCondition: "All Products",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:44:03.000Z",
      updated_at: "2020-03-02T17:44:03.000Z"
    },
    step: "Protect",
    productOrder: 1,
    locationOrder: 2,
    skin_care_product: {
      id: 37,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:46:31.000Z",
      productName: "Stokoderm® Protect PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokoderm-protect-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6172,
    hazzard: {
      id: 999,
      substanceWorkCondition: "All Products",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:44:03.000Z",
      updated_at: "2020-03-02T17:44:03.000Z"
    },
    step: "Protect",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 34,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:44:37.000Z",
      productName: "Stokoderm® Advanced",
      productURL: "https://www.debgroup.com/us/products/stokoderm-advanced-0"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: true,
    hair: false,
    glove: false,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6173,
    hazzard: {
      id: 999,
      substanceWorkCondition: "All Products",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:44:03.000Z",
      updated_at: "2020-03-02T17:44:03.000Z"
    },
    step: "Protect",
    productOrder: 7,
    locationOrder: 4,
    skin_care_product: {
      id: 38,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:47:22.000Z",
      productName: "Stokoderm® Sun Protect 30 PURE",
      productURL:
        "https://www.debgroup.com/us/products/stokoderm-sun-protect-30-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6174,
    hazzard: {
      id: 1,
      substanceWorkCondition: "2-Butanone (MEK)",
      cas: "78-93-3",
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: "oil",
      created_at: "2020-03-02T17:39:32.000Z",
      updated_at: "2020-03-05T14:48:01.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 1,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6175,
    hazzard: {
      id: 1,
      substanceWorkCondition: "2-Butanone (MEK)",
      cas: "78-93-3",
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: "oil",
      created_at: "2020-03-02T17:39:32.000Z",
      updated_at: "2020-03-05T14:48:01.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 1,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6176,
    hazzard: {
      id: 1,
      substanceWorkCondition: "2-Butanone (MEK)",
      cas: "78-93-3",
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: "oil",
      created_at: "2020-03-02T17:39:32.000Z",
      updated_at: "2020-03-05T14:48:01.000Z"
    },
    step: "Cleanse",
    productOrder: 18,
    locationOrder: 1,
    skin_care_product: {
      id: 16,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:31:28.000Z",
      productName: "Kresto® kwik wipes®",
      productURL: "https://www.debgroup.com/us/products/kresto-kwik-wipes"
    },
    rating: 4,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6177,
    hazzard: {
      id: 2,
      substanceWorkCondition: "Acetone / Dimethyl ketone",
      cas: "67-64-1",
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 1,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6178,
    hazzard: {
      id: 2,
      substanceWorkCondition: "Acetone / Dimethyl ketone",
      cas: "67-64-1",
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 1,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6179,
    hazzard: {
      id: 2,
      substanceWorkCondition: "Acetone / Dimethyl ketone",
      cas: "67-64-1",
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Cleanse",
    productOrder: 18,
    locationOrder: 1,
    skin_care_product: {
      id: 16,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:31:28.000Z",
      productName: "Kresto® kwik wipes®",
      productURL: "https://www.debgroup.com/us/products/kresto-kwik-wipes"
    },
    rating: 4,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6180,
    hazzard: {
      id: 3,
      substanceWorkCondition: "Acids, diluted",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 1,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6181,
    hazzard: {
      id: 3,
      substanceWorkCondition: "Acids, diluted",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 1,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6182,
    hazzard: {
      id: 4,
      substanceWorkCondition: "Acrylates",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Cleanse",
    productOrder: 14,
    locationOrder: 1,
    skin_care_product: {
      id: 32,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:07.000Z",
      productName: "Solopol® GFX™ (USDA, UL, GS)",
      productURL: "https://www.debgroup.com/us/products/solopol-gfx"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6183,
    hazzard: {
      id: 4,
      substanceWorkCondition: "Acrylates",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Cleanse",
    productOrder: 18,
    locationOrder: 1,
    skin_care_product: {
      id: 16,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:31:28.000Z",
      productName: "Kresto® kwik wipes®",
      productURL: "https://www.debgroup.com/us/products/kresto-kwik-wipes"
    },
    rating: 4,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6184,
    hazzard: {
      id: 5,
      substanceWorkCondition: "Adhesive single-component",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Cleanse",
    productOrder: 14,
    locationOrder: 1,
    skin_care_product: {
      id: 32,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:07.000Z",
      productName: "Solopol® GFX™ (USDA, UL, GS)",
      productURL: "https://www.debgroup.com/us/products/solopol-gfx"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6185,
    hazzard: {
      id: 5,
      substanceWorkCondition: "Adhesive single-component",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Cleanse",
    productOrder: 18,
    locationOrder: 1,
    skin_care_product: {
      id: 16,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:31:28.000Z",
      productName: "Kresto® kwik wipes®",
      productURL: "https://www.debgroup.com/us/products/kresto-kwik-wipes"
    },
    rating: 4,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6186,
    hazzard: {
      id: 6,
      substanceWorkCondition: "Adhesive, water-soluble",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Cleanse",
    productOrder: 13,
    locationOrder: 1,
    skin_care_product: {
      id: 33,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:49.000Z",
      productName: "Solopol® Lime (UL)",
      productURL: "https://www.debgroup.com/us/products/solopol-lime"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6187,
    hazzard: {
      id: 6,
      substanceWorkCondition: "Adhesive, water-soluble",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Cleanse",
    productOrder: 14,
    locationOrder: 1,
    skin_care_product: {
      id: 32,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:07.000Z",
      productName: "Solopol® GFX™ (USDA, UL, GS)",
      productURL: "https://www.debgroup.com/us/products/solopol-gfx"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6188,
    hazzard: {
      id: 6,
      substanceWorkCondition: "Adhesive, water-soluble",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Cleanse",
    productOrder: 18,
    locationOrder: 1,
    skin_care_product: {
      id: 16,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:31:28.000Z",
      productName: "Kresto® kwik wipes®",
      productURL: "https://www.debgroup.com/us/products/kresto-kwik-wipes"
    },
    rating: 4,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6189,
    hazzard: {
      id: 7,
      substanceWorkCondition: "Adhesives, glues based on synthetic resin",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Cleanse",
    productOrder: 13,
    locationOrder: 1,
    skin_care_product: {
      id: 33,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:49.000Z",
      productName: "Solopol® Lime (UL)",
      productURL: "https://www.debgroup.com/us/products/solopol-lime"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6190,
    hazzard: {
      id: 8,
      substanceWorkCondition: "Aldehydes, diluted",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 1,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6191,
    hazzard: {
      id: 8,
      substanceWorkCondition: "Aldehydes, diluted",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 1,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6192,
    hazzard: {
      id: 9,
      substanceWorkCondition: "Alkaline solutions / lyes / thinned caustics",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 1,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6193,
    hazzard: {
      id: 9,
      substanceWorkCondition: "Alkaline solutions / lyes / thinned caustics",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 1,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6194,
    hazzard: {
      id: 10,
      substanceWorkCondition: "Alkaline solutions, diluted",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 1,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6195,
    hazzard: {
      id: 10,
      substanceWorkCondition: "Alkaline solutions, diluted",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 1,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6196,
    hazzard: {
      id: 11,
      substanceWorkCondition: "Alkaline solutions, lyes (solvent based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 1,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6197,
    hazzard: {
      id: 11,
      substanceWorkCondition: "Alkaline solutions, lyes (solvent based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 1,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6198,
    hazzard: {
      id: 11,
      substanceWorkCondition: "Alkaline solutions, lyes (solvent based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 14,
    locationOrder: 1,
    skin_care_product: {
      id: 32,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:07.000Z",
      productName: "Solopol® GFX™ (USDA, UL, GS)",
      productURL: "https://www.debgroup.com/us/products/solopol-gfx"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6199,
    hazzard: {
      id: 12,
      substanceWorkCondition: "Aluminium",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 14,
    locationOrder: 1,
    skin_care_product: {
      id: 32,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:07.000Z",
      productName: "Solopol® GFX™ (USDA, UL, GS)",
      productURL: "https://www.debgroup.com/us/products/solopol-gfx"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6200,
    hazzard: {
      id: 12,
      substanceWorkCondition: "Aluminium",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 18,
    locationOrder: 1,
    skin_care_product: {
      id: 16,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:31:28.000Z",
      productName: "Kresto® kwik wipes®",
      productURL: "https://www.debgroup.com/us/products/kresto-kwik-wipes"
    },
    rating: 4,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:14.000Z",
    updated_at: "2020-03-02T22:50:14.000Z"
  },
  {
    id: 6201,
    hazzard: {
      id: 14,
      substanceWorkCondition: "Antistatics",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 1,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6202,
    hazzard: {
      id: 14,
      substanceWorkCondition: "Antistatics",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 1,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6203,
    hazzard: {
      id: 14,
      substanceWorkCondition: "Antistatics",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 18,
    locationOrder: 1,
    skin_care_product: {
      id: 16,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:31:28.000Z",
      productName: "Kresto® kwik wipes®",
      productURL: "https://www.debgroup.com/us/products/kresto-kwik-wipes"
    },
    rating: 4,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6204,
    hazzard: {
      id: 16,
      substanceWorkCondition:
        "Automotive Aftermarket - Automotive Maintenance & Repair",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 18,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:32:47.000Z",
      productName: "KrestoGT® Cherry Turbo",
      productURL: "https://www.debgroup.com/us/products/krestogt-cherry-turbo"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: false,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6205,
    hazzard: {
      id: 17,
      substanceWorkCondition: "Automotive Aftermarket - Paint & Bodyshops",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 18,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:32:47.000Z",
      productName: "KrestoGT® Cherry Turbo",
      productURL: "https://www.debgroup.com/us/products/krestogt-cherry-turbo"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6206,
    hazzard: {
      id: 18,
      substanceWorkCondition:
        "Automotive Aftermarket - Public Transport Maintenance&Repair",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 1,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6207,
    hazzard: {
      id: 18,
      substanceWorkCondition:
        "Automotive Aftermarket - Public Transport Maintenance&Repair",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 18,
    locationOrder: 1,
    skin_care_product: {
      id: 16,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:31:28.000Z",
      productName: "Kresto® kwik wipes®",
      productURL: "https://www.debgroup.com/us/products/kresto-kwik-wipes"
    },
    rating: 4,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6208,
    hazzard: {
      id: 19,
      substanceWorkCondition: "Battery acid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 1,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6209,
    hazzard: {
      id: 19,
      substanceWorkCondition: "Battery acid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 1,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6210,
    hazzard: {
      id: 20,
      substanceWorkCondition: "Benzene",
      cas: null,
      glovesRecommended: true,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 1,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6211,
    hazzard: {
      id: 20,
      substanceWorkCondition: "Benzene",
      cas: null,
      glovesRecommended: true,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 1,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6212,
    hazzard: {
      id: 22,
      substanceWorkCondition: "Bitumen emulsion",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 18,
    locationOrder: 1,
    skin_care_product: {
      id: 16,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:31:28.000Z",
      productName: "Kresto® kwik wipes®",
      productURL: "https://www.debgroup.com/us/products/kresto-kwik-wipes"
    },
    rating: 4,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6213,
    hazzard: {
      id: 24,
      substanceWorkCondition: "Brake fluid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 1,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6214,
    hazzard: {
      id: 24,
      substanceWorkCondition: "Brake fluid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 1,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6215,
    hazzard: {
      id: 24,
      substanceWorkCondition: "Brake fluid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 13,
    locationOrder: 1,
    skin_care_product: {
      id: 33,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:49.000Z",
      productName: "Solopol® Lime (UL)",
      productURL: "https://www.debgroup.com/us/products/solopol-lime"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6216,
    hazzard: {
      id: 24,
      substanceWorkCondition: "Brake fluid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 14,
    locationOrder: 1,
    skin_care_product: {
      id: 32,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:07.000Z",
      productName: "Solopol® GFX™ (USDA, UL, GS)",
      productURL: "https://www.debgroup.com/us/products/solopol-gfx"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6217,
    hazzard: {
      id: 24,
      substanceWorkCondition: "Brake fluid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 18,
    locationOrder: 1,
    skin_care_product: {
      id: 16,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:31:28.000Z",
      productName: "Kresto® kwik wipes®",
      productURL: "https://www.debgroup.com/us/products/kresto-kwik-wipes"
    },
    rating: 4,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6218,
    hazzard: {
      id: 25,
      substanceWorkCondition: "Casting dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 1,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6219,
    hazzard: {
      id: 25,
      substanceWorkCondition: "Casting dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 1,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6220,
    hazzard: {
      id: 25,
      substanceWorkCondition: "Casting dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 13,
    locationOrder: 1,
    skin_care_product: {
      id: 33,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:49.000Z",
      productName: "Solopol® Lime (UL)",
      productURL: "https://www.debgroup.com/us/products/solopol-lime"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6221,
    hazzard: {
      id: 25,
      substanceWorkCondition: "Casting dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 14,
    locationOrder: 1,
    skin_care_product: {
      id: 32,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:07.000Z",
      productName: "Solopol® GFX™ (USDA, UL, GS)",
      productURL: "https://www.debgroup.com/us/products/solopol-gfx"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6222,
    hazzard: {
      id: 25,
      substanceWorkCondition: "Casting dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 18,
    locationOrder: 1,
    skin_care_product: {
      id: 16,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:31:28.000Z",
      productName: "Kresto® kwik wipes®",
      productURL: "https://www.debgroup.com/us/products/kresto-kwik-wipes"
    },
    rating: 4,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6223,
    hazzard: {
      id: 26,
      substanceWorkCondition: "Cement (dry)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 1,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6224,
    hazzard: {
      id: 26,
      substanceWorkCondition: "Cement (dry)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 1,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6225,
    hazzard: {
      id: 26,
      substanceWorkCondition: "Cement (dry)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 18,
    locationOrder: 1,
    skin_care_product: {
      id: 16,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:31:28.000Z",
      productName: "Kresto® kwik wipes®",
      productURL: "https://www.debgroup.com/us/products/kresto-kwik-wipes"
    },
    rating: 4,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6226,
    hazzard: {
      id: 27,
      substanceWorkCondition: "Cement (wet)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 1,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6227,
    hazzard: {
      id: 27,
      substanceWorkCondition: "Cement (wet)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 1,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6228,
    hazzard: {
      id: 27,
      substanceWorkCondition: "Cement (wet)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 14,
    locationOrder: 1,
    skin_care_product: {
      id: 32,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:07.000Z",
      productName: "Solopol® GFX™ (USDA, UL, GS)",
      productURL: "https://www.debgroup.com/us/products/solopol-gfx"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6229,
    hazzard: {
      id: 27,
      substanceWorkCondition: "Cement (wet)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 18,
    locationOrder: 1,
    skin_care_product: {
      id: 16,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:31:28.000Z",
      productName: "Kresto® kwik wipes®",
      productURL: "https://www.debgroup.com/us/products/kresto-kwik-wipes"
    },
    rating: 4,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6230,
    hazzard: {
      id: 29,
      substanceWorkCondition:
        "Chromates (water- insoluble) = Chromium pigments",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 1,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6231,
    hazzard: {
      id: 29,
      substanceWorkCondition:
        "Chromates (water- insoluble) = Chromium pigments",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 1,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6232,
    hazzard: {
      id: 29,
      substanceWorkCondition:
        "Chromates (water- insoluble) = Chromium pigments",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 18,
    locationOrder: 1,
    skin_care_product: {
      id: 16,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:31:28.000Z",
      productName: "Kresto® kwik wipes®",
      productURL: "https://www.debgroup.com/us/products/kresto-kwik-wipes"
    },
    rating: 4,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6233,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 1,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6234,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 1,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6235,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Cleanse",
    productOrder: 18,
    locationOrder: 1,
    skin_care_product: {
      id: 16,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:31:28.000Z",
      productName: "Kresto® kwik wipes®",
      productURL: "https://www.debgroup.com/us/products/kresto-kwik-wipes"
    },
    rating: 4,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6236,
    hazzard: {
      id: 31,
      substanceWorkCondition: "cleaning agent, detergent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 1,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6237,
    hazzard: {
      id: 31,
      substanceWorkCondition: "cleaning agent, detergent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 1,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6238,
    hazzard: {
      id: 32,
      substanceWorkCondition: "Coal dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 13,
    locationOrder: 1,
    skin_care_product: {
      id: 33,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:49.000Z",
      productName: "Solopol® Lime (UL)",
      productURL: "https://www.debgroup.com/us/products/solopol-lime"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6239,
    hazzard: {
      id: 33,
      substanceWorkCondition: "Coating materials, bituminous (bitumen varnish)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 15,
    locationOrder: 1,
    skin_care_product: {
      id: 29,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:41:00.000Z",
      productName: "Solopol® Classic  (USDA)",
      productURL: "https://www.debgroup.com/us/products/solopol-classic"
    },
    rating: 4,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6240,
    hazzard: {
      id: 34,
      substanceWorkCondition: "Coating materials, emulsified",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 13,
    locationOrder: 1,
    skin_care_product: {
      id: 33,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:49.000Z",
      productName: "Solopol® Lime (UL)",
      productURL: "https://www.debgroup.com/us/products/solopol-lime"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6241,
    hazzard: {
      id: 34,
      substanceWorkCondition: "Coating materials, emulsified",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 18,
    locationOrder: 1,
    skin_care_product: {
      id: 16,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:31:28.000Z",
      productName: "Kresto® kwik wipes®",
      productURL: "https://www.debgroup.com/us/products/kresto-kwik-wipes"
    },
    rating: 4,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6242,
    hazzard: {
      id: 35,
      substanceWorkCondition: "Coating materials, oily",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 13,
    locationOrder: 1,
    skin_care_product: {
      id: 33,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:49.000Z",
      productName: "Solopol® Lime (UL)",
      productURL: "https://www.debgroup.com/us/products/solopol-lime"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6243,
    hazzard: {
      id: 37,
      substanceWorkCondition: "cold detergent, solvent based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 1,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6244,
    hazzard: {
      id: 37,
      substanceWorkCondition: "cold detergent, solvent based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 1,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6245,
    hazzard: {
      id: 37,
      substanceWorkCondition: "cold detergent, solvent based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 18,
    locationOrder: 1,
    skin_care_product: {
      id: 16,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:31:28.000Z",
      productName: "Kresto® kwik wipes®",
      productURL: "https://www.debgroup.com/us/products/kresto-kwik-wipes"
    },
    rating: 4,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6246,
    hazzard: {
      id: 38,
      substanceWorkCondition: "Cold working conditions",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 1,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6247,
    hazzard: {
      id: 38,
      substanceWorkCondition: "Cold working conditions",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 1,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6248,
    hazzard: {
      id: 39,
      substanceWorkCondition:
        "Commercial - Airports & Transport Terminals - Public Area",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 23,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:36:39.000Z",
      productName: "Refresh™ Clear FOAM (UL, GS)",
      productURL: "https://www.debgroup.com/us/products/refresh-clear-foam"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: true,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6249,
    hazzard: {
      id: 39,
      substanceWorkCondition:
        "Commercial - Airports & Transport Terminals - Public Area",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 22,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:36:04.000Z",
      productName: "Refresh™ Azure FOAM (UL, GS)",
      productURL: "https://www.debgroup.com/us/products/refresh-azure-foam"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6250,
    hazzard: {
      id: 39,
      substanceWorkCondition:
        "Commercial - Airports & Transport Terminals - Public Area",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 27,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:39:30.000Z",
      productName: "Refresh™ Rose FOAM (UL, GS)",
      productURL: "https://www.debgroup.com/us/products/refresh-rose-foam"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6251,
    hazzard: {
      id: 39,
      substanceWorkCondition:
        "Commercial - Airports & Transport Terminals - Public Area",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 7,
    locationOrder: 1,
    skin_care_product: {
      id: 21,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:35:12.000Z",
      productName: "Refresh™ AntiBac FOAM",
      productURL: "https://www.debgroup.com/us/products/refresh-antibac-foam"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6252,
    hazzard: {
      id: 40,
      substanceWorkCondition: "Commercial - Cleaning & Maintenance",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 23,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:36:39.000Z",
      productName: "Refresh™ Clear FOAM (UL, GS)",
      productURL: "https://www.debgroup.com/us/products/refresh-clear-foam"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: true,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6253,
    hazzard: {
      id: 40,
      substanceWorkCondition: "Commercial - Cleaning & Maintenance",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 22,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:36:04.000Z",
      productName: "Refresh™ Azure FOAM (UL, GS)",
      productURL: "https://www.debgroup.com/us/products/refresh-azure-foam"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6254,
    hazzard: {
      id: 40,
      substanceWorkCondition: "Commercial - Cleaning & Maintenance",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 27,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:39:30.000Z",
      productName: "Refresh™ Rose FOAM (UL, GS)",
      productURL: "https://www.debgroup.com/us/products/refresh-rose-foam"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6255,
    hazzard: {
      id: 40,
      substanceWorkCondition: "Commercial - Cleaning & Maintenance",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 7,
    locationOrder: 1,
    skin_care_product: {
      id: 21,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:35:12.000Z",
      productName: "Refresh™ AntiBac FOAM",
      productURL: "https://www.debgroup.com/us/products/refresh-antibac-foam"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6256,
    hazzard: {
      id: 41,
      substanceWorkCondition: "Commercial - Commerce & Office",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 23,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:36:39.000Z",
      productName: "Refresh™ Clear FOAM (UL, GS)",
      productURL: "https://www.debgroup.com/us/products/refresh-clear-foam"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: true,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6257,
    hazzard: {
      id: 41,
      substanceWorkCondition: "Commercial - Commerce & Office",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 22,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:36:04.000Z",
      productName: "Refresh™ Azure FOAM (UL, GS)",
      productURL: "https://www.debgroup.com/us/products/refresh-azure-foam"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6258,
    hazzard: {
      id: 41,
      substanceWorkCondition: "Commercial - Commerce & Office",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 27,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:39:30.000Z",
      productName: "Refresh™ Rose FOAM (UL, GS)",
      productURL: "https://www.debgroup.com/us/products/refresh-rose-foam"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6259,
    hazzard: {
      id: 41,
      substanceWorkCondition: "Commercial - Commerce & Office",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 7,
    locationOrder: 1,
    skin_care_product: {
      id: 21,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:35:12.000Z",
      productName: "Refresh™ AntiBac FOAM",
      productURL: "https://www.debgroup.com/us/products/refresh-antibac-foam"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6260,
    hazzard: {
      id: 42,
      substanceWorkCondition: "Commercial - Education",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 23,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:36:39.000Z",
      productName: "Refresh™ Clear FOAM (UL, GS)",
      productURL: "https://www.debgroup.com/us/products/refresh-clear-foam"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: true,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6261,
    hazzard: {
      id: 42,
      substanceWorkCondition: "Commercial - Education",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 22,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:36:04.000Z",
      productName: "Refresh™ Azure FOAM (UL, GS)",
      productURL: "https://www.debgroup.com/us/products/refresh-azure-foam"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6262,
    hazzard: {
      id: 42,
      substanceWorkCondition: "Commercial - Education",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 27,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:39:30.000Z",
      productName: "Refresh™ Rose FOAM (UL, GS)",
      productURL: "https://www.debgroup.com/us/products/refresh-rose-foam"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6263,
    hazzard: {
      id: 42,
      substanceWorkCondition: "Commercial - Education",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 7,
    locationOrder: 1,
    skin_care_product: {
      id: 21,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:35:12.000Z",
      productName: "Refresh™ AntiBac FOAM",
      productURL: "https://www.debgroup.com/us/products/refresh-antibac-foam"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6264,
    hazzard: {
      id: 43,
      substanceWorkCondition: "Commercial - Hotel & Leisure",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 23,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:36:39.000Z",
      productName: "Refresh™ Clear FOAM (UL, GS)",
      productURL: "https://www.debgroup.com/us/products/refresh-clear-foam"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: true,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6265,
    hazzard: {
      id: 43,
      substanceWorkCondition: "Commercial - Hotel & Leisure",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 13,
    locationOrder: 1,
    skin_care_product: {
      id: 33,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:49.000Z",
      productName: "Solopol® Lime (UL)",
      productURL: "https://www.debgroup.com/us/products/solopol-lime"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6266,
    hazzard: {
      id: 43,
      substanceWorkCondition: "Commercial - Hotel & Leisure",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 22,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:36:04.000Z",
      productName: "Refresh™ Azure FOAM (UL, GS)",
      productURL: "https://www.debgroup.com/us/products/refresh-azure-foam"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6267,
    hazzard: {
      id: 43,
      substanceWorkCondition: "Commercial - Hotel & Leisure",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 27,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:39:30.000Z",
      productName: "Refresh™ Rose FOAM (UL, GS)",
      productURL: "https://www.debgroup.com/us/products/refresh-rose-foam"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6268,
    hazzard: {
      id: 43,
      substanceWorkCondition: "Commercial - Hotel & Leisure",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 24,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:37:20.000Z",
      productName: "Refresh™ Energie FOAM",
      productURL: "https://www.debgroup.com/us/products/refresh-energie-foam"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: false,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6269,
    hazzard: {
      id: 43,
      substanceWorkCondition: "Commercial - Hotel & Leisure",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 7,
    locationOrder: 1,
    skin_care_product: {
      id: 21,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:35:12.000Z",
      productName: "Refresh™ AntiBac FOAM",
      productURL: "https://www.debgroup.com/us/products/refresh-antibac-foam"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6270,
    hazzard: {
      id: 43,
      substanceWorkCondition: "Commercial - Hotel & Leisure",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 9,
    locationOrder: 1,
    skin_care_product: {
      id: 1,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:17:13.000Z",
      productName: "AgroBac Pure FOAM",
      productURL: "https://www.debgroup.com/us/products/agrobac-pure-foam"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: null,
    ecoLogo: false,
    ecarf: false,
    nsf: true,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6271,
    hazzard: {
      id: 44,
      substanceWorkCondition: "Commercial - Prisons & Security Services",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 23,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:36:39.000Z",
      productName: "Refresh™ Clear FOAM (UL, GS)",
      productURL: "https://www.debgroup.com/us/products/refresh-clear-foam"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: true,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6272,
    hazzard: {
      id: 44,
      substanceWorkCondition: "Commercial - Prisons & Security Services",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 22,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:36:04.000Z",
      productName: "Refresh™ Azure FOAM (UL, GS)",
      productURL: "https://www.debgroup.com/us/products/refresh-azure-foam"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6273,
    hazzard: {
      id: 44,
      substanceWorkCondition: "Commercial - Prisons & Security Services",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 27,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:39:30.000Z",
      productName: "Refresh™ Rose FOAM (UL, GS)",
      productURL: "https://www.debgroup.com/us/products/refresh-rose-foam"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6274,
    hazzard: {
      id: 44,
      substanceWorkCondition: "Commercial - Prisons & Security Services",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 7,
    locationOrder: 1,
    skin_care_product: {
      id: 21,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:35:12.000Z",
      productName: "Refresh™ AntiBac FOAM",
      productURL: "https://www.debgroup.com/us/products/refresh-antibac-foam"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6275,
    hazzard: {
      id: 44,
      substanceWorkCondition: "Commercial - Prisons & Security Services",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 9,
    locationOrder: 1,
    skin_care_product: {
      id: 1,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:17:13.000Z",
      productName: "AgroBac Pure FOAM",
      productURL: "https://www.debgroup.com/us/products/agrobac-pure-foam"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: null,
    ecoLogo: false,
    ecarf: false,
    nsf: true,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6276,
    hazzard: {
      id: 45,
      substanceWorkCondition:
        "Commercial - Public Entertainments & Theme Parks",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 23,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:36:39.000Z",
      productName: "Refresh™ Clear FOAM (UL, GS)",
      productURL: "https://www.debgroup.com/us/products/refresh-clear-foam"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: true,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6277,
    hazzard: {
      id: 45,
      substanceWorkCondition:
        "Commercial - Public Entertainments & Theme Parks",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 13,
    locationOrder: 1,
    skin_care_product: {
      id: 33,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:49.000Z",
      productName: "Solopol® Lime (UL)",
      productURL: "https://www.debgroup.com/us/products/solopol-lime"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6278,
    hazzard: {
      id: 45,
      substanceWorkCondition:
        "Commercial - Public Entertainments & Theme Parks",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 22,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:36:04.000Z",
      productName: "Refresh™ Azure FOAM (UL, GS)",
      productURL: "https://www.debgroup.com/us/products/refresh-azure-foam"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6279,
    hazzard: {
      id: 45,
      substanceWorkCondition:
        "Commercial - Public Entertainments & Theme Parks",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 27,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:39:30.000Z",
      productName: "Refresh™ Rose FOAM (UL, GS)",
      productURL: "https://www.debgroup.com/us/products/refresh-rose-foam"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6280,
    hazzard: {
      id: 45,
      substanceWorkCondition:
        "Commercial - Public Entertainments & Theme Parks",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 7,
    locationOrder: 1,
    skin_care_product: {
      id: 21,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:35:12.000Z",
      productName: "Refresh™ AntiBac FOAM",
      productURL: "https://www.debgroup.com/us/products/refresh-antibac-foam"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6281,
    hazzard: {
      id: 45,
      substanceWorkCondition:
        "Commercial - Public Entertainments & Theme Parks",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 9,
    locationOrder: 1,
    skin_care_product: {
      id: 1,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:17:13.000Z",
      productName: "AgroBac Pure FOAM",
      productURL: "https://www.debgroup.com/us/products/agrobac-pure-foam"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: null,
    ecoLogo: false,
    ecarf: false,
    nsf: true,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6282,
    hazzard: {
      id: 46,
      substanceWorkCondition: "Commercial - Public Facilities",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 23,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:36:39.000Z",
      productName: "Refresh™ Clear FOAM (UL, GS)",
      productURL: "https://www.debgroup.com/us/products/refresh-clear-foam"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: true,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6283,
    hazzard: {
      id: 46,
      substanceWorkCondition: "Commercial - Public Facilities",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 22,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:36:04.000Z",
      productName: "Refresh™ Azure FOAM (UL, GS)",
      productURL: "https://www.debgroup.com/us/products/refresh-azure-foam"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6284,
    hazzard: {
      id: 46,
      substanceWorkCondition: "Commercial - Public Facilities",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 27,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:39:30.000Z",
      productName: "Refresh™ Rose FOAM (UL, GS)",
      productURL: "https://www.debgroup.com/us/products/refresh-rose-foam"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6285,
    hazzard: {
      id: 46,
      substanceWorkCondition: "Commercial - Public Facilities",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 7,
    locationOrder: 1,
    skin_care_product: {
      id: 21,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:35:12.000Z",
      productName: "Refresh™ AntiBac FOAM",
      productURL: "https://www.debgroup.com/us/products/refresh-antibac-foam"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6286,
    hazzard: {
      id: 46,
      substanceWorkCondition: "Commercial - Public Facilities",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 9,
    locationOrder: 1,
    skin_care_product: {
      id: 1,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:17:13.000Z",
      productName: "AgroBac Pure FOAM",
      productURL: "https://www.debgroup.com/us/products/agrobac-pure-foam"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: null,
    ecoLogo: false,
    ecarf: false,
    nsf: true,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6287,
    hazzard: {
      id: 47,
      substanceWorkCondition: "Concrete",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 1,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6288,
    hazzard: {
      id: 47,
      substanceWorkCondition: "Concrete",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 1,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6289,
    hazzard: {
      id: 47,
      substanceWorkCondition: "Concrete",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 13,
    locationOrder: 1,
    skin_care_product: {
      id: 33,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:49.000Z",
      productName: "Solopol® Lime (UL)",
      productURL: "https://www.debgroup.com/us/products/solopol-lime"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6290,
    hazzard: {
      id: 47,
      substanceWorkCondition: "Concrete",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 14,
    locationOrder: 1,
    skin_care_product: {
      id: 32,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:07.000Z",
      productName: "Solopol® GFX™ (USDA, UL, GS)",
      productURL: "https://www.debgroup.com/us/products/solopol-gfx"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6291,
    hazzard: {
      id: 47,
      substanceWorkCondition: "Concrete",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 18,
    locationOrder: 1,
    skin_care_product: {
      id: 16,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:31:28.000Z",
      productName: "Kresto® kwik wipes®",
      productURL: "https://www.debgroup.com/us/products/kresto-kwik-wipes"
    },
    rating: 4,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6292,
    hazzard: {
      id: 48,
      substanceWorkCondition: "Cooling lubricant (non-water-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 1,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6293,
    hazzard: {
      id: 48,
      substanceWorkCondition: "Cooling lubricant (non-water-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 1,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6294,
    hazzard: {
      id: 48,
      substanceWorkCondition: "Cooling lubricant (non-water-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 13,
    locationOrder: 1,
    skin_care_product: {
      id: 33,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:49.000Z",
      productName: "Solopol® Lime (UL)",
      productURL: "https://www.debgroup.com/us/products/solopol-lime"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6295,
    hazzard: {
      id: 48,
      substanceWorkCondition: "Cooling lubricant (non-water-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 14,
    locationOrder: 1,
    skin_care_product: {
      id: 32,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:07.000Z",
      productName: "Solopol® GFX™ (USDA, UL, GS)",
      productURL: "https://www.debgroup.com/us/products/solopol-gfx"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6296,
    hazzard: {
      id: 48,
      substanceWorkCondition: "Cooling lubricant (non-water-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 18,
    locationOrder: 1,
    skin_care_product: {
      id: 16,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:31:28.000Z",
      productName: "Kresto® kwik wipes®",
      productURL: "https://www.debgroup.com/us/products/kresto-kwik-wipes"
    },
    rating: 4,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6297,
    hazzard: {
      id: 49,
      substanceWorkCondition: "Cooling lubricant (water- based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 1,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6298,
    hazzard: {
      id: 49,
      substanceWorkCondition: "Cooling lubricant (water- based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 1,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6299,
    hazzard: {
      id: 49,
      substanceWorkCondition: "Cooling lubricant (water- based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 13,
    locationOrder: 1,
    skin_care_product: {
      id: 33,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:49.000Z",
      productName: "Solopol® Lime (UL)",
      productURL: "https://www.debgroup.com/us/products/solopol-lime"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6300,
    hazzard: {
      id: 49,
      substanceWorkCondition: "Cooling lubricant (water- based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 14,
    locationOrder: 1,
    skin_care_product: {
      id: 32,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:07.000Z",
      productName: "Solopol® GFX™ (USDA, UL, GS)",
      productURL: "https://www.debgroup.com/us/products/solopol-gfx"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6301,
    hazzard: {
      id: 49,
      substanceWorkCondition: "Cooling lubricant (water- based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 18,
    locationOrder: 1,
    skin_care_product: {
      id: 16,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:31:28.000Z",
      productName: "Kresto® kwik wipes®",
      productURL: "https://www.debgroup.com/us/products/kresto-kwik-wipes"
    },
    rating: 4,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6302,
    hazzard: {
      id: 50,
      substanceWorkCondition: "Cooling water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 1,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6303,
    hazzard: {
      id: 50,
      substanceWorkCondition: "Cooling water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 1,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6304,
    hazzard: {
      id: 50,
      substanceWorkCondition: "Cooling water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 18,
    locationOrder: 1,
    skin_care_product: {
      id: 16,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:31:28.000Z",
      productName: "Kresto® kwik wipes®",
      productURL: "https://www.debgroup.com/us/products/kresto-kwik-wipes"
    },
    rating: 4,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6305,
    hazzard: {
      id: 52,
      substanceWorkCondition: "Cutting oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 13,
    locationOrder: 1,
    skin_care_product: {
      id: 33,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:49.000Z",
      productName: "Solopol® Lime (UL)",
      productURL: "https://www.debgroup.com/us/products/solopol-lime"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6306,
    hazzard: {
      id: 52,
      substanceWorkCondition: "Cutting oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 14,
    locationOrder: 1,
    skin_care_product: {
      id: 32,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:07.000Z",
      productName: "Solopol® GFX™ (USDA, UL, GS)",
      productURL: "https://www.debgroup.com/us/products/solopol-gfx"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6307,
    hazzard: {
      id: 52,
      substanceWorkCondition: "Cutting oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 23,
    locationOrder: 1,
    skin_care_product: {
      id: 13,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:28:48.000Z",
      productName: "Kresto® Cherry Wipes",
      productURL: "https://www.debgroup.com/us/products/kresto-cherry-wipes"
    },
    rating: 5,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6308,
    hazzard: {
      id: 53,
      substanceWorkCondition: "Degreaser solvent - based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 13,
    locationOrder: 1,
    skin_care_product: {
      id: 33,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:49.000Z",
      productName: "Solopol® Lime (UL)",
      productURL: "https://www.debgroup.com/us/products/solopol-lime"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6309,
    hazzard: {
      id: 53,
      substanceWorkCondition: "Degreaser solvent - based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 14,
    locationOrder: 1,
    skin_care_product: {
      id: 32,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:07.000Z",
      productName: "Solopol® GFX™ (USDA, UL, GS)",
      productURL: "https://www.debgroup.com/us/products/solopol-gfx"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6310,
    hazzard: {
      id: 53,
      substanceWorkCondition: "Degreaser solvent - based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 18,
    locationOrder: 1,
    skin_care_product: {
      id: 16,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:31:28.000Z",
      productName: "Kresto® kwik wipes®",
      productURL: "https://www.debgroup.com/us/products/kresto-kwik-wipes"
    },
    rating: 4,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6311,
    hazzard: {
      id: 54,
      substanceWorkCondition: "Degreaser without organic solvent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 1,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6312,
    hazzard: {
      id: 54,
      substanceWorkCondition: "Degreaser without organic solvent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 1,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6313,
    hazzard: {
      id: 54,
      substanceWorkCondition: "Degreaser without organic solvent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 18,
    locationOrder: 1,
    skin_care_product: {
      id: 16,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:31:28.000Z",
      productName: "Kresto® kwik wipes®",
      productURL: "https://www.debgroup.com/us/products/kresto-kwik-wipes"
    },
    rating: 4,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6314,
    hazzard: {
      id: 55,
      substanceWorkCondition: "Detachment solution for concrete",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 1,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6315,
    hazzard: {
      id: 55,
      substanceWorkCondition: "Detachment solution for concrete",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 1,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6316,
    hazzard: {
      id: 55,
      substanceWorkCondition: "Detachment solution for concrete",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 13,
    locationOrder: 1,
    skin_care_product: {
      id: 33,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:49.000Z",
      productName: "Solopol® Lime (UL)",
      productURL: "https://www.debgroup.com/us/products/solopol-lime"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6317,
    hazzard: {
      id: 55,
      substanceWorkCondition: "Detachment solution for concrete",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 14,
    locationOrder: 1,
    skin_care_product: {
      id: 32,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:07.000Z",
      productName: "Solopol® GFX™ (USDA, UL, GS)",
      productURL: "https://www.debgroup.com/us/products/solopol-gfx"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6318,
    hazzard: {
      id: 56,
      substanceWorkCondition: "Detachments (alcohol-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 1,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6319,
    hazzard: {
      id: 56,
      substanceWorkCondition: "Detachments (alcohol-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 1,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6320,
    hazzard: {
      id: 57,
      substanceWorkCondition: "Detachments (solvent-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 1,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6321,
    hazzard: {
      id: 57,
      substanceWorkCondition: "Detachments (solvent-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 1,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6322,
    hazzard: {
      id: 57,
      substanceWorkCondition: "Detachments (solvent-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 13,
    locationOrder: 1,
    skin_care_product: {
      id: 33,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:49.000Z",
      productName: "Solopol® Lime (UL)",
      productURL: "https://www.debgroup.com/us/products/solopol-lime"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6323,
    hazzard: {
      id: 57,
      substanceWorkCondition: "Detachments (solvent-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Cleanse",
    productOrder: 14,
    locationOrder: 1,
    skin_care_product: {
      id: 32,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:07.000Z",
      productName: "Solopol® GFX™ (USDA, UL, GS)",
      productURL: "https://www.debgroup.com/us/products/solopol-gfx"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6324,
    hazzard: {
      id: 58,
      substanceWorkCondition: "Detachments (sour)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 1,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6325,
    hazzard: {
      id: 58,
      substanceWorkCondition: "Detachments (sour)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 1,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6326,
    hazzard: {
      id: 59,
      substanceWorkCondition: "Disinfectants (alcohol-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 1,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6327,
    hazzard: {
      id: 59,
      substanceWorkCondition: "Disinfectants (alcohol-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 1,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6328,
    hazzard: {
      id: 60,
      substanceWorkCondition: "Disinfectants (formaldehyd-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 1,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6329,
    hazzard: {
      id: 60,
      substanceWorkCondition: "Disinfectants (formaldehyd-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 1,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6330,
    hazzard: {
      id: 61,
      substanceWorkCondition: "Disinfectants (water-based) ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 1,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6331,
    hazzard: {
      id: 61,
      substanceWorkCondition: "Disinfectants (water-based) ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 1,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6332,
    hazzard: {
      id: 62,
      substanceWorkCondition: "Dispersing Agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 1,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6333,
    hazzard: {
      id: 62,
      substanceWorkCondition: "Dispersing Agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 1,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6334,
    hazzard: {
      id: 62,
      substanceWorkCondition: "Dispersing Agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 18,
    locationOrder: 1,
    skin_care_product: {
      id: 16,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:31:28.000Z",
      productName: "Kresto® kwik wipes®",
      productURL: "https://www.debgroup.com/us/products/kresto-kwik-wipes"
    },
    rating: 4,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6335,
    hazzard: {
      id: 63,
      substanceWorkCondition: "Drilling emulsion (water based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 1,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6336,
    hazzard: {
      id: 63,
      substanceWorkCondition: "Drilling emulsion (water based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 1,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6337,
    hazzard: {
      id: 63,
      substanceWorkCondition: "Drilling emulsion (water based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 13,
    locationOrder: 1,
    skin_care_product: {
      id: 33,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:49.000Z",
      productName: "Solopol® Lime (UL)",
      productURL: "https://www.debgroup.com/us/products/solopol-lime"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6338,
    hazzard: {
      id: 63,
      substanceWorkCondition: "Drilling emulsion (water based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 14,
    locationOrder: 1,
    skin_care_product: {
      id: 32,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:07.000Z",
      productName: "Solopol® GFX™ (USDA, UL, GS)",
      productURL: "https://www.debgroup.com/us/products/solopol-gfx"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6339,
    hazzard: {
      id: 63,
      substanceWorkCondition: "Drilling emulsion (water based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 18,
    locationOrder: 1,
    skin_care_product: {
      id: 16,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:31:28.000Z",
      productName: "Kresto® kwik wipes®",
      productURL: "https://www.debgroup.com/us/products/kresto-kwik-wipes"
    },
    rating: 4,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6340,
    hazzard: {
      id: 64,
      substanceWorkCondition: "Drilling oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 13,
    locationOrder: 1,
    skin_care_product: {
      id: 33,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:49.000Z",
      productName: "Solopol® Lime (UL)",
      productURL: "https://www.debgroup.com/us/products/solopol-lime"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6341,
    hazzard: {
      id: 64,
      substanceWorkCondition: "Drilling oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 14,
    locationOrder: 1,
    skin_care_product: {
      id: 32,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:07.000Z",
      productName: "Solopol® GFX™ (USDA, UL, GS)",
      productURL: "https://www.debgroup.com/us/products/solopol-gfx"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6342,
    hazzard: {
      id: 64,
      substanceWorkCondition: "Drilling oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 23,
    locationOrder: 1,
    skin_care_product: {
      id: 13,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:28:48.000Z",
      productName: "Kresto® Cherry Wipes",
      productURL: "https://www.debgroup.com/us/products/kresto-cherry-wipes"
    },
    rating: 5,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:15.000Z",
    updated_at: "2020-03-02T22:50:15.000Z"
  },
  {
    id: 6343,
    hazzard: {
      id: 65,
      substanceWorkCondition: "Dryness",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 1,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6344,
    hazzard: {
      id: 65,
      substanceWorkCondition: "Dryness",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 1,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6345,
    hazzard: {
      id: 66,
      substanceWorkCondition: "Dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 1,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6346,
    hazzard: {
      id: 66,
      substanceWorkCondition: "Dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 1,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6347,
    hazzard: {
      id: 66,
      substanceWorkCondition: "Dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 18,
    locationOrder: 1,
    skin_care_product: {
      id: 16,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:31:28.000Z",
      productName: "Kresto® kwik wipes®",
      productURL: "https://www.debgroup.com/us/products/kresto-kwik-wipes"
    },
    rating: 4,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6348,
    hazzard: {
      id: 71,
      substanceWorkCondition: "Etching (mordant)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 13,
    locationOrder: 1,
    skin_care_product: {
      id: 33,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:49.000Z",
      productName: "Solopol® Lime (UL)",
      productURL: "https://www.debgroup.com/us/products/solopol-lime"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6349,
    hazzard: {
      id: 71,
      substanceWorkCondition: "Etching (mordant)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 14,
    locationOrder: 1,
    skin_care_product: {
      id: 32,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:07.000Z",
      productName: "Solopol® GFX™ (USDA, UL, GS)",
      productURL: "https://www.debgroup.com/us/products/solopol-gfx"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6350,
    hazzard: {
      id: 71,
      substanceWorkCondition: "Etching (mordant)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 18,
    locationOrder: 1,
    skin_care_product: {
      id: 16,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:31:28.000Z",
      productName: "Kresto® kwik wipes®",
      productURL: "https://www.debgroup.com/us/products/kresto-kwik-wipes"
    },
    rating: 4,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6351,
    hazzard: {
      id: 72,
      substanceWorkCondition: "Ethanol / methylated spirits ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 1,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6352,
    hazzard: {
      id: 72,
      substanceWorkCondition: "Ethanol / methylated spirits ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 1,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6353,
    hazzard: {
      id: 72,
      substanceWorkCondition: "Ethanol / methylated spirits ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 18,
    locationOrder: 1,
    skin_care_product: {
      id: 16,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:31:28.000Z",
      productName: "Kresto® kwik wipes®",
      productURL: "https://www.debgroup.com/us/products/kresto-kwik-wipes"
    },
    rating: 4,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6354,
    hazzard: {
      id: 73,
      substanceWorkCondition: "Fat",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 13,
    locationOrder: 1,
    skin_care_product: {
      id: 33,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:49.000Z",
      productName: "Solopol® Lime (UL)",
      productURL: "https://www.debgroup.com/us/products/solopol-lime"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6355,
    hazzard: {
      id: 73,
      substanceWorkCondition: "Fat",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 23,
    locationOrder: 1,
    skin_care_product: {
      id: 13,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:28:48.000Z",
      productName: "Kresto® Cherry Wipes",
      productURL: "https://www.debgroup.com/us/products/kresto-cherry-wipes"
    },
    rating: 5,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6356,
    hazzard: {
      id: 74,
      substanceWorkCondition: "Fertilizer",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 1,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6357,
    hazzard: {
      id: 74,
      substanceWorkCondition: "Fertilizer",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 1,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6358,
    hazzard: {
      id: 74,
      substanceWorkCondition: "Fertilizer",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 18,
    locationOrder: 1,
    skin_care_product: {
      id: 16,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:31:28.000Z",
      productName: "Kresto® kwik wipes®",
      productURL: "https://www.debgroup.com/us/products/kresto-kwik-wipes"
    },
    rating: 4,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6359,
    hazzard: {
      id: 75,
      substanceWorkCondition: "fine anorganic/inorganic powders ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 13,
    locationOrder: 1,
    skin_care_product: {
      id: 33,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:49.000Z",
      productName: "Solopol® Lime (UL)",
      productURL: "https://www.debgroup.com/us/products/solopol-lime"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6360,
    hazzard: {
      id: 75,
      substanceWorkCondition: "fine anorganic/inorganic powders ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 18,
    locationOrder: 1,
    skin_care_product: {
      id: 16,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:31:28.000Z",
      productName: "Kresto® kwik wipes®",
      productURL: "https://www.debgroup.com/us/products/kresto-kwik-wipes"
    },
    rating: 4,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6361,
    hazzard: {
      id: 76,
      substanceWorkCondition: "Floor cleaner (water- based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 1,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6362,
    hazzard: {
      id: 76,
      substanceWorkCondition: "Floor cleaner (water- based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 1,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6363,
    hazzard: {
      id: 76,
      substanceWorkCondition: "Floor cleaner (water- based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 18,
    locationOrder: 1,
    skin_care_product: {
      id: 16,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:31:28.000Z",
      productName: "Kresto® kwik wipes®",
      productURL: "https://www.debgroup.com/us/products/kresto-kwik-wipes"
    },
    rating: 4,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6364,
    hazzard: {
      id: 77,
      substanceWorkCondition: "Food - Food Packaging",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 1,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6365,
    hazzard: {
      id: 77,
      substanceWorkCondition: "Food - Food Packaging",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 9,
    locationOrder: 1,
    skin_care_product: {
      id: 1,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:17:13.000Z",
      productName: "AgroBac Pure FOAM",
      productURL: "https://www.debgroup.com/us/products/agrobac-pure-foam"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: null,
    ecoLogo: false,
    ecarf: false,
    nsf: true,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6366,
    hazzard: {
      id: 78,
      substanceWorkCondition: "Food - Food Processing & Manufacturing",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 1,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6367,
    hazzard: {
      id: 78,
      substanceWorkCondition: "Food - Food Processing & Manufacturing",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 9,
    locationOrder: 1,
    skin_care_product: {
      id: 1,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:17:13.000Z",
      productName: "AgroBac Pure FOAM",
      productURL: "https://www.debgroup.com/us/products/agrobac-pure-foam"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: null,
    ecoLogo: false,
    ecarf: false,
    nsf: true,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6368,
    hazzard: {
      id: 79,
      substanceWorkCondition: "Food - Food Services & Catering",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 1,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6369,
    hazzard: {
      id: 79,
      substanceWorkCondition: "Food - Food Services & Catering",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 9,
    locationOrder: 1,
    skin_care_product: {
      id: 1,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:17:13.000Z",
      productName: "AgroBac Pure FOAM",
      productURL: "https://www.debgroup.com/us/products/agrobac-pure-foam"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: null,
    ecoLogo: false,
    ecarf: false,
    nsf: true,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6370,
    hazzard: {
      id: 81,
      substanceWorkCondition: "fuel: gas / diesel",
      cas: null,
      glovesRecommended: true,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 13,
    locationOrder: 1,
    skin_care_product: {
      id: 33,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:49.000Z",
      productName: "Solopol® Lime (UL)",
      productURL: "https://www.debgroup.com/us/products/solopol-lime"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6371,
    hazzard: {
      id: 81,
      substanceWorkCondition: "fuel: gas / diesel",
      cas: null,
      glovesRecommended: true,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 14,
    locationOrder: 1,
    skin_care_product: {
      id: 32,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:07.000Z",
      productName: "Solopol® GFX™ (USDA, UL, GS)",
      productURL: "https://www.debgroup.com/us/products/solopol-gfx"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6372,
    hazzard: {
      id: 82,
      substanceWorkCondition: "Galvanic bath",
      cas: null,
      glovesRecommended: true,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 1,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6373,
    hazzard: {
      id: 82,
      substanceWorkCondition: "Galvanic bath",
      cas: null,
      glovesRecommended: true,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 1,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6374,
    hazzard: {
      id: 83,
      substanceWorkCondition: "Glass fibre dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 1,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6375,
    hazzard: {
      id: 83,
      substanceWorkCondition: "Glass fibre dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 1,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6376,
    hazzard: {
      id: 83,
      substanceWorkCondition: "Glass fibre dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 13,
    locationOrder: 1,
    skin_care_product: {
      id: 33,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:49.000Z",
      productName: "Solopol® Lime (UL)",
      productURL: "https://www.debgroup.com/us/products/solopol-lime"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6377,
    hazzard: {
      id: 83,
      substanceWorkCondition: "Glass fibre dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 14,
    locationOrder: 1,
    skin_care_product: {
      id: 32,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:07.000Z",
      productName: "Solopol® GFX™ (USDA, UL, GS)",
      productURL: "https://www.debgroup.com/us/products/solopol-gfx"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6378,
    hazzard: {
      id: 83,
      substanceWorkCondition: "Glass fibre dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 18,
    locationOrder: 1,
    skin_care_product: {
      id: 16,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:31:28.000Z",
      productName: "Kresto® kwik wipes®",
      productURL: "https://www.debgroup.com/us/products/kresto-kwik-wipes"
    },
    rating: 4,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6379,
    hazzard: {
      id: 84,
      substanceWorkCondition: "Glass wool dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 1,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6380,
    hazzard: {
      id: 84,
      substanceWorkCondition: "Glass wool dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 1,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6381,
    hazzard: {
      id: 84,
      substanceWorkCondition: "Glass wool dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 13,
    locationOrder: 1,
    skin_care_product: {
      id: 33,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:49.000Z",
      productName: "Solopol® Lime (UL)",
      productURL: "https://www.debgroup.com/us/products/solopol-lime"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6382,
    hazzard: {
      id: 84,
      substanceWorkCondition: "Glass wool dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 14,
    locationOrder: 1,
    skin_care_product: {
      id: 32,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:07.000Z",
      productName: "Solopol® GFX™ (USDA, UL, GS)",
      productURL: "https://www.debgroup.com/us/products/solopol-gfx"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6383,
    hazzard: {
      id: 84,
      substanceWorkCondition: "Glass wool dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 18,
    locationOrder: 1,
    skin_care_product: {
      id: 16,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:31:28.000Z",
      productName: "Kresto® kwik wipes®",
      productURL: "https://www.debgroup.com/us/products/kresto-kwik-wipes"
    },
    rating: 4,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6384,
    hazzard: {
      id: 85,
      substanceWorkCondition: "Glycols",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 1,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6385,
    hazzard: {
      id: 85,
      substanceWorkCondition: "Glycols",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 1,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6386,
    hazzard: {
      id: 85,
      substanceWorkCondition: "Glycols",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 18,
    locationOrder: 1,
    skin_care_product: {
      id: 16,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:31:28.000Z",
      productName: "Kresto® kwik wipes®",
      productURL: "https://www.debgroup.com/us/products/kresto-kwik-wipes"
    },
    rating: 4,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6387,
    hazzard: {
      id: 86,
      substanceWorkCondition: "Graphite",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 1,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6388,
    hazzard: {
      id: 86,
      substanceWorkCondition: "Graphite",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 1,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6389,
    hazzard: {
      id: 86,
      substanceWorkCondition: "Graphite",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 14,
    locationOrder: 1,
    skin_care_product: {
      id: 32,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:07.000Z",
      productName: "Solopol® GFX™ (USDA, UL, GS)",
      productURL: "https://www.debgroup.com/us/products/solopol-gfx"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6390,
    hazzard: {
      id: 87,
      substanceWorkCondition: "Grease",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 1,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6391,
    hazzard: {
      id: 87,
      substanceWorkCondition: "Grease",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 1,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6392,
    hazzard: {
      id: 87,
      substanceWorkCondition: "Grease",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 13,
    locationOrder: 1,
    skin_care_product: {
      id: 33,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:49.000Z",
      productName: "Solopol® Lime (UL)",
      productURL: "https://www.debgroup.com/us/products/solopol-lime"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6393,
    hazzard: {
      id: 87,
      substanceWorkCondition: "Grease",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 14,
    locationOrder: 1,
    skin_care_product: {
      id: 32,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:07.000Z",
      productName: "Solopol® GFX™ (USDA, UL, GS)",
      productURL: "https://www.debgroup.com/us/products/solopol-gfx"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6394,
    hazzard: {
      id: 88,
      substanceWorkCondition: "Grinding oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 1,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6395,
    hazzard: {
      id: 88,
      substanceWorkCondition: "Grinding oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 1,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6396,
    hazzard: {
      id: 88,
      substanceWorkCondition: "Grinding oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 13,
    locationOrder: 1,
    skin_care_product: {
      id: 33,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:49.000Z",
      productName: "Solopol® Lime (UL)",
      productURL: "https://www.debgroup.com/us/products/solopol-lime"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6397,
    hazzard: {
      id: 88,
      substanceWorkCondition: "Grinding oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 14,
    locationOrder: 1,
    skin_care_product: {
      id: 32,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:07.000Z",
      productName: "Solopol® GFX™ (USDA, UL, GS)",
      productURL: "https://www.debgroup.com/us/products/solopol-gfx"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6398,
    hazzard: {
      id: 88,
      substanceWorkCondition: "Grinding oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 15,
    locationOrder: 1,
    skin_care_product: {
      id: 29,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:41:00.000Z",
      productName: "Solopol® Classic  (USDA)",
      productURL: "https://www.debgroup.com/us/products/solopol-classic"
    },
    rating: 4,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6399,
    hazzard: {
      id: 88,
      substanceWorkCondition: "Grinding oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 18,
    locationOrder: 1,
    skin_care_product: {
      id: 16,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:31:28.000Z",
      productName: "Kresto® kwik wipes®",
      productURL: "https://www.debgroup.com/us/products/kresto-kwik-wipes"
    },
    rating: 4,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6400,
    hazzard: {
      id: 89,
      substanceWorkCondition: "Grinding water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 1,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6401,
    hazzard: {
      id: 89,
      substanceWorkCondition: "Grinding water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 1,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6402,
    hazzard: {
      id: 89,
      substanceWorkCondition: "Grinding water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 13,
    locationOrder: 1,
    skin_care_product: {
      id: 33,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:49.000Z",
      productName: "Solopol® Lime (UL)",
      productURL: "https://www.debgroup.com/us/products/solopol-lime"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6403,
    hazzard: {
      id: 89,
      substanceWorkCondition: "Grinding water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 14,
    locationOrder: 1,
    skin_care_product: {
      id: 32,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:07.000Z",
      productName: "Solopol® GFX™ (USDA, UL, GS)",
      productURL: "https://www.debgroup.com/us/products/solopol-gfx"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6404,
    hazzard: {
      id: 89,
      substanceWorkCondition: "Grinding water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 18,
    locationOrder: 1,
    skin_care_product: {
      id: 16,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:31:28.000Z",
      productName: "Kresto® kwik wipes®",
      productURL: "https://www.debgroup.com/us/products/kresto-kwik-wipes"
    },
    rating: 4,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6405,
    hazzard: {
      id: 90,
      substanceWorkCondition: "Gypsum",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 13,
    locationOrder: 1,
    skin_care_product: {
      id: 33,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:49.000Z",
      productName: "Solopol® Lime (UL)",
      productURL: "https://www.debgroup.com/us/products/solopol-lime"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6406,
    hazzard: {
      id: 90,
      substanceWorkCondition: "Gypsum",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 14,
    locationOrder: 1,
    skin_care_product: {
      id: 32,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:07.000Z",
      productName: "Solopol® GFX™ (USDA, UL, GS)",
      productURL: "https://www.debgroup.com/us/products/solopol-gfx"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6407,
    hazzard: {
      id: 90,
      substanceWorkCondition: "Gypsum",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 18,
    locationOrder: 1,
    skin_care_product: {
      id: 16,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:31:28.000Z",
      productName: "Kresto® kwik wipes®",
      productURL: "https://www.debgroup.com/us/products/kresto-kwik-wipes"
    },
    rating: 4,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6408,
    hazzard: {
      id: 91,
      substanceWorkCondition: "Healthcare - Care Homes",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 23,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:36:39.000Z",
      productName: "Refresh™ Clear FOAM (UL, GS)",
      productURL: "https://www.debgroup.com/us/products/refresh-clear-foam"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: true,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6409,
    hazzard: {
      id: 91,
      substanceWorkCondition: "Healthcare - Care Homes",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 22,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:36:04.000Z",
      productName: "Refresh™ Azure FOAM (UL, GS)",
      productURL: "https://www.debgroup.com/us/products/refresh-azure-foam"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6410,
    hazzard: {
      id: 91,
      substanceWorkCondition: "Healthcare - Care Homes",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 13,
    locationOrder: 1,
    skin_care_product: {
      id: 33,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:49.000Z",
      productName: "Solopol® Lime (UL)",
      productURL: "https://www.debgroup.com/us/products/solopol-lime"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6411,
    hazzard: {
      id: 91,
      substanceWorkCondition: "Healthcare - Care Homes",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 27,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:39:30.000Z",
      productName: "Refresh™ Rose FOAM (UL, GS)",
      productURL: "https://www.debgroup.com/us/products/refresh-rose-foam"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6412,
    hazzard: {
      id: 91,
      substanceWorkCondition: "Healthcare - Care Homes",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 9,
    locationOrder: 1,
    skin_care_product: {
      id: 1,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:17:13.000Z",
      productName: "AgroBac Pure FOAM",
      productURL: "https://www.debgroup.com/us/products/agrobac-pure-foam"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: null,
    ecoLogo: false,
    ecarf: false,
    nsf: true,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6413,
    hazzard: {
      id: 92,
      substanceWorkCondition:
        "Healthcare - Medical Centers/Surgeries/General Practitioners",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 23,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:36:39.000Z",
      productName: "Refresh™ Clear FOAM (UL, GS)",
      productURL: "https://www.debgroup.com/us/products/refresh-clear-foam"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: true,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6414,
    hazzard: {
      id: 92,
      substanceWorkCondition:
        "Healthcare - Medical Centers/Surgeries/General Practitioners",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 22,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:36:04.000Z",
      productName: "Refresh™ Azure FOAM (UL, GS)",
      productURL: "https://www.debgroup.com/us/products/refresh-azure-foam"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6415,
    hazzard: {
      id: 92,
      substanceWorkCondition:
        "Healthcare - Medical Centers/Surgeries/General Practitioners",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 27,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:39:30.000Z",
      productName: "Refresh™ Rose FOAM (UL, GS)",
      productURL: "https://www.debgroup.com/us/products/refresh-rose-foam"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6416,
    hazzard: {
      id: 92,
      substanceWorkCondition:
        "Healthcare - Medical Centers/Surgeries/General Practitioners",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 9,
    locationOrder: 1,
    skin_care_product: {
      id: 1,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:17:13.000Z",
      productName: "AgroBac Pure FOAM",
      productURL: "https://www.debgroup.com/us/products/agrobac-pure-foam"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: null,
    ecoLogo: false,
    ecarf: false,
    nsf: true,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6417,
    hazzard: {
      id: 93,
      substanceWorkCondition: "Healthcare - Nursing Homes",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 23,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:36:39.000Z",
      productName: "Refresh™ Clear FOAM (UL, GS)",
      productURL: "https://www.debgroup.com/us/products/refresh-clear-foam"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: true,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6418,
    hazzard: {
      id: 93,
      substanceWorkCondition: "Healthcare - Nursing Homes",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 22,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:36:04.000Z",
      productName: "Refresh™ Azure FOAM (UL, GS)",
      productURL: "https://www.debgroup.com/us/products/refresh-azure-foam"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6419,
    hazzard: {
      id: 93,
      substanceWorkCondition: "Healthcare - Nursing Homes",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 27,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:39:30.000Z",
      productName: "Refresh™ Rose FOAM (UL, GS)",
      productURL: "https://www.debgroup.com/us/products/refresh-rose-foam"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6420,
    hazzard: {
      id: 93,
      substanceWorkCondition: "Healthcare - Nursing Homes",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 13,
    locationOrder: 1,
    skin_care_product: {
      id: 33,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:49.000Z",
      productName: "Solopol® Lime (UL)",
      productURL: "https://www.debgroup.com/us/products/solopol-lime"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6421,
    hazzard: {
      id: 93,
      substanceWorkCondition: "Healthcare - Nursing Homes",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 9,
    locationOrder: 1,
    skin_care_product: {
      id: 1,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:17:13.000Z",
      productName: "AgroBac Pure FOAM",
      productURL: "https://www.debgroup.com/us/products/agrobac-pure-foam"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: null,
    ecoLogo: false,
    ecarf: false,
    nsf: true,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6422,
    hazzard: {
      id: 94,
      substanceWorkCondition: "Healthcare - Veterinary Services",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 23,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:36:39.000Z",
      productName: "Refresh™ Clear FOAM (UL, GS)",
      productURL: "https://www.debgroup.com/us/products/refresh-clear-foam"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: true,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6423,
    hazzard: {
      id: 94,
      substanceWorkCondition: "Healthcare - Veterinary Services",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 22,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:36:04.000Z",
      productName: "Refresh™ Azure FOAM (UL, GS)",
      productURL: "https://www.debgroup.com/us/products/refresh-azure-foam"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6424,
    hazzard: {
      id: 94,
      substanceWorkCondition: "Healthcare - Veterinary Services",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 27,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:39:30.000Z",
      productName: "Refresh™ Rose FOAM (UL, GS)",
      productURL: "https://www.debgroup.com/us/products/refresh-rose-foam"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6425,
    hazzard: {
      id: 94,
      substanceWorkCondition: "Healthcare - Veterinary Services",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 13,
    locationOrder: 1,
    skin_care_product: {
      id: 33,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:49.000Z",
      productName: "Solopol® Lime (UL)",
      productURL: "https://www.debgroup.com/us/products/solopol-lime"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6426,
    hazzard: {
      id: 94,
      substanceWorkCondition: "Healthcare - Veterinary Services",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 9,
    locationOrder: 1,
    skin_care_product: {
      id: 1,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:17:13.000Z",
      productName: "AgroBac Pure FOAM",
      productURL: "https://www.debgroup.com/us/products/agrobac-pure-foam"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: null,
    ecoLogo: false,
    ecarf: false,
    nsf: true,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6427,
    hazzard: {
      id: 95,
      substanceWorkCondition: "Hydraulic fluid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 13,
    locationOrder: 1,
    skin_care_product: {
      id: 33,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:49.000Z",
      productName: "Solopol® Lime (UL)",
      productURL: "https://www.debgroup.com/us/products/solopol-lime"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6428,
    hazzard: {
      id: 95,
      substanceWorkCondition: "Hydraulic fluid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 14,
    locationOrder: 1,
    skin_care_product: {
      id: 32,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:07.000Z",
      productName: "Solopol® GFX™ (USDA, UL, GS)",
      productURL: "https://www.debgroup.com/us/products/solopol-gfx"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6429,
    hazzard: {
      id: 95,
      substanceWorkCondition: "Hydraulic fluid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 18,
    locationOrder: 1,
    skin_care_product: {
      id: 16,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:31:28.000Z",
      productName: "Kresto® kwik wipes®",
      productURL: "https://www.debgroup.com/us/products/kresto-kwik-wipes"
    },
    rating: 4,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6430,
    hazzard: {
      id: 96,
      substanceWorkCondition: "Hyrocarbons",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 1,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6431,
    hazzard: {
      id: 96,
      substanceWorkCondition: "Hyrocarbons",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 1,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6432,
    hazzard: {
      id: 96,
      substanceWorkCondition: "Hyrocarbons",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 18,
    locationOrder: 1,
    skin_care_product: {
      id: 16,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:31:28.000Z",
      productName: "Kresto® kwik wipes®",
      productURL: "https://www.debgroup.com/us/products/kresto-kwik-wipes"
    },
    rating: 4,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6433,
    hazzard: {
      id: 97,
      substanceWorkCondition: "Industry Heavy - Building Materials",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 13,
    locationOrder: 1,
    skin_care_product: {
      id: 33,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:49.000Z",
      productName: "Solopol® Lime (UL)",
      productURL: "https://www.debgroup.com/us/products/solopol-lime"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6434,
    hazzard: {
      id: 97,
      substanceWorkCondition: "Industry Heavy - Building Materials",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 14,
    locationOrder: 1,
    skin_care_product: {
      id: 32,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:07.000Z",
      productName: "Solopol® GFX™ (USDA, UL, GS)",
      productURL: "https://www.debgroup.com/us/products/solopol-gfx"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6435,
    hazzard: {
      id: 98,
      substanceWorkCondition: "Industry Heavy - Construction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 13,
    locationOrder: 1,
    skin_care_product: {
      id: 33,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:49.000Z",
      productName: "Solopol® Lime (UL)",
      productURL: "https://www.debgroup.com/us/products/solopol-lime"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6436,
    hazzard: {
      id: 98,
      substanceWorkCondition: "Industry Heavy - Construction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 14,
    locationOrder: 1,
    skin_care_product: {
      id: 32,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:07.000Z",
      productName: "Solopol® GFX™ (USDA, UL, GS)",
      productURL: "https://www.debgroup.com/us/products/solopol-gfx"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6437,
    hazzard: {
      id: 98,
      substanceWorkCondition: "Industry Heavy - Construction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 18,
    locationOrder: 1,
    skin_care_product: {
      id: 16,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:31:28.000Z",
      productName: "Kresto® kwik wipes®",
      productURL: "https://www.debgroup.com/us/products/kresto-kwik-wipes"
    },
    rating: 4,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6438,
    hazzard: {
      id: 99,
      substanceWorkCondition: "Industry Heavy - Mining & Extraction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 13,
    locationOrder: 1,
    skin_care_product: {
      id: 33,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:49.000Z",
      productName: "Solopol® Lime (UL)",
      productURL: "https://www.debgroup.com/us/products/solopol-lime"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6439,
    hazzard: {
      id: 99,
      substanceWorkCondition: "Industry Heavy - Mining & Extraction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 14,
    locationOrder: 1,
    skin_care_product: {
      id: 32,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:07.000Z",
      productName: "Solopol® GFX™ (USDA, UL, GS)",
      productURL: "https://www.debgroup.com/us/products/solopol-gfx"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6440,
    hazzard: {
      id: 99,
      substanceWorkCondition: "Industry Heavy - Mining & Extraction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 18,
    locationOrder: 1,
    skin_care_product: {
      id: 16,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:31:28.000Z",
      productName: "Kresto® kwik wipes®",
      productURL: "https://www.debgroup.com/us/products/kresto-kwik-wipes"
    },
    rating: 4,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6441,
    hazzard: {
      id: 100,
      substanceWorkCondition: "Industry Heavy - Off-Shore Extraction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 14,
    locationOrder: 1,
    skin_care_product: {
      id: 32,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:07.000Z",
      productName: "Solopol® GFX™ (USDA, UL, GS)",
      productURL: "https://www.debgroup.com/us/products/solopol-gfx"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6442,
    hazzard: {
      id: 101,
      substanceWorkCondition: "Industry Heavy - Printing Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 15,
    locationOrder: 1,
    skin_care_product: {
      id: 29,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:41:00.000Z",
      productName: "Solopol® Classic  (USDA)",
      productURL: "https://www.debgroup.com/us/products/solopol-classic"
    },
    rating: 4,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6443,
    hazzard: {
      id: 101,
      substanceWorkCondition: "Industry Heavy - Printing Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 20,
    locationOrder: 1,
    skin_care_product: {
      id: 15,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:30:30.000Z",
      productName: "Kresto® Heritage (UL)",
      productURL: "https://www.debgroup.com/us/products/kresto-heritage"
    },
    rating: 5,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6444,
    hazzard: {
      id: 102,
      substanceWorkCondition:
        "Industry Heavy - Road & Transportation Construction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 18,
    locationOrder: 1,
    skin_care_product: {
      id: 16,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:31:28.000Z",
      productName: "Kresto® kwik wipes®",
      productURL: "https://www.debgroup.com/us/products/kresto-kwik-wipes"
    },
    rating: 4,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6445,
    hazzard: {
      id: 103,
      substanceWorkCondition: "Industry Heavy - Ship & Boat Building",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 18,
    locationOrder: 1,
    skin_care_product: {
      id: 16,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:31:28.000Z",
      productName: "Kresto® kwik wipes®",
      productURL: "https://www.debgroup.com/us/products/kresto-kwik-wipes"
    },
    rating: 4,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6446,
    hazzard: {
      id: 104,
      substanceWorkCondition: "Industry Heavy - Shipping & Marine",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 18,
    locationOrder: 1,
    skin_care_product: {
      id: 16,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:31:28.000Z",
      productName: "Kresto® kwik wipes®",
      productURL: "https://www.debgroup.com/us/products/kresto-kwik-wipes"
    },
    rating: 4,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6447,
    hazzard: {
      id: 105,
      substanceWorkCondition:
        "Industry Heavy - Steel, Engineering & Heavy Manufacturing",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 18,
    locationOrder: 1,
    skin_care_product: {
      id: 16,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:31:28.000Z",
      productName: "Kresto® kwik wipes®",
      productURL: "https://www.debgroup.com/us/products/kresto-kwik-wipes"
    },
    rating: 4,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6448,
    hazzard: {
      id: 106,
      substanceWorkCondition:
        "Industry Light - Airports & Transportation Terminals - Services & Facility Maintenance",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 1,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6449,
    hazzard: {
      id: 106,
      substanceWorkCondition:
        "Industry Light - Airports & Transportation Terminals - Services & Facility Maintenance",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 1,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6450,
    hazzard: {
      id: 106,
      substanceWorkCondition:
        "Industry Light - Airports & Transportation Terminals - Services & Facility Maintenance",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 18,
    locationOrder: 1,
    skin_care_product: {
      id: 16,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:31:28.000Z",
      productName: "Kresto® kwik wipes®",
      productURL: "https://www.debgroup.com/us/products/kresto-kwik-wipes"
    },
    rating: 4,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6451,
    hazzard: {
      id: 107,
      substanceWorkCondition: "Industry Light - Emergency Services",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 1,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6452,
    hazzard: {
      id: 107,
      substanceWorkCondition: "Industry Light - Emergency Services",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 1,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6453,
    hazzard: {
      id: 107,
      substanceWorkCondition: "Industry Light - Emergency Services",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 13,
    locationOrder: 1,
    skin_care_product: {
      id: 33,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:49.000Z",
      productName: "Solopol® Lime (UL)",
      productURL: "https://www.debgroup.com/us/products/solopol-lime"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6454,
    hazzard: {
      id: 107,
      substanceWorkCondition: "Industry Light - Emergency Services",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 14,
    locationOrder: 1,
    skin_care_product: {
      id: 32,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:07.000Z",
      productName: "Solopol® GFX™ (USDA, UL, GS)",
      productURL: "https://www.debgroup.com/us/products/solopol-gfx"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6455,
    hazzard: {
      id: 107,
      substanceWorkCondition: "Industry Light - Emergency Services",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Cleanse",
    productOrder: 18,
    locationOrder: 1,
    skin_care_product: {
      id: 16,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:31:28.000Z",
      productName: "Kresto® kwik wipes®",
      productURL: "https://www.debgroup.com/us/products/kresto-kwik-wipes"
    },
    rating: 4,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6456,
    hazzard: {
      id: 108,
      substanceWorkCondition:
        "Industry Light - General Manufacturing & Assembly",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 1,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6457,
    hazzard: {
      id: 108,
      substanceWorkCondition:
        "Industry Light - General Manufacturing & Assembly",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 1,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6458,
    hazzard: {
      id: 108,
      substanceWorkCondition:
        "Industry Light - General Manufacturing & Assembly",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 18,
    locationOrder: 1,
    skin_care_product: {
      id: 16,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:31:28.000Z",
      productName: "Kresto® kwik wipes®",
      productURL: "https://www.debgroup.com/us/products/kresto-kwik-wipes"
    },
    rating: 4,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6459,
    hazzard: {
      id: 109,
      substanceWorkCondition:
        "Industry Light - Hosiery, Knitware, Fabric & Clothing Manufacturing",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 1,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6460,
    hazzard: {
      id: 109,
      substanceWorkCondition:
        "Industry Light - Hosiery, Knitware, Fabric & Clothing Manufacturing",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 1,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6461,
    hazzard: {
      id: 110,
      substanceWorkCondition: "Industry Light - Science & Laboratories",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 23,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:36:39.000Z",
      productName: "Refresh™ Clear FOAM (UL, GS)",
      productURL: "https://www.debgroup.com/us/products/refresh-clear-foam"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: true,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6462,
    hazzard: {
      id: 110,
      substanceWorkCondition: "Industry Light - Science & Laboratories",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 22,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:36:04.000Z",
      productName: "Refresh™ Azure FOAM (UL, GS)",
      productURL: "https://www.debgroup.com/us/products/refresh-azure-foam"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6463,
    hazzard: {
      id: 110,
      substanceWorkCondition: "Industry Light - Science & Laboratories",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 1,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6464,
    hazzard: {
      id: 110,
      substanceWorkCondition: "Industry Light - Science & Laboratories",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 1,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6465,
    hazzard: {
      id: 110,
      substanceWorkCondition: "Industry Light - Science & Laboratories",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 7,
    locationOrder: 1,
    skin_care_product: {
      id: 21,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:35:12.000Z",
      productName: "Refresh™ AntiBac FOAM",
      productURL: "https://www.debgroup.com/us/products/refresh-antibac-foam"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6466,
    hazzard: {
      id: 110,
      substanceWorkCondition: "Industry Light - Science & Laboratories",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 9,
    locationOrder: 1,
    skin_care_product: {
      id: 1,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:17:13.000Z",
      productName: "AgroBac Pure FOAM",
      productURL: "https://www.debgroup.com/us/products/agrobac-pure-foam"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: null,
    ecoLogo: false,
    ecarf: false,
    nsf: true,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6467,
    hazzard: {
      id: 111,
      substanceWorkCondition: "Industry Light - Warehousing & Logistics",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 1,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6468,
    hazzard: {
      id: 111,
      substanceWorkCondition: "Industry Light - Warehousing & Logistics",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 1,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6469,
    hazzard: {
      id: 111,
      substanceWorkCondition: "Industry Light - Warehousing & Logistics",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 18,
    locationOrder: 1,
    skin_care_product: {
      id: 16,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:31:28.000Z",
      productName: "Kresto® kwik wipes®",
      productURL: "https://www.debgroup.com/us/products/kresto-kwik-wipes"
    },
    rating: 4,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6470,
    hazzard: {
      id: 112,
      substanceWorkCondition: "Industry Medium - Ceramics & Glassware",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 13,
    locationOrder: 1,
    skin_care_product: {
      id: 33,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:49.000Z",
      productName: "Solopol® Lime (UL)",
      productURL: "https://www.debgroup.com/us/products/solopol-lime"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6471,
    hazzard: {
      id: 112,
      substanceWorkCondition: "Industry Medium - Ceramics & Glassware",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 18,
    locationOrder: 1,
    skin_care_product: {
      id: 16,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:31:28.000Z",
      productName: "Kresto® kwik wipes®",
      productURL: "https://www.debgroup.com/us/products/kresto-kwik-wipes"
    },
    rating: 4,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6472,
    hazzard: {
      id: 113,
      substanceWorkCondition: "Industry Medium - Chemical Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 13,
    locationOrder: 1,
    skin_care_product: {
      id: 33,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:49.000Z",
      productName: "Solopol® Lime (UL)",
      productURL: "https://www.debgroup.com/us/products/solopol-lime"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6473,
    hazzard: {
      id: 113,
      substanceWorkCondition: "Industry Medium - Chemical Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 14,
    locationOrder: 1,
    skin_care_product: {
      id: 32,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:07.000Z",
      productName: "Solopol® GFX™ (USDA, UL, GS)",
      productURL: "https://www.debgroup.com/us/products/solopol-gfx"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6474,
    hazzard: {
      id: 113,
      substanceWorkCondition: "Industry Medium - Chemical Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 18,
    locationOrder: 1,
    skin_care_product: {
      id: 16,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:31:28.000Z",
      productName: "Kresto® kwik wipes®",
      productURL: "https://www.debgroup.com/us/products/kresto-kwik-wipes"
    },
    rating: 4,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6475,
    hazzard: {
      id: 114,
      substanceWorkCondition: "Industry Medium - Electronics Industries",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 1,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6476,
    hazzard: {
      id: 114,
      substanceWorkCondition: "Industry Medium - Electronics Industries",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 13,
    locationOrder: 1,
    skin_care_product: {
      id: 33,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:49.000Z",
      productName: "Solopol® Lime (UL)",
      productURL: "https://www.debgroup.com/us/products/solopol-lime"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6477,
    hazzard: {
      id: 114,
      substanceWorkCondition: "Industry Medium - Electronics Industries",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 14,
    locationOrder: 1,
    skin_care_product: {
      id: 32,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:07.000Z",
      productName: "Solopol® GFX™ (USDA, UL, GS)",
      productURL: "https://www.debgroup.com/us/products/solopol-gfx"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6478,
    hazzard: {
      id: 114,
      substanceWorkCondition: "Industry Medium - Electronics Industries",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 18,
    locationOrder: 1,
    skin_care_product: {
      id: 16,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:31:28.000Z",
      productName: "Kresto® kwik wipes®",
      productURL: "https://www.debgroup.com/us/products/kresto-kwik-wipes"
    },
    rating: 4,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6479,
    hazzard: {
      id: 115,
      substanceWorkCondition: "Industry Medium - Metal Working & Fabrication",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 13,
    locationOrder: 1,
    skin_care_product: {
      id: 33,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:49.000Z",
      productName: "Solopol® Lime (UL)",
      productURL: "https://www.debgroup.com/us/products/solopol-lime"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6480,
    hazzard: {
      id: 115,
      substanceWorkCondition: "Industry Medium - Metal Working & Fabrication",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 14,
    locationOrder: 1,
    skin_care_product: {
      id: 32,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:07.000Z",
      productName: "Solopol® GFX™ (USDA, UL, GS)",
      productURL: "https://www.debgroup.com/us/products/solopol-gfx"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6481,
    hazzard: {
      id: 115,
      substanceWorkCondition: "Industry Medium - Metal Working & Fabrication",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 18,
    locationOrder: 1,
    skin_care_product: {
      id: 16,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:31:28.000Z",
      productName: "Kresto® kwik wipes®",
      productURL: "https://www.debgroup.com/us/products/kresto-kwik-wipes"
    },
    rating: 4,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6482,
    hazzard: {
      id: 116,
      substanceWorkCondition: "Industry Medium - Outdoor Maintenance & Repair",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 13,
    locationOrder: 1,
    skin_care_product: {
      id: 33,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:49.000Z",
      productName: "Solopol® Lime (UL)",
      productURL: "https://www.debgroup.com/us/products/solopol-lime"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6483,
    hazzard: {
      id: 116,
      substanceWorkCondition: "Industry Medium - Outdoor Maintenance & Repair",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 14,
    locationOrder: 1,
    skin_care_product: {
      id: 32,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:07.000Z",
      productName: "Solopol® GFX™ (USDA, UL, GS)",
      productURL: "https://www.debgroup.com/us/products/solopol-gfx"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6484,
    hazzard: {
      id: 116,
      substanceWorkCondition: "Industry Medium - Outdoor Maintenance & Repair",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 18,
    locationOrder: 1,
    skin_care_product: {
      id: 16,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:31:28.000Z",
      productName: "Kresto® kwik wipes®",
      productURL: "https://www.debgroup.com/us/products/kresto-kwik-wipes"
    },
    rating: 4,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6485,
    hazzard: {
      id: 117,
      substanceWorkCondition: "Industry Medium - Pharmaceutical Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 23,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:36:39.000Z",
      productName: "Refresh™ Clear FOAM (UL, GS)",
      productURL: "https://www.debgroup.com/us/products/refresh-clear-foam"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: true,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6486,
    hazzard: {
      id: 117,
      substanceWorkCondition: "Industry Medium - Pharmaceutical Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 1,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6487,
    hazzard: {
      id: 117,
      substanceWorkCondition: "Industry Medium - Pharmaceutical Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 1,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6488,
    hazzard: {
      id: 118,
      substanceWorkCondition: "Industry Medium - Plastics Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 1,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6489,
    hazzard: {
      id: 118,
      substanceWorkCondition: "Industry Medium - Plastics Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 1,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6490,
    hazzard: {
      id: 118,
      substanceWorkCondition: "Industry Medium - Plastics Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 13,
    locationOrder: 1,
    skin_care_product: {
      id: 33,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:49.000Z",
      productName: "Solopol® Lime (UL)",
      productURL: "https://www.debgroup.com/us/products/solopol-lime"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6491,
    hazzard: {
      id: 118,
      substanceWorkCondition: "Industry Medium - Plastics Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 14,
    locationOrder: 1,
    skin_care_product: {
      id: 32,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:07.000Z",
      productName: "Solopol® GFX™ (USDA, UL, GS)",
      productURL: "https://www.debgroup.com/us/products/solopol-gfx"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6492,
    hazzard: {
      id: 120,
      substanceWorkCondition: "Insulating material",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 13,
    locationOrder: 1,
    skin_care_product: {
      id: 33,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:49.000Z",
      productName: "Solopol® Lime (UL)",
      productURL: "https://www.debgroup.com/us/products/solopol-lime"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6493,
    hazzard: {
      id: 120,
      substanceWorkCondition: "Insulating material",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 18,
    locationOrder: 1,
    skin_care_product: {
      id: 16,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:31:28.000Z",
      productName: "Kresto® kwik wipes®",
      productURL: "https://www.debgroup.com/us/products/kresto-kwik-wipes"
    },
    rating: 4,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6494,
    hazzard: {
      id: 121,
      substanceWorkCondition: "Kerosene",
      cas: null,
      glovesRecommended: true,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 1,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6495,
    hazzard: {
      id: 121,
      substanceWorkCondition: "Kerosene",
      cas: null,
      glovesRecommended: true,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 1,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6496,
    hazzard: {
      id: 121,
      substanceWorkCondition: "Kerosene",
      cas: null,
      glovesRecommended: true,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 23,
    locationOrder: 1,
    skin_care_product: {
      id: 13,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:28:48.000Z",
      productName: "Kresto® Cherry Wipes",
      productURL: "https://www.debgroup.com/us/products/kresto-cherry-wipes"
    },
    rating: 5,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6497,
    hazzard: {
      id: 122,
      substanceWorkCondition: "Ketone",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 1,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6498,
    hazzard: {
      id: 122,
      substanceWorkCondition: "Ketone",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 1,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6499,
    hazzard: {
      id: 122,
      substanceWorkCondition: "Ketone",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 18,
    locationOrder: 1,
    skin_care_product: {
      id: 16,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:31:28.000Z",
      productName: "Kresto® kwik wipes®",
      productURL: "https://www.debgroup.com/us/products/kresto-kwik-wipes"
    },
    rating: 4,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6500,
    hazzard: {
      id: 127,
      substanceWorkCondition: "Latex paint",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 1,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6501,
    hazzard: {
      id: 127,
      substanceWorkCondition: "Latex paint",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 1,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6502,
    hazzard: {
      id: 127,
      substanceWorkCondition: "Latex paint",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 13,
    locationOrder: 1,
    skin_care_product: {
      id: 33,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:49.000Z",
      productName: "Solopol® Lime (UL)",
      productURL: "https://www.debgroup.com/us/products/solopol-lime"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:16.000Z",
    updated_at: "2020-03-02T22:50:16.000Z"
  },
  {
    id: 6503,
    hazzard: {
      id: 127,
      substanceWorkCondition: "Latex paint",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 18,
    locationOrder: 1,
    skin_care_product: {
      id: 16,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:31:28.000Z",
      productName: "Kresto® kwik wipes®",
      productURL: "https://www.debgroup.com/us/products/kresto-kwik-wipes"
    },
    rating: 4,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6504,
    hazzard: {
      id: 128,
      substanceWorkCondition: "Lime",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 1,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6505,
    hazzard: {
      id: 128,
      substanceWorkCondition: "Lime",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 1,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6506,
    hazzard: {
      id: 128,
      substanceWorkCondition: "Lime",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 13,
    locationOrder: 1,
    skin_care_product: {
      id: 33,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:49.000Z",
      productName: "Solopol® Lime (UL)",
      productURL: "https://www.debgroup.com/us/products/solopol-lime"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6507,
    hazzard: {
      id: 128,
      substanceWorkCondition: "Lime",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 18,
    locationOrder: 1,
    skin_care_product: {
      id: 16,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:31:28.000Z",
      productName: "Kresto® kwik wipes®",
      productURL: "https://www.debgroup.com/us/products/kresto-kwik-wipes"
    },
    rating: 4,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6508,
    hazzard: {
      id: 129,
      substanceWorkCondition: "Lubricants, water-based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 1,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6509,
    hazzard: {
      id: 129,
      substanceWorkCondition: "Lubricants, water-based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 1,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6510,
    hazzard: {
      id: 129,
      substanceWorkCondition: "Lubricants, water-based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 13,
    locationOrder: 1,
    skin_care_product: {
      id: 33,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:49.000Z",
      productName: "Solopol® Lime (UL)",
      productURL: "https://www.debgroup.com/us/products/solopol-lime"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6511,
    hazzard: {
      id: 129,
      substanceWorkCondition: "Lubricants, water-based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 14,
    locationOrder: 1,
    skin_care_product: {
      id: 32,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:07.000Z",
      productName: "Solopol® GFX™ (USDA, UL, GS)",
      productURL: "https://www.debgroup.com/us/products/solopol-gfx"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6512,
    hazzard: {
      id: 129,
      substanceWorkCondition: "Lubricants, water-based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 18,
    locationOrder: 1,
    skin_care_product: {
      id: 16,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:31:28.000Z",
      productName: "Kresto® kwik wipes®",
      productURL: "https://www.debgroup.com/us/products/kresto-kwik-wipes"
    },
    rating: 4,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6513,
    hazzard: {
      id: 130,
      substanceWorkCondition: "Metal dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 13,
    locationOrder: 1,
    skin_care_product: {
      id: 33,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:49.000Z",
      productName: "Solopol® Lime (UL)",
      productURL: "https://www.debgroup.com/us/products/solopol-lime"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6514,
    hazzard: {
      id: 130,
      substanceWorkCondition: "Metal dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 14,
    locationOrder: 1,
    skin_care_product: {
      id: 32,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:07.000Z",
      productName: "Solopol® GFX™ (USDA, UL, GS)",
      productURL: "https://www.debgroup.com/us/products/solopol-gfx"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6515,
    hazzard: {
      id: 130,
      substanceWorkCondition: "Metal dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 18,
    locationOrder: 1,
    skin_care_product: {
      id: 16,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:31:28.000Z",
      productName: "Kresto® kwik wipes®",
      productURL: "https://www.debgroup.com/us/products/kresto-kwik-wipes"
    },
    rating: 4,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6516,
    hazzard: {
      id: 131,
      substanceWorkCondition: "Methanol / Methyl Alcohol",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 1,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6517,
    hazzard: {
      id: 131,
      substanceWorkCondition: "Methanol / Methyl Alcohol",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 1,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6518,
    hazzard: {
      id: 132,
      substanceWorkCondition: "Mineral oil / paraffin oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 13,
    locationOrder: 1,
    skin_care_product: {
      id: 33,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:49.000Z",
      productName: "Solopol® Lime (UL)",
      productURL: "https://www.debgroup.com/us/products/solopol-lime"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6519,
    hazzard: {
      id: 132,
      substanceWorkCondition: "Mineral oil / paraffin oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 14,
    locationOrder: 1,
    skin_care_product: {
      id: 32,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:07.000Z",
      productName: "Solopol® GFX™ (USDA, UL, GS)",
      productURL: "https://www.debgroup.com/us/products/solopol-gfx"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6520,
    hazzard: {
      id: 132,
      substanceWorkCondition: "Mineral oil / paraffin oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 17,
    locationOrder: 1,
    skin_care_product: {
      id: 31,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:42:23.000Z",
      productName: "Solopol® GFX™",
      productURL: "https://www.debgroup.com/us/products/solopol-gfx"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: null,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6521,
    hazzard: {
      id: 135,
      substanceWorkCondition: "Naphthalene",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 13,
    locationOrder: 1,
    skin_care_product: {
      id: 33,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:49.000Z",
      productName: "Solopol® Lime (UL)",
      productURL: "https://www.debgroup.com/us/products/solopol-lime"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6522,
    hazzard: {
      id: 136,
      substanceWorkCondition: "Oil coke (carbon)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 13,
    locationOrder: 1,
    skin_care_product: {
      id: 33,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:49.000Z",
      productName: "Solopol® Lime (UL)",
      productURL: "https://www.debgroup.com/us/products/solopol-lime"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6523,
    hazzard: {
      id: 136,
      substanceWorkCondition: "Oil coke (carbon)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 18,
    locationOrder: 1,
    skin_care_product: {
      id: 16,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:31:28.000Z",
      productName: "Kresto® kwik wipes®",
      productURL: "https://www.debgroup.com/us/products/kresto-kwik-wipes"
    },
    rating: 4,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6524,
    hazzard: {
      id: 137,
      substanceWorkCondition: "Oil-emulsions (water- mixed)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 13,
    locationOrder: 1,
    skin_care_product: {
      id: 33,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:49.000Z",
      productName: "Solopol® Lime (UL)",
      productURL: "https://www.debgroup.com/us/products/solopol-lime"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6525,
    hazzard: {
      id: 137,
      substanceWorkCondition: "Oil-emulsions (water- mixed)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 14,
    locationOrder: 1,
    skin_care_product: {
      id: 32,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:07.000Z",
      productName: "Solopol® GFX™ (USDA, UL, GS)",
      productURL: "https://www.debgroup.com/us/products/solopol-gfx"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6526,
    hazzard: {
      id: 137,
      substanceWorkCondition: "Oil-emulsions (water- mixed)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 18,
    locationOrder: 1,
    skin_care_product: {
      id: 16,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:31:28.000Z",
      productName: "Kresto® kwik wipes®",
      productURL: "https://www.debgroup.com/us/products/kresto-kwik-wipes"
    },
    rating: 4,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6527,
    hazzard: {
      id: 138,
      substanceWorkCondition: "Oils / Oil based lubricants",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 13,
    locationOrder: 1,
    skin_care_product: {
      id: 33,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:49.000Z",
      productName: "Solopol® Lime (UL)",
      productURL: "https://www.debgroup.com/us/products/solopol-lime"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6528,
    hazzard: {
      id: 138,
      substanceWorkCondition: "Oils / Oil based lubricants",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 18,
    locationOrder: 1,
    skin_care_product: {
      id: 16,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:31:28.000Z",
      productName: "Kresto® kwik wipes®",
      productURL: "https://www.debgroup.com/us/products/kresto-kwik-wipes"
    },
    rating: 4,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6529,
    hazzard: {
      id: 140,
      substanceWorkCondition: "Paint Thinner ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 13,
    locationOrder: 1,
    skin_care_product: {
      id: 33,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:49.000Z",
      productName: "Solopol® Lime (UL)",
      productURL: "https://www.debgroup.com/us/products/solopol-lime"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6530,
    hazzard: {
      id: 140,
      substanceWorkCondition: "Paint Thinner ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 18,
    locationOrder: 1,
    skin_care_product: {
      id: 16,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:31:28.000Z",
      productName: "Kresto® kwik wipes®",
      productURL: "https://www.debgroup.com/us/products/kresto-kwik-wipes"
    },
    rating: 4,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6531,
    hazzard: {
      id: 139,
      substanceWorkCondition: "Paint",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 23,
    locationOrder: 1,
    skin_care_product: {
      id: 13,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:28:48.000Z",
      productName: "Kresto® Cherry Wipes",
      productURL: "https://www.debgroup.com/us/products/kresto-cherry-wipes"
    },
    rating: 5,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6532,
    hazzard: {
      id: 141,
      substanceWorkCondition: "Pit water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 1,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6533,
    hazzard: {
      id: 141,
      substanceWorkCondition: "Pit water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 1,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6534,
    hazzard: {
      id: 141,
      substanceWorkCondition: "Pit water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 7,
    locationOrder: 1,
    skin_care_product: {
      id: 21,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:35:12.000Z",
      productName: "Refresh™ AntiBac FOAM",
      productURL: "https://www.debgroup.com/us/products/refresh-antibac-foam"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6535,
    hazzard: {
      id: 143,
      substanceWorkCondition: "Polishes (water- soluble)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 1,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6536,
    hazzard: {
      id: 143,
      substanceWorkCondition: "Polishes (water- soluble)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 1,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6537,
    hazzard: {
      id: 143,
      substanceWorkCondition: "Polishes (water- soluble)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Cleanse",
    productOrder: 18,
    locationOrder: 1,
    skin_care_product: {
      id: 16,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:31:28.000Z",
      productName: "Kresto® kwik wipes®",
      productURL: "https://www.debgroup.com/us/products/kresto-kwik-wipes"
    },
    rating: 4,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6538,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Cleanse",
    productOrder: 13,
    locationOrder: 1,
    skin_care_product: {
      id: 33,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:49.000Z",
      productName: "Solopol® Lime (UL)",
      productURL: "https://www.debgroup.com/us/products/solopol-lime"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6539,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Cleanse",
    productOrder: 18,
    locationOrder: 1,
    skin_care_product: {
      id: 16,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:31:28.000Z",
      productName: "Kresto® kwik wipes®",
      productURL: "https://www.debgroup.com/us/products/kresto-kwik-wipes"
    },
    rating: 4,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6540,
    hazzard: {
      id: 148,
      substanceWorkCondition:
        "Propanols (propyl alcohols): n-Propanol, Isopropanol / 2-Propanol ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 1,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6541,
    hazzard: {
      id: 148,
      substanceWorkCondition:
        "Propanols (propyl alcohols): n-Propanol, Isopropanol / 2-Propanol ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 1,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6542,
    hazzard: {
      id: 148,
      substanceWorkCondition:
        "Propanols (propyl alcohols): n-Propanol, Isopropanol / 2-Propanol ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 18,
    locationOrder: 1,
    skin_care_product: {
      id: 16,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:31:28.000Z",
      productName: "Kresto® kwik wipes®",
      productURL: "https://www.debgroup.com/us/products/kresto-kwik-wipes"
    },
    rating: 4,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6543,
    hazzard: {
      id: 149,
      substanceWorkCondition: "release agent, parting agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 1,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6544,
    hazzard: {
      id: 149,
      substanceWorkCondition: "release agent, parting agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 1,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6545,
    hazzard: {
      id: 149,
      substanceWorkCondition: "release agent, parting agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 13,
    locationOrder: 1,
    skin_care_product: {
      id: 33,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:49.000Z",
      productName: "Solopol® Lime (UL)",
      productURL: "https://www.debgroup.com/us/products/solopol-lime"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6546,
    hazzard: {
      id: 149,
      substanceWorkCondition: "release agent, parting agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 14,
    locationOrder: 1,
    skin_care_product: {
      id: 32,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:07.000Z",
      productName: "Solopol® GFX™ (USDA, UL, GS)",
      productURL: "https://www.debgroup.com/us/products/solopol-gfx"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6547,
    hazzard: {
      id: 149,
      substanceWorkCondition: "release agent, parting agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 18,
    locationOrder: 1,
    skin_care_product: {
      id: 16,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:31:28.000Z",
      productName: "Kresto® kwik wipes®",
      productURL: "https://www.debgroup.com/us/products/kresto-kwik-wipes"
    },
    rating: 4,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6548,
    hazzard: {
      id: 151,
      substanceWorkCondition: "Rock wool",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 1,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6549,
    hazzard: {
      id: 151,
      substanceWorkCondition: "Rock wool",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 1,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6550,
    hazzard: {
      id: 151,
      substanceWorkCondition: "Rock wool",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 18,
    locationOrder: 1,
    skin_care_product: {
      id: 16,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:31:28.000Z",
      productName: "Kresto® kwik wipes®",
      productURL: "https://www.debgroup.com/us/products/kresto-kwik-wipes"
    },
    rating: 4,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6551,
    hazzard: {
      id: 153,
      substanceWorkCondition: "Salts / Salt solution",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 1,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6552,
    hazzard: {
      id: 153,
      substanceWorkCondition: "Salts / Salt solution",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 1,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6553,
    hazzard: {
      id: 153,
      substanceWorkCondition: "Salts / Salt solution",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 18,
    locationOrder: 1,
    skin_care_product: {
      id: 16,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:31:28.000Z",
      productName: "Kresto® kwik wipes®",
      productURL: "https://www.debgroup.com/us/products/kresto-kwik-wipes"
    },
    rating: 4,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6554,
    hazzard: {
      id: 154,
      substanceWorkCondition: "Sealing compound",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 1,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6555,
    hazzard: {
      id: 154,
      substanceWorkCondition: "Sealing compound",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 1,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6556,
    hazzard: {
      id: 155,
      substanceWorkCondition: "Sintering",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 13,
    locationOrder: 1,
    skin_care_product: {
      id: 33,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:49.000Z",
      productName: "Solopol® Lime (UL)",
      productURL: "https://www.debgroup.com/us/products/solopol-lime"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6557,
    hazzard: {
      id: 155,
      substanceWorkCondition: "Sintering",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 14,
    locationOrder: 1,
    skin_care_product: {
      id: 32,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:07.000Z",
      productName: "Solopol® GFX™ (USDA, UL, GS)",
      productURL: "https://www.debgroup.com/us/products/solopol-gfx"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6558,
    hazzard: {
      id: 155,
      substanceWorkCondition: "Sintering",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 18,
    locationOrder: 1,
    skin_care_product: {
      id: 16,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:31:28.000Z",
      productName: "Kresto® kwik wipes®",
      productURL: "https://www.debgroup.com/us/products/kresto-kwik-wipes"
    },
    rating: 4,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6559,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Cleanse",
    productOrder: 13,
    locationOrder: 1,
    skin_care_product: {
      id: 33,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:49.000Z",
      productName: "Solopol® Lime (UL)",
      productURL: "https://www.debgroup.com/us/products/solopol-lime"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6560,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Cleanse",
    productOrder: 14,
    locationOrder: 1,
    skin_care_product: {
      id: 32,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:07.000Z",
      productName: "Solopol® GFX™ (USDA, UL, GS)",
      productURL: "https://www.debgroup.com/us/products/solopol-gfx"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6561,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Cleanse",
    productOrder: 18,
    locationOrder: 1,
    skin_care_product: {
      id: 16,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:31:28.000Z",
      productName: "Kresto® kwik wipes®",
      productURL: "https://www.debgroup.com/us/products/kresto-kwik-wipes"
    },
    rating: 4,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6562,
    hazzard: {
      id: 157,
      substanceWorkCondition: "Solvents, - organic",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 13,
    locationOrder: 1,
    skin_care_product: {
      id: 33,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:49.000Z",
      productName: "Solopol® Lime (UL)",
      productURL: "https://www.debgroup.com/us/products/solopol-lime"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6563,
    hazzard: {
      id: 157,
      substanceWorkCondition: "Solvents, - organic",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 14,
    locationOrder: 1,
    skin_care_product: {
      id: 32,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:07.000Z",
      productName: "Solopol® GFX™ (USDA, UL, GS)",
      productURL: "https://www.debgroup.com/us/products/solopol-gfx"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6564,
    hazzard: {
      id: 157,
      substanceWorkCondition: "Solvents, - organic",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 18,
    locationOrder: 1,
    skin_care_product: {
      id: 16,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:31:28.000Z",
      productName: "Kresto® kwik wipes®",
      productURL: "https://www.debgroup.com/us/products/kresto-kwik-wipes"
    },
    rating: 4,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6565,
    hazzard: {
      id: 158,
      substanceWorkCondition: "Soot",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 14,
    locationOrder: 1,
    skin_care_product: {
      id: 32,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:07.000Z",
      productName: "Solopol® GFX™ (USDA, UL, GS)",
      productURL: "https://www.debgroup.com/us/products/solopol-gfx"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6566,
    hazzard: {
      id: 159,
      substanceWorkCondition: "Styrene",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 1,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6567,
    hazzard: {
      id: 159,
      substanceWorkCondition: "Styrene",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 1,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6568,
    hazzard: {
      id: 159,
      substanceWorkCondition: "Styrene",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 13,
    locationOrder: 1,
    skin_care_product: {
      id: 33,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:49.000Z",
      productName: "Solopol® Lime (UL)",
      productURL: "https://www.debgroup.com/us/products/solopol-lime"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6569,
    hazzard: {
      id: 159,
      substanceWorkCondition: "Styrene",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 14,
    locationOrder: 1,
    skin_care_product: {
      id: 32,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:07.000Z",
      productName: "Solopol® GFX™ (USDA, UL, GS)",
      productURL: "https://www.debgroup.com/us/products/solopol-gfx"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6570,
    hazzard: {
      id: 159,
      substanceWorkCondition: "Styrene",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 18,
    locationOrder: 1,
    skin_care_product: {
      id: 16,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:31:28.000Z",
      productName: "Kresto® kwik wipes®",
      productURL: "https://www.debgroup.com/us/products/kresto-kwik-wipes"
    },
    rating: 4,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6571,
    hazzard: {
      id: 160,
      substanceWorkCondition: "Surface Cleanser (surfactant based), Detergents",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 1,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6572,
    hazzard: {
      id: 160,
      substanceWorkCondition: "Surface Cleanser (surfactant based), Detergents",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 1,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6573,
    hazzard: {
      id: 161,
      substanceWorkCondition: "Synthetic melting glaze",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 1,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6574,
    hazzard: {
      id: 161,
      substanceWorkCondition: "Synthetic melting glaze",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 1,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6575,
    hazzard: {
      id: 161,
      substanceWorkCondition: "Synthetic melting glaze",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 18,
    locationOrder: 1,
    skin_care_product: {
      id: 16,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:31:28.000Z",
      productName: "Kresto® kwik wipes®",
      productURL: "https://www.debgroup.com/us/products/kresto-kwik-wipes"
    },
    rating: 4,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6576,
    hazzard: {
      id: 163,
      substanceWorkCondition: "Tanning agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 1,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6577,
    hazzard: {
      id: 163,
      substanceWorkCondition: "Tanning agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 1,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6578,
    hazzard: {
      id: 163,
      substanceWorkCondition: "Tanning agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 18,
    locationOrder: 1,
    skin_care_product: {
      id: 16,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:31:28.000Z",
      productName: "Kresto® kwik wipes®",
      productURL: "https://www.debgroup.com/us/products/kresto-kwik-wipes"
    },
    rating: 4,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6579,
    hazzard: {
      id: 166,
      substanceWorkCondition: "Thinner",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 1,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6580,
    hazzard: {
      id: 166,
      substanceWorkCondition: "Thinner",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 1,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6581,
    hazzard: {
      id: 166,
      substanceWorkCondition: "Thinner",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 18,
    locationOrder: 1,
    skin_care_product: {
      id: 16,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:31:28.000Z",
      productName: "Kresto® kwik wipes®",
      productURL: "https://www.debgroup.com/us/products/kresto-kwik-wipes"
    },
    rating: 4,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6582,
    hazzard: {
      id: 167,
      substanceWorkCondition: "Toluene",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 1,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6583,
    hazzard: {
      id: 167,
      substanceWorkCondition: "Toluene",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 1,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6584,
    hazzard: {
      id: 167,
      substanceWorkCondition: "Toluene",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 18,
    locationOrder: 1,
    skin_care_product: {
      id: 16,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:31:28.000Z",
      productName: "Kresto® kwik wipes®",
      productURL: "https://www.debgroup.com/us/products/kresto-kwik-wipes"
    },
    rating: 4,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6585,
    hazzard: {
      id: 168,
      substanceWorkCondition: "Turpentine oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 1,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6586,
    hazzard: {
      id: 168,
      substanceWorkCondition: "Turpentine oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 1,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6587,
    hazzard: {
      id: 168,
      substanceWorkCondition: "Turpentine oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 18,
    locationOrder: 1,
    skin_care_product: {
      id: 16,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:31:28.000Z",
      productName: "Kresto® kwik wipes®",
      productURL: "https://www.debgroup.com/us/products/kresto-kwik-wipes"
    },
    rating: 4,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6588,
    hazzard: {
      id: 169,
      substanceWorkCondition: "UV-rays (A/B) / Sunlight",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 1,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6589,
    hazzard: {
      id: 169,
      substanceWorkCondition: "UV-rays (A/B) / Sunlight",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 1,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6590,
    hazzard: {
      id: 169,
      substanceWorkCondition: "UV-rays (A/B) / Sunlight",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 18,
    locationOrder: 1,
    skin_care_product: {
      id: 16,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:31:28.000Z",
      productName: "Kresto® kwik wipes®",
      productURL: "https://www.debgroup.com/us/products/kresto-kwik-wipes"
    },
    rating: 4,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6591,
    hazzard: {
      id: 170,
      substanceWorkCondition: "Washing bath, washing liquid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 1,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6592,
    hazzard: {
      id: 170,
      substanceWorkCondition: "Washing bath, washing liquid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 1,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6593,
    hazzard: {
      id: 171,
      substanceWorkCondition: "Waste oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 1,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6594,
    hazzard: {
      id: 171,
      substanceWorkCondition: "Waste oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 1,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6595,
    hazzard: {
      id: 171,
      substanceWorkCondition: "Waste oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 13,
    locationOrder: 1,
    skin_care_product: {
      id: 33,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:49.000Z",
      productName: "Solopol® Lime (UL)",
      productURL: "https://www.debgroup.com/us/products/solopol-lime"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6596,
    hazzard: {
      id: 171,
      substanceWorkCondition: "Waste oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 14,
    locationOrder: 1,
    skin_care_product: {
      id: 32,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:07.000Z",
      productName: "Solopol® GFX™ (USDA, UL, GS)",
      productURL: "https://www.debgroup.com/us/products/solopol-gfx"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6597,
    hazzard: {
      id: 171,
      substanceWorkCondition: "Waste oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 18,
    locationOrder: 1,
    skin_care_product: {
      id: 16,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:31:28.000Z",
      productName: "Kresto® kwik wipes®",
      productURL: "https://www.debgroup.com/us/products/kresto-kwik-wipes"
    },
    rating: 4,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6598,
    hazzard: {
      id: 172,
      substanceWorkCondition: "Water / wet work",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 1,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6599,
    hazzard: {
      id: 172,
      substanceWorkCondition: "Water / wet work",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 1,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6600,
    hazzard: {
      id: 173,
      substanceWorkCondition:
        "Wearing protective clothing / Softening of the skin (Maceration)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 1,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6601,
    hazzard: {
      id: 173,
      substanceWorkCondition:
        "Wearing protective clothing / Softening of the skin (Maceration)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 1,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6602,
    hazzard: {
      id: 174,
      substanceWorkCondition: "wire drawing oil / wire drawing grease",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 14,
    locationOrder: 1,
    skin_care_product: {
      id: 32,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:07.000Z",
      productName: "Solopol® GFX™ (USDA, UL, GS)",
      productURL: "https://www.debgroup.com/us/products/solopol-gfx"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6603,
    hazzard: {
      id: 174,
      substanceWorkCondition: "wire drawing oil / wire drawing grease",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 18,
    locationOrder: 1,
    skin_care_product: {
      id: 16,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:31:28.000Z",
      productName: "Kresto® kwik wipes®",
      productURL: "https://www.debgroup.com/us/products/kresto-kwik-wipes"
    },
    rating: 4,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6604,
    hazzard: {
      id: 175,
      substanceWorkCondition: "Xylene",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 13,
    locationOrder: 1,
    skin_care_product: {
      id: 33,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:49.000Z",
      productName: "Solopol® Lime (UL)",
      productURL: "https://www.debgroup.com/us/products/solopol-lime"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6605,
    hazzard: {
      id: 175,
      substanceWorkCondition: "Xylene",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 14,
    locationOrder: 1,
    skin_care_product: {
      id: 32,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:43:07.000Z",
      productName: "Solopol® GFX™ (USDA, UL, GS)",
      productURL: "https://www.debgroup.com/us/products/solopol-gfx"
    },
    rating: 3,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: null,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6606,
    hazzard: {
      id: 175,
      substanceWorkCondition: "Xylene",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Cleanse",
    productOrder: 18,
    locationOrder: 1,
    skin_care_product: {
      id: 16,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:31:28.000Z",
      productName: "Kresto® kwik wipes®",
      productURL: "https://www.debgroup.com/us/products/kresto-kwik-wipes"
    },
    rating: 4,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: null,
    body: null,
    hair: false,
    glove: null,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6607,
    hazzard: {
      id: 999,
      substanceWorkCondition: "All Products",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:44:03.000Z",
      updated_at: "2020-03-02T17:44:03.000Z"
    },
    step: "Cleanse",
    productOrder: 1,
    locationOrder: 4,
    skin_care_product: {
      id: 23,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:36:39.000Z",
      productName: "Refresh™ Clear FOAM (UL, GS)",
      productURL: "https://www.debgroup.com/us/products/refresh-clear-foam"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: true,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6608,
    hazzard: {
      id: 999,
      substanceWorkCondition: "All Products",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:44:03.000Z",
      updated_at: "2020-03-02T17:44:03.000Z"
    },
    step: "Cleanse",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 22,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:36:04.000Z",
      productName: "Refresh™ Azure FOAM (UL, GS)",
      productURL: "https://www.debgroup.com/us/products/refresh-azure-foam"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6609,
    hazzard: {
      id: 999,
      substanceWorkCondition: "All Products",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:44:03.000Z",
      updated_at: "2020-03-02T17:44:03.000Z"
    },
    step: "Cleanse",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 27,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:39:30.000Z",
      productName: "Refresh™ Rose FOAM (UL, GS)",
      productURL: "https://www.debgroup.com/us/products/refresh-rose-foam"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: true,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: true,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6610,
    hazzard: {
      id: 999,
      substanceWorkCondition: "All Products",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:44:03.000Z",
      updated_at: "2020-03-02T17:44:03.000Z"
    },
    step: "Cleanse",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 24,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:37:20.000Z",
      productName: "Refresh™ Energie FOAM",
      productURL: "https://www.debgroup.com/us/products/refresh-energie-foam"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: false,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6611,
    hazzard: {
      id: 999,
      substanceWorkCondition: "All Products",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:44:03.000Z",
      updated_at: "2020-03-02T17:44:03.000Z"
    },
    step: "Cleanse",
    productOrder: 5,
    locationOrder: 4,
    skin_care_product: {
      id: 25,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:38:15.000Z",
      productName: "Refresh™ Hair & Body",
      productURL: "https://www.debgroup.com/us/products/refresh-hair-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: false,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6612,
    hazzard: {
      id: 999,
      substanceWorkCondition: "All Products",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:44:03.000Z",
      updated_at: "2020-03-02T17:44:03.000Z"
    },
    step: "Cleanse",
    productOrder: 6,
    locationOrder: 4,
    skin_care_product: {
      id: 26,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:38:51.000Z",
      productName: "Refresh™ Luxury 3-in-1 Hair & Body",
      productURL:
        "https://www.debgroup.com/us/products/refresh-luxury-3-1-hair-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: null,
    ecoLogo: false,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6613,
    hazzard: {
      id: 999,
      substanceWorkCondition: "All Products",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:44:03.000Z",
      updated_at: "2020-03-02T17:44:03.000Z"
    },
    step: "Cleanse",
    productOrder: 7,
    locationOrder: 1,
    skin_care_product: {
      id: 21,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:35:12.000Z",
      productName: "Refresh™ AntiBac FOAM",
      productURL: "https://www.debgroup.com/us/products/refresh-antibac-foam"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6614,
    hazzard: {
      id: 999,
      substanceWorkCondition: "All Products",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:44:03.000Z",
      updated_at: "2020-03-02T17:44:03.000Z"
    },
    step: "Cleanse",
    productOrder: 9,
    locationOrder: 1,
    skin_care_product: {
      id: 1,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:17:13.000Z",
      productName: "AgroBac Pure FOAM",
      productURL: "https://www.debgroup.com/us/products/agrobac-pure-foam"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: null,
    ecoLogo: false,
    ecarf: false,
    nsf: true,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: null,
    body: null,
    hair: false,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6615,
    hazzard: {
      id: 999,
      substanceWorkCondition: "All Products",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:44:03.000Z",
      updated_at: "2020-03-02T17:44:03.000Z"
    },
    step: "Cleanse",
    productOrder: 10,
    locationOrder: 5,
    skin_care_product: {
      id: 6,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:22:21.000Z",
      productName: "Estesol® Hair & Body (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-hair-body"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: true,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: true,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6616,
    hazzard: {
      id: 999,
      substanceWorkCondition: "All Products",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:44:03.000Z",
      updated_at: "2020-03-02T17:44:03.000Z"
    },
    step: "Cleanse",
    productOrder: 11,
    locationOrder: 3,
    skin_care_product: {
      id: 7,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:23:36.000Z",
      productName: "Estesol® Lotion (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6617,
    hazzard: {
      id: 999,
      substanceWorkCondition: "All Products",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:44:03.000Z",
      updated_at: "2020-03-02T17:44:03.000Z"
    },
    step: "Cleanse",
    productOrder: 12,
    locationOrder: 3,
    skin_care_product: {
      id: 8,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:06.000Z",
      productName: "Estesol® Lotion PURE (UL)",
      productURL: "https://www.debgroup.com/us/products/estesol-lotion-pure"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: true,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: false,
    alcoholFree: true,
    face: true,
    body: true,
    hair: null,
    glove: true,
    individual: false,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6618,
    hazzard: {
      id: 39,
      substanceWorkCondition:
        "Commercial - Airports & Transport Terminals - Public Area",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Sanitize",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 2,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:40.000Z",
      productName: "InstantFOAM™ (USDA, UL)",
      productURL:
        "https://www.debgroup.com/us/products/deb-instantfoam-alcohol-based-hand-sanitizer"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: true,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: true,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: false,
    body: false,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6619,
    hazzard: {
      id: 39,
      substanceWorkCondition:
        "Commercial - Airports & Transport Terminals - Public Area",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Sanitize",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 4,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:19:49.000Z",
      productName: "nstantFOAM™ Non-Alcohol",
      productURL:
        "https://www.debgroup.com/us/products/deb-instantfoam-non-alcohol-hand-sanitizer"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: false,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: false,
    hair: false,
    glove: false,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6620,
    hazzard: {
      id: 39,
      substanceWorkCondition:
        "Commercial - Airports & Transport Terminals - Public Area",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Sanitize",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 5,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:20:37.000Z",
      productName: "InstantFOAM™ Non-Alcohol PURE",
      productURL:
        "https://www.debgroup.com/us/products/deb-instantfoam-non-alcohol-pure-hand-sanitizer"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: null,
    ecoLogo: false,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: false,
    hair: false,
    glove: false,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6621,
    hazzard: {
      id: 40,
      substanceWorkCondition: "Commercial - Cleaning & Maintenance",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Sanitize",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 2,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:40.000Z",
      productName: "InstantFOAM™ (USDA, UL)",
      productURL:
        "https://www.debgroup.com/us/products/deb-instantfoam-alcohol-based-hand-sanitizer"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: true,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: true,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: false,
    body: false,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6622,
    hazzard: {
      id: 40,
      substanceWorkCondition: "Commercial - Cleaning & Maintenance",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Sanitize",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 4,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:19:49.000Z",
      productName: "nstantFOAM™ Non-Alcohol",
      productURL:
        "https://www.debgroup.com/us/products/deb-instantfoam-non-alcohol-hand-sanitizer"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: false,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: false,
    hair: false,
    glove: false,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6623,
    hazzard: {
      id: 40,
      substanceWorkCondition: "Commercial - Cleaning & Maintenance",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Sanitize",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 5,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:20:37.000Z",
      productName: "InstantFOAM™ Non-Alcohol PURE",
      productURL:
        "https://www.debgroup.com/us/products/deb-instantfoam-non-alcohol-pure-hand-sanitizer"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: null,
    ecoLogo: false,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: false,
    hair: false,
    glove: false,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6624,
    hazzard: {
      id: 41,
      substanceWorkCondition: "Commercial - Commerce & Office",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Sanitize",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 2,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:40.000Z",
      productName: "InstantFOAM™ (USDA, UL)",
      productURL:
        "https://www.debgroup.com/us/products/deb-instantfoam-alcohol-based-hand-sanitizer"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: true,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: true,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: false,
    body: false,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6625,
    hazzard: {
      id: 41,
      substanceWorkCondition: "Commercial - Commerce & Office",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Sanitize",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 4,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:19:49.000Z",
      productName: "nstantFOAM™ Non-Alcohol",
      productURL:
        "https://www.debgroup.com/us/products/deb-instantfoam-non-alcohol-hand-sanitizer"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: false,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: false,
    hair: false,
    glove: false,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6626,
    hazzard: {
      id: 41,
      substanceWorkCondition: "Commercial - Commerce & Office",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Sanitize",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 5,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:20:37.000Z",
      productName: "InstantFOAM™ Non-Alcohol PURE",
      productURL:
        "https://www.debgroup.com/us/products/deb-instantfoam-non-alcohol-pure-hand-sanitizer"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: null,
    ecoLogo: false,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: false,
    hair: false,
    glove: false,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6627,
    hazzard: {
      id: 42,
      substanceWorkCondition: "Commercial - Education",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Sanitize",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 2,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:40.000Z",
      productName: "InstantFOAM™ (USDA, UL)",
      productURL:
        "https://www.debgroup.com/us/products/deb-instantfoam-alcohol-based-hand-sanitizer"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: true,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: true,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: false,
    body: false,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6628,
    hazzard: {
      id: 42,
      substanceWorkCondition: "Commercial - Education",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Sanitize",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 4,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:19:49.000Z",
      productName: "nstantFOAM™ Non-Alcohol",
      productURL:
        "https://www.debgroup.com/us/products/deb-instantfoam-non-alcohol-hand-sanitizer"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: false,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: false,
    hair: false,
    glove: false,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6629,
    hazzard: {
      id: 42,
      substanceWorkCondition: "Commercial - Education",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Sanitize",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 5,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:20:37.000Z",
      productName: "InstantFOAM™ Non-Alcohol PURE",
      productURL:
        "https://www.debgroup.com/us/products/deb-instantfoam-non-alcohol-pure-hand-sanitizer"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: null,
    ecoLogo: false,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: false,
    hair: false,
    glove: false,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6630,
    hazzard: {
      id: 43,
      substanceWorkCondition: "Commercial - Hotel & Leisure",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Sanitize",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 2,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:40.000Z",
      productName: "InstantFOAM™ (USDA, UL)",
      productURL:
        "https://www.debgroup.com/us/products/deb-instantfoam-alcohol-based-hand-sanitizer"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: true,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: true,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: false,
    body: false,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6631,
    hazzard: {
      id: 43,
      substanceWorkCondition: "Commercial - Hotel & Leisure",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Sanitize",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 4,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:19:49.000Z",
      productName: "nstantFOAM™ Non-Alcohol",
      productURL:
        "https://www.debgroup.com/us/products/deb-instantfoam-non-alcohol-hand-sanitizer"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: false,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: false,
    hair: false,
    glove: false,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6632,
    hazzard: {
      id: 43,
      substanceWorkCondition: "Commercial - Hotel & Leisure",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Sanitize",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 5,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:20:37.000Z",
      productName: "InstantFOAM™ Non-Alcohol PURE",
      productURL:
        "https://www.debgroup.com/us/products/deb-instantfoam-non-alcohol-pure-hand-sanitizer"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: null,
    ecoLogo: false,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: false,
    hair: false,
    glove: false,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6633,
    hazzard: {
      id: 44,
      substanceWorkCondition: "Commercial - Prisons & Security Services",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Sanitize",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 2,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:40.000Z",
      productName: "InstantFOAM™ (USDA, UL)",
      productURL:
        "https://www.debgroup.com/us/products/deb-instantfoam-alcohol-based-hand-sanitizer"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: true,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: true,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: false,
    body: false,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6634,
    hazzard: {
      id: 44,
      substanceWorkCondition: "Commercial - Prisons & Security Services",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Sanitize",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 4,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:19:49.000Z",
      productName: "nstantFOAM™ Non-Alcohol",
      productURL:
        "https://www.debgroup.com/us/products/deb-instantfoam-non-alcohol-hand-sanitizer"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: false,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: false,
    hair: false,
    glove: false,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6635,
    hazzard: {
      id: 44,
      substanceWorkCondition: "Commercial - Prisons & Security Services",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Sanitize",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 5,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:20:37.000Z",
      productName: "InstantFOAM™ Non-Alcohol PURE",
      productURL:
        "https://www.debgroup.com/us/products/deb-instantfoam-non-alcohol-pure-hand-sanitizer"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: null,
    ecoLogo: false,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: false,
    hair: false,
    glove: false,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6636,
    hazzard: {
      id: 45,
      substanceWorkCondition:
        "Commercial - Public Entertainments & Theme Parks",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Sanitize",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 2,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:40.000Z",
      productName: "InstantFOAM™ (USDA, UL)",
      productURL:
        "https://www.debgroup.com/us/products/deb-instantfoam-alcohol-based-hand-sanitizer"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: true,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: true,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: false,
    body: false,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6637,
    hazzard: {
      id: 45,
      substanceWorkCondition:
        "Commercial - Public Entertainments & Theme Parks",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Sanitize",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 4,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:19:49.000Z",
      productName: "nstantFOAM™ Non-Alcohol",
      productURL:
        "https://www.debgroup.com/us/products/deb-instantfoam-non-alcohol-hand-sanitizer"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: null,
    ecoLogo: false,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: false,
    hair: false,
    glove: false,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6638,
    hazzard: {
      id: 45,
      substanceWorkCondition:
        "Commercial - Public Entertainments & Theme Parks",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Sanitize",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 5,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:20:37.000Z",
      productName: "InstantFOAM™ Non-Alcohol PURE",
      productURL:
        "https://www.debgroup.com/us/products/deb-instantfoam-non-alcohol-pure-hand-sanitizer"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: null,
    ecoLogo: false,
    ecarf: false,
    nsf: null,
    greenSealCertified: null,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: false,
    hair: false,
    glove: false,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6639,
    hazzard: {
      id: 46,
      substanceWorkCondition: "Commercial - Public Facilities",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Sanitize",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 2,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:40.000Z",
      productName: "InstantFOAM™ (USDA, UL)",
      productURL:
        "https://www.debgroup.com/us/products/deb-instantfoam-alcohol-based-hand-sanitizer"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: true,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: true,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: false,
    body: false,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6640,
    hazzard: {
      id: 46,
      substanceWorkCondition: "Commercial - Public Facilities",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Sanitize",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 3,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:25:32.000Z",
      productName: "InstantFOAM™ Complete (USDA, UL)",
      productURL:
        "https://www.debgroup.com/us/products/deb-instantfoam-complete"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: true,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: true,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: false,
    body: false,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6641,
    hazzard: {
      id: 46,
      substanceWorkCondition: "Commercial - Public Facilities",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Sanitize",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 4,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:19:49.000Z",
      productName: "nstantFOAM™ Non-Alcohol",
      productURL:
        "https://www.debgroup.com/us/products/deb-instantfoam-non-alcohol-hand-sanitizer"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: false,
    hair: false,
    glove: false,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6642,
    hazzard: {
      id: 46,
      substanceWorkCondition: "Commercial - Public Facilities",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Sanitize",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 5,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:20:37.000Z",
      productName: "InstantFOAM™ Non-Alcohol PURE",
      productURL:
        "https://www.debgroup.com/us/products/deb-instantfoam-non-alcohol-pure-hand-sanitizer"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: false,
    hair: false,
    glove: false,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6643,
    hazzard: {
      id: 77,
      substanceWorkCondition: "Food - Food Packaging",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Sanitize",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 2,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:40.000Z",
      productName: "InstantFOAM™ (USDA, UL)",
      productURL:
        "https://www.debgroup.com/us/products/deb-instantfoam-alcohol-based-hand-sanitizer"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: true,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: true,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: false,
    body: false,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6644,
    hazzard: {
      id: 77,
      substanceWorkCondition: "Food - Food Packaging",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Sanitize",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 3,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:25:32.000Z",
      productName: "InstantFOAM™ Complete (USDA, UL)",
      productURL:
        "https://www.debgroup.com/us/products/deb-instantfoam-complete"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: true,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: true,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: false,
    body: false,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6645,
    hazzard: {
      id: 78,
      substanceWorkCondition: "Food - Food Processing & Manufacturing",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Sanitize",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 2,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:40.000Z",
      productName: "InstantFOAM™ (USDA, UL)",
      productURL:
        "https://www.debgroup.com/us/products/deb-instantfoam-alcohol-based-hand-sanitizer"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: true,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: true,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: false,
    body: false,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6646,
    hazzard: {
      id: 79,
      substanceWorkCondition: "Food - Food Services & Catering",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Sanitize",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 2,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:40.000Z",
      productName: "InstantFOAM™ (USDA, UL)",
      productURL:
        "https://www.debgroup.com/us/products/deb-instantfoam-alcohol-based-hand-sanitizer"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: true,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: true,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: false,
    body: false,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6647,
    hazzard: {
      id: 79,
      substanceWorkCondition: "Food - Food Services & Catering",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Sanitize",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 3,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:25:32.000Z",
      productName: "InstantFOAM™ Complete (USDA, UL)",
      productURL:
        "https://www.debgroup.com/us/products/deb-instantfoam-complete"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: true,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: true,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: false,
    body: false,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6648,
    hazzard: {
      id: 91,
      substanceWorkCondition: "Healthcare - Care Homes",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Sanitize",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 2,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:40.000Z",
      productName: "InstantFOAM™ (USDA, UL)",
      productURL:
        "https://www.debgroup.com/us/products/deb-instantfoam-alcohol-based-hand-sanitizer"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: true,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: true,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: false,
    body: false,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6649,
    hazzard: {
      id: 91,
      substanceWorkCondition: "Healthcare - Care Homes",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Sanitize",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 3,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:25:32.000Z",
      productName: "InstantFOAM™ Complete (USDA, UL)",
      productURL:
        "https://www.debgroup.com/us/products/deb-instantfoam-complete"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: true,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: true,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: false,
    body: false,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6650,
    hazzard: {
      id: 91,
      substanceWorkCondition: "Healthcare - Care Homes",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Sanitize",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 4,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:19:49.000Z",
      productName: "nstantFOAM™ Non-Alcohol",
      productURL:
        "https://www.debgroup.com/us/products/deb-instantfoam-non-alcohol-hand-sanitizer"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: false,
    hair: false,
    glove: false,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6651,
    hazzard: {
      id: 91,
      substanceWorkCondition: "Healthcare - Care Homes",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Sanitize",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 5,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:20:37.000Z",
      productName: "InstantFOAM™ Non-Alcohol PURE",
      productURL:
        "https://www.debgroup.com/us/products/deb-instantfoam-non-alcohol-pure-hand-sanitizer"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: false,
    hair: false,
    glove: false,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6652,
    hazzard: {
      id: 92,
      substanceWorkCondition:
        "Healthcare - Medical Centers/Surgeries/General Practitioners",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Sanitize",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 2,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:40.000Z",
      productName: "InstantFOAM™ (USDA, UL)",
      productURL:
        "https://www.debgroup.com/us/products/deb-instantfoam-alcohol-based-hand-sanitizer"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: true,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: true,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: false,
    body: false,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:17.000Z",
    updated_at: "2020-03-02T22:50:17.000Z"
  },
  {
    id: 6653,
    hazzard: {
      id: 92,
      substanceWorkCondition:
        "Healthcare - Medical Centers/Surgeries/General Practitioners",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Sanitize",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 4,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:19:49.000Z",
      productName: "nstantFOAM™ Non-Alcohol",
      productURL:
        "https://www.debgroup.com/us/products/deb-instantfoam-non-alcohol-hand-sanitizer"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: false,
    hair: false,
    glove: false,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6654,
    hazzard: {
      id: 92,
      substanceWorkCondition:
        "Healthcare - Medical Centers/Surgeries/General Practitioners",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Sanitize",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 5,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:20:37.000Z",
      productName: "InstantFOAM™ Non-Alcohol PURE",
      productURL:
        "https://www.debgroup.com/us/products/deb-instantfoam-non-alcohol-pure-hand-sanitizer"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: false,
    hair: false,
    glove: false,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6655,
    hazzard: {
      id: 93,
      substanceWorkCondition: "Healthcare - Nursing Homes",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Sanitize",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 2,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:40.000Z",
      productName: "InstantFOAM™ (USDA, UL)",
      productURL:
        "https://www.debgroup.com/us/products/deb-instantfoam-alcohol-based-hand-sanitizer"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: true,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: true,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: false,
    body: false,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6656,
    hazzard: {
      id: 93,
      substanceWorkCondition: "Healthcare - Nursing Homes",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Sanitize",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 4,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:19:49.000Z",
      productName: "nstantFOAM™ Non-Alcohol",
      productURL:
        "https://www.debgroup.com/us/products/deb-instantfoam-non-alcohol-hand-sanitizer"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: false,
    hair: false,
    glove: false,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6657,
    hazzard: {
      id: 93,
      substanceWorkCondition: "Healthcare - Nursing Homes",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Sanitize",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 5,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:20:37.000Z",
      productName: "InstantFOAM™ Non-Alcohol PURE",
      productURL:
        "https://www.debgroup.com/us/products/deb-instantfoam-non-alcohol-pure-hand-sanitizer"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: false,
    hair: false,
    glove: false,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6658,
    hazzard: {
      id: 94,
      substanceWorkCondition: "Healthcare - Veterinary Services",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Sanitize",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 2,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:40.000Z",
      productName: "InstantFOAM™ (USDA, UL)",
      productURL:
        "https://www.debgroup.com/us/products/deb-instantfoam-alcohol-based-hand-sanitizer"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: true,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: true,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: false,
    body: false,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6659,
    hazzard: {
      id: 94,
      substanceWorkCondition: "Healthcare - Veterinary Services",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Sanitize",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 4,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:19:49.000Z",
      productName: "nstantFOAM™ Non-Alcohol",
      productURL:
        "https://www.debgroup.com/us/products/deb-instantfoam-non-alcohol-hand-sanitizer"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: false,
    hair: false,
    glove: false,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6660,
    hazzard: {
      id: 94,
      substanceWorkCondition: "Healthcare - Veterinary Services",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Sanitize",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 5,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:20:37.000Z",
      productName: "InstantFOAM™ Non-Alcohol PURE",
      productURL:
        "https://www.debgroup.com/us/products/deb-instantfoam-non-alcohol-pure-hand-sanitizer"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: false,
    hair: false,
    glove: false,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6661,
    hazzard: {
      id: 110,
      substanceWorkCondition: "Industry Light - Science & Laboratories",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Sanitize",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 2,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:40.000Z",
      productName: "InstantFOAM™ (USDA, UL)",
      productURL:
        "https://www.debgroup.com/us/products/deb-instantfoam-alcohol-based-hand-sanitizer"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: true,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: true,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: false,
    body: false,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6662,
    hazzard: {
      id: 141,
      substanceWorkCondition: "Pit water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Sanitize",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 2,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:24:40.000Z",
      productName: "InstantFOAM™ (USDA, UL)",
      productURL:
        "https://www.debgroup.com/us/products/deb-instantfoam-alcohol-based-hand-sanitizer"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: true,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: true,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: false,
    body: false,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6663,
    hazzard: {
      id: 999,
      substanceWorkCondition: "All Products",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:44:03.000Z",
      updated_at: "2020-03-02T17:44:03.000Z"
    },
    step: "Sanitize",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 9,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:26:59.000Z",
      productName: "InstantFOAM™ (USDA, UL)",
      productURL:
        "https://www.debgroup.com/us/products/deb-instantfoam-alcohol-based-hand-sanitizer"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: true,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: true,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: false,
    body: false,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6664,
    hazzard: {
      id: 999,
      substanceWorkCondition: "All Products",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:44:03.000Z",
      updated_at: "2020-03-02T17:44:03.000Z"
    },
    step: "Sanitize",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 10,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:25:48.000Z",
      productName: "InstantFOAM™ Complete (USDA, UL)",
      productURL:
        "https://www.debgroup.com/us/products/deb-instantfoam-complete"
    },
    rating: 2,
    location: "Hands",
    level1: true,
    level2: true,
    level3: true,
    preservative: false,
    perfume: false,
    dye: false,
    silicone: true,
    usdaCertified: true,
    ecoLogo: true,
    ecarf: false,
    nsf: true,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: false,
    face: false,
    body: false,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6665,
    hazzard: {
      id: 999,
      substanceWorkCondition: "All Products",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:44:03.000Z",
      updated_at: "2020-03-02T17:44:03.000Z"
    },
    step: "Sanitize",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 4,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:19:49.000Z",
      productName: "nstantFOAM™ Non-Alcohol",
      productURL:
        "https://www.debgroup.com/us/products/deb-instantfoam-non-alcohol-hand-sanitizer"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: true,
    dye: true,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: false,
    hair: false,
    glove: false,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6666,
    hazzard: {
      id: 999,
      substanceWorkCondition: "All Products",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:44:03.000Z",
      updated_at: "2020-03-02T17:44:03.000Z"
    },
    step: "Sanitize",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 11,
      created_at: "2020-03-02T20:33:11.000Z",
      updated_at: "2020-03-10T03:26:28.000Z",
      productName: "InstantFOAM™ Non-Alcohol PURE",
      productURL:
        "https://www.debgroup.com/us/products/deb-instantfoam-non-alcohol-pure-hand-sanitizer"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: true,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: false,
    body: false,
    hair: false,
    glove: false,
    individual: true,
    dispenser: true,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6667,
    hazzard: {
      id: 1,
      substanceWorkCondition: "2-Butanone (MEK)",
      cas: "78-93-3",
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: "oil",
      created_at: "2020-03-02T17:39:32.000Z",
      updated_at: "2020-03-05T14:48:01.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6668,
    hazzard: {
      id: 1,
      substanceWorkCondition: "2-Butanone (MEK)",
      cas: "78-93-3",
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: "oil",
      created_at: "2020-03-02T17:39:32.000Z",
      updated_at: "2020-03-05T14:48:01.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6669,
    hazzard: {
      id: 1,
      substanceWorkCondition: "2-Butanone (MEK)",
      cas: "78-93-3",
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: "oil",
      created_at: "2020-03-02T17:39:32.000Z",
      updated_at: "2020-03-05T14:48:01.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6670,
    hazzard: {
      id: 1,
      substanceWorkCondition: "2-Butanone (MEK)",
      cas: "78-93-3",
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: "oil",
      created_at: "2020-03-02T17:39:32.000Z",
      updated_at: "2020-03-05T14:48:01.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6671,
    hazzard: {
      id: 2,
      substanceWorkCondition: "Acetone / Dimethyl ketone",
      cas: "67-64-1",
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6672,
    hazzard: {
      id: 2,
      substanceWorkCondition: "Acetone / Dimethyl ketone",
      cas: "67-64-1",
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6673,
    hazzard: {
      id: 2,
      substanceWorkCondition: "Acetone / Dimethyl ketone",
      cas: "67-64-1",
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6674,
    hazzard: {
      id: 2,
      substanceWorkCondition: "Acetone / Dimethyl ketone",
      cas: "67-64-1",
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6675,
    hazzard: {
      id: 3,
      substanceWorkCondition: "Acids, diluted",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6676,
    hazzard: {
      id: 3,
      substanceWorkCondition: "Acids, diluted",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6677,
    hazzard: {
      id: 3,
      substanceWorkCondition: "Acids, diluted",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6678,
    hazzard: {
      id: 3,
      substanceWorkCondition: "Acids, diluted",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6679,
    hazzard: {
      id: 4,
      substanceWorkCondition: "Acrylates",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6680,
    hazzard: {
      id: 4,
      substanceWorkCondition: "Acrylates",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6681,
    hazzard: {
      id: 4,
      substanceWorkCondition: "Acrylates",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6682,
    hazzard: {
      id: 4,
      substanceWorkCondition: "Acrylates",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6683,
    hazzard: {
      id: 5,
      substanceWorkCondition: "Adhesive single-component",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6684,
    hazzard: {
      id: 5,
      substanceWorkCondition: "Adhesive single-component",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6685,
    hazzard: {
      id: 5,
      substanceWorkCondition: "Adhesive single-component",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6686,
    hazzard: {
      id: 5,
      substanceWorkCondition: "Adhesive single-component",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6687,
    hazzard: {
      id: 6,
      substanceWorkCondition: "Adhesive, water-soluble",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6688,
    hazzard: {
      id: 6,
      substanceWorkCondition: "Adhesive, water-soluble",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6689,
    hazzard: {
      id: 6,
      substanceWorkCondition: "Adhesive, water-soluble",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6690,
    hazzard: {
      id: 6,
      substanceWorkCondition: "Adhesive, water-soluble",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6691,
    hazzard: {
      id: 7,
      substanceWorkCondition: "Adhesives, glues based on synthetic resin",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6692,
    hazzard: {
      id: 7,
      substanceWorkCondition: "Adhesives, glues based on synthetic resin",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6693,
    hazzard: {
      id: 7,
      substanceWorkCondition: "Adhesives, glues based on synthetic resin",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6694,
    hazzard: {
      id: 7,
      substanceWorkCondition: "Adhesives, glues based on synthetic resin",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6695,
    hazzard: {
      id: 8,
      substanceWorkCondition: "Aldehydes, diluted",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6696,
    hazzard: {
      id: 8,
      substanceWorkCondition: "Aldehydes, diluted",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6697,
    hazzard: {
      id: 8,
      substanceWorkCondition: "Aldehydes, diluted",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6698,
    hazzard: {
      id: 8,
      substanceWorkCondition: "Aldehydes, diluted",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:31.000Z",
      updated_at: "2020-03-02T17:41:31.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6699,
    hazzard: {
      id: 9,
      substanceWorkCondition: "Alkaline solutions / lyes / thinned caustics",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6700,
    hazzard: {
      id: 9,
      substanceWorkCondition: "Alkaline solutions / lyes / thinned caustics",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6701,
    hazzard: {
      id: 9,
      substanceWorkCondition: "Alkaline solutions / lyes / thinned caustics",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6702,
    hazzard: {
      id: 9,
      substanceWorkCondition: "Alkaline solutions / lyes / thinned caustics",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6703,
    hazzard: {
      id: 10,
      substanceWorkCondition: "Alkaline solutions, diluted",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6704,
    hazzard: {
      id: 10,
      substanceWorkCondition: "Alkaline solutions, diluted",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6705,
    hazzard: {
      id: 10,
      substanceWorkCondition: "Alkaline solutions, diluted",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6706,
    hazzard: {
      id: 10,
      substanceWorkCondition: "Alkaline solutions, diluted",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6707,
    hazzard: {
      id: 11,
      substanceWorkCondition: "Alkaline solutions, lyes (solvent based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6708,
    hazzard: {
      id: 11,
      substanceWorkCondition: "Alkaline solutions, lyes (solvent based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6709,
    hazzard: {
      id: 11,
      substanceWorkCondition: "Alkaline solutions, lyes (solvent based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6710,
    hazzard: {
      id: 11,
      substanceWorkCondition: "Alkaline solutions, lyes (solvent based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6711,
    hazzard: {
      id: 12,
      substanceWorkCondition: "Aluminium",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6712,
    hazzard: {
      id: 12,
      substanceWorkCondition: "Aluminium",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6713,
    hazzard: {
      id: 12,
      substanceWorkCondition: "Aluminium",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6714,
    hazzard: {
      id: 12,
      substanceWorkCondition: "Aluminium",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6715,
    hazzard: {
      id: 14,
      substanceWorkCondition: "Antistatics",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6716,
    hazzard: {
      id: 14,
      substanceWorkCondition: "Antistatics",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6717,
    hazzard: {
      id: 14,
      substanceWorkCondition: "Antistatics",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6718,
    hazzard: {
      id: 14,
      substanceWorkCondition: "Antistatics",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6719,
    hazzard: {
      id: 15,
      substanceWorkCondition: "Asphalt",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6720,
    hazzard: {
      id: 15,
      substanceWorkCondition: "Asphalt",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6721,
    hazzard: {
      id: 15,
      substanceWorkCondition: "Asphalt",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6722,
    hazzard: {
      id: 15,
      substanceWorkCondition: "Asphalt",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6723,
    hazzard: {
      id: 18,
      substanceWorkCondition:
        "Automotive Aftermarket - Public Transport Maintenance&Repair",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6724,
    hazzard: {
      id: 18,
      substanceWorkCondition:
        "Automotive Aftermarket - Public Transport Maintenance&Repair",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6725,
    hazzard: {
      id: 19,
      substanceWorkCondition: "Battery acid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6726,
    hazzard: {
      id: 19,
      substanceWorkCondition: "Battery acid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6727,
    hazzard: {
      id: 19,
      substanceWorkCondition: "Battery acid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6728,
    hazzard: {
      id: 19,
      substanceWorkCondition: "Battery acid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6729,
    hazzard: {
      id: 20,
      substanceWorkCondition: "Benzene",
      cas: null,
      glovesRecommended: true,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6730,
    hazzard: {
      id: 20,
      substanceWorkCondition: "Benzene",
      cas: null,
      glovesRecommended: true,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6731,
    hazzard: {
      id: 20,
      substanceWorkCondition: "Benzene",
      cas: null,
      glovesRecommended: true,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6732,
    hazzard: {
      id: 20,
      substanceWorkCondition: "Benzene",
      cas: null,
      glovesRecommended: true,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6733,
    hazzard: {
      id: 22,
      substanceWorkCondition: "Bitumen emulsion",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6734,
    hazzard: {
      id: 22,
      substanceWorkCondition: "Bitumen emulsion",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6735,
    hazzard: {
      id: 22,
      substanceWorkCondition: "Bitumen emulsion",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6736,
    hazzard: {
      id: 22,
      substanceWorkCondition: "Bitumen emulsion",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6737,
    hazzard: {
      id: 21,
      substanceWorkCondition: "Bitumen",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6738,
    hazzard: {
      id: 21,
      substanceWorkCondition: "Bitumen",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6739,
    hazzard: {
      id: 21,
      substanceWorkCondition: "Bitumen",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6740,
    hazzard: {
      id: 21,
      substanceWorkCondition: "Bitumen",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6741,
    hazzard: {
      id: 23,
      substanceWorkCondition: "Bleaching agent (lye)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6742,
    hazzard: {
      id: 23,
      substanceWorkCondition: "Bleaching agent (lye)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6743,
    hazzard: {
      id: 23,
      substanceWorkCondition: "Bleaching agent (lye)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6744,
    hazzard: {
      id: 23,
      substanceWorkCondition: "Bleaching agent (lye)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6745,
    hazzard: {
      id: 24,
      substanceWorkCondition: "Brake fluid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6746,
    hazzard: {
      id: 24,
      substanceWorkCondition: "Brake fluid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6747,
    hazzard: {
      id: 24,
      substanceWorkCondition: "Brake fluid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6748,
    hazzard: {
      id: 24,
      substanceWorkCondition: "Brake fluid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6749,
    hazzard: {
      id: 25,
      substanceWorkCondition: "Casting dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6750,
    hazzard: {
      id: 25,
      substanceWorkCondition: "Casting dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6751,
    hazzard: {
      id: 25,
      substanceWorkCondition: "Casting dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6752,
    hazzard: {
      id: 25,
      substanceWorkCondition: "Casting dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6753,
    hazzard: {
      id: 26,
      substanceWorkCondition: "Cement (dry)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6754,
    hazzard: {
      id: 26,
      substanceWorkCondition: "Cement (dry)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6755,
    hazzard: {
      id: 26,
      substanceWorkCondition: "Cement (dry)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6756,
    hazzard: {
      id: 26,
      substanceWorkCondition: "Cement (dry)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6757,
    hazzard: {
      id: 27,
      substanceWorkCondition: "Cement (wet)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6758,
    hazzard: {
      id: 27,
      substanceWorkCondition: "Cement (wet)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6759,
    hazzard: {
      id: 27,
      substanceWorkCondition: "Cement (wet)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6760,
    hazzard: {
      id: 27,
      substanceWorkCondition: "Cement (wet)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6761,
    hazzard: {
      id: 28,
      substanceWorkCondition: "Cement, putty epoxy-based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6762,
    hazzard: {
      id: 28,
      substanceWorkCondition: "Cement, putty epoxy-based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6763,
    hazzard: {
      id: 28,
      substanceWorkCondition: "Cement, putty epoxy-based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6764,
    hazzard: {
      id: 28,
      substanceWorkCondition: "Cement, putty epoxy-based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6765,
    hazzard: {
      id: 29,
      substanceWorkCondition:
        "Chromates (water- insoluble) = Chromium pigments",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6766,
    hazzard: {
      id: 29,
      substanceWorkCondition:
        "Chromates (water- insoluble) = Chromium pigments",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6767,
    hazzard: {
      id: 29,
      substanceWorkCondition:
        "Chromates (water- insoluble) = Chromium pigments",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6768,
    hazzard: {
      id: 29,
      substanceWorkCondition:
        "Chromates (water- insoluble) = Chromium pigments",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6769,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6770,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6771,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6772,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6773,
    hazzard: {
      id: 31,
      substanceWorkCondition: "cleaning agent, detergent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6774,
    hazzard: {
      id: 31,
      substanceWorkCondition: "cleaning agent, detergent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6775,
    hazzard: {
      id: 31,
      substanceWorkCondition: "cleaning agent, detergent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6776,
    hazzard: {
      id: 31,
      substanceWorkCondition: "cleaning agent, detergent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6777,
    hazzard: {
      id: 32,
      substanceWorkCondition: "Coal dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6778,
    hazzard: {
      id: 32,
      substanceWorkCondition: "Coal dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6779,
    hazzard: {
      id: 32,
      substanceWorkCondition: "Coal dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6780,
    hazzard: {
      id: 32,
      substanceWorkCondition: "Coal dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6781,
    hazzard: {
      id: 33,
      substanceWorkCondition: "Coating materials, bituminous (bitumen varnish)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6782,
    hazzard: {
      id: 33,
      substanceWorkCondition: "Coating materials, bituminous (bitumen varnish)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6783,
    hazzard: {
      id: 33,
      substanceWorkCondition: "Coating materials, bituminous (bitumen varnish)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6784,
    hazzard: {
      id: 33,
      substanceWorkCondition: "Coating materials, bituminous (bitumen varnish)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6785,
    hazzard: {
      id: 34,
      substanceWorkCondition: "Coating materials, emulsified",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6786,
    hazzard: {
      id: 34,
      substanceWorkCondition: "Coating materials, emulsified",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6787,
    hazzard: {
      id: 34,
      substanceWorkCondition: "Coating materials, emulsified",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6788,
    hazzard: {
      id: 34,
      substanceWorkCondition: "Coating materials, emulsified",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6789,
    hazzard: {
      id: 35,
      substanceWorkCondition: "Coating materials, oily",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6790,
    hazzard: {
      id: 35,
      substanceWorkCondition: "Coating materials, oily",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6791,
    hazzard: {
      id: 35,
      substanceWorkCondition: "Coating materials, oily",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6792,
    hazzard: {
      id: 35,
      substanceWorkCondition: "Coating materials, oily",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6793,
    hazzard: {
      id: 36,
      substanceWorkCondition: "Cold degreasing agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6794,
    hazzard: {
      id: 36,
      substanceWorkCondition: "Cold degreasing agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6795,
    hazzard: {
      id: 36,
      substanceWorkCondition: "Cold degreasing agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6796,
    hazzard: {
      id: 36,
      substanceWorkCondition: "Cold degreasing agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6797,
    hazzard: {
      id: 37,
      substanceWorkCondition: "cold detergent, solvent based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6798,
    hazzard: {
      id: 37,
      substanceWorkCondition: "cold detergent, solvent based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:18.000Z",
    updated_at: "2020-03-02T22:50:18.000Z"
  },
  {
    id: 6799,
    hazzard: {
      id: 37,
      substanceWorkCondition: "cold detergent, solvent based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6800,
    hazzard: {
      id: 37,
      substanceWorkCondition: "cold detergent, solvent based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6801,
    hazzard: {
      id: 38,
      substanceWorkCondition: "Cold working conditions",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6802,
    hazzard: {
      id: 38,
      substanceWorkCondition: "Cold working conditions",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6803,
    hazzard: {
      id: 38,
      substanceWorkCondition: "Cold working conditions",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6804,
    hazzard: {
      id: 38,
      substanceWorkCondition: "Cold working conditions",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6805,
    hazzard: {
      id: 39,
      substanceWorkCondition:
        "Commercial - Airports & Transport Terminals - Public Area",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6806,
    hazzard: {
      id: 39,
      substanceWorkCondition:
        "Commercial - Airports & Transport Terminals - Public Area",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6807,
    hazzard: {
      id: 40,
      substanceWorkCondition: "Commercial - Cleaning & Maintenance",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6808,
    hazzard: {
      id: 40,
      substanceWorkCondition: "Commercial - Cleaning & Maintenance",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6809,
    hazzard: {
      id: 41,
      substanceWorkCondition: "Commercial - Commerce & Office",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6810,
    hazzard: {
      id: 41,
      substanceWorkCondition: "Commercial - Commerce & Office",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6811,
    hazzard: {
      id: 42,
      substanceWorkCondition: "Commercial - Education",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6812,
    hazzard: {
      id: 42,
      substanceWorkCondition: "Commercial - Education",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6813,
    hazzard: {
      id: 43,
      substanceWorkCondition: "Commercial - Hotel & Leisure",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6814,
    hazzard: {
      id: 43,
      substanceWorkCondition: "Commercial - Hotel & Leisure",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6815,
    hazzard: {
      id: 44,
      substanceWorkCondition: "Commercial - Prisons & Security Services",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6816,
    hazzard: {
      id: 44,
      substanceWorkCondition: "Commercial - Prisons & Security Services",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6817,
    hazzard: {
      id: 45,
      substanceWorkCondition:
        "Commercial - Public Entertainments & Theme Parks",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6818,
    hazzard: {
      id: 45,
      substanceWorkCondition:
        "Commercial - Public Entertainments & Theme Parks",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6819,
    hazzard: {
      id: 46,
      substanceWorkCondition: "Commercial - Public Facilities",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6820,
    hazzard: {
      id: 46,
      substanceWorkCondition: "Commercial - Public Facilities",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6821,
    hazzard: {
      id: 47,
      substanceWorkCondition: "Concrete",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6822,
    hazzard: {
      id: 47,
      substanceWorkCondition: "Concrete",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6823,
    hazzard: {
      id: 47,
      substanceWorkCondition: "Concrete",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6824,
    hazzard: {
      id: 47,
      substanceWorkCondition: "Concrete",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6825,
    hazzard: {
      id: 48,
      substanceWorkCondition: "Cooling lubricant (non-water-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6826,
    hazzard: {
      id: 48,
      substanceWorkCondition: "Cooling lubricant (non-water-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6827,
    hazzard: {
      id: 48,
      substanceWorkCondition: "Cooling lubricant (non-water-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6828,
    hazzard: {
      id: 48,
      substanceWorkCondition: "Cooling lubricant (non-water-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6829,
    hazzard: {
      id: 49,
      substanceWorkCondition: "Cooling lubricant (water- based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6830,
    hazzard: {
      id: 49,
      substanceWorkCondition: "Cooling lubricant (water- based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6831,
    hazzard: {
      id: 49,
      substanceWorkCondition: "Cooling lubricant (water- based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6832,
    hazzard: {
      id: 49,
      substanceWorkCondition: "Cooling lubricant (water- based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6833,
    hazzard: {
      id: 50,
      substanceWorkCondition: "Cooling water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6834,
    hazzard: {
      id: 50,
      substanceWorkCondition: "Cooling water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6835,
    hazzard: {
      id: 50,
      substanceWorkCondition: "Cooling water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6836,
    hazzard: {
      id: 50,
      substanceWorkCondition: "Cooling water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6837,
    hazzard: {
      id: 51,
      substanceWorkCondition: "Copying ink",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6838,
    hazzard: {
      id: 51,
      substanceWorkCondition: "Copying ink",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6839,
    hazzard: {
      id: 51,
      substanceWorkCondition: "Copying ink",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6840,
    hazzard: {
      id: 51,
      substanceWorkCondition: "Copying ink",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6841,
    hazzard: {
      id: 52,
      substanceWorkCondition: "Cutting oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6842,
    hazzard: {
      id: 52,
      substanceWorkCondition: "Cutting oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6843,
    hazzard: {
      id: 52,
      substanceWorkCondition: "Cutting oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6844,
    hazzard: {
      id: 52,
      substanceWorkCondition: "Cutting oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6845,
    hazzard: {
      id: 53,
      substanceWorkCondition: "Degreaser solvent - based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6846,
    hazzard: {
      id: 53,
      substanceWorkCondition: "Degreaser solvent - based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6847,
    hazzard: {
      id: 53,
      substanceWorkCondition: "Degreaser solvent - based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6848,
    hazzard: {
      id: 53,
      substanceWorkCondition: "Degreaser solvent - based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6849,
    hazzard: {
      id: 54,
      substanceWorkCondition: "Degreaser without organic solvent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6850,
    hazzard: {
      id: 54,
      substanceWorkCondition: "Degreaser without organic solvent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6851,
    hazzard: {
      id: 54,
      substanceWorkCondition: "Degreaser without organic solvent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6852,
    hazzard: {
      id: 54,
      substanceWorkCondition: "Degreaser without organic solvent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6853,
    hazzard: {
      id: 55,
      substanceWorkCondition: "Detachment solution for concrete",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6854,
    hazzard: {
      id: 55,
      substanceWorkCondition: "Detachment solution for concrete",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6855,
    hazzard: {
      id: 55,
      substanceWorkCondition: "Detachment solution for concrete",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6856,
    hazzard: {
      id: 55,
      substanceWorkCondition: "Detachment solution for concrete",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6857,
    hazzard: {
      id: 56,
      substanceWorkCondition: "Detachments (alcohol-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6858,
    hazzard: {
      id: 56,
      substanceWorkCondition: "Detachments (alcohol-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6859,
    hazzard: {
      id: 56,
      substanceWorkCondition: "Detachments (alcohol-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6860,
    hazzard: {
      id: 56,
      substanceWorkCondition: "Detachments (alcohol-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6861,
    hazzard: {
      id: 57,
      substanceWorkCondition: "Detachments (solvent-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6862,
    hazzard: {
      id: 57,
      substanceWorkCondition: "Detachments (solvent-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6863,
    hazzard: {
      id: 57,
      substanceWorkCondition: "Detachments (solvent-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6864,
    hazzard: {
      id: 57,
      substanceWorkCondition: "Detachments (solvent-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:32.000Z",
      updated_at: "2020-03-02T17:41:32.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6865,
    hazzard: {
      id: 58,
      substanceWorkCondition: "Detachments (sour)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6866,
    hazzard: {
      id: 58,
      substanceWorkCondition: "Detachments (sour)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6867,
    hazzard: {
      id: 58,
      substanceWorkCondition: "Detachments (sour)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6868,
    hazzard: {
      id: 58,
      substanceWorkCondition: "Detachments (sour)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6869,
    hazzard: {
      id: 59,
      substanceWorkCondition: "Disinfectants (alcohol-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6870,
    hazzard: {
      id: 59,
      substanceWorkCondition: "Disinfectants (alcohol-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6871,
    hazzard: {
      id: 59,
      substanceWorkCondition: "Disinfectants (alcohol-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6872,
    hazzard: {
      id: 59,
      substanceWorkCondition: "Disinfectants (alcohol-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6873,
    hazzard: {
      id: 60,
      substanceWorkCondition: "Disinfectants (formaldehyd-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6874,
    hazzard: {
      id: 60,
      substanceWorkCondition: "Disinfectants (formaldehyd-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6875,
    hazzard: {
      id: 60,
      substanceWorkCondition: "Disinfectants (formaldehyd-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6876,
    hazzard: {
      id: 60,
      substanceWorkCondition: "Disinfectants (formaldehyd-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6877,
    hazzard: {
      id: 61,
      substanceWorkCondition: "Disinfectants (water-based) ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6878,
    hazzard: {
      id: 61,
      substanceWorkCondition: "Disinfectants (water-based) ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6879,
    hazzard: {
      id: 61,
      substanceWorkCondition: "Disinfectants (water-based) ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6880,
    hazzard: {
      id: 61,
      substanceWorkCondition: "Disinfectants (water-based) ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6881,
    hazzard: {
      id: 62,
      substanceWorkCondition: "Dispersing Agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6882,
    hazzard: {
      id: 62,
      substanceWorkCondition: "Dispersing Agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6883,
    hazzard: {
      id: 62,
      substanceWorkCondition: "Dispersing Agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6884,
    hazzard: {
      id: 62,
      substanceWorkCondition: "Dispersing Agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6885,
    hazzard: {
      id: 63,
      substanceWorkCondition: "Drilling emulsion (water based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6886,
    hazzard: {
      id: 63,
      substanceWorkCondition: "Drilling emulsion (water based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6887,
    hazzard: {
      id: 63,
      substanceWorkCondition: "Drilling emulsion (water based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6888,
    hazzard: {
      id: 63,
      substanceWorkCondition: "Drilling emulsion (water based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6889,
    hazzard: {
      id: 64,
      substanceWorkCondition: "Drilling oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6890,
    hazzard: {
      id: 64,
      substanceWorkCondition: "Drilling oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6891,
    hazzard: {
      id: 64,
      substanceWorkCondition: "Drilling oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6892,
    hazzard: {
      id: 64,
      substanceWorkCondition: "Drilling oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6893,
    hazzard: {
      id: 65,
      substanceWorkCondition: "Dryness",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6894,
    hazzard: {
      id: 65,
      substanceWorkCondition: "Dryness",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6895,
    hazzard: {
      id: 65,
      substanceWorkCondition: "Dryness",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6896,
    hazzard: {
      id: 65,
      substanceWorkCondition: "Dryness",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6897,
    hazzard: {
      id: 66,
      substanceWorkCondition: "Dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6898,
    hazzard: {
      id: 66,
      substanceWorkCondition: "Dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6899,
    hazzard: {
      id: 66,
      substanceWorkCondition: "Dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6900,
    hazzard: {
      id: 66,
      substanceWorkCondition: "Dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6901,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6902,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6903,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6904,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6905,
    hazzard: {
      id: 70,
      substanceWorkCondition: "Epoxy resin",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6906,
    hazzard: {
      id: 70,
      substanceWorkCondition: "Epoxy resin",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6907,
    hazzard: {
      id: 70,
      substanceWorkCondition: "Epoxy resin",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6908,
    hazzard: {
      id: 70,
      substanceWorkCondition: "Epoxy resin",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6909,
    hazzard: {
      id: 71,
      substanceWorkCondition: "Etching (mordant)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6910,
    hazzard: {
      id: 71,
      substanceWorkCondition: "Etching (mordant)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6911,
    hazzard: {
      id: 71,
      substanceWorkCondition: "Etching (mordant)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6912,
    hazzard: {
      id: 71,
      substanceWorkCondition: "Etching (mordant)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6913,
    hazzard: {
      id: 72,
      substanceWorkCondition: "Ethanol / methylated spirits ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6914,
    hazzard: {
      id: 72,
      substanceWorkCondition: "Ethanol / methylated spirits ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6915,
    hazzard: {
      id: 72,
      substanceWorkCondition: "Ethanol / methylated spirits ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6916,
    hazzard: {
      id: 72,
      substanceWorkCondition: "Ethanol / methylated spirits ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6917,
    hazzard: {
      id: 73,
      substanceWorkCondition: "Fat",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6918,
    hazzard: {
      id: 73,
      substanceWorkCondition: "Fat",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6919,
    hazzard: {
      id: 73,
      substanceWorkCondition: "Fat",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6920,
    hazzard: {
      id: 73,
      substanceWorkCondition: "Fat",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6921,
    hazzard: {
      id: 74,
      substanceWorkCondition: "Fertilizer",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6922,
    hazzard: {
      id: 74,
      substanceWorkCondition: "Fertilizer",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6923,
    hazzard: {
      id: 74,
      substanceWorkCondition: "Fertilizer",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6924,
    hazzard: {
      id: 74,
      substanceWorkCondition: "Fertilizer",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6925,
    hazzard: {
      id: 75,
      substanceWorkCondition: "fine anorganic/inorganic powders ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6926,
    hazzard: {
      id: 75,
      substanceWorkCondition: "fine anorganic/inorganic powders ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6927,
    hazzard: {
      id: 75,
      substanceWorkCondition: "fine anorganic/inorganic powders ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6928,
    hazzard: {
      id: 75,
      substanceWorkCondition: "fine anorganic/inorganic powders ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6929,
    hazzard: {
      id: 76,
      substanceWorkCondition: "Floor cleaner (water- based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6930,
    hazzard: {
      id: 76,
      substanceWorkCondition: "Floor cleaner (water- based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6931,
    hazzard: {
      id: 76,
      substanceWorkCondition: "Floor cleaner (water- based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6932,
    hazzard: {
      id: 76,
      substanceWorkCondition: "Floor cleaner (water- based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6933,
    hazzard: {
      id: 77,
      substanceWorkCondition: "Food - Food Packaging",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:19.000Z",
    updated_at: "2020-03-02T22:50:19.000Z"
  },
  {
    id: 6934,
    hazzard: {
      id: 78,
      substanceWorkCondition: "Food - Food Processing & Manufacturing",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 6935,
    hazzard: {
      id: 79,
      substanceWorkCondition: "Food - Food Services & Catering",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 6936,
    hazzard: {
      id: 80,
      substanceWorkCondition: "Food Dyestuff",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 6937,
    hazzard: {
      id: 81,
      substanceWorkCondition: "fuel: gas / diesel",
      cas: null,
      glovesRecommended: true,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 6938,
    hazzard: {
      id: 81,
      substanceWorkCondition: "fuel: gas / diesel",
      cas: null,
      glovesRecommended: true,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 6939,
    hazzard: {
      id: 81,
      substanceWorkCondition: "fuel: gas / diesel",
      cas: null,
      glovesRecommended: true,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 6940,
    hazzard: {
      id: 81,
      substanceWorkCondition: "fuel: gas / diesel",
      cas: null,
      glovesRecommended: true,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 6941,
    hazzard: {
      id: 82,
      substanceWorkCondition: "Galvanic bath",
      cas: null,
      glovesRecommended: true,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 6942,
    hazzard: {
      id: 82,
      substanceWorkCondition: "Galvanic bath",
      cas: null,
      glovesRecommended: true,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 6943,
    hazzard: {
      id: 82,
      substanceWorkCondition: "Galvanic bath",
      cas: null,
      glovesRecommended: true,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 6944,
    hazzard: {
      id: 82,
      substanceWorkCondition: "Galvanic bath",
      cas: null,
      glovesRecommended: true,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 6945,
    hazzard: {
      id: 83,
      substanceWorkCondition: "Glass fibre dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 6946,
    hazzard: {
      id: 83,
      substanceWorkCondition: "Glass fibre dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 6947,
    hazzard: {
      id: 83,
      substanceWorkCondition: "Glass fibre dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 6948,
    hazzard: {
      id: 83,
      substanceWorkCondition: "Glass fibre dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 6949,
    hazzard: {
      id: 84,
      substanceWorkCondition: "Glass wool dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 6950,
    hazzard: {
      id: 84,
      substanceWorkCondition: "Glass wool dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 6951,
    hazzard: {
      id: 84,
      substanceWorkCondition: "Glass wool dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 6952,
    hazzard: {
      id: 84,
      substanceWorkCondition: "Glass wool dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 6953,
    hazzard: {
      id: 85,
      substanceWorkCondition: "Glycols",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 6954,
    hazzard: {
      id: 85,
      substanceWorkCondition: "Glycols",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 6955,
    hazzard: {
      id: 85,
      substanceWorkCondition: "Glycols",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 6956,
    hazzard: {
      id: 85,
      substanceWorkCondition: "Glycols",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 6957,
    hazzard: {
      id: 86,
      substanceWorkCondition: "Graphite",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 6958,
    hazzard: {
      id: 86,
      substanceWorkCondition: "Graphite",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 6959,
    hazzard: {
      id: 86,
      substanceWorkCondition: "Graphite",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 6960,
    hazzard: {
      id: 86,
      substanceWorkCondition: "Graphite",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 6961,
    hazzard: {
      id: 87,
      substanceWorkCondition: "Grease",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 6962,
    hazzard: {
      id: 87,
      substanceWorkCondition: "Grease",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 6963,
    hazzard: {
      id: 87,
      substanceWorkCondition: "Grease",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 6964,
    hazzard: {
      id: 87,
      substanceWorkCondition: "Grease",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 6965,
    hazzard: {
      id: 88,
      substanceWorkCondition: "Grinding oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 6966,
    hazzard: {
      id: 88,
      substanceWorkCondition: "Grinding oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 6967,
    hazzard: {
      id: 88,
      substanceWorkCondition: "Grinding oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 6968,
    hazzard: {
      id: 88,
      substanceWorkCondition: "Grinding oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 6969,
    hazzard: {
      id: 89,
      substanceWorkCondition: "Grinding water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 6970,
    hazzard: {
      id: 89,
      substanceWorkCondition: "Grinding water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 6971,
    hazzard: {
      id: 89,
      substanceWorkCondition: "Grinding water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 6972,
    hazzard: {
      id: 89,
      substanceWorkCondition: "Grinding water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 6973,
    hazzard: {
      id: 90,
      substanceWorkCondition: "Gypsum",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 6974,
    hazzard: {
      id: 90,
      substanceWorkCondition: "Gypsum",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 6975,
    hazzard: {
      id: 90,
      substanceWorkCondition: "Gypsum",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 6976,
    hazzard: {
      id: 90,
      substanceWorkCondition: "Gypsum",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 6977,
    hazzard: {
      id: 91,
      substanceWorkCondition: "Healthcare - Care Homes",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 6978,
    hazzard: {
      id: 91,
      substanceWorkCondition: "Healthcare - Care Homes",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 6979,
    hazzard: {
      id: 92,
      substanceWorkCondition:
        "Healthcare - Medical Centers/Surgeries/General Practitioners",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 6980,
    hazzard: {
      id: 92,
      substanceWorkCondition:
        "Healthcare - Medical Centers/Surgeries/General Practitioners",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 6981,
    hazzard: {
      id: 93,
      substanceWorkCondition: "Healthcare - Nursing Homes",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 6982,
    hazzard: {
      id: 93,
      substanceWorkCondition: "Healthcare - Nursing Homes",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 6983,
    hazzard: {
      id: 94,
      substanceWorkCondition: "Healthcare - Veterinary Services",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 6984,
    hazzard: {
      id: 94,
      substanceWorkCondition: "Healthcare - Veterinary Services",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 6985,
    hazzard: {
      id: 95,
      substanceWorkCondition: "Hydraulic fluid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 6986,
    hazzard: {
      id: 95,
      substanceWorkCondition: "Hydraulic fluid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 6987,
    hazzard: {
      id: 95,
      substanceWorkCondition: "Hydraulic fluid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 6988,
    hazzard: {
      id: 95,
      substanceWorkCondition: "Hydraulic fluid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 6989,
    hazzard: {
      id: 96,
      substanceWorkCondition: "Hyrocarbons",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 6990,
    hazzard: {
      id: 96,
      substanceWorkCondition: "Hyrocarbons",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 6991,
    hazzard: {
      id: 96,
      substanceWorkCondition: "Hyrocarbons",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 6992,
    hazzard: {
      id: 96,
      substanceWorkCondition: "Hyrocarbons",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 6993,
    hazzard: {
      id: 97,
      substanceWorkCondition: "Industry Heavy - Building Materials",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 6994,
    hazzard: {
      id: 97,
      substanceWorkCondition: "Industry Heavy - Building Materials",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 6995,
    hazzard: {
      id: 98,
      substanceWorkCondition: "Industry Heavy - Construction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 6996,
    hazzard: {
      id: 98,
      substanceWorkCondition: "Industry Heavy - Construction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 6997,
    hazzard: {
      id: 99,
      substanceWorkCondition: "Industry Heavy - Mining & Extraction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 6998,
    hazzard: {
      id: 99,
      substanceWorkCondition: "Industry Heavy - Mining & Extraction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 6999,
    hazzard: {
      id: 100,
      substanceWorkCondition: "Industry Heavy - Off-Shore Extraction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 7000,
    hazzard: {
      id: 100,
      substanceWorkCondition: "Industry Heavy - Off-Shore Extraction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 7001,
    hazzard: {
      id: 101,
      substanceWorkCondition: "Industry Heavy - Printing Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 7002,
    hazzard: {
      id: 101,
      substanceWorkCondition: "Industry Heavy - Printing Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 7003,
    hazzard: {
      id: 102,
      substanceWorkCondition:
        "Industry Heavy - Road & Transportation Construction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 7004,
    hazzard: {
      id: 102,
      substanceWorkCondition:
        "Industry Heavy - Road & Transportation Construction",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 7005,
    hazzard: {
      id: 103,
      substanceWorkCondition: "Industry Heavy - Ship & Boat Building",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 7006,
    hazzard: {
      id: 103,
      substanceWorkCondition: "Industry Heavy - Ship & Boat Building",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 7007,
    hazzard: {
      id: 104,
      substanceWorkCondition: "Industry Heavy - Shipping & Marine",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 7008,
    hazzard: {
      id: 104,
      substanceWorkCondition: "Industry Heavy - Shipping & Marine",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 7009,
    hazzard: {
      id: 105,
      substanceWorkCondition:
        "Industry Heavy - Steel, Engineering & Heavy Manufacturing",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 7010,
    hazzard: {
      id: 105,
      substanceWorkCondition:
        "Industry Heavy - Steel, Engineering & Heavy Manufacturing",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 7011,
    hazzard: {
      id: 106,
      substanceWorkCondition:
        "Industry Light - Airports & Transportation Terminals - Services & Facility Maintenance",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 7012,
    hazzard: {
      id: 106,
      substanceWorkCondition:
        "Industry Light - Airports & Transportation Terminals - Services & Facility Maintenance",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 7013,
    hazzard: {
      id: 106,
      substanceWorkCondition:
        "Industry Light - Airports & Transportation Terminals - Services & Facility Maintenance",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 7014,
    hazzard: {
      id: 107,
      substanceWorkCondition: "Industry Light - Emergency Services",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 7015,
    hazzard: {
      id: 107,
      substanceWorkCondition: "Industry Light - Emergency Services",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 7016,
    hazzard: {
      id: 107,
      substanceWorkCondition: "Industry Light - Emergency Services",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:33.000Z",
      updated_at: "2020-03-02T17:41:33.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 7017,
    hazzard: {
      id: 108,
      substanceWorkCondition:
        "Industry Light - General Manufacturing & Assembly",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 7018,
    hazzard: {
      id: 108,
      substanceWorkCondition:
        "Industry Light - General Manufacturing & Assembly",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 7019,
    hazzard: {
      id: 108,
      substanceWorkCondition:
        "Industry Light - General Manufacturing & Assembly",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 7020,
    hazzard: {
      id: 109,
      substanceWorkCondition:
        "Industry Light - Hosiery, Knitware, Fabric & Clothing Manufacturing",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 7021,
    hazzard: {
      id: 109,
      substanceWorkCondition:
        "Industry Light - Hosiery, Knitware, Fabric & Clothing Manufacturing",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 7022,
    hazzard: {
      id: 109,
      substanceWorkCondition:
        "Industry Light - Hosiery, Knitware, Fabric & Clothing Manufacturing",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 7023,
    hazzard: {
      id: 110,
      substanceWorkCondition: "Industry Light - Science & Laboratories",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 7024,
    hazzard: {
      id: 110,
      substanceWorkCondition: "Industry Light - Science & Laboratories",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 7025,
    hazzard: {
      id: 110,
      substanceWorkCondition: "Industry Light - Science & Laboratories",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 7026,
    hazzard: {
      id: 111,
      substanceWorkCondition: "Industry Light - Warehousing & Logistics",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 7027,
    hazzard: {
      id: 111,
      substanceWorkCondition: "Industry Light - Warehousing & Logistics",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 7028,
    hazzard: {
      id: 111,
      substanceWorkCondition: "Industry Light - Warehousing & Logistics",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 7029,
    hazzard: {
      id: 112,
      substanceWorkCondition: "Industry Medium - Ceramics & Glassware",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 7030,
    hazzard: {
      id: 112,
      substanceWorkCondition: "Industry Medium - Ceramics & Glassware",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 7031,
    hazzard: {
      id: 113,
      substanceWorkCondition: "Industry Medium - Chemical Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 7032,
    hazzard: {
      id: 113,
      substanceWorkCondition: "Industry Medium - Chemical Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 7033,
    hazzard: {
      id: 114,
      substanceWorkCondition: "Industry Medium - Electronics Industries",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 7034,
    hazzard: {
      id: 114,
      substanceWorkCondition: "Industry Medium - Electronics Industries",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 7035,
    hazzard: {
      id: 115,
      substanceWorkCondition: "Industry Medium - Metal Working & Fabrication",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 7036,
    hazzard: {
      id: 115,
      substanceWorkCondition: "Industry Medium - Metal Working & Fabrication",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 7037,
    hazzard: {
      id: 116,
      substanceWorkCondition: "Industry Medium - Outdoor Maintenance & Repair",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 7038,
    hazzard: {
      id: 116,
      substanceWorkCondition: "Industry Medium - Outdoor Maintenance & Repair",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 7039,
    hazzard: {
      id: 117,
      substanceWorkCondition: "Industry Medium - Pharmaceutical Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 7040,
    hazzard: {
      id: 117,
      substanceWorkCondition: "Industry Medium - Pharmaceutical Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 7041,
    hazzard: {
      id: 118,
      substanceWorkCondition: "Industry Medium - Plastics Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 7042,
    hazzard: {
      id: 118,
      substanceWorkCondition: "Industry Medium - Plastics Industry",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 7043,
    hazzard: {
      id: 119,
      substanceWorkCondition: "Ink / printing ink",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 7044,
    hazzard: {
      id: 119,
      substanceWorkCondition: "Ink / printing ink",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 7045,
    hazzard: {
      id: 119,
      substanceWorkCondition: "Ink / printing ink",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 7046,
    hazzard: {
      id: 119,
      substanceWorkCondition: "Ink / printing ink",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 7047,
    hazzard: {
      id: 120,
      substanceWorkCondition: "Insulating material",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 7048,
    hazzard: {
      id: 120,
      substanceWorkCondition: "Insulating material",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 7049,
    hazzard: {
      id: 120,
      substanceWorkCondition: "Insulating material",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 7050,
    hazzard: {
      id: 120,
      substanceWorkCondition: "Insulating material",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 7051,
    hazzard: {
      id: 121,
      substanceWorkCondition: "Kerosene",
      cas: null,
      glovesRecommended: true,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 7052,
    hazzard: {
      id: 121,
      substanceWorkCondition: "Kerosene",
      cas: null,
      glovesRecommended: true,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 7053,
    hazzard: {
      id: 121,
      substanceWorkCondition: "Kerosene",
      cas: null,
      glovesRecommended: true,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 7054,
    hazzard: {
      id: 121,
      substanceWorkCondition: "Kerosene",
      cas: null,
      glovesRecommended: true,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 7055,
    hazzard: {
      id: 122,
      substanceWorkCondition: "Ketone",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 7056,
    hazzard: {
      id: 122,
      substanceWorkCondition: "Ketone",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 7057,
    hazzard: {
      id: 122,
      substanceWorkCondition: "Ketone",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 7058,
    hazzard: {
      id: 122,
      substanceWorkCondition: "Ketone",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 7059,
    hazzard: {
      id: 123,
      substanceWorkCondition: "Lacquers (nitro/solvent-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 7060,
    hazzard: {
      id: 123,
      substanceWorkCondition: "Lacquers (nitro/solvent-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 7061,
    hazzard: {
      id: 123,
      substanceWorkCondition: "Lacquers (nitro/solvent-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 7062,
    hazzard: {
      id: 123,
      substanceWorkCondition: "Lacquers (nitro/solvent-based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 7063,
    hazzard: {
      id: 124,
      substanceWorkCondition: "Lacquers (PU based, synthetic resin based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 7064,
    hazzard: {
      id: 124,
      substanceWorkCondition: "Lacquers (PU based, synthetic resin based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 7065,
    hazzard: {
      id: 124,
      substanceWorkCondition: "Lacquers (PU based, synthetic resin based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 7066,
    hazzard: {
      id: 124,
      substanceWorkCondition: "Lacquers (PU based, synthetic resin based)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 7067,
    hazzard: {
      id: 125,
      substanceWorkCondition: "Lacquers (UV-hardening)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 7068,
    hazzard: {
      id: 125,
      substanceWorkCondition: "Lacquers (UV-hardening)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 7069,
    hazzard: {
      id: 125,
      substanceWorkCondition: "Lacquers (UV-hardening)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 7070,
    hazzard: {
      id: 125,
      substanceWorkCondition: "Lacquers (UV-hardening)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 7071,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 7072,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 7073,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 7074,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 7075,
    hazzard: {
      id: 127,
      substanceWorkCondition: "Latex paint",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 7076,
    hazzard: {
      id: 127,
      substanceWorkCondition: "Latex paint",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 7077,
    hazzard: {
      id: 127,
      substanceWorkCondition: "Latex paint",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 7078,
    hazzard: {
      id: 127,
      substanceWorkCondition: "Latex paint",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 7079,
    hazzard: {
      id: 128,
      substanceWorkCondition: "Lime",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 7080,
    hazzard: {
      id: 128,
      substanceWorkCondition: "Lime",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 7081,
    hazzard: {
      id: 128,
      substanceWorkCondition: "Lime",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 7082,
    hazzard: {
      id: 128,
      substanceWorkCondition: "Lime",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 7083,
    hazzard: {
      id: 129,
      substanceWorkCondition: "Lubricants, water-based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 7084,
    hazzard: {
      id: 129,
      substanceWorkCondition: "Lubricants, water-based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 7085,
    hazzard: {
      id: 129,
      substanceWorkCondition: "Lubricants, water-based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:20.000Z",
    updated_at: "2020-03-02T22:50:20.000Z"
  },
  {
    id: 7086,
    hazzard: {
      id: 129,
      substanceWorkCondition: "Lubricants, water-based",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7087,
    hazzard: {
      id: 130,
      substanceWorkCondition: "Metal dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7088,
    hazzard: {
      id: 130,
      substanceWorkCondition: "Metal dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7089,
    hazzard: {
      id: 130,
      substanceWorkCondition: "Metal dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7090,
    hazzard: {
      id: 130,
      substanceWorkCondition: "Metal dust",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7091,
    hazzard: {
      id: 131,
      substanceWorkCondition: "Methanol / Methyl Alcohol",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7092,
    hazzard: {
      id: 131,
      substanceWorkCondition: "Methanol / Methyl Alcohol",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7093,
    hazzard: {
      id: 131,
      substanceWorkCondition: "Methanol / Methyl Alcohol",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7094,
    hazzard: {
      id: 131,
      substanceWorkCondition: "Methanol / Methyl Alcohol",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7095,
    hazzard: {
      id: 132,
      substanceWorkCondition: "Mineral oil / paraffin oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7096,
    hazzard: {
      id: 132,
      substanceWorkCondition: "Mineral oil / paraffin oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7097,
    hazzard: {
      id: 132,
      substanceWorkCondition: "Mineral oil / paraffin oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7098,
    hazzard: {
      id: 132,
      substanceWorkCondition: "Mineral oil / paraffin oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7099,
    hazzard: {
      id: 132,
      substanceWorkCondition: "Mineral oil / paraffin oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7100,
    hazzard: {
      id: 132,
      substanceWorkCondition: "Mineral oil / paraffin oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7101,
    hazzard: {
      id: 132,
      substanceWorkCondition: "Mineral oil / paraffin oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7102,
    hazzard: {
      id: 132,
      substanceWorkCondition: "Mineral oil / paraffin oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7103,
    hazzard: {
      id: 133,
      substanceWorkCondition: "Multi component adhesive",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7104,
    hazzard: {
      id: 133,
      substanceWorkCondition: "Multi component adhesive",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7105,
    hazzard: {
      id: 133,
      substanceWorkCondition: "Multi component adhesive",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7106,
    hazzard: {
      id: 133,
      substanceWorkCondition: "Multi component adhesive",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7107,
    hazzard: {
      id: 134,
      substanceWorkCondition: "Multi component resin",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7108,
    hazzard: {
      id: 134,
      substanceWorkCondition: "Multi component resin",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7109,
    hazzard: {
      id: 134,
      substanceWorkCondition: "Multi component resin",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7110,
    hazzard: {
      id: 134,
      substanceWorkCondition: "Multi component resin",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7111,
    hazzard: {
      id: 135,
      substanceWorkCondition: "Naphthalene",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7112,
    hazzard: {
      id: 135,
      substanceWorkCondition: "Naphthalene",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7113,
    hazzard: {
      id: 135,
      substanceWorkCondition: "Naphthalene",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7114,
    hazzard: {
      id: 135,
      substanceWorkCondition: "Naphthalene",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7115,
    hazzard: {
      id: 136,
      substanceWorkCondition: "Oil coke (carbon)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7116,
    hazzard: {
      id: 136,
      substanceWorkCondition: "Oil coke (carbon)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7117,
    hazzard: {
      id: 136,
      substanceWorkCondition: "Oil coke (carbon)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7118,
    hazzard: {
      id: 136,
      substanceWorkCondition: "Oil coke (carbon)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7119,
    hazzard: {
      id: 137,
      substanceWorkCondition: "Oil-emulsions (water- mixed)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7120,
    hazzard: {
      id: 137,
      substanceWorkCondition: "Oil-emulsions (water- mixed)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7121,
    hazzard: {
      id: 137,
      substanceWorkCondition: "Oil-emulsions (water- mixed)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7122,
    hazzard: {
      id: 137,
      substanceWorkCondition: "Oil-emulsions (water- mixed)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7123,
    hazzard: {
      id: 138,
      substanceWorkCondition: "Oils / Oil based lubricants",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7124,
    hazzard: {
      id: 138,
      substanceWorkCondition: "Oils / Oil based lubricants",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7125,
    hazzard: {
      id: 138,
      substanceWorkCondition: "Oils / Oil based lubricants",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7126,
    hazzard: {
      id: 138,
      substanceWorkCondition: "Oils / Oil based lubricants",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7127,
    hazzard: {
      id: 140,
      substanceWorkCondition: "Paint Thinner ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7128,
    hazzard: {
      id: 140,
      substanceWorkCondition: "Paint Thinner ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7129,
    hazzard: {
      id: 140,
      substanceWorkCondition: "Paint Thinner ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7130,
    hazzard: {
      id: 140,
      substanceWorkCondition: "Paint Thinner ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7131,
    hazzard: {
      id: 139,
      substanceWorkCondition: "Paint",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7132,
    hazzard: {
      id: 139,
      substanceWorkCondition: "Paint",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7133,
    hazzard: {
      id: 139,
      substanceWorkCondition: "Paint",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7134,
    hazzard: {
      id: 139,
      substanceWorkCondition: "Paint",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7135,
    hazzard: {
      id: 141,
      substanceWorkCondition: "Pit water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7136,
    hazzard: {
      id: 141,
      substanceWorkCondition: "Pit water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7137,
    hazzard: {
      id: 141,
      substanceWorkCondition: "Pit water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7138,
    hazzard: {
      id: 141,
      substanceWorkCondition: "Pit water",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7139,
    hazzard: {
      id: 142,
      substanceWorkCondition: "Pitch",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7140,
    hazzard: {
      id: 142,
      substanceWorkCondition: "Pitch",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7141,
    hazzard: {
      id: 142,
      substanceWorkCondition: "Pitch",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7142,
    hazzard: {
      id: 142,
      substanceWorkCondition: "Pitch",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7143,
    hazzard: {
      id: 143,
      substanceWorkCondition: "Polishes (water- soluble)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7144,
    hazzard: {
      id: 143,
      substanceWorkCondition: "Polishes (water- soluble)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7145,
    hazzard: {
      id: 143,
      substanceWorkCondition: "Polishes (water- soluble)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7146,
    hazzard: {
      id: 143,
      substanceWorkCondition: "Polishes (water- soluble)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7147,
    hazzard: {
      id: 144,
      substanceWorkCondition: "Polyester / Polyester resin",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7148,
    hazzard: {
      id: 144,
      substanceWorkCondition: "Polyester / Polyester resin",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7149,
    hazzard: {
      id: 144,
      substanceWorkCondition: "Polyester / Polyester resin",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7150,
    hazzard: {
      id: 144,
      substanceWorkCondition: "Polyester / Polyester resin",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7151,
    hazzard: {
      id: 145,
      substanceWorkCondition: "Polyurethane",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7152,
    hazzard: {
      id: 145,
      substanceWorkCondition: "Polyurethane",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7153,
    hazzard: {
      id: 145,
      substanceWorkCondition: "Polyurethane",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7154,
    hazzard: {
      id: 145,
      substanceWorkCondition: "Polyurethane",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:41:34.000Z",
      updated_at: "2020-03-02T17:41:34.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7155,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7156,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7157,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7158,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7159,
    hazzard: {
      id: 148,
      substanceWorkCondition:
        "Propanols (propyl alcohols): n-Propanol, Isopropanol / 2-Propanol ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7160,
    hazzard: {
      id: 148,
      substanceWorkCondition:
        "Propanols (propyl alcohols): n-Propanol, Isopropanol / 2-Propanol ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7161,
    hazzard: {
      id: 148,
      substanceWorkCondition:
        "Propanols (propyl alcohols): n-Propanol, Isopropanol / 2-Propanol ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7162,
    hazzard: {
      id: 148,
      substanceWorkCondition:
        "Propanols (propyl alcohols): n-Propanol, Isopropanol / 2-Propanol ",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7163,
    hazzard: {
      id: 149,
      substanceWorkCondition: "release agent, parting agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7164,
    hazzard: {
      id: 149,
      substanceWorkCondition: "release agent, parting agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7165,
    hazzard: {
      id: 149,
      substanceWorkCondition: "release agent, parting agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7166,
    hazzard: {
      id: 149,
      substanceWorkCondition: "release agent, parting agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7167,
    hazzard: {
      id: 150,
      substanceWorkCondition: "Resins / urea resins",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7168,
    hazzard: {
      id: 150,
      substanceWorkCondition: "Resins / urea resins",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7169,
    hazzard: {
      id: 150,
      substanceWorkCondition: "Resins / urea resins",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7170,
    hazzard: {
      id: 150,
      substanceWorkCondition: "Resins / urea resins",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7171,
    hazzard: {
      id: 151,
      substanceWorkCondition: "Rock wool",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7172,
    hazzard: {
      id: 151,
      substanceWorkCondition: "Rock wool",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7173,
    hazzard: {
      id: 151,
      substanceWorkCondition: "Rock wool",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7174,
    hazzard: {
      id: 151,
      substanceWorkCondition: "Rock wool",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7175,
    hazzard: {
      id: 152,
      substanceWorkCondition: "Rubber",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7176,
    hazzard: {
      id: 152,
      substanceWorkCondition: "Rubber",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7177,
    hazzard: {
      id: 152,
      substanceWorkCondition: "Rubber",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7178,
    hazzard: {
      id: 152,
      substanceWorkCondition: "Rubber",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7179,
    hazzard: {
      id: 153,
      substanceWorkCondition: "Salts / Salt solution",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7180,
    hazzard: {
      id: 153,
      substanceWorkCondition: "Salts / Salt solution",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7181,
    hazzard: {
      id: 153,
      substanceWorkCondition: "Salts / Salt solution",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7182,
    hazzard: {
      id: 153,
      substanceWorkCondition: "Salts / Salt solution",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7183,
    hazzard: {
      id: 154,
      substanceWorkCondition: "Sealing compound",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7184,
    hazzard: {
      id: 154,
      substanceWorkCondition: "Sealing compound",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7185,
    hazzard: {
      id: 154,
      substanceWorkCondition: "Sealing compound",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7186,
    hazzard: {
      id: 154,
      substanceWorkCondition: "Sealing compound",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7187,
    hazzard: {
      id: 155,
      substanceWorkCondition: "Sintering",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7188,
    hazzard: {
      id: 155,
      substanceWorkCondition: "Sintering",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7189,
    hazzard: {
      id: 155,
      substanceWorkCondition: "Sintering",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7190,
    hazzard: {
      id: 155,
      substanceWorkCondition: "Sintering",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7191,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7192,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7193,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7194,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7195,
    hazzard: {
      id: 157,
      substanceWorkCondition: "Solvents, - organic",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7196,
    hazzard: {
      id: 157,
      substanceWorkCondition: "Solvents, - organic",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7197,
    hazzard: {
      id: 157,
      substanceWorkCondition: "Solvents, - organic",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7198,
    hazzard: {
      id: 157,
      substanceWorkCondition: "Solvents, - organic",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7199,
    hazzard: {
      id: 158,
      substanceWorkCondition: "Soot",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7200,
    hazzard: {
      id: 158,
      substanceWorkCondition: "Soot",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7201,
    hazzard: {
      id: 158,
      substanceWorkCondition: "Soot",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7202,
    hazzard: {
      id: 158,
      substanceWorkCondition: "Soot",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7203,
    hazzard: {
      id: 159,
      substanceWorkCondition: "Styrene",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7204,
    hazzard: {
      id: 159,
      substanceWorkCondition: "Styrene",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7205,
    hazzard: {
      id: 159,
      substanceWorkCondition: "Styrene",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7206,
    hazzard: {
      id: 159,
      substanceWorkCondition: "Styrene",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7207,
    hazzard: {
      id: 160,
      substanceWorkCondition: "Surface Cleanser (surfactant based), Detergents",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7208,
    hazzard: {
      id: 160,
      substanceWorkCondition: "Surface Cleanser (surfactant based), Detergents",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7209,
    hazzard: {
      id: 160,
      substanceWorkCondition: "Surface Cleanser (surfactant based), Detergents",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7210,
    hazzard: {
      id: 160,
      substanceWorkCondition: "Surface Cleanser (surfactant based), Detergents",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7211,
    hazzard: {
      id: 161,
      substanceWorkCondition: "Synthetic melting glaze",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7212,
    hazzard: {
      id: 161,
      substanceWorkCondition: "Synthetic melting glaze",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7213,
    hazzard: {
      id: 161,
      substanceWorkCondition: "Synthetic melting glaze",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7214,
    hazzard: {
      id: 161,
      substanceWorkCondition: "Synthetic melting glaze",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7215,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:21.000Z",
    updated_at: "2020-03-02T22:50:21.000Z"
  },
  {
    id: 7216,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:22.000Z",
    updated_at: "2020-03-02T22:50:22.000Z"
  },
  {
    id: 7217,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:22.000Z",
    updated_at: "2020-03-02T22:50:22.000Z"
  },
  {
    id: 7218,
    hazzard: {
      id: 998,
      substanceWorkCondition: "Unknown",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T22:40:38.000Z",
      updated_at: "2020-03-02T22:40:38.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:22.000Z",
    updated_at: "2020-03-02T22:50:22.000Z"
  },
  {
    id: 7219,
    hazzard: {
      id: 163,
      substanceWorkCondition: "Tanning agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:22.000Z",
    updated_at: "2020-03-02T22:50:22.000Z"
  },
  {
    id: 7220,
    hazzard: {
      id: 163,
      substanceWorkCondition: "Tanning agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:22.000Z",
    updated_at: "2020-03-02T22:50:22.000Z"
  },
  {
    id: 7221,
    hazzard: {
      id: 163,
      substanceWorkCondition: "Tanning agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:22.000Z",
    updated_at: "2020-03-02T22:50:22.000Z"
  },
  {
    id: 7222,
    hazzard: {
      id: 163,
      substanceWorkCondition: "Tanning agent",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:22.000Z",
    updated_at: "2020-03-02T22:50:22.000Z"
  },
  {
    id: 7223,
    hazzard: {
      id: 164,
      substanceWorkCondition: "Tar",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:22.000Z",
    updated_at: "2020-03-02T22:50:22.000Z"
  },
  {
    id: 7224,
    hazzard: {
      id: 164,
      substanceWorkCondition: "Tar",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:22.000Z",
    updated_at: "2020-03-02T22:50:22.000Z"
  },
  {
    id: 7225,
    hazzard: {
      id: 164,
      substanceWorkCondition: "Tar",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:22.000Z",
    updated_at: "2020-03-02T22:50:22.000Z"
  },
  {
    id: 7226,
    hazzard: {
      id: 164,
      substanceWorkCondition: "Tar",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:22.000Z",
    updated_at: "2020-03-02T22:50:22.000Z"
  },
  {
    id: 7227,
    hazzard: {
      id: 166,
      substanceWorkCondition: "Thinner",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:22.000Z",
    updated_at: "2020-03-02T22:50:22.000Z"
  },
  {
    id: 7228,
    hazzard: {
      id: 166,
      substanceWorkCondition: "Thinner",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:22.000Z",
    updated_at: "2020-03-02T22:50:22.000Z"
  },
  {
    id: 7229,
    hazzard: {
      id: 166,
      substanceWorkCondition: "Thinner",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:22.000Z",
    updated_at: "2020-03-02T22:50:22.000Z"
  },
  {
    id: 7230,
    hazzard: {
      id: 166,
      substanceWorkCondition: "Thinner",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:22.000Z",
    updated_at: "2020-03-02T22:50:22.000Z"
  },
  {
    id: 7231,
    hazzard: {
      id: 167,
      substanceWorkCondition: "Toluene",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:22.000Z",
    updated_at: "2020-03-02T22:50:22.000Z"
  },
  {
    id: 7232,
    hazzard: {
      id: 167,
      substanceWorkCondition: "Toluene",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:22.000Z",
    updated_at: "2020-03-02T22:50:22.000Z"
  },
  {
    id: 7233,
    hazzard: {
      id: 167,
      substanceWorkCondition: "Toluene",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:22.000Z",
    updated_at: "2020-03-02T22:50:22.000Z"
  },
  {
    id: 7234,
    hazzard: {
      id: 167,
      substanceWorkCondition: "Toluene",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: true,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:22.000Z",
    updated_at: "2020-03-02T22:50:22.000Z"
  },
  {
    id: 7235,
    hazzard: {
      id: 168,
      substanceWorkCondition: "Turpentine oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:22.000Z",
    updated_at: "2020-03-02T22:50:22.000Z"
  },
  {
    id: 7236,
    hazzard: {
      id: 168,
      substanceWorkCondition: "Turpentine oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:22.000Z",
    updated_at: "2020-03-02T22:50:22.000Z"
  },
  {
    id: 7237,
    hazzard: {
      id: 168,
      substanceWorkCondition: "Turpentine oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:22.000Z",
    updated_at: "2020-03-02T22:50:22.000Z"
  },
  {
    id: 7238,
    hazzard: {
      id: 168,
      substanceWorkCondition: "Turpentine oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:22.000Z",
    updated_at: "2020-03-02T22:50:22.000Z"
  },
  {
    id: 7239,
    hazzard: {
      id: 169,
      substanceWorkCondition: "UV-rays (A/B) / Sunlight",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:22.000Z",
    updated_at: "2020-03-02T22:50:22.000Z"
  },
  {
    id: 7240,
    hazzard: {
      id: 169,
      substanceWorkCondition: "UV-rays (A/B) / Sunlight",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:22.000Z",
    updated_at: "2020-03-02T22:50:22.000Z"
  },
  {
    id: 7241,
    hazzard: {
      id: 170,
      substanceWorkCondition: "Washing bath, washing liquid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:22.000Z",
    updated_at: "2020-03-02T22:50:22.000Z"
  },
  {
    id: 7242,
    hazzard: {
      id: 170,
      substanceWorkCondition: "Washing bath, washing liquid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:22.000Z",
    updated_at: "2020-03-02T22:50:22.000Z"
  },
  {
    id: 7243,
    hazzard: {
      id: 170,
      substanceWorkCondition: "Washing bath, washing liquid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:22.000Z",
    updated_at: "2020-03-02T22:50:22.000Z"
  },
  {
    id: 7244,
    hazzard: {
      id: 170,
      substanceWorkCondition: "Washing bath, washing liquid",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:22.000Z",
    updated_at: "2020-03-02T22:50:22.000Z"
  },
  {
    id: 7245,
    hazzard: {
      id: 171,
      substanceWorkCondition: "Waste oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:22.000Z",
    updated_at: "2020-03-02T22:50:22.000Z"
  },
  {
    id: 7246,
    hazzard: {
      id: 171,
      substanceWorkCondition: "Waste oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:22.000Z",
    updated_at: "2020-03-02T22:50:22.000Z"
  },
  {
    id: 7247,
    hazzard: {
      id: 171,
      substanceWorkCondition: "Waste oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:22.000Z",
    updated_at: "2020-03-02T22:50:22.000Z"
  },
  {
    id: 7248,
    hazzard: {
      id: 171,
      substanceWorkCondition: "Waste oil",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:22.000Z",
    updated_at: "2020-03-02T22:50:22.000Z"
  },
  {
    id: 7249,
    hazzard: {
      id: 172,
      substanceWorkCondition: "Water / wet work",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:22.000Z",
    updated_at: "2020-03-02T22:50:22.000Z"
  },
  {
    id: 7250,
    hazzard: {
      id: 172,
      substanceWorkCondition: "Water / wet work",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:22.000Z",
    updated_at: "2020-03-02T22:50:22.000Z"
  },
  {
    id: 7251,
    hazzard: {
      id: 172,
      substanceWorkCondition: "Water / wet work",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:22.000Z",
    updated_at: "2020-03-02T22:50:22.000Z"
  },
  {
    id: 7252,
    hazzard: {
      id: 172,
      substanceWorkCondition: "Water / wet work",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:22.000Z",
    updated_at: "2020-03-02T22:50:22.000Z"
  },
  {
    id: 7253,
    hazzard: {
      id: 173,
      substanceWorkCondition:
        "Wearing protective clothing / Softening of the skin (Maceration)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:22.000Z",
    updated_at: "2020-03-02T22:50:22.000Z"
  },
  {
    id: 7254,
    hazzard: {
      id: 173,
      substanceWorkCondition:
        "Wearing protective clothing / Softening of the skin (Maceration)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:22.000Z",
    updated_at: "2020-03-02T22:50:22.000Z"
  },
  {
    id: 7255,
    hazzard: {
      id: 173,
      substanceWorkCondition:
        "Wearing protective clothing / Softening of the skin (Maceration)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:22.000Z",
    updated_at: "2020-03-02T22:50:22.000Z"
  },
  {
    id: 7256,
    hazzard: {
      id: 173,
      substanceWorkCondition:
        "Wearing protective clothing / Softening of the skin (Maceration)",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:22.000Z",
    updated_at: "2020-03-02T22:50:22.000Z"
  },
  {
    id: 7257,
    hazzard: {
      id: 174,
      substanceWorkCondition: "wire drawing oil / wire drawing grease",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:22.000Z",
    updated_at: "2020-03-02T22:50:22.000Z"
  },
  {
    id: 7258,
    hazzard: {
      id: 174,
      substanceWorkCondition: "wire drawing oil / wire drawing grease",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:22.000Z",
    updated_at: "2020-03-02T22:50:22.000Z"
  },
  {
    id: 7259,
    hazzard: {
      id: 174,
      substanceWorkCondition: "wire drawing oil / wire drawing grease",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:22.000Z",
    updated_at: "2020-03-02T22:50:22.000Z"
  },
  {
    id: 7260,
    hazzard: {
      id: 174,
      substanceWorkCondition: "wire drawing oil / wire drawing grease",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:22.000Z",
    updated_at: "2020-03-02T22:50:22.000Z"
  },
  {
    id: 7261,
    hazzard: {
      id: 175,
      substanceWorkCondition: "Xylene",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 1,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:22.000Z",
    updated_at: "2020-03-02T22:50:22.000Z"
  },
  {
    id: 7262,
    hazzard: {
      id: 175,
      substanceWorkCondition: "Xylene",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 1,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:22.000Z",
    updated_at: "2020-03-02T22:50:22.000Z"
  },
  {
    id: 7263,
    hazzard: {
      id: 175,
      substanceWorkCondition: "Xylene",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 1,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:22.000Z",
    updated_at: "2020-03-02T22:50:22.000Z"
  },
  {
    id: 7264,
    hazzard: {
      id: 175,
      substanceWorkCondition: "Xylene",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:43:41.000Z",
      updated_at: "2020-03-02T17:43:41.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 1,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:22.000Z",
    updated_at: "2020-03-02T22:50:22.000Z"
  },
  {
    id: 7265,
    hazzard: {
      id: 999,
      substanceWorkCondition: "All Products",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:44:03.000Z",
      updated_at: "2020-03-02T17:44:03.000Z"
    },
    step: "Restore",
    productOrder: 1,
    locationOrder: 3,
    skin_care_product: {
      id: 42,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:50:16.000Z",
      productName: "Stokolan® Light PURE (USDA)",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-pure"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: false,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:22.000Z",
    updated_at: "2020-03-02T22:50:22.000Z"
  },
  {
    id: 7266,
    hazzard: {
      id: 999,
      substanceWorkCondition: "All Products",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:44:03.000Z",
      updated_at: "2020-03-02T17:44:03.000Z"
    },
    step: "Restore",
    productOrder: 2,
    locationOrder: 3,
    skin_care_product: {
      id: 28,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:40:19.000Z",
      productName: "SBS® 40 (USDA)",
      productURL:
        "https://www.debgroup.com/us/products/sbs-40-medicated-skin-cream"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: true,
    ecoLogo: false,
    ecarf: false,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: true,
    environmentalCert: true,
    created_at: "2020-03-02T22:50:22.000Z",
    updated_at: "2020-03-02T22:50:22.000Z"
  },
  {
    id: 7267,
    hazzard: {
      id: 999,
      substanceWorkCondition: "All Products",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:44:03.000Z",
      updated_at: "2020-03-02T17:44:03.000Z"
    },
    step: "Restore",
    productOrder: 3,
    locationOrder: 3,
    skin_care_product: {
      id: 41,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:49:43.000Z",
      productName: "Stokolan® Light GEL",
      productURL: "https://www.debgroup.com/us/products/stokolan-light-gel"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:22.000Z",
    updated_at: "2020-03-02T22:50:22.000Z"
  },
  {
    id: 7268,
    hazzard: {
      id: 999,
      substanceWorkCondition: "All Products",
      cas: null,
      glovesRecommended: false,
      shortTermAccidental: false,
      searchKeywords: null,
      created_at: "2020-03-02T17:44:03.000Z",
      updated_at: "2020-03-02T17:44:03.000Z"
    },
    step: "Restore",
    productOrder: 4,
    locationOrder: 4,
    skin_care_product: {
      id: 40,
      created_at: "2020-03-02T20:33:12.000Z",
      updated_at: "2020-03-10T03:48:31.000Z",
      productName: "Stokolan® Hand & Body",
      productURL: "https://www.debgroup.com/us/products/stokolan-hand-body"
    },
    rating: 1,
    location: "Hands",
    level1: true,
    level2: false,
    level3: false,
    preservative: true,
    perfume: true,
    dye: false,
    silicone: false,
    usdaCertified: false,
    ecoLogo: false,
    ecarf: true,
    nsf: false,
    greenSealCertified: false,
    outdoorNoWaterAvail: true,
    alcoholFree: true,
    face: true,
    body: true,
    hair: false,
    glove: true,
    individual: true,
    dispenser: false,
    environmentalCert: false,
    created_at: "2020-03-02T22:50:22.000Z",
    updated_at: "2020-03-02T22:50:22.000Z"
  }
];

export default SCJdata;
