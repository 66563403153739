import React, { Component } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getUser, changing } from "../redux/clientConfiguration/action";
import Footer from "../components/footer";
import Banner from "../components/banner";
import Table from "../components/table";
let hello = "";

let obj = {};
let lengthObj = 0;

function autocomplete(inp, arr) {
  /*the autocomplete function takes two arguments,
  the text field element and an array of possible autocompleted values:*/
  var currentFocus;
  /*execute a function when someone writes in the text field:*/
  inp.addEventListener("input", function (e) {
    var a,
      b,
      i,
      val = this.value;
    /*close any already open lists of autocompleted values*/
    closeAllLists();
    if (!val) {
      return false;
    }
    currentFocus = -1;
    /*create a DIV element that will contain the items (values):*/
    a = document.createElement("DIV");
    a.setAttribute("id", this.id + "autocomplete-list");
    a.setAttribute("class", "autocomplete-items");
    /*append the DIV element as a child of the autocomplete container:*/
    this.parentNode.appendChild(a);
    /*for each item in the array...*/
    for (i = 0; i < arr.length; i++) {
      /*check if the item starts with the same letters as the text field value:*/
      if (arr[i].toUpperCase().includes(val.toUpperCase())) {
        /*create a DIV element for each matching element:*/
        b = document.createElement("DIV");
        /*make the matching letters bold:*/
        //b.innerHTML = "<strong>" + arr[i].substr(0, val.length) + "</strong>";
        b.innerHTML += arr[i];
        /*insert a input field that will hold the current array item's value:*/
        b.innerHTML += "<input type='hidden' value='" + arr[i] + "'>";
        /*execute a function when someone clicks on the item value (DIV element):*/
        b.addEventListener("click", function (e) {
          /*insert the value for the autocomplete text field:*/
          inp.value = this.getElementsByTagName("input")[0].value;
          document.getElementById("clicky").click();

          /*close the list of autocompleted values,
              (or any other open lists of autocompleted values:*/
          closeAllLists();
        });
        a.appendChild(b);
      }
    }
  });
  /*execute a function presses a key on the keyboard:*/
  inp.addEventListener("keydown", function (e) {
    var x = document.getElementById(this.id + "autocomplete-list");
    if (x) x = x.getElementsByTagName("div");
    if (e.keyCode == 40) {
      /*If the arrow DOWN key is pressed,
        increase the currentFocus variable:*/
      currentFocus++;
      /*and and make the current item more visible:*/
      addActive(x);
    } else if (e.keyCode == 38) {
      //up
      /*If the arrow UP key is pressed,
        decrease the currentFocus variable:*/
      currentFocus--;
      /*and and make the current item more visible:*/
      addActive(x);
    } else if (e.keyCode == 13) {
      /*If the ENTER key is pressed, prevent the form from being submitted,*/
      e.preventDefault();
      if (currentFocus > -1) {
        /*and simulate a click on the "active" item:*/
        if (x) x[currentFocus].click();
      }
    }
  });
  function addActive(x) {
    /*a function to classify an item as "active":*/
    if (!x) return false;
    /*start by removing the "active" class on all items:*/
    removeActive(x);
    if (currentFocus >= x.length) currentFocus = 0;
    if (currentFocus < 0) currentFocus = x.length - 1;
    /*add class "autocomplete-active":*/
    x[currentFocus].classList.add("autocomplete-active");
  }
  function removeActive(x) {
    /*a function to remove the "active" class from all autocomplete items:*/
    for (var i = 0; i < x.length; i++) {
      x[i].classList.remove("autocomplete-active");
    }
  }
  function closeAllLists(elmnt) {
    /*close all autocomplete lists in the document,
    except the one passed as an argument:*/
    var x = document.getElementsByClassName("autocomplete-items");
    for (var i = 0; i < x.length; i++) {
      if (elmnt != x[i] && elmnt != inp) {
        x[i].parentNode.removeChild(x[i]);
      }
    }
  }
  /*execute a function when someone clicks in the document:*/
  document.addEventListener("click", function (e) {
    closeAllLists(e.target);
  });
}

export function filter(a) {
  obj = {};
  for (let [key, value] of Object.entries(a)) {
    if (value) {
      obj[key] = value;
    }
  }
  lengthObj = Object.keys(obj).length;
  console.log("objLength: ", lengthObj);
}

// constructor(props) {
//   super(props);
//   this.state = { e: 0 };
// }

class Format extends Component {
  componentDidMount() {
    this.props.getUser();
  }

  active(a, b) {
    //if
    //this.props.getUser(queries);
  }

  render() {
    const { data, selected, substance } = this.props || {};
    let arrCon = [];
    return (
      <div>
        <Banner />
        <div className="container padding-bottom-3x mb-2">
          <Formik
            initialValues={{
              hands: true,
              inputtext: "",
              q12: true,
              q13: true,
              q14: true,
              q21: "",
              q22: "",
              q31: "",
              q32: "",
              q33: true,
              q41: "",
              q42: "",
              q43: "",
              q51: "",
              q52: "",
              q53: "",
              q54: "",
              q55: "",
              q56: "",
              q57: "",
              q58: "",
              textinput: "",
            }}
            onSubmit={async (values) => {
              await new Promise((resolve) => setTimeout(resolve, 500));
              alert(JSON.stringify(values, null, 2));
            }}
            validationSchema={Yup.object().shape({
              //city: Yup.string().required("Required")
            })}
          >
            {(props) => {
              const {
                values,
                touched,
                errors,
                dirty,
                isSubmitting,
                handleChange,
                handleBlur,
                handleSubmit,
                handleReset,
                setFieldValue,
              } = props;

              return (
                <Form onSubmit={handleSubmit}>
                  <div className="container padding-bottom-3x mb-2">
                    <div className="row justify-content-center">
                      <div className="col-xl-12 col-lg-12">
                        <div className="row mb-30">
                          <div className="col-sm-12 text-center">
                            <h3>
                              Select from the options and/or enter keywords
                              describing your application, to find compatible
                              products.
                            </h3>
                          </div>
                        </div>
                        <div className="row">
                          <div
                            className="col-sm-4"
                            style={{ borderRight: "1px solid #ddd" }}
                          >
                            <div
                              className="form-group autocomplete"
                              style={{ width: "300px" }}
                            >
                              <label for="payroc-first-name">
                                Application / Area / Specific Hazard&nbsp;
                                <i
                                  className="pe-7s-help1"
                                  data-container="body"
                                  data-toggle="popover"
                                  data-placement="top"
                                  data-trigger="hover"
                                  title=""
                                  data-content="Indicate the specific type of issue you're addressing."
                                  data-original-title=""
                                />
                              </label>
                              <div className="input-group form-group">
                                <span className="input-group-btn">
                                  <button
                                    type="button"
                                    id="clicky"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      document
                                        .getElementById("textinput")
                                        .focus();
                                      this.props.changing(
                                        document.getElementById("textinput")
                                          .value,
                                        "textinput"
                                      );
                                    }}
                                  >
                                    <i
                                      className="pe-7s-search"
                                      style={{
                                        fontsize: "26px",
                                        paddingTop: "9px",
                                      }}
                                    />
                                  </button>
                                </span>
                                <input
                                  style={{ borderRadius: "0px" }}
                                  className="form-control"
                                  id="textinput"
                                  type="text"
                                  placeholder="Search for item or issue"
                                  onChange={(e) => {
                                    arrCon = [];
                                    handleChange(e);
                                    this.props.changing(
                                      e.currentTarget.value,
                                      "textinput"
                                    );
                                    autocomplete(
                                      document.getElementById("textinput"),
                                      substance
                                    );
                                  }}
                                  onClick={(e) => {
                                    this.props.changing(
                                      e.currentTarget.value,
                                      "textinput"
                                    );
                                  }}
                                  onBlur={handleBlur}
                                />
                              </div>
                              {values.textinput !== "" ? (
                                <div>
                                  <label for="checkout-fn">
                                    Exposure / Skin Stress:
                                  </label>
                                  <br />
                                  <div className="custom-control custom-checkbox ">
                                    <input
                                      className="custom-control-input"
                                      type="checkbox"
                                      id="q41"
                                      value={values.q41}
                                      onChange={(e) => {
                                        arrCon = [];
                                        handleChange(e);
                                        this.props.changing(
                                          !values.q41,
                                          "level1"
                                        );
                                      }}
                                      onBlur={handleBlur}
                                    />
                                    <label
                                      className="custom-control-label"
                                      for="q41"
                                    >
                                      Light
                                    </label>
                                  </div>
                                  <div className="custom-control custom-checkbox ">
                                    <input
                                      className="custom-control-input"
                                      type="checkbox"
                                      id="q42"
                                      value={values.q42}
                                      onChange={(e) => {
                                        arrCon = [];
                                        handleChange(e);
                                        this.props.changing(
                                          !values.q42,
                                          "level2"
                                        );
                                      }}
                                      onBlur={handleBlur}
                                    />
                                    <label
                                      className="custom-control-label"
                                      for="q42"
                                    >
                                      Medium
                                    </label>
                                  </div>
                                  <div className="custom-control custom-checkbox ">
                                    <input
                                      className="custom-control-input"
                                      type="checkbox"
                                      id="q43"
                                      value={values.q43}
                                      onChange={(e) => {
                                        arrCon = [];
                                        handleChange(e);
                                        this.props.changing(
                                          !values.q43,
                                          "level3"
                                        );
                                      }}
                                      onBlur={handleBlur}
                                    />
                                    <label
                                      className="custom-control-label"
                                      for="q43"
                                    >
                                      Extreme
                                    </label>
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-sm-8">
                            <div className="row">
                              <div className="col-sm-4">
                                <div className="form-group">
                                  <label for="checkout-fn">
                                    List products for use:
                                  </label>
                                  <br />
                                  <div className="custom-control custom-checkbox ">
                                    <input
                                      className="custom-control-input"
                                      type="checkbox"
                                      id="hands"
                                      checked={values.hands}
                                      value={values.hands}
                                      onChange={(e) => {
                                        arrCon = [];
                                        handleChange(e);
                                        this.props.changing(
                                          !values.hands,
                                          "hands"
                                        );
                                      }}
                                      onBlur={handleBlur}
                                    />
                                    <label
                                      className="custom-control-label"
                                      for="hands"
                                    >
                                      on Hands
                                    </label>
                                  </div>
                                  <div className="custom-control custom-checkbox ">
                                    <input
                                      checked={values.q12}
                                      className="custom-control-input"
                                      type="checkbox"
                                      id="q12"
                                      value={values.q12}
                                      onChange={(e) => {
                                        arrCon = [];
                                        handleChange(e);
                                        this.props.changing(
                                          !values.q12,
                                          "face"
                                        );
                                      }}
                                      onBlur={handleBlur}
                                    />
                                    <label
                                      className="custom-control-label"
                                      for="q12"
                                    >
                                      on Face
                                    </label>
                                  </div>
                                  <div className="custom-control custom-checkbox ">
                                    <input
                                      className="custom-control-input"
                                      checked={values.q13}
                                      type="checkbox"
                                      id="q13"
                                      value={values.q13}
                                      onChange={(e) => {
                                        arrCon = [];
                                        handleChange(e);
                                        this.props.changing(
                                          !values.q13,
                                          "body"
                                        );
                                      }}
                                      onBlur={handleBlur}
                                    />
                                    <label
                                      className="custom-control-label"
                                      for="q13"
                                    >
                                      on Body
                                    </label>
                                  </div>
                                  <div className="custom-control custom-checkbox ">
                                    <input
                                      className="custom-control-input"
                                      type="checkbox"
                                      checked={values.q14}
                                      id="q14"
                                      value={values.q14}
                                      onChange={(e) => {
                                        arrCon = [];
                                        handleChange(e);
                                        this.props.changing(
                                          !values.q14,
                                          "glove"
                                        );
                                      }}
                                      onBlur={handleBlur}
                                    />
                                    <label
                                      className="custom-control-label"
                                      for="q14"
                                    >
                                      Glove compatibility
                                    </label>
                                  </div>

                                  {/* <div className="custom-control custom-checkbox ">
                                    <input
                                      className="custom-control-input"
                                      type="checkbox"
                                      id="q22"
                                      value={values.q22}
                                      onChange={e => {
                                        handleChange(e);
                                        this.props.changing(
                                          !values.q22,
                                          "glove"
                                        );
                                      }}
                                      onBlur={handleBlur}
                                    />
                                    <label
                                      className="custom-control-label"
                                      for="q22"
                                    >
                                      Gloves worn intermittently
                                    </label>
                                  </div> */}
                                </div>
                              </div>
                              <div className="col-sm-4">
                                <div className="form-group">
                                  <label for="checkout-fn">
                                    Mark Dispensing Solutions:
                                  </label>
                                  <br />
                                  <div className="custom-control custom-checkbox ">
                                    <input
                                      className="custom-control-input"
                                      type="checkbox"
                                      id="q31"
                                      value={values.q31}
                                      onChange={(e) => {
                                        arrCon = [];
                                        handleChange(e);
                                        this.props.changing(
                                          !values.q31,
                                          "dispenser"
                                        );
                                      }}
                                      onBlur={handleBlur}
                                    />
                                    <label
                                      className="custom-control-label"
                                      for="q31"
                                    >
                                      Cartridge format for dispenser
                                    </label>
                                  </div>
                                  <div className="custom-control custom-checkbox ">
                                    <input
                                      className="custom-control-input"
                                      type="checkbox"
                                      id="q32"
                                      value={values.q32}
                                      onChange={(e) => {
                                        arrCon = [];
                                        handleChange(e);
                                        this.props.changing(
                                          !values.q32,
                                          "individual"
                                        );
                                      }}
                                      onBlur={handleBlur}
                                    />
                                    <label
                                      className="custom-control-label"
                                      for="q32"
                                    >
                                      Individual package
                                    </label>
                                  </div>
                                  {/* <div className="custom-control custom-checkbox ">
                                    <input
                                      className="custom-control-input"
                                      type="checkbox"
                                      id="q33"
                                      checked = {values.q33}
                                      value={values.q33}
                                      onChange={e => {
                                        handleChange(e);
                                        this.props.changing(!values.q33, "");
                                      }}
                                      onBlur={handleBlur}
                                    />
                                    <label
                                      className="custom-control-label"
                                      for="q33"
                                    >
                                      All packaging types
                                    </label>
                                  </div> */}
                                  <label for="checkout-fn">
                                    Special Conditions:
                                  </label>
                                  <br />
                                  <div className="custom-control custom-checkbox ">
                                    <input
                                      className="custom-control-input"
                                      type="checkbox"
                                      id="q21"
                                      value={values.q21}
                                      onChange={(e) => {
                                        arrCon = [];
                                        handleChange(e);
                                        this.props.changing(
                                          !values.q21,
                                          "outdoorNoWaterAvail"
                                        );
                                      }}
                                      onBlur={handleBlur}
                                    />
                                    <label
                                      className="custom-control-label"
                                      for="q21"
                                    >
                                      No water available
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-4">
                                <div className="form-group">
                                  <label for="checkout-fn">Preferences:</label>
                                  <br />
                                  <div className="custom-control custom-checkbox ">
                                    <input
                                      className="custom-control-input"
                                      type="checkbox"
                                      id="q51"
                                      value={values.q51}
                                      onChange={(e) => {
                                        arrCon = [];
                                        handleChange(e);
                                        this.props.changing(
                                          !values.q51,
                                          "perfume"
                                        );
                                      }}
                                      onBlur={handleBlur}
                                    />
                                    <label
                                      className="custom-control-label"
                                      for="q51"
                                    >
                                      Perfume-free
                                    </label>
                                  </div>
                                  <div className="custom-control custom-checkbox ">
                                    <input
                                      className="custom-control-input"
                                      type="checkbox"
                                      id="q52"
                                      value={values.q52}
                                      onChange={(e) => {
                                        arrCon = [];
                                        handleChange(e);
                                        this.props.changing(!values.q52, "dye");
                                      }}
                                      onBlur={handleBlur}
                                    />
                                    <label
                                      className="custom-control-label"
                                      for="q52"
                                    >
                                      Dye-free
                                    </label>
                                  </div>
                                  <div className="custom-control custom-checkbox ">
                                    <input
                                      className="custom-control-input"
                                      type="checkbox"
                                      id="q53"
                                      value={values.q53}
                                      onChange={(e) => {
                                        arrCon = [];
                                        handleChange(e);
                                        this.props.changing(
                                          !values.q53,
                                          "preservative"
                                        );
                                      }}
                                      onBlur={handleBlur}
                                    />
                                    <label
                                      className="custom-control-label"
                                      for="q53"
                                    >
                                      Preservative-free
                                    </label>
                                  </div>
                                  <div className="custom-control custom-checkbox ">
                                    <input
                                      className="custom-control-input"
                                      type="checkbox"
                                      id="q54"
                                      value={values.q54}
                                      onChange={(e) => {
                                        arrCon = [];
                                        handleChange(e);
                                        this.props.changing(
                                          !values.q54,
                                          "environmentalCert"
                                        );
                                      }}
                                      onBlur={handleBlur}
                                    />
                                    <label
                                      className="custom-control-label"
                                      for="q54"
                                    >
                                      Environmental Certs
                                    </label>
                                  </div>
                                  <div className="custom-control custom-checkbox ">
                                    <input
                                      className="custom-control-input"
                                      type="checkbox"
                                      id="q55"
                                      value={values.q55}
                                      onChange={(e) => {
                                        arrCon = [];
                                        handleChange(e);
                                        this.props.changing(
                                          !values.q55,
                                          "ecarf"
                                        );
                                      }}
                                      onBlur={handleBlur}
                                    />
                                    <label
                                      className="custom-control-label"
                                      for="q55"
                                    >
                                      ECARF-Label
                                    </label>
                                  </div>
                                  <div className="custom-control custom-checkbox ">
                                    <input
                                      className="custom-control-input"
                                      type="checkbox"
                                      id="q56"
                                      value={values.q56}
                                      onChange={(e) => {
                                        arrCon = [];
                                        handleChange(e);
                                        this.props.changing(!values.q56, "nsf");
                                      }}
                                      onBlur={handleBlur}
                                    />
                                    <label
                                      className="custom-control-label"
                                      for="q56"
                                    >
                                      NSF
                                    </label>
                                  </div>
                                  <div className="custom-control custom-checkbox ">
                                    <input
                                      className="custom-control-input"
                                      type="checkbox"
                                      id="q57"
                                      value={values.q57}
                                      onChange={(e) => {
                                        arrCon = [];
                                        handleChange(e);
                                        this.props.changing(
                                          !values.q57,
                                          "silicone"
                                        );
                                      }}
                                      onBlur={handleBlur}
                                    />
                                    <label
                                      className="custom-control-label"
                                      for="q57"
                                    >
                                      Silicone-free
                                    </label>
                                  </div>
                                  <div className="custom-control custom-checkbox ">
                                    <input
                                      className="custom-control-input"
                                      type="checkbox"
                                      id="q58"
                                      value={values.q58}
                                      onChange={(e) => {
                                        arrCon = [];
                                        handleChange(e);
                                        this.props.changing(
                                          !values.q58,
                                          "alcoholFree"
                                        );
                                      }}
                                      onBlur={handleBlur}
                                    />
                                    <label
                                      className="custom-control-label"
                                      for="q58"
                                    >
                                      Solvent-free/Alcohol-free
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <Table
                          filters={obj}
                          lengthObj={lengthObj}
                          e={0}
                          arrCon={arrCon}
                        />
                      </div>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
        <Footer />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  data: state.users.data,
  selected: state.users.selected,
  substance: state.users.substance,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getUser, changing }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Format);
