import { call, put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import {GET_USER,successGetUser,errorGetUser, CHANGING} from "./action";

const base_url = "https://scjp.mojolicious.net";
var getSiteUrl = window.location.hostname

function* getUser(action) {
  try {
    const data = yield call(
      axios.get,
      base_url + "/skin-care-recommendations?_limit=100000000"
    );
   console.log(action.data)
    // action.data.map(a => {
      
    // })


    yield put(successGetUser(data.data))
  
  } catch (data) {
    yield put(errorGetUser(data.data));
  }
}



function* changeIt(action){
  //let set = 
}

export function* watchUsers() {
  yield takeEvery(GET_USER, getUser);
  yield takeEvery(CHANGING, changeIt)
}
