import { applyMiddleware, createStore, compose, combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import usersReducer, {
  initialState as usersInitialState
} from "./clientConfiguration/reducer";

import logger from "redux-logger";
import createSagaMiddleware from "redux-saga";
import rootSaga from "./sagas";

const initState = {
  users: usersInitialState
};

const reducers = combineReducers({
  users: usersReducer
});

const composeEnhancers =
  typeof window === "object" &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
  typeof window !== undefined
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;
const sagaMiddleware = createSagaMiddleware();
const middleware = [logger, sagaMiddleware];
const enhancer = composeEnhancers(
  applyMiddleware(...middleware)
  // other store enhancers if any
);

export const store = createStore(reducers, initState, enhancer);

sagaMiddleware.run(rootSaga);
