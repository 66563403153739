import React from "react";
import ReactDOM from "react-dom";
//import './index.css';
import { Provider } from "react-redux";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import ScrollToTop from "./components/scroll";
//import { BrowserRouter as Router } from "react-router-dom";
import { Router } from 'react-router';
import { store } from "./redux/store";
import history from './history';

ReactDOM.render(
  <Router history={history}>
    <Provider store={store}>
    <ScrollToTop>
      <App />
    </ScrollToTop>
    </Provider>
  </Router>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
