import React, { Component } from "react";
import Footer from "../components/footer";
import Banner from "../components/banner";

class Thanks extends Component {
  componentDidMount() {
    // if (window.performance) {
    //   if (performance.navigation.type == 1) {
    //     window.location.href = "/"
    //   } 
    // }
  }
  render() {
    return (
      <div>
        <Banner
          title={"Thank You!"}
          subtitle={"You're application has been submitted."}
        />
        <div className="container padding-bottom-3x mb-2">
          <div className="row justify-content-center">
            <div className="col-xl-10 col-lg-8">
              <p>
                <center>
                We recommend that you print and/or save a copy of your S.C. Johnson Professional recommendations.
                </center>
              </p>
              
              <div className="form-footer">
                {/* <a
                  className="btn btn-lg btn-rounded btn-secondary"
                  href="#"
                >
                  Print and Save
                </a> */}
                <a
                  className="btn btn-lg btn-rounded btn-primary"
                  href="https://www.scjohnson-professional.com/en-us"
                >
                  Go to S.C. Johnson Professional
                </a>
                {/* <div style={{ paddingTop: "20px" }}>
                  Reference ID: 443413&nbsp;&nbsp;&nbsp;
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Thanks;
